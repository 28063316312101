import {Trans, t} from '@lingui/macro';
import images from 'config/images';
import theme from 'config/theme';
import {Requests, api} from 'fast-sdk';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import Auth from 'interface/stacks/auth/consts';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import type {SSOMethod} from 'store/slices/user/types';
import {
  getPreviouslySignedInMethod,
  getPreviouslySignedInResult,
  handleSignNavigation,
  setPreviouslySignedInResult,
  setPreviouslySignedInMethod as storeSetPreviouslySignedInMethod,
} from 'utils/fast/auth';
import {
  SSO_LOGIN_ORIGIN1,
  SSO_LOGIN_ORIGIN2,
  SSO_LOGIN_ORIGIN3,
} from 'utils/fast/constants';
import {AuthPageTypes, RouteLayout} from '../_layout/RouteLayout';
import {LoginButton} from '../components/LoginButton';
import {LoginButtons} from '../components/LoginButtons';
import {LoginPageError} from '../components/LoginPageError';

type Props = {
  type: AuthPageTypes;
};

export function Signin(props: Props) {
  const navigate = useNavigate();
  const isSignUpPage = props.type === AuthPageTypes.SIGNUP;
  const backgroundColor = theme.colors.neutral.$white;
  const borderBackgroundColor = theme.colors.neutral.$12;
  const dimension = useWindowDimensions();
  const {subdomain} = useSubDomain();
  const [previouslySignedInAccepted, setPreviouslySignedInAccepted] =
    useState<boolean>();
  const [previouslySignedInMethod, setPreviouslySignedInMethod] = useState<
    SSOMethod | ''
  >();

  const currentJoinInvitation = useSelector(
    app.selectors.getCurrentJoinInvitation,
  );

  const organization = useSelector(user.selectors.getSelectedOrganization);
  const dispatch = useDispatch();
  const toast = useToast();

  // const {headerLogo} = useGetAuthHeaderLogo(
  //   currentJoinInvitation?.org ?? organization,
  // );

  const renderInformation = useCallback(() => {
    if (currentJoinInvitation?.org) {
      const {org} = currentJoinInvitation;
      return {
        title: (
          <Text>
            {isSignUpPage ? 'Create an account to join' : 'Sign in to join'}
            {'\n'}
            <Text style={{color: theme.colors.brand.$3}}>{org.name}</Text>
          </Text>
        ),
        subTitle: `Use your work email to ${isSignUpPage ? 'create an account' : 'sign in'}`,
      };
    }

    // if (isSubdomainValid(subdomain) && organization) {
    //   if (isSignUpPage) {
    //     return {
    //       title: (
    //         <Text>
    //           Create an account to join{' '}
    //           <Text style={{color: theme.colors.brand.$3}}>
    //             {organization.name}
    //           </Text>
    //         </Text>
    //       ),
    //       subTitle: 'use your work email to create an account.',
    //       subContent: (
    //         <Text>
    //           Don't have an{' '}
    //           <Text style={{fontWeight: '600'}}>@{subdomain}.fast.io</Text>{' '}
    //           email address? Contact your organization administrator for an
    //           invite
    //         </Text>
    //       ),
    //     };
    //   }

    //   return {
    //     title: (
    //       <Text>
    //         Sign in to{' '}
    //         <Text style={{color: theme.colors.brand.$3}}>
    //           {organization.name}
    //         </Text>
    //       </Text>
    //     ),
    //     subTitle: `${subdomain}.fast.io`,
    //   };
    // }

    if (isSignUpPage) {
      return {
        title: 'Create an account',
        subTitle: 'Select an option to create your Fast.io account.',
      };
    }
    return {
      title: 'Sign in to Fast.io',
      subTitle: 'Enter your email address and password to continue.',
    };
  }, [currentJoinInvitation?.org, subdomain, isSignUpPage, organization]);

  useEffect(() => {
    const getPreviousData = async () => {
      const preMethod = await getPreviouslySignedInMethod();
      const preResult = await getPreviouslySignedInResult();
      setPreviouslySignedInMethod(preMethod as SSOMethod | '');
      setPreviouslySignedInAccepted(preResult ? preResult : false);
    };
    getPreviousData();
    const messageHandler = async event => {
      if (
        event.origin === SSO_LOGIN_ORIGIN1 ||
        event.origin === SSO_LOGIN_ORIGIN2 ||
        event.origin === SSO_LOGIN_ORIGIN3
      ) {
        const receivedData = event.data;
        if (receivedData?.userToken) {
          Requests.setAuthToken(receivedData.userToken);
          await Auth.setAuthToken(receivedData.userToken);

          const userToken = receivedData.userToken;

          if (userToken) {
            dispatch(user.default.actions.setUserToken(userToken));
          }

          const [userRes, availableProfilesRes] = await Promise.all([
            api.user.userDetails(),
            api.user.getAvailableProfiles(),
          ]);

          if (!userRes.result) {
            toast.show(userRes.error?.text, {type: 'danger'});
          }

          dispatch(user.default.actions.setUserDetails(userRes.user));

          if (!availableProfilesRes.result) {
            toast.show(availableProfilesRes.error?.text, {type: 'danger'});
          }

          const {has_orgs, has_workspaces, has_shares} = availableProfilesRes;
          dispatch(
            user.default.actions.setAvailableProfiles({
              hasOrgs: has_orgs,
              hasWorkspaces: has_workspaces,
              hasShares: has_shares,
            }),
          );

          handleSignNavigation(navigate, {
            authToken: userToken,
            user: userRes.user,
            hasOrgs: has_orgs,
            hasWorkspaces: has_workspaces,
            hasShares: has_shares,
          });
        }
        if (receivedData.SSOProvider) {
          await storeSetPreviouslySignedInMethod(receivedData.SSOProvider);
        }
        if (receivedData.SSOResult) {
          await setPreviouslySignedInResult(JSON.parse(receivedData.SSOResult));
        }
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const {title, subTitle} = useMemo(
    () => renderInformation(),
    [renderInformation],
  );

  return (
    <RouteLayout
      title={title}
      subTitle={subTitle}
      // subContent={subContent}
      // headerLogo={headerLogo}
      type={props.type}
    >
      <View style={styles.root}>
        {!previouslySignedInAccepted && previouslySignedInMethod ? (
          <LoginPageError errorOption={previouslySignedInMethod} />
        ) : null}
        <LoginButtons signUpButtons={isSignUpPage} />
        <>
          <View style={styles.orContainer}>
            <Text style={[styles.or, {backgroundColor: backgroundColor}]}>
              <Trans>or</Trans>
            </Text>
            <View
              style={[
                styles.orBorder,
                {
                  backgroundColor: borderBackgroundColor,
                  width: dimension.width > 790 ? '100%' : dimension.width - 40,
                },
              ]}
            />
          </View>
          <LoginButton
            signUpButton
            title={isSignUpPage ? t`Sign up with email` : t`Sign in with email`}
            customRootStyle={{marginBottom: 0}}
            onPress={() =>
              isSignUpPage
                ? navigate('/signup/email')
                : navigate('/signin/email')
            }
            icon={{
              uri: images.blackEmailIcon,
            }}
            height={16}
            width={20}
          />
        </>
      </View>
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: 15,
  },
  orContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: '0.5rem',
    marginVertical: '1.5rem',
  },
  or: {
    position: 'absolute',
    flexWrap: 'nowrap',
    paddingHorizontal: 10,
    zIndex: 1,
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '400',
    color: theme.colors.neutral.$5,
  },
  orBorder: {
    height: 1,
  },
});
