import {t} from '@lingui/macro';
import {api} from 'fast-sdk';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {LinkType} from 'fast-sdk/src/api/storage/consts';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';

export default function useCreateShare() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const createShare = useCallback(
    async (
      workspaceFolderName: string,
      folderParentId: string,
      share: Partial<ShareItem>,
    ) => {
      setIsLoading(true);
      try {
        if (folderParentId) {
          const {result, share: createdShare} = await api.share.createShare(
            workspaceFolderName,
            share,
          );
          if (result) {
            const storage = api.storage.init('workspace', workspaceFolderName);
            await storage.addLink(
              folderParentId,
              LinkType.Share,
              createdShare.id,
            );
            toast.show(t`Created shared folder "${share.title}"`, {
              type: 'neutral',
            });
          }
        } else {
          toast.show(
            t`No parent folder provided to create the shared folder "${share.title}"`,
            {type: 'danger'},
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {
    createShare,
    isLoading,
  };
}
