import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import store, {persistor} from 'store';
import {AppLoading} from './stacks/app/AppLoading';

export interface StoreProps {
  children: JSX.Element;
}

export function Store(props: StoreProps) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<AppLoading />}>
        {props.children}
      </PersistGate>
    </Provider>
  );
}
