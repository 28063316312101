import {t} from '@lingui/macro';
import type {ViewStyle} from 'react-native';
import {CustomTextInput} from './CustomTextInput';

interface Props {
  text: string;
  onChangeText: (text: string) => void;
  label?: string;
  numberOfLines?: number;
  customRootStyle?: ViewStyle;
  placeholder?: string;
}

export const TextArea = ({
  text,
  onChangeText,
  label,
  numberOfLines,
  placeholder,
}: Props) => {
  return (
    <CustomTextInput
      value={text}
      setValue={onChangeText}
      ariaLabel={t`${label}`}
      placeholder={placeholder}
      label={label}
      customProps={{
        multiline: true,
        numberOfLines,
      }}
    />
  );
};
