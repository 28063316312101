import theme from 'config/theme';
import {Avatar} from 'interface/base/Avatar';
import {ProfileMemberItemInvite} from 'interface/stacks/app/ProfileMemberItemInvite';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/user';
import {getHashColor} from 'utils/common/color';

type Props = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePic: string;
  isInvite?: boolean;
};

const MemberAvatarCard = ({
  id,
  firstName,
  lastName,
  email,
  profilePic,
  isInvite,
}: Props) => {
  const user = useSelector(selectors.getUserDetails);
  const isMe = user?.id === id;

  return (
    <View style={styles.info}>
      <View style={styles.avatar}>
        {isInvite ? (
          <ProfileMemberItemInvite id={id} email={email} />
        ) : (
          <Avatar
            color={getHashColor(id)}
            initials={firstName[0].toUpperCase() + lastName[0].toUpperCase()}
            photo={profilePic}
          />
        )}
      </View>
      {!isInvite && (
        <View>
          <Text style={styles.name}>
            {firstName} {lastName} {isMe && '(you)'}
          </Text>
          <Text style={styles.subtitle}>{email}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '30%',
  },
  name: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  avatar: {
    marginRight: 9,
  },
  email: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    width: '25%',
  },
  subtitle: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    color: theme.colors.neutral.$2Base,
  },
});

export default MemberAvatarCard;
