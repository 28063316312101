import theme from 'config/theme';
import {Avatar} from 'interface/base/Avatar';
import {useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {getHashColor} from 'utils/common/color';

interface AccountDropdownAvatarProps {
  id: string;
  photo: string;
  color: string;
  initials: string;
  openProfilePage: () => void;
}

export function AccountDropdownAvatar(props: AccountDropdownAvatarProps) {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <View style={{position: 'relative'}}>
      <Pressable
        style={styles.root}
        onPress={props.openProfilePage}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}>
        <Avatar
          photo={props.photo}
          color={props.color ?? getHashColor(props.id)}
          initials={props.initials}
          size={4}
        />
      </Pressable>
      {isHover ? (
        <Pressable
          style={styles.edit}
          onPress={props.openProfilePage}
          onHoverIn={() => setIsHover(true)}
          onHoverOut={() => setIsHover(false)}>
          <Icon name="pencil" size={16} color={theme.colors.neutral.$white} />
        </Pressable>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderStyle: 'solid',
    borderRadius: 99999,
    width: 64,
    height: 64,
    aspectRatio: 1,
  },
  edit: {
    boxShadow: '0 0 0 2px #fff',
    borderRadius: 100,
    backgroundColor: theme.colors.neutral.$2Base,
    position: 'absolute',
    bottom: -2,
    right: -2,
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: 18,
    fontWeight: '400',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
