import {t} from '@lingui/macro';
import ShareGraphic from 'assets/svgs/shares/share_graphic.svg';
import theme from 'config/theme';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/base/Button';
import {useOrganizationPublicDetails} from 'interface/base/hooks/useOrganizationPublicDetails';
import {StyleSheet, Text, View} from 'react-native';
import {getHashColor} from 'utils/common/color';
import RowCard, {type BorderProps} from './RowCard';

type Props = {
  share: ShareItem;
  onJoinButtonPress?: (share: ShareItem) => Promise<void>;
  borderProps?: BorderProps;
};

export default function ShareInvitation({
  share,
  onJoinButtonPress,
  borderProps,
}: Props) {
  const {org, loading: loadingOrg} = useOrganizationPublicDetails(
    share.parent_org,
  );

  const handleJoinShare = async () => {
    if (onJoinButtonPress) {
      await onJoinButtonPress(share);
    }
  };

  return (
    <RowCard
      disabled
      borderProps={borderProps}
      avatarColor={getHashColor(share.id)}
      avatarPhoto={ShareGraphic}
      avatarImageStyle={styles.avatarImage}
      main={
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.name} numberOfLines={1}>
              {share.title}
            </Text>
            <Text style={styles.domain} numberOfLines={1}>
              {loadingOrg ? '(Loading organization)' : `(${org?.name})`}
            </Text>
          </View>
        </View>
      }
      right={
        <Button
          type="Primary"
          label={t`Join`}
          onPress={handleJoinShare}
          customRootStyle={styles.joinButton}
          ariaLabel="Join Organization"
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  content: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 7,
    marginBottom: 7,
  },
  name: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  domain: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$4,
  },
  joinButton: {
    minWidth: 80,
  },
  avatarImage: {
    width: 32,
    height: 32,
  },
});
