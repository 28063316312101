import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import type {Zoom} from 'interface/stacks/content-viewer/hooks/useZoom';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Image, type ImageResizeMode, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import {FileType, getFileTypeFromMime, typeToIcon} from 'utils/fast/files';
import useFileThumbnailSource from '../hooks/useFileThumbnailSource';

export const MEDIUM_THUMBNAIL_SIZE = 182;

interface FileThumbnailProps {
  item: FilesItem;
  size: FileThumbnailSizes;
  uploading?: boolean;
  options?: {
    backgroundColor?: string;
    previewResizeMode?: ImageResizeMode;
    imageResizeMode?: ImageResizeMode;
    imageZoom?: Zoom;
  };
}

export default function FileThumbnail({
  item,
  size,
  uploading,
  options,
}: FileThumbnailProps) {
  const dispatch = useDispatch();
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  const fileType = useMemo(() => getFileTypeFromMime(item), [item]);
  const fileCacheKey = useMemo(() => `${item.id}-${size}`, [item.id, size]);

  const thumbnailUrl = useSelector(
    files.selectors.selectThumbnail(fileCacheKey),
  );

  const {fetchFileThumbnail} = useFileThumbnailSource();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {threshold: 0},
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    const loadThumbnail = async () => {
      const isFolder = fileType === FileType.Folder;
      if (!thumbnailUrl && isInView && !isFolder) {
        const newThumbnail = await fetchFileThumbnail(item, size, fileType);
        if (newThumbnail) {
          dispatch(
            files.default.actions.setThumbnail({
              thumbnailKey: fileCacheKey,
              source: newThumbnail,
            }),
          );
        }
      }
    };

    if (isInView) {
      loadThumbnail();
    }
  }, [isInView, item.id, size, thumbnailUrl]);

  const containerStyle = useMemo(
    () => [
      styles.container,
      styles[`container${size}`],
      uploading && styles.uploading,
      thumbnailUrl && styles.containerLoaded,
    ],
    [size, thumbnailUrl, uploading],
  );

  const imageStyle = useMemo(
    () => [
      styles.image,
      styles[`image${size}`],
      options?.previewResizeMode && {resizeMode: options?.previewResizeMode},
      options?.backgroundColor && {backgroundColor: options.backgroundColor},
      thumbnailUrl && styles.imageLoaded,
    ],
    [size, options?.backgroundColor, options?.previewResizeMode, thumbnailUrl],
  );

  const typeIconSource = useMemo(
    () => ({uri: typeToIcon(fileType)}),
    [fileType],
  );

  return (
    <View ref={ref} style={containerStyle}>
      <Image
        style={[imageStyle, styles[`fallback${size}`]]}
        source={typeIconSource}
        resizeMode="center"
      />
      {thumbnailUrl && (
        <Image
          style={[
            imageStyle,
            styles.thumbnailImage,
            options?.imageZoom && {transform: [{scale: options.imageZoom}]},
          ]}
          source={{uri: thumbnailUrl}}
          resizeMode={options?.imageResizeMode}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  uploading: {
    opacity: 0.5,
  },
  image: {
    resizeMode: 'contain',
  },
  containerLoaded: {
    padding: 0,
  },
  imageLoaded: {
    height: '100%',
    width: '100%',
  },
  thumbnailImage: {
    ...StyleSheet.absoluteFillObject,
  },
  [`container${FileThumbnailSizes.Small}`]: {
    width: 36,
    height: 36,
    padding: 5,
  },
  [`image${FileThumbnailSizes.Small}`]: {
    width: 28,
    height: 28,
  },
  [`container${FileThumbnailSizes.Medium}`]: {
    width: MEDIUM_THUMBNAIL_SIZE,
    height: MEDIUM_THUMBNAIL_SIZE,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$15,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$border,
  },
  [`image${FileThumbnailSizes.Medium}`]: {
    width: 40,
    height: 40,
  },
  [`container${FileThumbnailSizes.Preview}`]: {
    width: '100%',
    height: '100%',
  },
  [`image${FileThumbnailSizes.Preview}`]: {
    width: 84,
    height: 74,
    resizeMode: 'center',
  },
  [`fallback${FileThumbnailSizes.Preview}`]: {
    tintColor: theme.colors.neutral.$6,
  },
});
