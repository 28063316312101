import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {handleApiError} from 'errors';
import {Requests, api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import * as user from 'store/slices/user';
import {AppLoading} from '../app/AppLoading';
import Auth from '../auth/consts';
import {useLayoutWorkspaces} from '../workspace/hooks/useLayoutWorkspaces';
import useSubdomainAuth from './useSubdomainAuth';

export function FromSubdomain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {getToken, loaded} = useSubdomainAuth();

  const {subdomain} = useSubDomain();
  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();

  const getUserInfo = async (token: string) => {
    Requests.setAuthToken(token);
    try {
      const {result, org} =
        await api.organization.getOrganizationDetails(subdomain);

      if (result) {
        const [userResponse, orgResponse] = await Promise.all([
          api.user.userDetails(),
          api.organization.getListOfAccessibleOrganizations(),
          fetchAndUpdateWorkspaces(),
        ]);
        const {result: userResult, user: userDetails} = userResponse;
        const {orgs} = orgResponse;

        if (userResult) {
          dispatch(user.default.actions.setUserDetails(userDetails));
          await Auth.setAuthToken(token);
        }

        dispatch(user.default.actions.setSelectedOrganization({org}));

        dispatch(
          user.default.actions.setOrganizationsList({
            orgsList: orgs,
          }),
        );
      } else {
        navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
      }
    } catch (err) {
      handleApiError({
        code: ApiErrors.CorsError,
        text: 'There is a CORS error, please click on the try again button',
      });
    }
  };

  useEffect(() => {
    if (loaded) {
      const token = getToken();
      if (token) {
        dispatch(user.default.actions.setUserToken(token));
        async function fetchUserSession() {
          try {
            await getUserInfo(token);
          } finally {
            navigate(
              `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`,
              {replace: true},
            );
          }
        }

        fetchUserSession();
      } else {
        navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
      }
    }
  }, [loaded]);

  return <AppLoading />;
}
