import {t} from '@lingui/macro';
import {DEFAULT_INVITE_SHARE_MESSAGE} from 'constants/invites';
import {api} from 'fast-sdk';
import {AddEmailTextArea} from 'interface/base/AddEmailTextArea';
import {FooterActions} from 'interface/base/Modal';
import {TextArea} from 'interface/base/TextArea';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import type {Share} from 'store/slices/shared/types';

interface Props {
  share: Share;
  toogleCurrentView: () => void;
}

const MEMBER_PERMISSION = 'guest';

export function AddRecipientModalContent({share, toogleCurrentView}: Props) {
  const toast = useToast();

  const [emails, setEmails] = useState<Array<string>>([]);
  const [message, setMessage] = useState<string>(undefined);
  const [sendingInvites, setSendingInvites] = useState(false);

  const inviteMembers = async () => {
    setSendingInvites(true);
    try {
      if (emails) {
        const successfulEmails = [];
        const errorEmails = [];
        for (const email of emails) {
          const {result} = await api.share.addShareMember(
            share.id,
            email,
            MEMBER_PERMISSION,
            message ?? DEFAULT_INVITE_SHARE_MESSAGE,
          );
          if (result) {
            successfulEmails.push(email);
          } else {
            errorEmails.push(email);
          }
        }
        if (successfulEmails.length === emails.length)
          toast.show(
            t`Existing org members added to share, email invites sent to new members`,
            {type: 'neutral'},
          );
        else
          toast.show(t`Unable to send emails to: ${errorEmails.join(', ')}`, {
            type: 'danger',
          });
      }
      setEmails([]);
    } finally {
      setSendingInvites(false);
      toogleCurrentView();
    }
  };

  const onCancel = () => {
    toogleCurrentView();
  };

  return (
    <View style={styles.root}>
      <AddEmailTextArea
        emails={emails}
        setEmails={setEmails}
        numberOfLines={4}
        customRootStyle={{marginBottom: 10}}
      />
      <TextArea
        text={message}
        onChangeText={setMessage}
        label={t`Message (optional)`}
        numberOfLines={4}
        placeholder={t`Enter a message...`}
      />
      <FooterActions
        actionButtonLabel={t`Send Invites`}
        isFormInvalid={emails.length === 0}
        onSave={inviteMembers}
        onCancel={onCancel}
        isActionLoading={sendingInvites}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {},
});
