import LogoDark from 'assets/fastio_logo_assets/logo_dark.svg';
import LogoLight from 'assets/fastio_logo_assets/logo_light.svg';
import {Image, StyleSheet} from 'react-native';

interface AppLogoProps {
  kind?: 'light' | 'dark';
}

export default function AppLogo({kind = 'light'}: AppLogoProps) {
  return (
    <Image
      source={kind === 'light' ? LogoLight : LogoDark}
      style={styles.logo}
      alt="Fast.io logo"
    />
  );
}

const styles = StyleSheet.create({
  logo: {
    width: 129,
    height: 48,
  },
});
