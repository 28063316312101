import {t} from '@lingui/macro';
import {Transfers} from 'fast-sdk';
import {createRef, memo, useEffect, useMemo} from 'react';
import {StyleSheet, View, useWindowDimensions} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import theme from 'config/theme';
import {WORKSPACE_TRASH} from 'constants/routes';
import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {Loading} from 'interface/base/Loading';
import {Mouse} from 'interface/base/Mouse';
import {NoResultsAvailable} from 'interface/base/NoResultsAvailable';
import {AppMemberCursor} from 'interface/stacks/app/AppMemberCursor';
import {AppHeader} from 'interface/stacks/app/layout/AppHeader';
import {combineNodesAndUploads} from 'interface/stacks/uploads/utils/filelist';
import files, {selectors} from 'store/slices/files';
import {load} from 'utils/fast/storage';

import {WorkspaceFrame} from '../base/WorkspaceFrame';
import {WorkspaceTabList} from '../layout/WorkspaceTabs';
import {SharedCustomizeDialog} from '../shared/dialogs/SharedCustomizeDialog';
import {FileList} from './FileList';
import {FolderToolbar} from './FolderToolbar';
import {PreviewSidePanel} from './PreviewSidePanel';
import {StorageToolbar} from './StorageToolbar';
import {WorkspaceActivityTray} from './WorkspaceActivityTray';
import {useContextMenu} from './hooks/useContextMenu';
import {useDragDrop} from './hooks/useDragDrop';
import {useModalCreateFolder} from './hooks/useModalCreateFolder';
import {useWorkspaceContext} from './hooks/useWorkspaceContext';

export interface FolderContentProps {
  id: string;
}

export const FolderContents = memo((props: FolderContentProps) => {
  const {workspace, uploader, multiplayer, hasFixedMenu} =
    useWorkspaceContext();

  const modal = useModal();
  const popup = usePopup();
  const screen = useWindowDimensions();
  const localItems = useSelector(selectors.getItems);
  const localView = useSelector(
    selectors.getFolder('workspace', workspace.folder_name, props.id),
  );

  const [view, items] = useMemo(
    () =>
      combineNodesAndUploads(
        localView,
        localItems,
        uploader.uploads,
        workspace.id,
        props.id,
      ),
    [localView, localItems, uploader.uploads, workspace.id, props.id],
  );

  const isTrash = props.id === WORKSPACE_TRASH;
  const isMinimal = screen.width < 600;
  const sizeActual = view ? view.length : 0;
  const folder = localItems[props.id];

  const dispatch = useDispatch();

  const clearSelection = () => {
    dispatch(files.actions.select({ids: []}));
  };

  const addFilesFromLocal = async () => {
    const files = await Transfers.pickFiles();
    uploader.instance.addFiles(files, props.id, workspace.id);
  };

  const openCreateShareDialog = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspace.folder_name}
        folderParentId={props.id}
        close={modal.close}
      />,
    );
  };

  const dragDrop = useDragDrop({
    instanceId: workspace.id,
    uploader,
  });

  const modalCreateFolder = useModalCreateFolder(
    props.id,
    workspace.folder_name,
    'workspace',
  );

  const menuRef = createRef<any>();
  const menuFiles = useContextMenu({
    noDividers: true,
    ignoreMultiSelect: true,
    headerText: workspace.name,
    uploadHereOnPress: addFilesFromLocal,
    createShareOnPress: openCreateShareDialog,
    createFolderOnPress: modalCreateFolder.open,
  });

  // Load folder contents when workspace or folder changes
  useEffect(() => {
    load(dispatch, props.id, workspace.folder_name, 'workspace');
  }, [props.id, workspace.folder_name]);

  return (
    <View style={styles.root}>
      <Mouse
        onClick={clearSelection}
        onDragEnter={e => dragDrop.enter(e, props.id)}
        onDragLeave={e => dragDrop.leave(e)}
        onDragDrop={e =>
          dragDrop.drop(e, props.id, undefined, workspace.id, folder?.id)
        }
        onContextMenu={e => {
          e?.preventDefault();
          e?.stopPropagation();
          if (popup) {
            if ('nativeEvent' in e) popup.setPosition({x: e.pageX, y: e.pageY});
            popup.setRef(menuRef);
          }
          menuFiles.open();
        }}>
        <View style={[styles.flex, isMinimal && styles.minimal]}>
          <AppHeader
            id={props.id}
            members={multiplayer?.members}
            search
            activity
          />
          <WorkspaceTabList workspaceAlt={workspace.folder_name} />
          <View style={styles.content}>
            <View style={styles.flex}>
              <FolderToolbar
                id={props.id}
                count={sizeActual}
                members={multiplayer?.members}
                root={['root', workspace.name]}
              />
              <StorageToolbar item={folder} />
              <View style={styles.lists}>
                <View style={styles.list}>
                  <FileList
                    view={view}
                    items={items}
                    multiplayer={multiplayer}
                    root={['root', workspace.name]}
                    options={{
                      disableOpen: isTrash,
                      disableDrag: isTrash,
                      disableActions: isTrash,
                    }}
                    renderEmpty={() => (
                      <NoResultsAvailable
                        message={
                          isTrash
                            ? t`Workspace trash is empty.`
                            : t`No folders or files added yet.`
                        }
                      />
                    )}
                    renderLoading={() => (
                      <View style={styles.loading}>
                        <Loading />
                      </View>
                    )}
                  />
                </View>
              </View>
            </View>
            <WorkspaceActivityTray workspaceId={workspace.folder_name} />
            <PreviewSidePanel workspaceId={workspace.folder_name} />
          </View>
        </View>
        {multiplayer?.cursors?.map(cursor => (
          <AppMemberCursor
            key={cursor.id}
            hasMenu={hasFixedMenu}
            localState={multiplayer?.localState}
            {...cursor}
          />
        ))}
        {dragDrop.dropTarget === props.id && (
          <WorkspaceFrame mode="upload" color={theme.colors.brand.$4Base} />
        )}
      </Mouse>
    </View>
  );
});

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  minimal: {
    marginHorizontal: 0,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
  lists: {
    flex: 1,
    flexDirection: 'row',
  },
  list: {
    flex: 1,
    margin: 0,
    flexDirection: 'row',
  },
  loading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
