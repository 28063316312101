import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import useHost from 'interface/base/hooks/useHost';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {isSubdomainValid} from 'utils/common/platform';

export default function useNavigateToSelectOrg(close: () => void) {
  const {domain, protocol, loaded} = useHost();
  const {subdomain} = useSubDomain();
  const navigate = useNavigate();

  const navigateToSelectOrg = () => {
    if (!loaded) {
      return;
    }
    if (isSubdomainValid(subdomain)) {
      const url = `${protocol}://${domain}/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`;
      window.location.href = url.replace(subdomain, 'go');
    } else {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`);
    }
    close();
  };
  return navigateToSelectOrg;
}
