import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Requests, api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {Button} from 'interface/base/Button';
import {CustomTextInput} from 'interface/base/CustomTextInput';
import {FormError} from 'interface/base/FormError';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import Auth from 'interface/stacks/auth/consts';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import user, {selectors as userSelectors} from 'store/slices/user';
import {validateEmail} from 'utils/common/validation';
import {handleSignNavigation} from 'utils/fast/auth';
import {AuthPageTypes, RouteLayout} from '../_layout/RouteLayout';
import {PasswordInput} from '../components/PasswordInput';

export function SigninWithEmail() {
  const {subdomain} = useSubDomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const currentJoinInvitation = useSelector(
    app.selectors.getCurrentJoinInvitation,
  );
  const organization = useSelector(userSelectors.getSelectedOrganization);

  // const {headerLogo} = useGetAuthHeaderLogo(
  //   currentJoinInvitation?.org ?? organization,
  // );

  const renderInformation = useCallback(() => {
    if (currentJoinInvitation) {
      const {org} = currentJoinInvitation;
      if (org)
        return {
          title: (
            <Text>
              Sign in to join
              {'\n'}
              <Text style={{color: theme.colors.brand.$3}}>{org.name}</Text>
            </Text>
          ),
          subTitle: 'Use your work email to sign in',
        };
    }
    // if (isSubdomainValid(subdomain) && organization) {
    //   return {
    //     title: (
    //       <Text>
    //         Sign in to{' '}
    //         <Text style={{color: theme.colors.brand.$3}}>
    //           {organization.name}
    //         </Text>
    //       </Text>
    //     ),
    //     subTitle: <Text>{subdomain}.fast.io</Text>,
    //   };
    // }
    return {
      title: 'Sign in to Fast.io',
      subTitle: 'Enter your email address and password to continue',
    };
  }, [subdomain, currentJoinInvitation, organization]);

  useEffect(() => {
    if (email) {
      if (validateEmail(email)) {
        setEmailIsValid(true);
      } else setEmailIsValid(false);
    } else {
      setEmailIsValid(false);
    }
  }, [email]);

  const submit = async () => {
    setErrorMessage(undefined);
    setLoading(true);

    try {
      const signInRes = await api.auth.signIn({email: email, password});

      if (!signInRes.result) {
        return setErrorMessage(signInRes.error?.text);
      }

      // Store the user token in indexedDB and the SDK Authorizations
      Requests.setAuthToken(signInRes.auth_token);
      await Auth.setAuthToken(signInRes.auth_token);
      await Auth.setUserEmail(email);
      dispatch(user.actions.setUserToken(signInRes.auth_token));

      const [userRes, availableProfilesRes] = await Promise.all([
        api.user.userDetails(),
        api.user.getAvailableProfiles(),
      ]);

      if (userRes.error?.code === ApiErrors.UserNotValidated) {
        return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`);
      }

      if (!userRes.result) {
        return setErrorMessage(userRes.error?.text);
      }

      dispatch(user.actions.setUserDetails(userRes.user));

      if (!availableProfilesRes.result) {
        return setErrorMessage(availableProfilesRes.error?.text);
      }

      const {has_orgs, has_workspaces, has_shares} = availableProfilesRes;
      dispatch(
        user.actions.setAvailableProfiles({
          hasOrgs: has_orgs,
          hasWorkspaces: has_workspaces,
          hasShares: has_shares,
        }),
      );

      handleSignNavigation(navigate, {
        authToken: signInRes.auth_token,
        user: userRes.user,
        hasOrgs: has_orgs,
        hasWorkspaces: has_workspaces,
        hasShares: has_shares,
      });
    } finally {
      setLoading(false);
    }
  };

  const {title, subTitle} = useMemo(
    () => renderInformation(),
    [renderInformation],
  );

  return (
    <RouteLayout
      title={title}
      subTitle={subTitle}
      // headerLogo={headerLogo}
      type={AuthPageTypes.SIGNIN}
    >
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <View style={styles.container}>
        <CustomTextInput
          placeholder={t`Enter your email address`}
          value={email}
          setValue={setEmail}
          disabled={loading}
          isInValid={!(!email || validateEmail(email))}
          errorMessage={t`Invalid email address`}
          customInputStyle={styles.input}
          ariaLabel="Email Address"
          size="Large"
        />
        <PasswordInput
          password={password}
          setPassword={setPassword}
          disabled={loading}
        />
        <Button
          label={t`Continue`}
          onPress={submit}
          disabled={!emailIsValid || !password}
          type={'Primary'}
          customRootStyle={{
            width: '100%',
            borderRadius: 5,
            height: 48,
          }}
          customTextStyle={styles.buttonText}
          loading={loading}
          customLoadingColor={theme.colors.neutral.$white}
        />
      </View>
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
  },
  input: {
    width: '100%',
    height: 48,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
