import {api} from 'fast-sdk';
import type {BillingUsageMember} from 'fast-sdk/src/api/billing/consts';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useEffect, useState} from 'react';

export default function useBillingMembers() {
  const [members, setMembers] = useState<Array<BillingUsageMember>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {subdomain} = useSubDomain();

  useEffect(() => {
    setLoading(true);

    async function fetchMembers() {
      const response = await api.billing.getBillingUsageMembers(subdomain);

      if (response.result) {
        const members = response.billable_members
          ? Object.values(response.billable_members)
          : [];

        setMembers(members);
      }

      setLoading(false);
    }

    if (subdomain) {
      fetchMembers();
    }
  }, [subdomain]);

  return {
    members,
    loading,
  };
}
