import {Trans, t} from '@lingui/macro';
import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useLocation, useNavigate} from 'extensions/navigation';
import {Popup} from 'extensions/viewport/Popup';
import {useModal} from 'extensions/viewport/useModal';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {Divider} from 'interface/base/Divider';
import {IconButton} from 'interface/base/IconButton';
import {Loading} from 'interface/base/Loading';
import useHasExternalWorkspacesShares from 'interface/base/hooks/useHasExternalWorkspacesShares';
import {useShares} from 'interface/base/hooks/useShares';
import {WorkspaceCreate} from 'interface/stacks/workspace/dialogs/WorkspaceCreate';
import useUserWorkspaces from 'interface/stacks/workspace/hooks/useUserWorkspaces';
import {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import {AppSideNavFooter} from './AppSideNavFooter';
import {AppSideNavHeader} from './AppSideNavHeader';
import {AppSideNavItem} from './AppSideNavItem';
import {AppSideNavOrphanShareItem} from './AppSideNavOrphanShareItem';
import AppSideNavOrphanWorkspaceItem from './AppSideNavOrphanWorkspaceItem';
import {AppSideNavWorkspaceList} from './AppSideNavWorkspaceList';
import {WorkspacePopup} from './WorkspacePopup';

export default function AppSideNav() {
  const modal = useModal();
  const location = useLocation();
  const navigate = useNavigate();

  const locationPathName = location.pathname.toLowerCase();

  const {workspaces} = useUserWorkspaces();
  const {fetchAndUpdateShares, loading: loadingShares} = useShares();
  const {
    hasExternalWorkspacesOrShares,
    hasExternalWorkspaces,
    hasExternalShares,
  } = useHasExternalWorkspacesShares();

  const workspacesLoading = useSelector(
    workspace.selectors.getWorkspacesLoading,
  );
  const selectedOrganization = useSelector(
    user.selectors.getSelectedOrganization,
  );
  const externalWorkspaces = useSelector(
    workspace.selectors.getExternalWorkspaces,
  );
  const externalShares = useSelector(
    shared.selectors.getWorkspaceShares('externals'),
  );
  const sharesLoaded = useSelector(shared.selectors.getSharesLoaded);

  const isSharedWithMe = !selectedOrganization;

  const isInboxEnabled = useFlag('view-inbox');
  const isInboxActive = locationPathName.startsWith(
    `/${ROUTES.LOGGED_IN_WITH_ORG.INBOX}`,
  );

  const [isAddWorkspacePopupOpen, setIsAddWorkspacePopupOpen] = useState(false);

  const handleCreateWorkspace = () => {
    setIsAddWorkspacePopupOpen(false);
    modal.open(<WorkspaceCreate close={modal.close} />);
  };

  useEffect(() => {
    if (!sharesLoaded) {
      fetchAndUpdateShares();
    }
  }, [sharesLoaded]);

  const renderInboxItem = () =>
    isInboxEnabled && (
      <View style={styles.inboxNavItem}>
        <AppSideNavItem
          link={`/${ROUTES.LOGGED_IN_WITH_ORG.INBOX}`}
          label={t`Inbox`}
          state={isInboxActive ? 'Active' : 'Default'}
          icon={
            <Icon
              name="inbox"
              size={20}
              color={
                isInboxActive
                  ? theme.colors.neutral.$white
                  : theme.colors.neutral.$5
              }
            />
          }
        />
      </View>
    );

  const renderHeader = (title: string, includeAddButton = false) => (
    <View style={styles.heading}>
      <Text style={styles.label}>{title}</Text>
      {includeAddButton && (
        <Popup
          placement="right-start"
          isOpen={isAddWorkspacePopupOpen}
          close={() => setIsAddWorkspacePopupOpen(false)}
          triggerElement={
            <IconButton
              buttonStyle={styles.menuIconButton}
              iconStyle={styles.menuButton}
              buttonHoverStyle={styles.menuIconButtonHovered}
              onPress={() => setIsAddWorkspacePopupOpen(true)}
              icon={
                <Icon
                  name="add"
                  size={20}
                  color={theme.colors.neutral.$white}
                />
              }
            />
          }>
          <WorkspacePopup
            createWorkspace={handleCreateWorkspace}
            browseWorkspaces={() => {
              setIsAddWorkspacePopupOpen(false);
              navigate('/browse-workspaces');
            }}
          />
        </Popup>
      )}
    </View>
  );

  return (
    <View style={styles.root}>
      <AppSideNavHeader />
      {renderInboxItem()}
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <View>
          {!isSharedWithMe && (
            <View style={styles.section}>
              <Text style={styles.sectionTitleText}>
                {selectedOrganization.name}
              </Text>
              <View>
                {renderHeader(t`Workspaces`, true)}
                {workspacesLoading && !workspaces.length && (
                  <Loading size="small" />
                )}
                <AppSideNavWorkspaceList workspaces={workspaces} />
              </View>
            </View>
          )}
          {hasExternalWorkspacesOrShares && (
            <View style={styles.divider}>
              <Divider color={theme.colors.neutral.$25} />
            </View>
          )}
          {hasExternalWorkspacesOrShares && (
            <View style={styles.section}>
              <Text style={styles.sectionTitleText}>
                <Trans>Shared with Me</Trans>
              </Text>
              {hasExternalWorkspaces && (
                <View style={styles.workspaces}>
                  {renderHeader(t`Workspaces`)}
                  {workspacesLoading && !workspaces.length && (
                    <Loading size="small" />
                  )}
                  {!workspacesLoading &&
                    externalWorkspaces.map(workspace => (
                      <AppSideNavOrphanWorkspaceItem
                        key={workspace.id}
                        workspace={workspace}
                      />
                    ))}
                </View>
              )}
              {!sharesLoaded && loadingShares ? (
                <Loading size="small" />
              ) : (
                hasExternalShares && (
                  <View style={styles.shares}>
                    {renderHeader(t`Shares`)}
                    {Object.values(externalShares).map((share: ShareItem) => (
                      <AppSideNavOrphanShareItem key={share.id} share={share} />
                    ))}
                  </View>
                )
              )}
            </View>
          )}
        </View>
      </ScrollView>
      <AppSideNavFooter showAppStatus />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.colors.neutral.$1,
  },
  workspaces: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingVertical: 10,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 7,
    paddingLeft: 25,
    paddingRight: 15,
    marginBottom: 5,
  },
  label: {
    flex: 1,
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '700',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$white,
  },
  menuButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    borderRadius: 3,
  },
  menuIconButton: {
    minWidth: 40,
  },
  menuIconButtonHovered: {
    backgroundColor: theme.colors.neutral.$2Base,
  },
  inboxNavItem: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$25,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$25,
    paddingVertical: 10,
  },
  shares: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingVertical: 10,
  },
  section: {
    paddingBottom: 5,
    paddingTop: 10,
  },
  sectionTitleText: {
    fontSize: 11,
    fontWeight: '700',
    lineHeight: 12,
    color: theme.colors.neutral.$6,
    paddingHorizontal: 25,
  },
  divider: {
    paddingVertical: 15,
  },
});
