import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch} from 'react-redux';
import * as app from 'store/slices/app';
import LoadingImage from './LoadingImage';

export default function LoadingTimeoutPrompt() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleReload = async () => {
    try {
      setIsLoading(true);
      dispatch(app.default.actions.setForceLogout(true));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.root}>
      <LoadingImage />
      <Text style={styles.text}>
        <Trans>
          Is Fastio taking a long time to load? Try reloading the page.
        </Trans>
      </Text>
      <Button
        type="Primary"
        onPress={handleReload}
        label={'Reload Fastio'}
        loading={isLoading}
        lIcon={
          <MCIcon
            name={'refresh'}
            size={20}
            color={theme.colors.neutral.$white}
          />
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
    backgroundColor: theme.colors.neutral.$15,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    padding: 20,
    gap: 20,
  },
  text: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 22,
    paddingVertical: 10,
    width: 240,
  },
});
