import {ROUTES, STORAGE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {useParams} from 'extensions/navigation';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {WorkspaceBadge} from 'interface/base/WorkspaceBadge';
import WorkspaceOrgBadge from 'interface/base/WorkspaceOrgBadge';
import type {MouseEvent} from 'react';
import {View} from 'react-native';
import useWorkspaceMenuSettings from '../hooks/useWorkspaceMenuSettings';
import {AppSideNavItem} from './AppSideNavItem';

interface AppSideNavWorkspaceItemProps {
  workspace: WorkspaceListDetail;
  currentUserId: string;
  isExternal?: boolean;
}

export function AppSideNavWorkspaceItem({
  workspace,
  currentUserId,
  isExternal = false,
}: AppSideNavWorkspaceItemProps) {
  const {workspaceId: activeWorkspaceId} = useParams();

  const {handleOpenMenu} = useWorkspaceMenuSettings(workspace, currentUserId);

  const storageUrl = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/${WORKSPACE_STORAGE}/${STORAGE_ROOT}`;

  const handleRightClick = (event: MouseEvent) => {
    if (isExternal) {
      return;
    }

    event.preventDefault();
    handleOpenMenu(event);
  };

  return (
    // @ts-expect-error
    <View onContextMenu={handleRightClick}>
      <AppSideNavItem
        link={storageUrl}
        label={workspace.name}
        state={
          workspace.folder_name === activeWorkspaceId ? 'Active' : 'Default'
        }
        icon={
          isExternal ? (
            <WorkspaceOrgBadge
              id={workspace.id}
              domain={workspace.org_domain}
            />
          ) : (
            <WorkspaceBadge id={workspace.id} />
          )
        }
      />
    </View>
  );
}
