import {slices} from 'store';
import {load} from 'utils/fast/storage';
import {loadSearch, syncFolder} from 'utils/mediafire';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {Dispatch} from 'redux';
import type {FilesItem, FilesPick, Store} from 'store/slices/files/types';

// GENERAL

export function layout(dispatch: Dispatch) {
  return () => {
    dispatch(slices.files.actions.layout());
  };
}

export function sort(dispatch: Dispatch, id: string) {
  return (sort: Store['sort'], filter: Store['filter']) => {
    dispatch(slices.files.actions.sort(sort));
    syncFolder(dispatch, id, -1, -1, -1, sort, filter);
  };
}

export function filter(dispatch: Dispatch, id: string) {
  return (sort: Store['sort'], filter: Store['filter']) => {
    dispatch(slices.files.actions.filter(filter));
    syncFolder(dispatch, id, -1, -1, -1, sort, filter);
  };
}

export function search(
  dispatch: Dispatch,
  workspaceId: string,
  id: string | null,
  term?: string,
) {
  return () => {
    const query = term && typeof term === 'string' && term.length >= 3 && term;
    query && loadSearch(dispatch, id, query);
    dispatch(slices.files.actions.search({id, query, workspaceId}));
  };
}

export function downloadFile(url: string, forceDownload?: true) {
  return (e: any) => {
    e.stopPropagation?.();
  };
}

// Picker

export function pick(dispatch: Dispatch, context: FilesPick) {
  return (e?: any) => {
    e?.stopPropagation?.();
    dispatch(slices.files.actions.pick(context));
  };
}

export function pickClear(dispatch: Dispatch) {
  dispatch(slices.files.actions.pick(null));
}

export function pickSubmit(
  pick: FilesPick,
  dispatch: Dispatch,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  return (item?: FilesItem) => {
    if (item) {
      load(dispatch, item.id, instanceId, instanceNs);
    }
    if (pick) {
      const {ids, action} = pick;
      dispatch(
        slices.files.actions.pick({
          ids,
          action,
          destination: item.id,
        }),
      );
    } else {
      dispatch(slices.files.actions.pick(null));
    }
  };
}

// SELECTING

export function selectClear(dispatch: Dispatch, id: string | null) {
  return (e: any) => {
    dispatch(slices.files.actions.select({ids: id ? [id] : []}));
    e.stopPropagation?.();
  };
}
