import {t} from '@lingui/macro';
import theme from 'config/theme';
import {CustomTextInput} from 'interface/base/CustomTextInput';
import {IconButton} from 'interface/base/IconButton';
import ProfilePhotoPicker from 'interface/base/ProfilePhotoPicker';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {default as CommunityIcon} from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import Layout from './Layout';

interface Props {
  next: () => void;
}

export default function AddProfilePicture(props: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const firstName = useSelector(user.selectors.getUserFirstName);
  const lastName = useSelector(user.selectors.getUserLastName);
  const {imgSrc} = useSelector(onboarding.selectors.getUserData);
  const [fullname, setFullname] = useState(() => `${firstName} ${lastName}`);
  const dispatch = useDispatch();

  const onCropImage = (file: File, src: string) => {
    dispatch(
      onboarding.default.actions.setUserData({
        user: {
          imgFile: file,
          imgSrc: src,
          imgType: file.type,
          imgName: file.name,
        },
      }),
    );
  };

  const next = () => {
    const [newFirstName, newLastName] = fullname.split(' ');
    dispatch(
      onboarding.default.actions.setUserData({
        user: {
          firstName: newFirstName || '',
          lastName: newLastName || '',
        },
      }),
    );
    props.next();
  };

  return (
    <Layout
      title="Welcome to Fastio!"
      subtitle="This is what your profile will look like to your teammates in the Fastio app. You can change your name and profile picture now or later."
      next={next}>
      <View style={styles.root}>
        <ProfilePhotoPicker
          fullname={fullname}
          hideButtons={isEditing}
          profilePicture={imgSrc}
          onCropImage={onCropImage}>
          {isEditing ? (
            <CustomTextInput
              placeholder={t`Enter your name`}
              customProps={{
                autoFocus: true,
                onKeyPress: e =>
                  e.nativeEvent.key === 'Escape' && setIsEditing(false),
              }}
              value={fullname}
              setValue={setFullname}
              ariaLabel="Full name"
              size="Large"
              onBlur={() => setIsEditing(false)}
            />
          ) : (
            <View style={styles.nameRoot}>
              <Text style={styles.nameText}>{fullname}</Text>
              <IconButton
                icon={
                  <CommunityIcon
                    name="pencil"
                    size={20}
                    color={theme.colors.neutral.$5}
                  />
                }
                buttonStyle={styles.editIcon}
                onPress={() => setIsEditing(true)}
              />
            </View>
          )}
        </ProfilePhotoPicker>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 42,
  },
  right: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  emptyPhoto: {
    position: 'relative',
    width: 96,
    height: 96,
    borderRadius: 100,
    backgroundColor: '#6524FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadedPhoto: {
    width: 96,
    height: 96,
    borderRadius: 100,
    borderWidth: 3,
    borderColor: '#6524FF',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  initial: {
    fontSize: 24,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
    textTransform: 'uppercase',
  },
  button: {
    maxWidth: 108,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
  },
  image: {
    width: 96,
    height: 96,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addIcon: {
    borderRadius: 50,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$white,
    position: 'absolute',
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.neutral.$white,
  },
  nameRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 12,
  },
  nameText: {
    fontSize: 21,
    lineHeight: 34,
    fontWeight: '600',
    color: theme.colors.neutral.$2Base,
  },
  editIcon: {
    minWidth: 36,
    minHeight: 36,
  },
});
