import Modal from 'interface/base/Modal';
import {useState} from 'react';
import useGetShareDetails from '../hooks/useGetShareDetails';
import {AddRecipientModalContent} from './AddRecipientModalContent';
import {ShareSettings} from './ShareSettings';

interface Props {
  shareId: string;
  workspaceFolderName: string;
  done: () => void;
  displayTitle?: boolean;
}

enum ShareView {
  ShareSettings = 'shareSettings',
  AddRecipient = 'addRecipient',
}

export function ShareModal({
  shareId,
  workspaceFolderName,
  done,
  displayTitle = true,
}: Props) {
  const {share} = useGetShareDetails({shareId, workspaceFolderName});

  const [currentView, setCurrentView] = useState<ShareView>(
    ShareView.ShareSettings,
  );

  const toogleCurrentView = () => {
    setCurrentView(
      currentView === ShareView.ShareSettings
        ? ShareView.AddRecipient
        : ShareView.ShareSettings,
    );
  };

  return (
    <Modal
      title={`Share Settings${displayTitle ? ` - ${share?.name}` : ''}`}
      close={done}>
      {currentView === ShareView.ShareSettings ? (
        <ShareSettings
          share={share}
          toogleCurrentView={toogleCurrentView}
          done={done}
        />
      ) : (
        <AddRecipientModalContent
          share={share}
          toogleCurrentView={toogleCurrentView}
        />
      )}
    </Modal>
  );
}
