import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';

interface Props {
  title: string;
  tax: number;
  free: boolean;
  freeDays: number;
  priceBase: number;
  billed: string;
  discount: number;
}

export default function BillingPlanDetails(props: Props) {
  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View style={styles.headerTextContainer}>
          <Text selectable style={styles.headerText}>
            <Trans>{props.title}</Trans>
          </Text>
        </View>
        <View style={styles.headerPriceContainer}>
          <Text selectable style={styles.headerPriceText}>
            <Trans>{props.freeDays} day free trial</Trans>
          </Text>
          <Text selectable style={styles.headerPriceUnitText}>
            <Trans>{`$${props.priceBase} per user / month after`}</Trans>
          </Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Text selectable style={styles.contentHeaderText}>
          <Trans>{`Recurring ${props.billed} cost`}</Trans>
        </Text>
        <View style={styles.contentItem}>
          <Text selectable style={styles.contentItemText}>
            <Trans>Subtotal</Trans>
          </Text>
          <Text selectable style={styles.contentItemText}>
            ${props.priceBase}
          </Text>
        </View>
        {props.discount && (
          <View style={styles.contentItem}>
            <Text selectable style={styles.contentItemText}>
              <Trans>Discount (annual billing)</Trans>
            </Text>
            <Text
              selectable
              style={[
                styles.contentItemText,
                {color: theme.colors.brand.$4Base},
              ]}>
              {`-$${props.discount}`}
            </Text>
          </View>
        )}
        <View style={styles.contentItem}>
          <Text style={styles.contentItemText}>
            <Trans>Tax if applicable</Trans>
          </Text>
          <Text style={styles.contentItemText}>
            {props.tax ? `$${props.tax}` : '—'}
          </Text>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.contentItem}>
          <Text
            selectable
            style={[styles.contentItemText, {color: theme.colors.neutral.$4}]}>
            <Trans>Total after trial</Trans>
          </Text>
          <Text
            selectable
            style={[styles.contentItemText, {color: theme.colors.neutral.$4}]}>
            <Trans>${props.priceBase + props.tax}</Trans>
          </Text>
        </View>
        <View style={styles.contentItem}>
          <Text
            selectable
            style={[styles.contentItemText, {fontWeight: '700'}]}>
            <Trans>Total due today</Trans>
          </Text>
          <Text
            selectable
            style={[styles.contentItemText, {fontWeight: '700'}]}>
            <Trans>
              ${props.free ? '0' : `${props.priceBase + props.tax}`}
            </Trans>
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBottom: '3rem',
    borderRadius: 14,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: theme.colors.neutral.$11,
    borderBottomWidth: 1,
    padding: '1.5rem',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 14,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.colors.neutral.$15,
    borderTopColor: theme.colors.neutral.$11,
    borderTopWidth: 1,
    paddingVertical: '1.5rem',
    paddingHorizontal: '2rem',
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  headerText: {
    fontSize: 16.5,
    fontWeight: '700',
    lineHeight: 26,
  },
  discountBadge: {
    display: 'flex',
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderRadius: 50,
    backgroundColor: theme.colors.success.$7,
    color: theme.colors.success.$2,
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
  },
  headerPriceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  headerPriceText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  headerPriceUnitText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$5,
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginVertical: '1.5rem',
    paddingHorizontal: '2rem',
  },
  contentHeaderText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$5,
    marginBottom: '1.5rem',
  },
  contentItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  contentItemText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
});
