import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {StyleSheet, View} from 'react-native';
import {Image, Text, type TextStyle, type ViewStyle} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {BilledDurationType} from 'store/slices/settings/types';

import EnterprisePriceIcon from '../../upgrade-downgrade/assets/Enterprise-icon.png';
import {calcAmount} from '../lib/utils';

import type {PlanMetaData} from '../lib/types';

interface PlanProps {
  plan: PlanMetaData;
  unit: BilledDurationType;
  submitPlan: (plan: PlanMetaData) => void;
  selectedPlan?: boolean;
  buttonType?: 'Primary' | 'Secondary' | 'Text' | 'Text_Secondary';
  buttonText?: string;
  buttonCustomStyle?: ViewStyle;
  buttonCustomTextStyle?: TextStyle;
  customWidth?: number;
  customHeight?: number;
  customPadding?: number | string;
}

const featuresIconNames = [
  'account-multiple',
  'monitor-dashboard',
  'file',
  'database',
  'web',
];

export function Plan(props: PlanProps) {
  return (
    <View
      style={[
        styles.root,
        {
          maxWidth: props.customWidth ? props.customWidth : 380,
          height: props.customHeight ? props.customHeight : 787,
          padding: props.customPadding ? props.customPadding : undefined,
          borderColor: theme.colors.neutral.$2Base,
          borderWidth: props.selectedPlan ? 1 : 0,
        },
      ]}>
      <Text selectable style={styles.name}>
        {props.plan.name}
      </Text>
      <Text selectable style={styles.desc}>
        {props.plan.description}
      </Text>
      <View style={styles.priceRelatedContainer}>
        <View style={styles.priceContainer}>
          {!Number.isNaN(props.plan.price) ? (
            <>
              <Text selectable style={styles.priceNumber}>
                <Text selectable style={{fontWeight: '400'}}>
                  $
                </Text>
                {`${
                  calcAmount(props.plan.price, props.unit) % 1 === 0
                    ? calcAmount(props.plan.price, props.unit).toFixed(0)
                    : calcAmount(props.plan.price, props.unit).toFixed(2)
                }`}
              </Text>
              <View style={styles.priceUnitContainer}>
                <Text selectable style={styles.priceUnit}>
                  {'user /\nmonth'}
                </Text>
              </View>
            </>
          ) : (
            <Image
              source={{uri: EnterprisePriceIcon}}
              style={{
                width: 64,
                height: 58,
                marginBottom:
                  props.unit === BilledDurationType.Yearly ? '1.2rem' : 0,
              }}
            />
          )}
        </View>
        {props.unit === BilledDurationType.Yearly &&
          !Number.isNaN(props.plan.price) && (
            <Text
              selectable
              style={[styles.priceUnit, {color: theme.colors.neutral.$4}]}>
              <Trans>Billed annually</Trans>
            </Text>
          )}
      </View>
      <View style={styles.buttonContainer}>
        <Button
          type={props.buttonType ? props.buttonType : 'Primary'}
          label={
            props.buttonText ? props.buttonText : t`Start 14-Day Free Trial`
          }
          onPress={() => props.submitPlan(props.plan)}
          customRootStyle={[styles.buttonRoot, props.buttonCustomStyle]}
          customTextStyle={[styles.buttonText, props.buttonCustomTextStyle]}
        />
      </View>
      <View style={styles.featuresContainer}>
        {props.plan.features.map((feature, idx) => (
          <View style={styles.feature} key={feature + idx}>
            <Icon
              name={featuresIconNames[idx]}
              color={theme.colors.brand.$3}
              size={20}
            />
            <Text selectable style={styles.featureText}>
              <Trans>{feature}</Trans>
            </Text>
          </View>
        ))}
      </View>
      <View style={styles.featureHighlightContainer}>
        {props.plan.previousPlan ? (
          <Text selectable style={styles.featureHighlightTitle}>
            <Trans>
              Includes{'  '}
              <Text selectable style={{color: theme.colors.success.$3}}>
                {props.plan.previousPlan}
              </Text>
              , plus:
            </Trans>
          </Text>
        ) : (
          <Text selectable style={styles.featureHighlightTitle}>
            <Trans>Feature highlights:</Trans>
          </Text>
        )}
        {props.plan.highlights.map((highlight, idx) => (
          <View style={styles.highlightContainer} key={highlight + idx}>
            <Icon name="check" size={20} color={theme.colors.neutral.$2Base} />
            <Text selectable style={styles.highlight}>
              <Trans>{highlight}</Trans>
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: '3rem',
    paddingVertical: '2.5rem',
    borderRadius: 14,
    backgroundColor: theme.colors.neutral.$white,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
  name: {
    fontSize: 21,
    fontWeight: '700',
    lineHeight: 34,
    color: theme.colors.neutral.$2Base,
    marginBottom: '0.5rem',
  },
  desc: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$4,
    marginBottom: '1.5rem',
    height: 44,
  },
  priceRelatedContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '2.5rem',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  priceNumber: {
    fontSize: 48,
    fontWeight: '700',
    lineHeight: 58,
    color: theme.colors.neutral.$2Base,
  },
  priceUnitContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceUnit: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  buttonContainer: {
    width: '100%',
    marginBottom: '2.5rem',
    alignSelf: 'flex-start',
  },
  buttonRoot: {
    paddingVertical: 12,
    paddingHorizontal: 14,
    height: 48,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$white,
  },
  featuresContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  feature: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 7,
    paddingVertical: 4,
  },
  featureText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  featureHighlightContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderTopColor: theme.colors.neutral.$11,
    borderTopWidth: 1,
  },
  featureHighlightTitle: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    marginVertical: '1rem',
  },
  highlightContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 7,
    paddingVertical: 4,
  },
  highlight: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
  },
});
