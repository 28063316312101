import theme from 'config/theme';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import type React from 'react';
import {StyleSheet, Text, View} from 'react-native';

type Props = {
  title: string;
  pendingInvitations: Array<WorkspaceListDetail | ShareItem>;
  renderListItem: (
    item: WorkspaceListDetail | ShareItem,
    index?: number,
  ) => React.ReactNode;
  first?: boolean;
};

export default function PendingInvitationSection({
  title,
  pendingInvitations,
  renderListItem,
  first,
}: Props) {
  return (
    <View>
      <View style={[styles.header, first && styles.roundedTop]}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>
        {pendingInvitations.map((item, index) => renderListItem(item, index))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 16,
  },
  header: {
    height: 44,
    backgroundColor: theme.colors.neutral.$white,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    paddingHorizontal: 20,
    paddingVertical: 11,
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  joinButton: {
    minWidth: 80,
  },
  roundedTop: {
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
  },
});
