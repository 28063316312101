import theme from 'config/theme';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

type Props = {
  description: string;
};

const PreviewSidePanelSummary = ({description}: Props) => {
  return (
    <View style={styles.root}>
      <View style={styles.descriptionContainer}>
        <Text style={styles.description}>{description}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 10,
  },
  description: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  descriptionContainer: {
    paddingHorizontal: 10,
    paddingVertical: 6,
  },
  tagsContainer: {
    paddingHorizontal: 10,
    paddingTop: 15,
    paddingBottom: 5,
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
  },
});

export default PreviewSidePanelSummary;
