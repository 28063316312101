import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/base/Button';
import {StyleSheet, Text, View} from 'react-native';

interface Props {
  isQuickShare?: boolean;
}

export function ShareNotAvailableCard({isQuickShare = false}: Props) {
  const navigate = useNavigate();

  const shareTypeLabel = isQuickShare ? 'Quick-Share' : 'Share';

  return (
    <View
      style={[styles.container, isQuickShare && styles.quickShareContainer]}>
      <Text
        style={[
          styles.closedContentHeaderText,
          isQuickShare && styles.quickShareText,
        ]}>
        <Trans>This {shareTypeLabel} is no longer available</Trans>
      </Text>
      <Text style={[styles.normalText, isQuickShare && styles.quickShareText]}>
        <Trans>
          This {shareTypeLabel} is no longer available. Click the button below
          to return to Fastio.
        </Trans>
      </Text>
      <Button
        type="Primary"
        label={t`Return to Fastio`}
        onPress={() => navigate('/', {replace: true})}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: '60%',
    backgroundColor: theme.colors.neutral.$white,
    padding: 32,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
    borderRadius: 12,
    borderColor: theme.colors.neutral.$12,
    borderWidth: StyleSheet.hairlineWidth,
    marginTop: 32,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  quickShareContainer: {
    backgroundColor: theme.colors.neutral.$1,
    borderWidth: 0,
  },
  closedContentHeaderText: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  normalText: {
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
    marginBottom: '1rem',
  },
  quickShareText: {
    color: theme.colors.neutral.$white,
  },
});
