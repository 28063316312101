import theme from 'config/theme';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {FolderToolbarButton} from 'interface/stacks/workspace/storage/FolderToolbarButton';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import files, {selectors} from 'store/slices/files';

const RecentHeader = () => {
  const layout = useSelector(selectors.getLayout);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <View style={styles.root}>
      <Text style={styles.title}>Recent</Text>
      <View style={styles.buttons}>
        <FolderToolbarButton
          customRootStyle={styles.recentsButton}
          customTextStyle={styles.recentsButtonText}
          label="Recent"
          onPress={() => navigate(-1)}
          icon={
            <Icon
              name="access-time"
              size={20}
              color={theme.colors.neutral.$white}
            />
          }
        />
        <FolderToolbarButton
          icon={
            <Icon
              name={
                layout === FileListItemLayout.GridSimple
                  ? 'view-list'
                  : 'view-module'
              }
              size={20}
              color={theme.colors.neutral.$5}
            />
          }
          onPress={() => dispatch(files.actions.layout())}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 30,
  },
  buttons: {
    flexDirection: 'row',
    gap: 10,
  },
  recentsButton: {
    backgroundColor: theme.colors.neutral.$25,
  },
  recentsButtonText: {
    color: theme.colors.neutral.$white,
  },
});

export default RecentHeader;
