import theme from 'config/theme';
import {Avatar} from 'interface/base/Avatar';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {createInitials} from 'utils/common/data';
import {VARIANT, type Variant} from './base/consts';

export interface PreviewSidePanelDetailsItemProps {
  detail?: string;
  icon?: boolean;
  memberBadge?: boolean;
  heading?: string;
  graphic?: boolean;
  variant?: Variant;
  graphicColor?: string;
}

export function PreviewSidePanelDetailsItem(
  props: PreviewSidePanelDetailsItemProps,
) {
  const isDark = props.variant === VARIANT.dark;
  const classes = {
    root: {
      ...styles.root,
      backgroundColor: isDark
        ? theme.colors.neutral.$1
        : theme.colors.neutral.$white,
      borderColor: isDark ? theme.colors.neutral.$25 : theme.colors.neutral.$12,
    },
    headingText: {
      ...styles.headingText,
      color: isDark ? theme.colors.neutral.$8 : theme.colors.neutral.$4,
    },
    content: {
      ...styles.content,
      color: isDark ? theme.colors.neutral.$white : theme.colors.neutral.$2Base,
    },
  };

  return (
    <View style={classes.root}>
      <View style={styles.heading}>
        <Text style={classes.headingText}>{props.heading}</Text>
      </View>
      <View style={classes.content}>
        {props.graphic && (
          <View style={styles.graphic}>
            {props.memberBadge && (
              <Avatar
                initials={createInitials(props.detail)}
                color={props.graphicColor ?? '#FFC804'}
              />
            )}
            {props.icon && (
              <Icon
                name="folder"
                size={22}
                color={
                  isDark
                    ? theme.colors.neutral.$white
                    : theme.colors.neutral.$2Base
                }
              />
            )}
          </View>
        )}
        <View style={styles.text}>
          <Text
            style={classes.content}
            numberOfLines={1}
            ellipsizeMode={'head'}>
            {props.detail}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$white,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.neutral.$12,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 100,
    paddingVertical: 4,
  },
  headingText: {
    flex: 1,
    height: 20,
    textAlign: 'left',
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
  },
  content: {
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    flexDirection: 'row',
    color: theme.colors.neutral.$2Base,
    flex: 1,
  },
  graphic: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingRight: 10,
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingVertical: 4,
  },
});
