import {t} from '@lingui/macro';
import {format, isToday, isYesterday} from 'date-fns';
import type {FileExifMetadata} from 'fast-sdk/src/api/storage/consts';
import {StyleSheet, View} from 'react-native';
import type {User} from 'store/slices/user/types';
import {getHashColor} from 'utils/common/color';
import {bytesize} from 'utils/common/data';
import {PreviewSidePanelDetailsItem} from './PreviewSidePanelDetailsItem';
import type {Variant} from './base/consts';

interface PreviewSidePanelDetailsProps {
  type: string;
  size: number;
  items: number;
  location: string;
  created: string;
  modified: string;
  variant?: Variant;
  metadata: FileExifMetadata;
  uploadedBy?: User;
}

const renderDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, "'Today at' h:mm a");
  }

  if (isYesterday(date)) {
    return format(date, "'Yesterday at' h:mm a");
  }

  return format(date, 'MMMM do, yyyy, h:mm a');
};

const pascalToSentence = (pascalString: string): string => {
  return pascalString
    .replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
    .replace(/^./, str => str.toUpperCase());
};

export function PreviewSidePanelDetails(props: PreviewSidePanelDetailsProps) {
  return (
    <View style={styles.root}>
      {props.uploadedBy && (
        <PreviewSidePanelDetailsItem
          heading={t`Uploaded by:`}
          detail={`${props.uploadedBy.first_name} ${props.uploadedBy.last_name}`}
          memberBadge
          graphic
          variant={props.variant}
          graphicColor={getHashColor(props.uploadedBy.id)}
        />
      )}
      <PreviewSidePanelDetailsItem
        heading={t`Type:`}
        detail={props.type.slice(0, 1).toUpperCase() + props.type.slice(1)}
        memberBadge
        variant={props.variant}
      />
      {props.type !== 'folder' && (
        <PreviewSidePanelDetailsItem
          heading={t`Size:`}
          detail={bytesize(props.size)}
          memberBadge
          variant={props.variant}
        />
      )}
      {props.location && (
        <PreviewSidePanelDetailsItem
          heading={t`Location:`}
          detail={props.location}
          icon
          graphic
          variant={props.variant}
        />
      )}
      <PreviewSidePanelDetailsItem
        heading={t`Created:`}
        detail={renderDate(new Date(props.modified.replace(/\s/g, 'T')))}
        memberBadge
        variant={props.variant}
      />
      <PreviewSidePanelDetailsItem
        heading={t`Modified:`}
        detail={renderDate(new Date(props.modified.replace(/\s/g, 'T')))}
        memberBadge
        variant={props.variant}
      />
      {props.metadata &&
        Object.keys(props.metadata)
          .filter(key => Boolean(props.metadata[key]))
          .map(key => (
            <PreviewSidePanelDetailsItem
              heading={pascalToSentence(key)}
              detail={props.metadata[key]}
              memberBadge
              variant={props.variant}
              key={key}
            />
          ))}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
  },
});
