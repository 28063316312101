import {api} from 'fast-sdk';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';

export default function useUserOrgPermission() {
  const permission = useSelector(app.selectors.getUserPermission);
  const dispatch = useDispatch();

  const {subdomain} = useSubDomain();
  const currentUser = useSelector(user.selectors.getUserDetails);

  useEffect(() => {
    async function getMembers() {
      const {result, users} =
        await api.organization.getOrganizationMembers(subdomain);
      if (result) {
        const permission =
          users.find(user => user.id === currentUser.id)?.permissions || '';
        dispatch(app.default.actions.setUserPermission({permission}));
      }
    }

    if (isSubdomainValid(subdomain) && !permission) {
      getMembers();
    }
  }, [subdomain, currentUser, permission]);

  return {permission};
}
