import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {useState} from 'react';
import ReactCrop, {type PercentCrop} from 'react-image-crop';
import {Image, StyleSheet, View} from 'react-native';
import {getCroppedImg} from 'utils/common/image';
import {Button} from './Button';

interface Props {
  image: string;
  setCompletedImg: (completedImg: string) => void;
  setImage?: (image: File) => void;
  imageType?: string;
  imageName?: string;
}

export function CropImage(props: Props) {
  const [crop, setCrop] = useState<PercentCrop>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const modal = useModal();

  const base64ToBlob = (base64: string) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = Array.from(byteCharacters).map(char =>
      char.charCodeAt(0),
    );
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: 'image/jpeg'});
  };

  const submit = async () => {
    const img = await getCroppedImg(props.image, crop);
    const blob = new Blob([base64ToBlob(img)], {type: props.imageType});
    const file = new File([blob], props.imageName, {type: props.imageType});
    if (props.setImage) {
      props.setImage(file);
    }
    props.setCompletedImg(img);
  };

  return (
    <View style={styles.root}>
      <ReactCrop
        crop={crop}
        onChange={(pixelCrop, percentCrop) => {
          setCrop(percentCrop);
        }}
        circularCrop
        style={styles.cropContainer}
        onComplete={(pixelCrop, percentCrop) => {
          setCrop(percentCrop);
        }}
        aspect={1}>
        <Image
          source={{uri: props.image}}
          style={styles.image}
          resizeMode="contain"
        />
      </ReactCrop>
      <View style={styles.buttons}>
        <Button
          type="Secondary"
          label={t`Cancel`}
          customRootStyle={{height: 40}}
          onPress={() => {
            modal.close();
          }}
        />
        <Button
          type="Primary"
          label={t`Confirm`}
          customRootStyle={{height: 40}}
          onPress={() => {
            submit();
            modal.close();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
  },
  cropContainer: {
    marginBottom: '3rem',
    width: '100%',
    aspectRatio: 1,
  },
  image: {
    width: 320,
    height: 320,
    // aspectRatio: 1,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
