import React from 'react';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import OrgBadge from './OrgBadge';
import {useOrganizationPublicDetails} from './hooks/useOrganizationPublicDetails';
import useSingleOrgLogo from './hooks/useSingleOrgLogo';

type Props = {
  id: string;
  orgId: string;
};

const DEFAULT_INITIALS = 'O R G';

export default function ShareOrgBadge({id, orgId}: Props) {
  const {org} = useOrganizationPublicDetails(orgId);
  const {logo} = useSingleOrgLogo(org?.domain);

  return (
    <OrgBadge
      badgeColor={getHashColor(id)}
      color={getHashColor(orgId)}
      initials={createInitials(org?.name || DEFAULT_INITIALS)}
      photo={logo}
    />
  );
}
