import {t} from '@lingui/macro';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import {Loading} from 'interface/base/Loading';
import {useProccessPendingInvitation} from 'interface/base/hooks/useProccessPendingInvitation';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import Auth from '../consts';

export function VerifyEmail() {
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const isInvitationFlow = useSelector(app.selectors.getIsInvitationFlow);
  const {handleInvitationFlow} = useProccessPendingInvitation();

  const [token, setToken] = useState<string>();
  const [email, setEmail] = useState<string>();

  const userDetails = useSelector(user.selectors.getUserDetails);

  useEffect(() => {
    async function getEmail() {
      if (userDetails?.email_address && userDetails.id) {
        return setEmail(userDetails.email_address);
      }

      const userEmail = await Auth.getUserEmail();

      if (userEmail) {
        return setEmail(userEmail);
      }

      toast.show(
        t`The verify email should happen in the same browser that the user logged in, please log in again`,
        {type: 'danger'},
      );
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }

    getEmail();
  }, [userDetails]);

  useEffect(() => {
    const tokenParam = searchparams.get('token');

    if (!tokenParam) {
      return navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }

    setToken(tokenParam.split('-').join(''));
  }, [searchparams]);

  useEffect(() => {
    if (!token || !email) {
      return;
    }

    async function validateEmail() {
      const validateRes = await api.user.validateEmail({
        email_token: token,
        email,
      });

      if (!validateRes.result) {
        throw Error(validateRes.error?.text);
      }

      const userRes = await api.user.userDetails();
      dispatch(user.default.actions.setUserDetails({...userRes.user}));

      if (!userRes.result) {
        toast.show(userRes.error?.text);
        return navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
      }

      if (isInvitationFlow) {
        return handleInvitationFlow(userRes.user.email_address);
      }

      return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
    }

    validateEmail();
  }, [token, email]);

  return (
    <View style={styles.root}>
      <Loading />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});
