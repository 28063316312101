import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {WORKSPACE_TRASH} from 'constants/routes';
import CircleButton from 'interface/base/CircleButton';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {FolderLayoutSwitchButton} from 'interface/stacks/workspace/storage/FolderLayoutSwitchButton';
import {useAddNewNode} from 'interface/stacks/workspace/storage/hooks/useAddNewNode';
import {StyleSheet, Text, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {useOutletContext} from 'react-router';
import shared from 'store/slices/shared';

import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {FilesData} from '../../hooks/useFetchShareFiles';

interface FilesHeaderProps {
  share: Share;
  filesData: FilesData;
  selectedFolder: FilesItem;
  folderKey: string;
  onGoBack: () => void;
  onTrashOpen: () => void;
  onTrashEmpty: () => void;
  canManageFiles: boolean;
}

interface HeaderTitleProps {
  selectedFolder: FilesItem | null;
  isTrashView: boolean;
  onGoBack?: () => void;
}

interface HeaderActionsProps {
  share: Share;
  filesData: FilesData;
  addNewNodeButtonRef: React.RefObject<any>;
  openAddNewNodePopup: () => void;
  onFilterChange: () => void;
  onLayoutChange: () => void;
  onTrashOpen: () => void;
  onTrashEmpty: () => void;
  canManageFiles: boolean;
  isTrashView: boolean;
}

const HeaderTitle = ({
  selectedFolder,
  isTrashView,
  onGoBack,
}: HeaderTitleProps) => {
  return (
    <View style={styles.headerLeft}>
      {(selectedFolder || isTrashView) && (
        <CircleButton
          type="Primary"
          iconName="arrow-left"
          showBorder={false}
          onPress={onGoBack}
        />
      )}
      <Text style={styles.headerText}>
        <Trans>
          {isTrashView
            ? 'Trash'
            : selectedFolder
              ? selectedFolder.name
              : 'Files'}
        </Trans>
      </Text>
    </View>
  );
};

const HeaderActions = ({
  share,
  addNewNodeButtonRef,
  openAddNewNodePopup,
  onFilterChange,
  onLayoutChange,
  onTrashOpen,
  onTrashEmpty,
  canManageFiles,
  isTrashView,
}: HeaderActionsProps) => {
  const layout = share.currentLayout ?? FileListItemLayout.ListNormal;
  const isOrderSortAsc = share.sort?.order === 'asc';

  return (
    <View style={styles.headerOptions}>
      {canManageFiles && !isTrashView && (
        <CircleButton
          ref={addNewNodeButtonRef}
          iconName="plus"
          onPress={openAddNewNodePopup}
          text="Add files"
        />
      )}
      {canManageFiles && isTrashView && (
        <CircleButton
          ref={addNewNodeButtonRef}
          type="Danger"
          iconName="delete"
          onPress={onTrashEmpty}
          text="Empty Trash"
        />
      )}
      {!isTrashView && (
        <CircleButton
          type="Secondary"
          iconName="delete-outline"
          onPress={onTrashOpen}
          tooltipPos="top"
          tooltipText="Open Trash"
        />
      )}
      {!isTrashView && (
        <CircleButton
          type="Secondary"
          iconName="filter-variant"
          onPress={onFilterChange}
          tooltipPos="top"
          tooltipText={isOrderSortAsc ? 'Oldest' : 'Newest'}
          iconStyle={{
            transform: [{rotate: isOrderSortAsc ? '180deg' : '0deg'}],
          }}
        />
      )}
      {!isTrashView && (
        <FolderLayoutSwitchButton
          layout={layout}
          onLayoutChange={onLayoutChange}
        />
      )}
    </View>
  );
};

export function FilesHeader({
  share,
  filesData,
  selectedFolder,
  folderKey,
  onGoBack,
  onTrashOpen,
  onTrashEmpty,
  canManageFiles,
}: FilesHeaderProps) {
  const dispatch = useDispatch();
  const [, uploader] = useOutletContext<[unknown, UploaderState]>();

  const {addNewNodeButtonRef, openAddNewNodePopup} = useAddNewNode({
    instanceId: share.id,
    instanceAlt: share.custom_name,
    instanceNs: 'share',
    folderKey,
    uploader,
  });

  const handleFilterChange = () => {
    const newOrder = share.sort?.order === 'asc' ? 'desc' : 'asc';
    dispatch(
      shared.actions.setShareSort({
        share,
        sort: {
          order: newOrder,
          category: 'date',
        },
      }),
    );
  };

  const handleLayoutChange = () => {
    const newLayout =
      share.currentLayout === FileListItemLayout.ListNormal
        ? FileListItemLayout.GridSimple
        : FileListItemLayout.ListNormal;
    dispatch(shared.actions.setShareLayout({share, layout: newLayout}));
  };

  const isGridLayoutWithContent =
    share.currentLayout === FileListItemLayout.GridSimple &&
    Boolean(filesData.filesIds.at(0));

  const isTrashView = folderKey === WORKSPACE_TRASH;

  return (
    <View
      style={[styles.root, isGridLayoutWithContent && styles.gridWithContent]}>
      <HeaderTitle
        selectedFolder={selectedFolder}
        isTrashView={isTrashView}
        onGoBack={onGoBack}
      />
      <HeaderActions
        share={share}
        filesData={filesData}
        addNewNodeButtonRef={addNewNodeButtonRef}
        openAddNewNodePopup={openAddNewNodePopup}
        onFilterChange={handleFilterChange}
        onLayoutChange={handleLayoutChange}
        onTrashOpen={onTrashOpen}
        onTrashEmpty={onTrashEmpty}
        canManageFiles={canManageFiles}
        isTrashView={isTrashView}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  gridWithContent: {
    marginBottom: 20,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  headerText: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  headerOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  normalText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
  },
});
