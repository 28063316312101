import theme from 'config/theme';
import {Pressable, StyleSheet, Text} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface EmailItemBadgeProps {
  email: string;
  deleteString: () => void;
}

export const EmailItemBadge = (props: EmailItemBadgeProps) => {
  return (
    <Pressable style={styles.root} onPress={props.deleteString}>
      <Text style={styles.email}>{props.email}</Text>
      <Icon
        name="close"
        size={18}
        color={theme.colors.neutral.$5}
        style={{padding: 6}}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$white,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$9,
    borderRadius: 50,
    padding: 3,
    maxHeight: 35,
  },
  email: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
    paddingLeft: 10,
    paddingVertical: 5,
  },
});
