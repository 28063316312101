import {Loading} from 'interface/base/Loading';
import {StyleSheet, Text, View} from 'react-native';

const Processing = () => {
  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Loading size="small" />
          <Text style={styles.title}>Processing, please wait...</Text>
        </View>
        <Text style={styles.subtitle}>
          We are processing your payment info. This may take a moment so please
          remain on this page until this step is complete.
        </Text>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    gap: 14,
    maxWidth: 500,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  title: {
    fontSize: 26,
    lineHeight: 40,
    fontWeight: '700',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
    textAlign: 'center',
  },
});

export default Processing;
