import theme from 'config/theme';
import {HoverWrapper} from 'interface/base/HoverWrapper';
import {useHover} from 'interface/base/hooks/useHover';
import useClickOutside from 'interface/stacks/content-viewer/hooks/useClickOutside';
import type React from 'react';
import {type PropsWithChildren, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import DropdownPillContent from './DropdownPillContent';

type Props = {
  active?: boolean;
  label: string | React.ReactNode;
  disabled?: boolean;
  removeFilter: () => void;
};

const DropdownPill = ({
  active,
  label,
  children,
  disabled,
  removeFilter,
}: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false);
  const {targetRef, sourceRef} = useClickOutside(isOpen, () =>
    setIsOpen(false),
  );
  const {isHover, onHoverIn, onHoverOut} = useHover();

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const handleRemove = () => {
    removeFilter();
    setIsOpen(false);
  };

  const classes = {
    root: [
      styles.root,
      {
        backgroundColor: active
          ? theme.colors.neutral.$25
          : theme.colors.neutral.$white,
      },
      {
        borderColor: active
          ? theme.colors.neutral.$25
          : theme.colors.neutral.$10,
      },
    ],
    label: [
      styles.label,
      {
        color: active ? theme.colors.neutral.$white : theme.colors.neutral.$4,
      },
    ],
    icon: [
      styles.icon,
      !active && {transform: [{rotate: isOpen ? '180deg' : '0deg'}]},
      active && isHover && styles.hoverClose,
    ],
  };

  return (
    <View>
      <HoverWrapper hoverStyle={styles.hover}>
        <Pressable
          ref={sourceRef}
          style={classes.root}
          onPress={toggleDropdown}
          disabled={disabled}>
          {typeof label === 'string' ? (
            <Text style={classes.label}>{label}</Text>
          ) : (
            label
          )}
          <Icon
            name={active ? 'close' : 'keyboard-arrow-down'}
            size={20}
            color={
              active
                ? isHover
                  ? theme.colors.neutral.$black
                  : theme.colors.neutral.$white
                : theme.colors.neutral.$5
            }
            style={classes.icon}
            // @ts-ignore
            onMouseEnter={onHoverIn}
            onMouseLeave={onHoverOut}
            onPress={active ? handleRemove : undefined}
          />
        </Pressable>
      </HoverWrapper>
      {isOpen && (
        <DropdownPillContent ref={targetRef}>{children}</DropdownPillContent>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    zIndex: 2,
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 3,
    paddingVertical: 8,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
    height: 36,
  },
  hover: {
    backgroundColor: theme.colors.neutral.$13,
    borderRadius: 50,
  },
  hoverClose: {
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 50,
  },
  label: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
    paddingHorizontal: 10,
  },
  icon: {
    padding: 5,
  },
});

export default DropdownPill;
