import type {AnyAction, Dispatch} from '@reduxjs/toolkit';
import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {Requests, api} from 'fast-sdk';
import Auth from 'interface/stacks/auth/consts';
import {OnboardSteps} from 'interface/stacks/onboard/lib/consts';
import {authDb} from 'store';
import type {Organization, SSOMethod, User} from 'store/slices/user/types';

export async function setPreviouslySignedInMethod(type: SSOMethod) {
  return await authDb.setItem('signin_method', type);
}

export async function setPreviouslySignedInResult(result: boolean) {
  return await authDb.setItem('signin_result', result);
}

export async function getPreviouslySignedInMethod() {
  return await authDb.getItem('signin_method');
}

export async function getPreviouslySignedInResult() {
  const res = await authDb.getItem('signin_result');
  return !!res;
}

export async function checkUserExists(
  setToken: (input: string) => void,
  successCallBack: (input: User) => void,
  errorCallBack: (input: Error) => void,
) {
  const userToken = await Auth.getAuthToken();
  if (userToken) {
    Requests.setAuthToken(userToken);
    setToken(userToken);
    api.user
      .userDetails()
      .then(res => res.result && successCallBack(res.user))
      .catch(err => errorCallBack(err));
  }
}

export async function loginGoogle(_dispatch: Dispatch<AnyAction>) {
  try {
    const {redirect_url} = await api.auth.getGoogleRedirectURL();
    return redirect_url;
  } catch (e) {
    return false;
  }
}

export async function loginMicrosoft(_dispatch: Dispatch<AnyAction>) {
  try {
    const {redirect_url} = await api.auth.getMicrosoftRedirectURL();
    return redirect_url;
  } catch (e) {
    return false;
  }
}

export async function loginFacebook(_dispatch: Dispatch<AnyAction>) {
  try {
    const {redirect_url} = await api.auth.getFacebookRedirectURL();
    return redirect_url;
  } catch (e) {
    return false;
  }
}

export const redirectUserAfterLoggedIn = (
  organizationsList: Array<Organization>,
  userDetails: User,
  subdomain,
) => {
  // Skip and use gate in onboarding if we're coming from stripe
  if (
    location.href.includes(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Payment}`,
    )
  ) {
    return;
  }
  if (userDetails.email_address) {
    if (organizationsList) {
      if (organizationsList.length === 0)
        return `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`;
      if (organizationsList.length === 1 && subdomain)
        return `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`;
      if (organizationsList.length > 1)
        return `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`;
    }
  } else {
    return '/signin';
  }
};

export const handleSignNavigation = (
  navigate: (path: string) => void,
  {
    authToken,
    user,
    hasOrgs,
    hasWorkspaces,
    hasShares,
  }: {
    authToken: string;
    user: User;
    hasOrgs: boolean;
    hasWorkspaces: boolean;
    hasShares: boolean;
  },
) => {
  const shouldRedirectToEmailValidation = authToken && !user.email_address;
  const shouldRedirectToOnboarding =
    authToken &&
    user.email_address &&
    user.valid_email &&
    !hasOrgs &&
    !hasWorkspaces &&
    !hasShares;
  const shouldRedirectToOrgSelection = hasOrgs;
  const shouldRedirectToWorkspace = hasWorkspaces || hasShares;

  if (shouldRedirectToEmailValidation) {
    navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION}`);
  } else if (shouldRedirectToOnboarding) {
    navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
  } else if (shouldRedirectToOrgSelection) {
    navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`);
  } else if (shouldRedirectToWorkspace) {
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`);
  }
};
