import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';
import {VARIANT, type Variant} from './base/consts';

export interface PreviewSidePanelTimelineHeaderProps {
  text: string;
  variant?: Variant;
}

export function PreviewSidePanelTimelineHeader(
  props: PreviewSidePanelTimelineHeaderProps,
) {
  const isDark = props.variant === VARIANT.dark;
  const classes = {
    root: {
      ...styles.root,
      backgroundColor: isDark
        ? theme.colors.neutral.$1
        : theme.colors.neutral.$white,
    },
    text: {
      ...styles.text,
      color: isDark ? theme.colors.neutral.$white : theme.colors.neutral.$2Base,
    },
  };

  return (
    <View style={classes.root}>
      <Text style={classes.text}>{props.text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
    backgroundColor: theme.colors.neutral.$white,
  },
  text: {
    flex: 1,
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '700',
    textAlign: 'left',
    color: theme.colors.neutral.$2Base,
  },
});
