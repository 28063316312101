import {t} from '@lingui/macro';
import {PaymentElement, useElements} from '@stripe/react-stripe-js';
import type {Stripe} from '@stripe/stripe-js';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import useCreateWorkspace from 'interface/stacks/onboard/hooks/useCreateWorkspace';
import usePayment from 'interface/stacks/onboard/hooks/usePayment';
import {useEffect, useState} from 'react';
import {StyleSheet} from 'react-native';
import {getOrigin} from 'utils/common/platform';

interface AddCardProps {
  stripe: Stripe;
  orgDomain: string;
  orgId: string;
}

const MAX_RETRIES = 3;
const SUBSCRIPTION_WAIT_TIME = 3000;

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const AddCard = ({orgDomain, orgId}: AddCardProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const {handlePayment} = usePayment();
  const {isWorkspaceCreated} = useCreateWorkspace(
    isSubscribed,
    orgDomain,
    orgId,
  );

  const elements = useElements();

  useEffect(() => {
    if (isWorkspaceCreated) {
      async function redirectToSubdomain() {
        const origin = await getOrigin();
        setLoading(false);
        window.location.href = `${origin}/to-subdomain?orgDomain=${orgDomain}`;
      }

      redirectToSubdomain();
    }
  }, [isWorkspaceCreated]);

  const handleSubmit = async event => {
    event.preventDefault();

    const elementsResult = await elements?.submit();

    if (elementsResult?.error) {
      return;
    }

    setLoading(true);

    const payment = await handlePayment();

    if (payment.error) {
      const isPaymentDone = payment.error.setup_intent?.status === 'succeeded';

      if (!isPaymentDone) {
        analytics.notify(payment.error.message);
        setLoading(false);
      }
    }

    for (let i = 0; i < MAX_RETRIES; i++) {
      await timeout(SUBSCRIPTION_WAIT_TIME);
      const response = await api.billing.getSubscriptionDetails(orgDomain);

      if (response.result && response.billing_status.active) {
        return setIsSubscribed(true);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <PaymentElement
        onReady={() => setLoading(false)}
        onLoaderStart={() => setLoading(true)}
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
        }}
      />
      <Button
        type="Primary"
        onPress={handleSubmit}
        label={t`Complete trial activation - pay $0.00`}
        customRootStyle={styles.button}
        customTextStyle={styles.buttonText}
        loading={loading}
        disabled={loading}
        customLoadingColor={theme.colors.neutral.$white}
      />
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    width: '100%',
    marginTop: 26,
    height: 48,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 24,
  },
});
