import {Trans} from '@lingui/macro';
import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import {PreviewSidePanelTab} from 'interface/stacks/workspace/storage/PreviewSidePanel';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import {selectors as appSelectors} from 'store/slices/app';
import {ActivityTrayTab} from 'store/slices/app/types';
import * as files from 'store/slices/files';

export function WorkspaceActivityTrayHeader() {
  const dispatch = useDispatch();
  const {tab} = useSelector(appSelectors.activityTrayInfo);

  const closeTray = () => {
    dispatch(
      slices.app.actions.toogleActivityTray({isOpen: false, isManual: true}),
    );
  };
  const setTab = (type: ActivityTrayTab) => {
    dispatch(slices.app.actions.changeActivityTrayTab({tab: type}));
  };

  return (
    <View style={styles.root}>
      <View style={styles.tabs}>
        <Pressable onPress={() => setTab(ActivityTrayTab.Activity)}>
          <Text
            style={[
              styles.tab,
              tab === ActivityTrayTab.Activity && styles.active,
            ]}>
            <Trans>Activity</Trans>
          </Text>
        </Pressable>
      </View>
      <Pressable onPress={closeTray} style={styles.close}>
        <Icon
          name="arrow-collapse-right"
          size={20}
          color={theme.colors.neutral.$5}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    backgroundColor: theme.colors.neutral.$white,
    padding: 20,
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  close: {
    padding: 6,
  },
  tab: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: theme.colors.neutral.$4,
    paddingVertical: 8,
    paddingHorizontal: 10,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 3,
  },
  active: {
    color: theme.colors.neutral.$2Base,
    backgroundColor: theme.colors.neutral.$13,
  },
});
