import theme from 'config/theme';
import {ROUTES, WORKSPACE_STORAGE} from 'constants/routes';
import {IconButton} from 'interface/base/IconButton';
import {useHover} from 'interface/base/hooks/useHover';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import * as files from 'store/slices/files';
import {hexToRGBA} from 'utils/common/color';
import useContentViewerParams from '../../hooks/useContentViewerParams';

const BackButton = () => {
  const {instanceId, fileId} = useContentViewerParams();
  const {isHover, onHoverIn, onHoverOut} = useHover();
  const location = useLocation();
  const navigate = useNavigate();
  const file = useSelector(files.selectors.getItem(fileId));

  const onGoBack = () => {
    const isHistoryEmpty = location.key === 'default';
    if (isHistoryEmpty && file) {
      return navigate(
        `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${instanceId}/${WORKSPACE_STORAGE}/${file.parent}`,
      );
    }

    navigate(-1);
  };

  return (
    <View style={styles.root}>
      <IconButton
        buttonStyle={styles.button}
        iconStyle={styles.icon}
        buttonHoverStyle={styles.hover}
        onPress={onGoBack}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        icon={
          <Icon
            name="arrow-back"
            size={20}
            color={isHover ? theme.colors.brand.$6 : theme.colors.brand.$5}
          />
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: 36,
    height: 36,
  },
  button: {
    minWidth: '100%',
    minHeight: '100%',
  },
  icon: {
    width: 20,
    height: 20,
  },
  hover: {
    backgroundColor: hexToRGBA(theme.colors.neutral.$white, 0.1),
  },
});

export default BackButton;
