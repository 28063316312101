import {Picker} from '@react-native-picker/picker';
import theme from 'config/theme';
import type React from 'react';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const ICON_GAP = 8;

export type DropdownOption = {
  title: string;
  value: string;
};

interface CustomPickerProps {
  label?: string;
  icon?: {
    name: string;
    color: string;
    size: number;
  };
  options: Array<DropdownOption>;
  setSelected: (inp: string) => void;
  selected: string;
  noBorder?: boolean;
  enabled?: boolean;
}

export const CustomPicker: React.FC<CustomPickerProps> = ({
  label,
  icon,
  options,
  setSelected,
  selected,
  noBorder,
  enabled,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(selected);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    setSelected(value);
  };

  return (
    <View style={styles.root}>
      <View style={styles.labelContainer}>
        <Text style={styles.label}>{label}</Text>
        {icon && (
          <Icon
            name={icon.name}
            color={icon.color}
            size={icon.size}
            style={styles.icon}
          />
        )}
      </View>

      <Picker
        selectedValue={selectedOption ?? ''}
        onValueChange={newValue => handleOptionChange(newValue)}
        style={[styles.picker, noBorder && styles.noBorder]}
        enabled={enabled}>
        {options.map(option => (
          <Picker.Item
            key={option.value}
            label={option.title}
            value={option.value}
          />
        ))}
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: ICON_GAP,
  },
  label: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  icon: {
    position: 'absolute',
    left: 15,
    top: 10,
  },
  picker: {
    display: 'flex',
    paddingHorizontal: 8,
    paddingVertical: 6,
    gap: 8,
    borderRadius: 3,
    borderWidth: 0,
    height: 36,
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    color: theme.colors.neutral.$4,
    boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.$10}`,
    borderColor: 'transparent',
  },
  noBorder: {
    borderWidth: 0,
    boxShadow: 'none',
  },
});
