import {createSlice} from '@reduxjs/toolkit';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  sort: {category: 'name', order: 'asc'},
  filter: {type: 'all'},
  layout: FileListItemLayout.ListNormal,
  items: {},
  share: {},
  workspace: {},
  selection: [],
  focusedId: '',
  following: null,
  focusedType: undefined,
  thumbnails: {},
};

export default createSlice({name: 'files', reducers, initialState});
export {selectors};
