import type React from 'react';
import {useCallback, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Mouse} from './Mouse';

interface Props<T> {
  data: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  onReorder: (newOrder: T[]) => void;
  keyExtractor: (item: T) => string;
}

export function DraggableList<T>({
  data,
  renderItem,
  onReorder,
  keyExtractor,
}: Props<T>) {
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  const handleDragStart = useCallback((index: number) => {
    setDraggingIndex(index);
  }, []);

  const handleDragOver = useCallback(
    (index: number) => {
      if (draggingIndex !== null && draggingIndex !== index) {
        const newData = [...data];
        const [removed] = newData.splice(draggingIndex, 1);
        newData.splice(index, 0, removed);
        setDraggingIndex(index);
        onReorder(newData);
      }
    },
    [data, draggingIndex, onReorder],
  );

  const handleDragEnd = useCallback(() => {
    setDraggingIndex(null);
  }, []);

  return (
    <>
      {data.map((item, index) => (
        <Mouse
          key={keyExtractor(item)}
          onDragStart={() => handleDragStart(index)}
          onDragEnd={handleDragEnd}
          onDragDrop={() => handleDragOver(index)}>
          <View
            style={[styles.item, draggingIndex === index && styles.dragging]}>
            {renderItem(item, index)}
          </View>
        </Mouse>
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  item: {
    width: '100%',
    //@ts-ignore
    cursor: 'move',
  },
  dragging: {
    opacity: 0.8,
  },
});
