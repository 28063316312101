import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {useMenu} from 'extensions/viewport/useMenu';
import {useModal} from 'extensions/viewport/useModal';
import AppLogo from 'interface/base/AppLogo';
import {UserBadge} from 'interface/stacks/auth/components/UserBadge';
import {type MouseEvent, createRef, useCallback} from 'react';
import {
  type GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';

export function Header() {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const initials = `${userDetails.first_name[0]}${userDetails.last_name[0]}`;
  const dispatch = useDispatch();

  const modal = useModal();

  const headerMenu = useMenu(
    () => [
      {
        command: 'different',
        text: t`Use a different account`,
        icon: (
          <Icon
            name="account-arrow-right"
            size={20}
            color={theme.colors.neutral.$6}
          />
        ),
      },
    ],
    command => {
      switch (command) {
        case 'different':
          dispatch(app.default.actions.logout());
          break;
      }
      modal.close();
    },
    false,
  );

  const menuAnchorRef = createRef<any>();

  const menu = useCallback(
    (e: MouseEvent | GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (headerMenu) {
        if (e?.nativeEvent) {
          headerMenu.setPosition({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          });
        }
        headerMenu.setRef(menuAnchorRef);
      }
      headerMenu.open();
    },
    [headerMenu],
  );

  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: theme.colors.neutral.$15,
        },
      ]}>
      <AppLogo />
      {isLoggedIn && userDetails ? (
        <View style={styles.userContainer}>
          <Text style={styles.text}>
            <Trans>Signed in as:</Trans>
          </Text>
          <Pressable onPress={e => menu(e)} ref={menuAnchorRef}>
            <UserBadge
              id={userDetails.id}
              email={userDetails.email_address}
              imageSrc={userDetails.profile_pic}
              initials={initials}
              customStyle={{backgroundColor: theme.colors.neutral.$white}}
            />
          </Pressable>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$11,
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$5,
  },
});
