import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/base/Button';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export const CancelError = () => {
  const navigate = useNavigate();

  return (
    <View style={styles.root}>
      <Icon
        name="close"
        style={styles.errorImage}
        size={84}
        color={theme.colors.danger.$4Base}
      />
      <Text selectable style={styles.header}>
        <Trans>Plan cancelation faced error</Trans>
      </Text>
      <Text selectable style={styles.content}>
        <Trans>Your plan has not been canceled. Please try again later.</Trans>
      </Text>
      <View style={styles.buttonContainer}>
        <Button
          type="Primary"
          label={t`Go Back`}
          onPress={() => navigate(-1)}
          customRootStyle={styles.buttonRoot}
          customTextStyle={styles.buttonText}
          ariaLabel={t`Go Back`}
          fontStyle="Normal, Large"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    borderRadius: 14,
    backgroundColor: theme.colors.neutral.$white,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
    maxWidth: 650,
  },
  errorImage: {
    marginBottom: '1rem',
    borderRadius: 100,
    borderWidth: 5,
    borderColor: theme.colors.danger.$4Base,
  },
  header: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    color: theme.colors.neutral.$2Base,
    marginBottom: '1.5rem',
    textAlign: 'center',
    width: '100%',
  },
  content: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    marginBottom: '3rem',
    textAlign: 'center',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonRoot: {
    paddingHorizontal: '1rem',
    paddingVertical: 12,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
