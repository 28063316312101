import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Button} from 'interface/base/Button';
import CircleButton from 'interface/base/CircleButton';
import {StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as workspace from 'store/slices/workspace';
import {CustomPicker} from '../../../base/CustomPicker';

enum SortType {
  asc = 'A to Z',
  desc = 'Z to A',
}

interface WorkspacesToolbarProps {
  title?: string;
  showArchived?: boolean;
  goBack?: () => void;
}

export function WorkspacesToolbar({
  title = 'Workspaces',
  showArchived,
  goBack,
}: WorkspacesToolbarProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sort = useSelector(workspace.selectors.getWorkspaceSort);

  const handleShowArchived = () => {
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.ARCHIVED_WORKSPACES}`, {
      replace: true,
    });
  };

  const handleSetSort = (value: SortType) => {
    dispatch(
      workspace.default.actions.setWorkspaceSort({
        category: 'name',
        order: value === SortType.asc ? 'asc' : 'desc',
      }),
    );
  };

  return (
    <View style={styles.root}>
      <View style={styles.leftContainer}>
        {goBack && (
          <CircleButton
            type="Primary"
            iconName="arrow-left"
            showBorder={false}
            onPress={goBack}
          />
        )}
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.rightContainer}>
        <CustomPicker
          options={[
            {
              title: SortType.asc,
              value: SortType.asc,
            },
            {
              title: SortType.desc,
              value: SortType.desc,
            },
          ]}
          selected={sort?.order ? SortType[sort.order] : SortType.asc}
          setSelected={handleSetSort}
        />
        {showArchived && (
          <Button
            type="Secondary"
            label="Archived"
            onPress={handleShowArchived}
            lIcon={
              <MCIcon
                name={'archive-outline'}
                size={20}
                color={theme.colors.neutral.$5}
              />
            }
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 30,
    color: theme.colors.neutral.$2Base,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
});
