import {ROUTES} from 'constants/routes';
import {
  ROUTES_EXCLUDED_FROM_ORGANIZATION_LOADING,
  ROUTES_EXEMPT_FROM_USER_DETAILS_VALIDATION,
  SESSION_VALIDATION_EXEMPT_ROUTES,
} from 'constants/sessionRoutes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {api} from 'fast-sdk';
import {useLayoutWorkspaces} from 'interface/stacks/workspace/hooks/useLayoutWorkspaces';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {getCookie, setCookie} from 'utils/common/cookies';
import {getCurrentPath, isSubdomainValid} from 'utils/common/platform';
import {getEnvironmentDomain} from 'utils/common/version';
import {routesGroups} from './navigator/useNavigatorRedirection';
import {useOrganizationLogo} from './useOrganizationLogo';
import {useProfilePhoto} from './useProfilePhoto';
import {useSubDomain} from './useSubDomain';

const spreadVeAuthCookie = () => {
  const host = window.location?.host;

  if (host.includes('localhost')) {
    return;
  }

  const authCookie = getCookie('ve-authorization');
  if (authCookie && host) {
    const domains = host.split('.');
    const cookieDomain =
      domains.length > 2 ? `.${domains[1]}.${domains[2]}` : `.${host}`;
    setCookie({
      name: 've-authorization',
      value: authCookie,
      domain: cookieDomain,
      path: '/',
      expires: 'Fri, 31 Dec 9999 23:59:59 GMT',
    });
  }
};

export const useLoadUserSessionData = () => {
  const dispatch = useDispatch();
  const {subdomain} = useSubDomain();

  const isLoaded = useSelector(app.selectors.isLoaded);
  const selectedOrganization = useSelector(
    user.selectors.getSelectedOrganization,
  );
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userOrganizations = useSelector(user.selectors.getOrganizationsList);
  const userToken = useSelector(user.selectors.getToken);

  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();

  const fetchAndValidateUserDetails = async (currentPath: string) => {
    const {result, user: userDetails} = await api.user.userDetails();
    if (result) {
      return userDetails;
    }

    if (!ROUTES_EXEMPT_FROM_USER_DETAILS_VALIDATION.includes(currentPath)) {
      dispatch(user.default.actions.deleteUserDetails());
    }
    return user.defaultUser;
  };

  const fetchOrganizationList = async (currentPath: string) => {
    const shouldLoadOrganizations =
      !ROUTES_EXCLUDED_FROM_ORGANIZATION_LOADING.includes(currentPath) ||
      (userOrganizations.length === 0 &&
        currentPath === ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT);

    if (shouldLoadOrganizations) {
      const {result, orgs} =
        await api.organization.getListOfAccessibleOrganizations();
      if (result) {
        dispatch(user.default.actions.setOrganizationsList({orgsList: orgs}));
      }
    }
  };

  const setLoadedApp = () => {
    dispatch(app.default.actions.load({loaded: true}));
  };

  const validateAndRefreshUserSession = async () => {
    const currentPath = getCurrentPath();
    if (
      currentPath &&
      (SESSION_VALIDATION_EXEMPT_ROUTES.includes(currentPath) ||
        ROUTES.LOGGED_IN_WITHOUT_ORG.VERIFY_EMAIL.includes(currentPath))
    ) {
      setLoadedApp();
    } else {
      try {
        if (userToken) {
          const {result: isValidSession} = await api.auth.checkSession();
          if (isValidSession) {
            const [userDetails] = await Promise.all([
              fetchAndValidateUserDetails(currentPath),
              fetchOrganizationList(currentPath),
              fetchAndUpdateWorkspaces(),
            ]);
            setLoadedApp();
            dispatch(user.default.actions.setUserDetails(userDetails));
          } else {
            if (routesGroups.loggedOrNotLoggedRoutes.includes(currentPath)) {
              dispatch(user.default.actions.deleteUserDetails());
            } else {
              dispatch(app.default.actions.setForceLogout(true));
            }
          }
        } else {
          dispatch(user.default.actions.deleteUserDetails());
        }
      } catch (err) {
        handleAppError({
          appError: AppErrors.LoadUserSessionDataError,
          exception: err,
        });
        dispatch(user.default.actions.deleteUserDetails());
      } finally {
        setLoadedApp();
      }
    }
  };

  const addEnvironmentDomain = async () => {
    const environmentDomain = await getEnvironmentDomain();
    dispatch(app.default.actions.setDomainOrigin({origin: environmentDomain}));
  };

  const getSelectedOrganization = useCallback(async () => {
    if (isSubdomainValid(subdomain)) {
      const endpoint = isLoggedIn
        ? api.organization.getOrganizationDetails
        : api.organization.getOrganizationPublicDetails;
      const {result, org} = await endpoint(subdomain);
      if (result) {
        if (isLoggedIn) {
          dispatch(user.default.actions.updateOrganization({org}));
        }
        dispatch(user.default.actions.setSelectedOrganization({org}));
      } else if (isLoggedIn) {
        dispatch(app.default.actions.setForceLogout(true));
      }
    }
  }, [subdomain, isLoggedIn]);

  useEffect(() => {
    if (!selectedOrganization && isSubdomainValid(subdomain) && isLoaded) {
      getSelectedOrganization();
    }
  }, [subdomain, isLoaded]);

  useEffect(() => {
    validateAndRefreshUserSession();
    addEnvironmentDomain();
    spreadVeAuthCookie();
  }, []);

  useProfilePhoto();
  useOrganizationLogo();
};
