import {websockets} from 'fast-sdk';
import {WebSocketCategory} from 'fast-sdk/src/websockets/types';
import {
  getActivityWebsocketAuthToken,
  getMultiplayerWebsocketAuthToken,
} from './utils';

export const WEBSOCKET_CATEGORIES_CONFIG = {
  [WebSocketCategory.Activity]: {
    connector: websockets.activityWebSocket,
    fetchAuth: getActivityWebsocketAuthToken,
  },
  [WebSocketCategory.Multiplayer]: {
    connector: websockets.multiplayerWebSocket,
    fetchAuth: getMultiplayerWebsocketAuthToken,
  },
};

//This is the time in seconds that the polling will wait before timing out
export const DEFAULT_POLLING_WAIT_TIME = 95;

//This is the time in seconds that we give between one polling and the next
export const POLLING_INTERVAL_DELAY = 2 * 1000;

export const ACTIVITY_MESSAGE_DELAY = 1 * 1000;

//This time is the minimum time between server and client before updating
export const MIN_TIME_BETWEEN_CACHE_UPDATES = 200;
