import {api} from 'fast-sdk';
import {PermJoin} from 'fast-sdk/src/api/workspace/consts';
import {useEffect, useState} from 'react';

const useCreateWorkspace = (
  isSubscribed: boolean,
  subdomain: string,
  orgId: string,
) => {
  const [isWorkspaceCreated, setIsWorkspaceCreated] = useState(false);

  useEffect(() => {
    if (isSubscribed && subdomain && orgId) {
      async function createWorkspace() {
        await api.workspace.createWorkspace(subdomain, {
          name: 'General',
          folder_name: `${orgId}-general`,
          perm_join: PermJoin['Member or above'],
        });

        setIsWorkspaceCreated(true);
      }

      createWorkspace();
    }
  }, [isSubscribed, subdomain, orgId]);

  return {isWorkspaceCreated};
};

export default useCreateWorkspace;
