import {createSelector} from '@reduxjs/toolkit';
import {WorkspaceUserStatus} from 'fast-sdk/src/api/workspace/consts';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getWorkspacesLoaded = createSelector(
  x,
  store => store.workspace.workspacesLoaded,
);
export const getWorkspacesLoading = createSelector(
  x,
  store => store.workspace.workspacesLoading,
);
// Get only the workspaces where the user has joined
export const getUserWorkspaces = createSelector(x, store =>
  store.workspace.workspaces.filter(workspace => {
    const isValidWorkspace =
      [WorkspaceUserStatus.Joined].includes(workspace.user_status) &&
      !workspace.archived;
    const {selectedOrganization, organizationsList} = store.user;
    if (!selectedOrganization) {
      return (
        isValidWorkspace &&
        !organizationsList.some(o => o.domain === workspace.org_domain)
      );
    }
    return (
      isValidWorkspace && workspace.org_domain === selectedOrganization.domain
    );
  }),
);

export const getOrgWorkspaces = createSelector(x, store =>
  store.workspace.workspaces.filter(workspace => {
    return (
      [WorkspaceUserStatus.Joined].includes(workspace.user_status) &&
      !workspace.archived &&
      workspace.org_domain === store.user.selectedOrganization?.domain
    );
  }),
);

export const getExternalWorkspaces = createSelector(x, store =>
  store.workspace.workspaces.filter(workspace => {
    const isValidWorkspace =
      [WorkspaceUserStatus.Joined].includes(workspace.user_status) &&
      !workspace.archived;
    const {selectedOrganization, organizationsList} = store.user;
    if (selectedOrganization) {
      return (
        isValidWorkspace &&
        workspace.org_domain !== selectedOrganization.domain &&
        !organizationsList.some(o => o.domain === workspace.org_domain)
      );
    }
    return (
      isValidWorkspace &&
      !organizationsList.some(o => o.domain === workspace.org_domain)
    );
  }),
);

export const getAllWorkspacesInOrg = createSelector(x, store =>
  store.workspace.workspaces.filter(workspace => {
    const isValidWorkspace = !workspace.archived;
    const {selectedOrganization, organizationsList} = store.user;
    if (!selectedOrganization) {
      return (
        isValidWorkspace &&
        !organizationsList.some(o => o.domain === workspace.org_domain)
      );
    }
    return (
      isValidWorkspace && workspace.org_domain === selectedOrganization.domain
    );
  }),
);

export const getAllJoinedWorkspaces = createSelector(x, store =>
  store.workspace.workspaces.filter(workspace =>
    [WorkspaceUserStatus.Joined].includes(workspace.user_status),
  ),
);

export const getWorkspaceByAlt = (workspaceAlt: string) =>
  createSelector(x, store =>
    store.workspace.workspaces.find(
      workspace => workspace.folder_name === workspaceAlt,
    ),
  );

export const getPendingWorkspaces = createSelector(x, store =>
  store.workspace.workspaces.filter(workspace => {
    const isValidWorkspace =
      [WorkspaceUserStatus.Available].includes(workspace.user_status) &&
      !workspace.archived;
    const {selectedOrganization, organizationsList} = store.user;
    if (selectedOrganization) {
      return (
        isValidWorkspace &&
        workspace.org_domain !== selectedOrganization.domain &&
        !organizationsList.some(o => o.domain === workspace.org_domain)
      );
    }
    return (
      isValidWorkspace &&
      !organizationsList.some(o => o.domain === workspace.org_domain)
    );
  }),
);

export const getArchivedWorkspacesInOrg = createSelector(x, store =>
  store.workspace.workspaces.filter(workspace => {
    const isArchivedWorkspace = workspace.archived;
    const {selectedOrganization, organizationsList} = store.user;
    if (!selectedOrganization) {
      return (
        isArchivedWorkspace &&
        !organizationsList.some(o => o.domain === workspace.org_domain)
      );
    }
    return (
      isArchivedWorkspace &&
      workspace.org_domain === selectedOrganization.domain
    );
  }),
);

export const getWorkspaceSort = createSelector(
  x,
  store => store.workspace.sort,
);
