import {Trans, t} from '@lingui/macro';
import CreateImage from 'assets/svgs/organizations/create_org_graphic.svg';
import theme from 'config/theme';
import {Link} from 'extensions/navigation';
import {Button} from 'interface/base/Button';
import useLegalNavigation from 'interface/stacks/app/hooks/useLegalNavigation';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

type Props = {
  onCreateOrganization: () => void;
};

export default function CreateOrganizationBanner({
  onCreateOrganization,
}: Props) {
  const {navigateToTerms, navigateToPrivacy} = useLegalNavigation();

  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <Text style={styles.title}>
          <Trans>Create a new Fastio Organization</Trans>
        </Text>
        <Text style={styles.body}>
          <Trans>
            Fastio helps your team leverage your collective knowledge. To create
            a new Organization, click the button below.
          </Trans>
        </Text>
        <Button
          type="Primary"
          fontStyle="Normal, Large"
          label={t`Create an Organization`}
          customRootStyle={styles.button}
          customTextStyle={styles.buttonText}
          onPress={onCreateOrganization}
        />
        <Text style={styles.privacyContent}>
          By continuing, you are setting up a Fast.io account and agree to the
          <Link
            onClick={navigateToPrivacy}
            style={styles.simpleLink}
            role="link">
            {' '}
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link onClick={navigateToTerms} style={styles.simpleLink} role="link">
            Terms of Service
          </Link>
          .
        </Text>
      </View>
      <View style={styles.right}>
        <Image
          source={{uri: CreateImage}}
          style={styles.image}
          alt="Create Organization Image"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: 50,
    paddingVertical: 30,
  },
  left: {
    width: 455,
  },
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.neutral.$2Base,
    marginBottom: 14,
  },
  body: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    marginBottom: 28,
  },
  right: {},
  image: {
    width: 449,
    height: 310,
  },
  simpleLink: {
    color: theme.colors.brand.$4Base,
  },
  privacyContent: {
    marginTop: 21,
    padding: 7,
    lineHeight: 21,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: '#71757F',
  },
  button: {
    height: 50,
  },
  buttonText: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
  },
});
