import {useRef, useState} from 'react';

export const ZOOM_VALUES = {
  '12.5%': 0.125,
  '25%': 0.25,
  '50%': 0.5,
  '100%': 1,
  '200%': 2,
  '300%': 3,
  '400%': 4,
};

const getClosestZoom = (zoom: number, dir: 'in' | 'out') => {
  const zoomValues = Object.values(ZOOM_VALUES);
  const index = zoomValues.indexOf(zoom);

  if (index >= 0) {
    const lastIndex = zoomValues.length - 1;

    if (dir === 'in') {
      return index === lastIndex
        ? zoomValues[lastIndex]
        : zoomValues[index + 1];
    }

    return index === 0 ? zoomValues[0] : zoomValues[index - 1];
  }

  if (dir === 'in') {
    return (
      zoomValues.find(value => value > zoom) ??
      zoomValues[zoomValues.length - 1]
    );
  }

  const reversedZoomValues = zoomValues.reverse();
  return reversedZoomValues.find(value => value < zoom) ?? zoomValues[0];
};

export type Zoom = (typeof ZOOM_VALUES)[keyof typeof ZOOM_VALUES];

export default function useZoom() {
  const [zoom, setZoom] = useState(ZOOM_VALUES['100%']);

  const lastZoom = useRef(ZOOM_VALUES['100%']);

  const handleZoomIn = () =>
    setZoom(currentZoom => {
      lastZoom.current = currentZoom;
      return getClosestZoom(currentZoom, 'in');
    });

  const handleZoomOut = () =>
    setZoom(currentZoom => {
      lastZoom.current = currentZoom;
      return getClosestZoom(currentZoom, 'out');
    });

  const handleLastZoom = () => setZoom(lastZoom.current);

  const handleZoom = (zoom: Zoom) => setZoom(zoom);

  return {zoom, handleZoomIn, handleZoomOut, handleZoom, handleLastZoom};
}
