import theme from 'config/theme';
import {Outlet, useNavigate} from 'extensions/navigation';
import AppLogo from 'interface/base/AppLogo';
import {Button} from 'interface/base/Button';
import AppHeaderAccountDropdown from 'interface/stacks/app/layout/AppHeaderAccountDropdown';
import {useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

export enum SubscriptionBackButtonState {
  Disabled = 'Disabled',
  Show = 'Show',
  Remove = 'Remove',
}

export function SubscriptionLayout() {
  const [status, setStatus] = useState<SubscriptionBackButtonState>(
    SubscriptionBackButtonState.Show,
  );

  const navigate = useNavigate();

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        {status === SubscriptionBackButtonState.Show ||
        status === SubscriptionBackButtonState.Disabled ? (
          <Button
            type="Text"
            customRootStyle={{
              backgroundColor: theme.colors.neutral.$white,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            lIcon={
              <Icon
                name="arrow-back"
                size={30}
                color={theme.colors.brand.$4Base}
                style={styles.back}
              />
            }
            ariaLabel="Go Back"
            onPress={() => {
              navigate(-1);
            }}
            disabled={status === SubscriptionBackButtonState.Disabled}
          />
        ) : (
          <AppLogo />
        )}
        <AppHeaderAccountDropdown />
      </View>
      <ScrollView contentContainerStyle={styles.outletWrapper}>
        <Outlet context={[status, setStatus]} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.$15,
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$10,
    backgroundColor: theme.colors.neutral.$white,
  },
  backIcon: {
    paddingVertical: 12,
    paddingHorizontal: '1rem',
  },
  account: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 3,
    paddingLeft: 3,
    paddingRight: 15,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 50,
    borderColor: '#e4e5e7',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  text: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    color: theme.colors.neutral.$2Base,
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: '100%',
  },
  outletWrapper: {
    width: '100%',
    minHeight: '100%',
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  back: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
