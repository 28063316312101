import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useEffect, useRef, useState} from 'react';
import {Pressable, StyleSheet, TextInput, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {validateEmail} from 'utils/common/validation';
import {CustomTextInput} from './CustomTextInput';
import {EmailItemBadge} from './EmailItemBadge';

import type {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  ViewStyle,
} from 'react-native';

interface AddEmailTextAreaProps {
  customRootStyle?: ViewStyle;
  setEmails: (emails: Array<string>) => void;
  numberOfLines?: number;
  emails: Array<string>;
  label?: string;
}

export const AddEmailTextArea = (props: AddEmailTextAreaProps) => {
  const emailsContainerRef = useRef(null);
  const [numberOfLines, setNumberOfLines] = useState<number>(
    props.numberOfLines || 6,
  );
  const [newEmail, setNewEmail] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);

  const toast = useToast();
  const inputRef = useRef(null);
  const containerInputRef = useRef(null);

  useEffect(() => {
    const scrollHeight = emailsContainerRef.current?.scrollHeight;
    const clientHeight = emailsContainerRef.current?.clientHeight;

    if (!scrollHeight || !clientHeight) {
      return;
    }

    if (scrollHeight > clientHeight) {
      setNumberOfLines(prev => prev + 1);
    }
  }, [props.emails]);

  const addEmail = () => {
    const isEmailValid = validateEmail(newEmail);
    if (!isEmailValid) {
      toast.show(t`The email is not valid`, {type: 'danger'});
      return;
    }
    if (!props.emails.includes(newEmail)) {
      props.setEmails(props.emails.concat([newEmail]));
      setNewEmail('');
    } else {
      toast.show(t`The email is already exists`, {type: 'danger'});
      return;
    }
  };

  const removeEmail = (email: string) => {
    const newEmails = props.emails.filter(item => item !== email);
    props.setEmails(newEmails);
  };

  const removeLastEmail = () => {
    const newEmails = props.emails.slice(0, -1);
    props.setEmails(newEmails);
  };

  const onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    const {key} = e.nativeEvent;
    // Remove the last email by pressing Backspace with no input
    if (!newEmail && key === 'Backspace') {
      e.preventDefault();
      removeLastEmail();
      return;
    }
    // Submit the email by pressing Enter, Tab, Space or comma with input
    if (
      newEmail &&
      (key === 'Enter' || key === 'Tab' || key === ' ' || key === ',')
    ) {
      e.preventDefault();
      addEmail();
    }
  };

  return (
    <View style={[styles.root, props.customRootStyle]}>
      <Pressable
        // @ts-ignore
        style={{cursor: 'default'}}
        onPress={() => inputRef.current.focus()}>
        <CustomTextInput
          reference={containerInputRef}
          value={''}
          setValue={() => {}}
          ariaLabel={t`Invite Members by Email`}
          placeholder={
            props.emails.length || newEmail || focused
              ? undefined
              : t`Enter email addresses...`
          }
          label={props.label ?? t`Send invitations to:`}
          customInputStyle={focused ? styles.focused : styles.regular}
          customProps={{
            multiline: true,
            placeholderTextColor: '#8A9098',
            numberOfLines,
          }}
        />
        <View ref={emailsContainerRef} style={styles.emails}>
          {props.emails.map(email => (
            <EmailItemBadge
              key={email}
              email={email}
              deleteString={() => removeEmail(email)}
            />
          ))}
          <TextInput
            ref={inputRef}
            value={newEmail}
            onChangeText={setNewEmail}
            aria-label={t`Email Address`}
            onKeyPress={onKeyPress}
            // The next line of code is the web only style
            // and because of that the next line is removed from the
            // typescript check
            // @ts-ignore
            style={{flex: 1, height: 35, padding: 5, outline: 'none'}}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              if (newEmail) {
                addEmail();
              }
            }}
          />
        </View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    position: 'relative',
  },
  regular: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: `0px -1px ${theme.colors.neutral.$10} inset`,
  },
  focused: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: `0px -2px ${theme.colors.brand.$4Base} inset`,
  },
  emails: {
    overflow: 'hidden',
    position: 'absolute',
    flexDirection: 'row',
    alignContent: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    top: 26,
    bottom: 0,
    left: 0,
    right: 0,
    gap: 5,
    padding: 5,
    marginBottom: 2,
  },
});
