import type {ShareLink} from 'fast-sdk/src/api/share/consts';
import useOpenLinkInNewTab from 'interface/base/hooks/useOpenLinkInNewTab';
import {Pressable, StyleSheet, Text} from 'react-native';
import type {Share} from 'store/slices/shared/types';

interface Props {
  share: Share;
}

export default function ShareHeaderLinks({share}: Props) {
  const {openExternalLink} = useOpenLinkInNewTab();

  const links = [share.link_1, share.link_2, share.link_3].filter(Boolean);

  const openShareLink = (link: ShareLink) => {
    openExternalLink(link.url);
  };

  return (
    <>
      {links.map((link, index) => (
        <Pressable
          key={`share-link-${index}`}
          onPress={() => openShareLink(link)}>
          <Text style={styles.link}>{link.title}</Text>
        </Pressable>
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  link: {
    padding: 16,
  },
});
