import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {useState} from 'react';
import {Pressable, StyleSheet, Text, type ViewStyle} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

interface Props extends React.ComponentProps<typeof Pressable> {
  iconName: string;
  onPress: () => void;
  label: string;
  customStyle?: ViewStyle;
  showDivider?: boolean;
}

export function DropdownButton(props: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Pressable
      style={[
        styles.actionButton,
        props.customStyle,
        {
          backgroundColor: isHover
            ? theme.colors.neutral.$13
            : theme.colors.neutral.$white,
        },
        props.showDivider && styles.divider,
      ]}
      onPress={props.onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      aria-label={props.label}>
      <Icon
        name={props.iconName}
        color={isHover ? theme.colors.neutral.$2Base : theme.colors.neutral.$6}
        size={20}
      />
      <Text style={styles.actionButtonText}>
        <Trans>{props.label}</Trans>
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  actionButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 30,
    gap: 8,
  },
  actionButtonText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  divider: {
    borderBottomColor: theme.colors.neutral.$11,
    borderBottomWidth: 1,
  },
});
