import {t} from '@lingui/macro';
import {Button} from 'interface/base/Button';
import {StyleSheet, Text, View} from 'react-native';

interface Props {
  title: string;
  content: string;
  buttonText?: string;
  buttonOnPress?: () => void;
  hasCancelButton?: boolean;
  hidePrimaryButton?: boolean;
}

export function BillingInfoItem({
  title,
  content,
  buttonText,
  buttonOnPress,
  hasCancelButton,
  hidePrimaryButton,
}: Props) {
  return (
    <View style={styles.root}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.content}>{content}</Text>
      <View style={styles.buttons}>
        {!hidePrimaryButton && buttonText && (
          <Button
            type="Secondary"
            label={buttonText}
            onPress={buttonOnPress}
            customRootStyle={{height: 36}}
          />
        )}
        {hasCancelButton && (
          <Button
            type="Text"
            label={t`Cancel`}
            onPress={() => {}}
            customRootStyle={{height: 36}}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    marginBottom: 7,
  },
  content: {
    fontSize: 21,
    fontWeight: '600',
    lineHeight: 34,
    marginBottom: 21,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: 7,
  },
});
