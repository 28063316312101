import {api} from 'fast-sdk';
import {useEffect, useState} from 'react';
import {blobToBase64} from 'utils/common/image';

export default function useSingleOrgLogo(domain: string) {
  const [logo, setLogo] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!domain) {
      return;
    }

    async function fetchLogo() {
      const logo = await api.organization.getAsset(domain, 'logo');
      blobToBase64(logo, setLogo);
      setLoading(false);
    }

    fetchLogo();
  }, [domain]);

  return {logo, loading};
}
