import {CustomCheckbox} from 'interface/base/CustomCheckbox';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {type OnChangeFilter, type OnRemoveFilter, getLabel} from '../consts';
import DropdownPill from './dropdown-pill/DropdownPill';
import DropdownPillItem from './dropdown-pill/DropdownPillItem';

const label = {
  folders: 'Folders',
  pdfs: 'PDFs',
  documents: 'Documents',
  archives: 'Archives',
  images: 'Photos & Images',
  videos: 'Videos',
  audio: 'Audio',
};

const options = [
  {label: label.folders, value: 'folders'},
  {label: label.pdfs, value: 'pdfs'},
  {label: label.documents, value: 'documents'},
  {label: label.archives, value: 'archives'},
  {label: label.images, value: 'images'},
  {label: label.videos, value: 'videos'},
  {label: label.audio, value: 'audio'},
];

type Props = {
  selected: Array<string>;
  onChange: OnChangeFilter;
  onRemove: OnRemoveFilter;
};

const TypeFilter = ({selected, onChange, onRemove}: Props) => {
  return (
    <DropdownPill
      label={getLabel(selected, 'Type', label)}
      active={Boolean(selected.length)}
      removeFilter={onRemove}>
      {options.map(option => (
        <DropdownPillItem
          key={option.value}
          onPress={() => onChange(option.value)}>
          <CustomCheckbox
            pointerEvents="none"
            customStyle={styles.checkbox}
            check={selected.includes(option.value)}
          />
          <Text numberOfLines={1}>{option.label}</Text>
        </DropdownPillItem>
      ))}
    </DropdownPill>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    paddingHorizontal: 0,
  },
});

export default TypeFilter;
