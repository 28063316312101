import {useNavigate, useParams} from 'extensions/navigation';
import {useAndroidBackButton} from 'extensions/navigation/hooks/useAndroidBackButton';
import {FolderContents} from 'interface/stacks/workspace/storage/FolderContents';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as effectsFiles from 'interface/stacks/workspace/storage/hooks/files';
import * as files from 'store/slices/files';

export function RouteFolder() {
  const {folderKey, workspaceId} = useParams<'folderKey' | 'workspaceId'>();
  const isSearch = useSelector(files.selectors.isSearch);
  const parentKey = useSelector(files.selectors.getParent(folderKey));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchClose = useCallback(
    effectsFiles.search(dispatch, workspaceId, null),
    [],
  );

  const handleBackButton = useCallback(() => {
    if (isSearch) {
      searchClose();
      return true;
    }
    if (folderKey === 'root') {
      return false;
    }
    navigate(parentKey);
    return true;
  }, [folderKey, parentKey, isSearch]);

  useAndroidBackButton(handleBackButton);

  return <FolderContents id={folderKey} />;
}

export default RouteFolder;
