import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Loading} from 'interface/base/Loading';
import {useEffect} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {ActiveQuickShare} from './ActiveQuickShare';
import {ExpirationItem} from './ExpirationItem';

interface Props {
  quickShare;
  createQuickShare: (time: number) => void;
  removeQuickShare: () => void;
  close: () => void;
  status: ExpirationStatus;
  setStatus: (status: ExpirationStatus) => void;
  isRemoveLoading: boolean;
}

export enum ExpirationStatus {
  Start = 'Start',
  Running = 'Running',
  Remove = 'Remove',
  CreateError = 'CreateError',
  CreateLoading = 'CreateLoading',
}

export function Expiration({
  quickShare,
  createQuickShare,
  removeQuickShare,
  status,
  setStatus,
  close,
  isRemoveLoading,
}: Props) {
  useEffect(() => {
    if (quickShare) {
      if (quickShare.duration) {
        setStatus(ExpirationStatus.Running);
      }
    }
  }, [quickShare]);

  const goToCancelMode = () => {
    setStatus(ExpirationStatus.Remove);
  };

  const renderContent = () => {
    switch (status) {
      case ExpirationStatus.Start:
        return (
          <View style={styles.expirationButtons}>
            <ExpirationItem
              label="30 min"
              value={30 * 60}
              setValue={createQuickShare}
            />
            <ExpirationItem
              label="1 hour"
              value={60 * 60}
              setValue={createQuickShare}
            />
            <ExpirationItem
              label="6 hours"
              value={6 * 60 * 60}
              setValue={createQuickShare}
            />
            <ExpirationItem
              label="1 day"
              value={24 * 60 * 60}
              setValue={createQuickShare}
            />
          </View>
        );
      case ExpirationStatus.Running:
        return (
          <ActiveQuickShare
            quickShare={quickShare}
            goToCancelMode={goToCancelMode}
            close={close}
          />
        );
      case ExpirationStatus.Remove:
        return (
          <View>
            <Text style={[styles.text, {fontWeight: '400', marginBottom: 0}]}>
              The link for this file will no longer be valid if you proceed.
              Anyone using this link with will no longer have access.
            </Text>
            <View style={styles.ButtonsContainer}>
              <Button
                type="Secondary"
                label={t`Cancel`}
                onPress={() => {
                  setStatus(ExpirationStatus.Running);
                }}
                ariaLabel="Cancel Removing Quick Share"
              />
              <Button
                type="Secondary"
                label={t`Remove Link`}
                onPress={removeQuickShare}
                ariaLabel="Remove Quick Share"
                customRootStyle={styles.dangerButton}
                customTextStyle={styles.dangerButtonText}
                loading={isRemoveLoading}
                customLoadingColor={theme.colors.neutral.$white}
              />
            </View>
          </View>
        );
      case ExpirationStatus.CreateError:
        return (
          <View style={styles.ButtonsContainer}>
            <Button
              type="Primary"
              label={t`Close`}
              onPress={close}
              ariaLabel="Close"
            />
          </View>
        );
      case ExpirationStatus.CreateLoading:
        return <Loading centered text={t`Creating QuickShare...`} />;
    }
  };

  const headerText = {
    [ExpirationStatus.Start]: t`Choose and expiration time to create QuickShare.`,
    [ExpirationStatus.CreateError]: t`QuickShare cannot be created for this file because it exceeds the maximum file size of 5 GB.`,
  };

  return (
    <>
      {headerText[status] && (
        <Text style={styles.text}>
          <Trans>{headerText[status]}</Trans>
        </Text>
      )}
      {renderContent()}
    </>
  );
}

const styles = StyleSheet.create({
  expirationButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  extendButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  ButtonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 16,
    gap: 10,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    marginBottom: 16,
    color: theme.colors.neutral.$2Base,
  },
  dangerButton: {
    backgroundColor: theme.colors.danger.$4Base,
    borderColor: 'transparent',
  },
  dangerButtonText: {
    color: theme.colors.neutral.$2Base,
  },
});
