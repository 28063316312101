import {type IStorage, Storage} from 'extensions/storage';
import {api} from 'fast-sdk';
import {blobToBase64} from 'utils/common/image';

interface ProfilePhotoConfig {
  cacheDuration?: number;
}

const CACHE_DB_KEY = 'user-photos-v1';
const CACHE_PREFIX = 'photo-';
const DEFAULT_CACHE_DURATION = 5 * 60 * 1000;

class ProfilePhotoService {
  private cacheDuration: number;
  private storage: IStorage;

  constructor(config: ProfilePhotoConfig = {}) {
    this.cacheDuration = config?.cacheDuration || DEFAULT_CACHE_DURATION;
    this.storage = new Storage().init(CACHE_DB_KEY);
  }

  private getCacheKey(userId: string): string {
    return `${CACHE_PREFIX}${userId}`;
  }

  private async saveBase64ToCache(
    userId: string,
    base64: string | undefined,
  ): Promise<void> {
    const cacheData = {
      timestamp: Date.now(),
      data: base64,
    };

    await this.storage.setItem(
      this.getCacheKey(userId),
      JSON.stringify(cacheData),
    );
  }

  private async getCachedPhoto(userId: string): Promise<string | null> {
    try {
      const cacheData = await this.storage.getItem(this.getCacheKey(userId));
      if (!cacheData) return undefined;

      const {timestamp, data} = JSON.parse(cacheData);
      const age = Date.now() - timestamp;

      if (age > this.cacheDuration) {
        await this.clearUserCache(userId);
        return undefined;
      }

      return data ?? '';
    } catch (error) {
      return undefined;
    }
  }

  async getProfilePhoto(userId: string): Promise<string> {
    try {
      const cachedPhoto = await this.getCachedPhoto(userId);
      if (cachedPhoto !== undefined) {
        return cachedPhoto;
      }

      const photoContent = await api.user.getProfilePhoto(userId);

      if (photoContent instanceof Blob) {
        return await new Promise(resolve =>
          blobToBase64(photoContent, content => {
            this.saveBase64ToCache(userId, content);
            resolve(content);
          }),
        );
      }
      this.saveBase64ToCache(userId, '');
    } catch (error) {
      return '';
    }
    return '';
  }

  async updateProfilePhoto(
    userId: string,
    photoContent: string,
  ): Promise<void> {
    try {
      await this.saveBase64ToCache(userId, photoContent);
    } catch (error) {
      console.error('Error in updateProfilePhoto:', error);
    }
  }

  async clearUserCache(userId: string): Promise<void> {
    try {
      const cacheKey = this.getCacheKey(userId);
      const cacheData = await this.storage.getItem(cacheKey);

      if (cacheData) {
        await this.storage.removeItem(cacheKey);
      }
    } catch (error) {
      console.error('Error clearing cache:', error);
      throw error;
    }
  }

  async clearAllCache(): Promise<void> {
    try {
      await this.storage.clearAll();
    } catch (error) {
      console.error('Error clearing all cache:', error);
      throw error;
    }
  }
}

export const profilePhotoService = new ProfilePhotoService();
