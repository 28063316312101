import theme from 'config/theme';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useShares} from 'interface/base/hooks/useShares';
import {useWorkspaceJoinMember} from 'interface/stacks/workspace/hooks/useWorkspaceJoinMember';
import React, {useEffect} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import * as workspace from 'store/slices/workspace';
import PendingInvitationSection from './PendingInvitationSection';
import ShareInvitation from './ShareInvitation';
import WorkspaceInvitation from './WorkspaceInvitation';

export default function PendingInvitations() {
  const {fetchAndUpdateShares, loading: loadingShares} = useShares();
  const {onJoinButtonPress: handleJoinWorkspace} = useWorkspaceJoinMember();

  const pendingWorkspaces = useSelector(
    workspace.selectors.getPendingWorkspaces,
  );
  const sharesLoaded = useSelector(shared.selectors.getSharesLoaded);
  const pendingShares = useSelector(
    shared.selectors.getPendingWorkspaceShares('externals'),
  );

  useEffect(() => {
    if (!sharesLoaded) {
      fetchAndUpdateShares();
    }
  }, [sharesLoaded]);

  const handleJoinShare = async (share: ShareItem) => {
    // TODO: Implement join share
  };

  if (!pendingWorkspaces.length && !Object.keys(pendingShares).length) {
    return null;
  }

  return (
    <View style={styles.root}>
      <Text style={styles.title}>Pending Invitations:</Text>
      {pendingWorkspaces.length > 0 && (
        <PendingInvitationSection
          first={pendingWorkspaces.length > 0}
          title="Workspaces"
          pendingInvitations={pendingWorkspaces}
          renderListItem={(workspace: WorkspaceListDetail, index: number) => (
            <WorkspaceInvitation
              onJoinButtonPress={handleJoinWorkspace}
              workspace={workspace}
              borderProps={{
                borderTop: index > 0,
                borderBottom: index < pendingWorkspaces.length,
                roundedBottom:
                  index === pendingWorkspaces.length - 1 &&
                  !Object.keys(pendingShares).length,
              }}
            />
          )}
        />
      )}
      {Object.keys(pendingShares).length > 0 && (
        <PendingInvitationSection
          first={!pendingWorkspaces.length}
          title="Shares"
          pendingInvitations={Object.values(pendingShares)}
          renderListItem={(share: ShareItem, index: number) => (
            <ShareInvitation
              onJoinButtonPress={handleJoinShare}
              share={share}
              borderProps={{
                borderTop: index > 0,
                borderBottom: index < Object.keys(pendingShares).length,
                roundedBottom: index === Object.keys(pendingShares).length - 1,
              }}
            />
          )}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 35,
    gap: 14,
  },
  title: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
});
