import {t} from '@lingui/macro';
import appLogoDark from 'assets/fastio_logo_assets/logo_dark.svg';
// import appLogoLight from 'assets/fastio_logo_assets/logo_light.svg';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {IconButton} from 'interface/base/IconButton';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

/**
 * Settings side menu header component
 */
export function SettingsGeneralHeader() {
  const navigate = useNavigate();

  // const mode = useColorScheme();

  return (
    <View style={styles.root}>
      <View style={styles.logoName}>
        <View style={styles.orgLogo}>
          <img
            // src={mode === "dark" ? appLogoLight : appLogoDark}
            src={appLogoDark}
            alt="Fast.IO Logo"
            width="142px"
            height="48px"
            style={styles.appLogo}
          />
        </View>
        {/* <Text style={styles.name}>Fast.io</Text> */}
      </View>
      <View style={styles.backButtonContainer}>
        <IconButton
          mode="primary"
          text={t`Back to App`}
          onPress={() =>
            navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/root`)
          }
          icon={<Icon name="arrow-forward" size={18} style={styles.icon} />}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 8,
    padding: 10,
    backgroundColor: theme.colors.neutral.$1,
  },
  logoName: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 7,
    flex: 1,
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$25,
  },
  orgLogo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
  },
  appLogo: {
    display: 'flex',
    flexDirection: 'column',
    width: 142,
    height: 48,
    borderRadius: 100,
  },
  name: {
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '700',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$white,
  },
  backButtonContainer: {
    width: '100%',
    padding: 10,
  },
  backButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 13,
    paddingVertical: 6,
    backgroundColor: theme.colors.brand.$4Base,
  },
  buttonText: {
    color: theme.colors.neutral.$white,
    fontSize: 13,
    lineHeight: 24,
    fontWeight: '400',
  },
  icon: {
    transform: [{rotate: '180deg'}],
    color: theme.colors.neutral.$white,
  },
});
