import {api} from 'fast-sdk';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {useEffect, useState} from 'react';

export const useOrganizationPublicDetails = (domain: string) => {
  const [org, setOrg] = useState<OrganizationDetails | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!domain) {
      return;
    }

    async function fetchOrganizationDetails() {
      const {result, org} =
        await api.organization.getOrganizationPublicDetails(domain);
      if (result) {
        setOrg(org);
      }
      setLoading(false);
    }

    fetchOrganizationDetails();
  }, [domain]);

  return {org, loading};
};
