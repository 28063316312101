import theme from 'config/theme';
import {HoverWrapper} from 'interface/base/HoverWrapper';
import React, {type PropsWithChildren} from 'react';
import {type GestureResponderEvent, Pressable, StyleSheet} from 'react-native';

type Props = {
  onPress: (e: GestureResponderEvent) => void;
};

const DropdownPillItem = ({children, onPress}: PropsWithChildren<Props>) => {
  return (
    <HoverWrapper hoverStyle={styles.hover}>
      <Pressable style={styles.root} onPress={onPress}>
        {children}
      </Pressable>
    </HoverWrapper>
  );
};

const styles = StyleSheet.create({
  hover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  root: {
    cursor: 'pointer',
    maxHeight: 38,
    minHeight: 36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 4,
    gap: 8,
  },
});

export default DropdownPillItem;
