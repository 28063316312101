import theme from 'config/theme';
import {useState} from 'react';
import {
  Pressable,
  StyleSheet,
  Switch,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

type Props = {
  label: string;
  onChange: (inp: boolean) => void;
  checked?: boolean;
  withIcon?: boolean;
  customRootStyle?: ViewStyle;
  customTextStyle?: TextStyle;
};

export function CustomSwitch(props: Props) {
  const [checked, setChecked] = useState<boolean>(props.checked);

  const handleToogle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    props.onChange(newChecked);
  };

  return (
    <View style={{...styles.root, ...props.customRootStyle}}>
      <View style={styles.labelContainer}>
        {props.label && (
          <Text style={[styles.label, props.customTextStyle]}>
            {props.label}
          </Text>
        )}
        {props.withIcon && (
          <View style={styles.icon}>
            <Icon
              name="error"
              size={20}
              style={{color: theme.colors.neutral.$7}}
            />
          </View>
        )}
      </View>
      <Pressable onPress={handleToogle}>
        <Switch
          trackColor={{
            false: theme.colors.neutral.$11,
            true: theme.colors.brand.$4Base,
          }}
          thumbColor={theme.colors.neutral.$white}
          // @ts-ignore This is for the WEB version
          activeThumbColor={theme.colors.neutral.$white}
          value={checked}
          style={styles.switch}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 46,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  label: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  switch: {
    width: 28,
    height: 16,
  },
  icon: {
    marginLeft: 11.5,
  },
});
