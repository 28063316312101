import {CustomTextInput} from 'interface/base/CustomTextInput';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import Layout from './Layout';

interface Props {
  next: () => void;
}

export default function OrganizationName(props: Props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const organizationName = useSelector(
    onboarding.selectors.getOrganizationName,
  );
  const [orgName, setOrgName] = useState<string>(organizationName);

  const next = () => {
    dispatch(onboarding.default.actions.setOrganizationName({orgName}));
    props.next();
  };

  return (
    <Layout
      title="Your organization"
      subtitle="Add the name of your organization. This will be visible on pages you share with your recipients."
      next={next}
      disabled={!orgName || orgName.length < 3 || orgName.length > 100}>
      <View style={styles.root}>
        <CustomTextInput
          customProps={{autoFocus: true}}
          size="Large"
          value={orgName}
          label="Organization name"
          setValue={setOrgName}
          placeholder="Ex: Cyberdyne Marketing"
          customInputStyle={styles.input}
          customRootStyle={{width: '100%'}}
          ariaLabel="Organization Name"
          isInValid={orgName && (orgName.length < 3 || orgName.length > 100)}
          errorMessage="name should be between 3 and 100 characters"
        />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 42,
  },
  input: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    height: 48,
  },
});
