import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';

export interface DropDownItemProps {
  icon: JSX.Element;
  label: string;
  state?: DropDownItemState;
}

export enum DropDownItemState {
  Default = 'Default',
  Hover = 'Hover',
}

/**
 * Item subcomponent for a basic dropdown menu. Use properties to change states, icons, and text.
 */
export function DropDownItem(props: DropDownItemProps) {
  const classes = {
    root: [
      styles.root,
      props.state === DropDownItemState.Hover && styles.rootStateHover,
    ],
  };

  return (
    <View style={classes.root}>
      {!!props.icon && props.icon}
      <Text style={styles.label}>{props.label}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    gap: 8,
    flexDirection: 'row',
    paddingVertical: 7,
    paddingHorizontal: 30,
  },
  rootStateHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  label: {
    color: theme.colors.neutral.$2Base,
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
  },
});
