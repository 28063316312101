import React from 'react';
import {StyleSheet, View} from 'react-native';
import {getHashColor} from 'utils/common/color';

interface WorkspaceBadgeProps {
  id: string;
  size?: number;
}

export function WorkspaceBadge({id, size = 16}: WorkspaceBadgeProps) {
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.workspaceBagde,
          {backgroundColor: getHashColor(id), width: size, height: size},
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  workspaceBagde: {
    width: 16,
    height: 16,
    borderRadius: 3,
  },
  container: {
    padding: 2,
  },
});
