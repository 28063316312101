import {AppHeader} from 'interface/stacks/app/layout/AppHeader';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useParams} from 'react-router';
import {WorkspaceTabList} from '../layout/WorkspaceTabs';
import {PreviewSidePanel} from '../storage/PreviewSidePanel';
import {SharedFoldersList} from './components/main/SharedFoldersList';

interface WorkspaceSharedListProps {
  showArchived?: boolean;
}

export function WorkspaceSharedList({showArchived}: WorkspaceSharedListProps) {
  const {workspaceId} = useParams<'workspaceId'>();

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      <View style={styles.root}>
        <AppHeader search />
        <WorkspaceTabList workspaceAlt={workspaceId} />
        <View style={styles.content}>
          <SharedFoldersList
            workspaceAlt={workspaceId}
            showArchived={showArchived}
          />
          {!showArchived && <PreviewSidePanel workspaceId={workspaceId} />}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  scrollViewContainer: {
    width: '100%',
    display: 'flex',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
});
