import {t} from '@lingui/macro';
import theme from 'config/theme';
import {WORKSPACE_ROOT} from 'constants/routes';
import type {MultiplayerMember} from 'interface/multiplayer/types';
import {AppMemberAvatar} from 'interface/stacks/app/AppMemberAvatar';
import {FolderToolbarButton} from 'interface/stacks/workspace/storage/FolderToolbarButton';
import * as effectsFiles from 'interface/stacks/workspace/storage/hooks/files';
import {useCallback, useState} from 'react';
import {Pressable, StyleSheet, TextInput, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {slices} from 'store';
import {selectors as app} from 'store/slices/app';
import AppHeaderAccountDropdown from './AppHeaderAccountDropdown';

export interface AppHeaderProps {
  id?: string;
  members?: MultiplayerMember[];
  search?: boolean;
  activity?: boolean;
  backBtn?: boolean;
}

/**
 * Header
 *
 * Main header for the app. Includes a search input, list of active team members, and button to access account options
 */
export function AppHeader(props: AppHeaderProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {isOpen: isActivityTrayOpen} = useSelector(app.activityTrayInfo);

  const [stateSearch, setSearch] = useState('');

  const searchSubmit = useCallback(
    effectsFiles.search(dispatch, WORKSPACE_ROOT, stateSearch),
    [stateSearch],
  );

  const searchClose = useCallback(() => {
    setSearch('');
    setTimeout(searchSubmit, 200);
  }, []);

  return (
    <View style={styles.root}>
      <View style={styles.searchBackBtn}>
        {props.backBtn && (
          <Pressable
            style={styles.back}
            onPress={() => navigate('storage/root')}>
            <Icon
              name="keyboard-backspace"
              size={24}
              style={{color: theme.colors.brand.$4Base}}
            />
          </Pressable>
        )}
        {props.search && (
          <View style={styles.search}>
            <Icon
              style={styles.searchGlass}
              name="search"
              size={20}
              color={theme.colors.neutral.$5}
            />
            <TextInput
              style={styles.searchInput}
              value={stateSearch}
              onChangeText={setSearch}
              onSubmitEditing={searchSubmit}
              placeholder={t`Type or press ⌘+F to search files…`}
              placeholderTextColor={theme.colors.neutral.$2Base}
              autoCorrect={false}
              maxLength={40}
            />
            {!!stateSearch && (
              <Pressable onPress={searchClose} style={styles.searchClose}>
                <Icon name="close" size={20} color={theme.colors.neutral.$5} />
              </Pressable>
            )}
          </View>
        )}
      </View>
      <View style={styles.teamShareLinkAccount}>
        {props.members && props.id && (
          <View style={styles.team}>
            {props.members.map(member => (
              <AppMemberAvatar
                key={member.clientId}
                member={member}
                borderKind={'light'}
              />
            ))}
          </View>
        )}
        {props.activity && (
          <FolderToolbarButton
            customTextStyle={
              isActivityTrayOpen && styles.activityTrayButtonText
            }
            customRootStyle={isActivityTrayOpen && styles.activityTrayButton}
            label="Activity"
            onPress={() => {
              dispatch(
                slices.app.actions.toogleActivityTray({
                  isManual: true,
                  isOpen: !isActivityTrayOpen,
                }),
              );
            }}
            icon={
              <MCIcon
                name="timeline-text-outline"
                size={20}
                color={
                  isActivityTrayOpen
                    ? theme.colors.neutral.$white
                    : theme.colors.neutral.$5
                }
              />
            }
          />
        )}
        <AppHeaderAccountDropdown />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    backgroundColor: theme.colors.neutral.$white,
  },
  searchBackBtn: {
    flexDirection: 'row',
    gap: 15,
  },
  back: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  searchGlass: {
    position: 'absolute',
    top: 9,
    left: 9,
    width: 20,
    height: 20,
  },
  searchClose: {
    position: 'absolute',
    top: 9,
    right: 9,
    width: 20,
    height: 20,
  },
  searchInput: {
    width: 280,
    paddingLeft: 35,
    paddingRight: 8,
    paddingVertical: 9.75,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 50,
    borderColor: '#e4e5e7',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  text: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    color: theme.colors.neutral.$2Base,
  },
  teamShareLinkAccount: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 15,
    flex: 1,
  },
  team: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    paddingRight: 5,
  },
  activityTrayButton: {
    backgroundColor: theme.colors.neutral.$25,
  },
  activityTrayButtonText: {
    color: theme.colors.neutral.$white,
  },
  icon: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: '100%',
  },
});
