import {t} from '@lingui/macro';
import theme from 'config/theme';
import {
  ROUTES,
  WORKSPACE_ROOT,
  WORKSPACE_STORAGE,
  WORKSPACE_TRASH,
} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import CircleButton from 'interface/base/CircleButton';
import {Fragment, useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import {selectors} from 'store/slices/files';
import {FolderLayoutSwitchButton} from './FolderLayoutSwitchButton';
import {FolderToolbarButton} from './FolderToolbarButton';
import {FolderToolbarItem} from './FolderToolbarItem';
import {useModalPurge} from './hooks/useModalPurge';

import type {MultiplayerMember} from 'interface/multiplayer/types';
import type {FilesItem} from 'store/slices/files/types';

export interface FolderToolbarProps {
  id: string;
  count: number;
  root: string[];
  members?: MultiplayerMember[];
}

export function FolderToolbar(props: FolderToolbarProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {workspaceId} = useParams<'workspaceId'>();

  const sort = useSelector(selectors.getSort);
  const items = useSelector(selectors.getItems);
  const folder = useSelector(selectors.getItem(props.id));
  const purgeTrashModal = useModalPurge(null, workspaceId, 'workspace');
  const isTrash = props.id === WORKSPACE_TRASH;

  const buildHierarchy = (fileItem: FilesItem): FilesItem[] => {
    return fileItem
      ? [...buildHierarchy(items[fileItem.parent]), fileItem]
      : [];
  };

  const handleNavigation = path => {
    navigate(path);
  };

  const baseUrl = `/workspace/${workspaceId}`;

  const hierarchy = useMemo(
    () => [props.root, ...buildHierarchy(folder)],
    [props.root, folder?.id],
  );

  return (
    <View style={styles.root}>
      {isTrash ? (
        <View style={styles.headerTrash}>
          <CircleButton
            type="Primary"
            iconName="arrow-left"
            showBorder={false}
            onPress={() => {
              navigate(
                `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspaceId}/${WORKSPACE_STORAGE}/${WORKSPACE_ROOT}`,
              );
            }}
          />
          <Text style={styles.headerTrashText}>{t`Trash`}</Text>
        </View>
      ) : (
        <View style={styles.breadcrumbs}>
          {hierarchy.map((item, i, paths) => {
            const last = i === paths.length - 1;
            const isFolder = 'id' in item;
            const folderId = isFolder ? item.id : item[0];
            const folderName = isFolder ? item.name : item[1];
            const isShared = isFolder
              ? item.type === StorageItemType.Link
              : false;
            return (
              <Fragment key={i}>
                <FolderToolbarItem
                  id={folderId}
                  name={folderName}
                  state={last ? 'Current' : 'Default'}
                  onPress={() =>
                    handleNavigation(`${baseUrl}/storage/${folderId}`)
                  }
                  info={
                    last
                      ? `(${props.count} ${props.count === 1 ? 'item' : 'items'})`
                      : undefined
                  }
                  members={last ? [] : props.members}
                  isShared={isShared}
                />
                {!last && (
                  <Icon
                    name="chevron-right"
                    size={20}
                    color={theme.colors.neutral.$8}
                  />
                )}
              </Fragment>
            );
          })}
        </View>
      )}
      <View style={styles.filterSelectionTools}>
        <View style={styles.filters}>
          {isTrash ? (
            <FolderToolbarButton
              label="Empty Trash"
              onPress={() => purgeTrashModal.open()}
              icon={
                <Icon
                  name="delete-outline"
                  size={20}
                  color={theme.colors.danger.$4Base}
                />
              }
            />
          ) : (
            <>
              <FolderToolbarButton
                label="Size"
                isActive={sort.category === 'size'}
                onPress={() =>
                  dispatch(
                    slices.files.actions.sort({
                      category:
                        sort.category !== 'size' || sort.order !== 'desc'
                          ? 'size'
                          : 'name',
                      order:
                        sort.category !== 'size'
                          ? sort.order
                          : sort.order === 'asc'
                            ? 'desc'
                            : 'asc',
                    }),
                  )
                }
                icon={
                  <Icon
                    name={
                      sort.category !== 'size'
                        ? 'filter-list'
                        : sort.order === 'asc'
                          ? 'arrow-upward'
                          : 'arrow-downward'
                    }
                    size={20}
                    color={theme.colors.neutral.$5}
                  />
                }
              />
              <FolderToolbarButton
                label="Trash"
                onPress={() => {
                  navigate(
                    `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspaceId}/${WORKSPACE_STORAGE}/${WORKSPACE_TRASH}`,
                  );
                }}
                icon={
                  <Icon
                    name="delete-outline"
                    size={20}
                    color={theme.colors.neutral.$5}
                  />
                }
              />
              <FolderToolbarButton
                label="Recent"
                isActive={sort.category === 'date'}
                onPress={() => {
                  navigate(
                    `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspaceId}/${ROUTES.LOGGED_IN_WITH_ORG.RECENTS}`,
                  );
                }}
                icon={
                  <Icon
                    name={
                      sort.category !== 'date'
                        ? 'access-time'
                        : sort.order === 'asc'
                          ? 'arrow-upward'
                          : 'arrow-downward'
                    }
                    size={20}
                    color={theme.colors.neutral.$5}
                  />
                }
              />
            </>
          )}
        </View>
        {!isTrash && <FolderLayoutSwitchButton />}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 20,
    backgroundColor: theme.colors.neutral.$white,
  },
  breadcrumbs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterSelectionTools: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  headerTrash: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  headerTrashText: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
});
