import theme from 'config/theme';
import CircleButton from 'interface/base/CircleButton';
import {useCallback} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';

import * as filesEffects from './hooks/files';

export interface FolderPickerHeaderProps {
  id: string;
  rootName: string;
}

export function FolderPickerHeader(props: FolderPickerHeaderProps) {
  const folder = useSelector(selectors.getItem(props.id));
  const pick = useSelector(selectors.getPick);

  const title = folder ? folder.name : props.rootName;
  const isRoot = props.id === 'root';
  const parent = isRoot ? 'root' : folder?.parent;

  const dispatch = useDispatch();
  const close = useCallback(filesEffects.pick(dispatch, null), []);
  const back = useCallback(
    filesEffects.pick(dispatch, {...pick, destination: parent}),
    [pick, parent],
  );

  return (
    <View style={[styles.header, {paddingLeft: isRoot ? 16 : 0}]}>
      {!isRoot && (
        <View style={styles.back}>
          <CircleButton
            type="Primary"
            iconName="arrow-left"
            showBorder={false}
            onPress={back}
          />
        </View>
      )}
      <View style={styles.button}>
        <Text
          style={styles.title}
          numberOfLines={1}
          ellipsizeMode="middle"
          textBreakStrategy="balanced">
          {title}
        </Text>
        <View style={styles.close}>
          <CircleButton
            type="Text"
            iconName="close"
            showBorder={false}
            onPress={close}
          />
        </View>
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.neutral.$12,
    backgroundColor: theme.colors.neutral.$white,
    gap: 4,
    height: 52,
  },
  back: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
  title: {
    flex: 1,
    fontSize: 14,
    color: theme.colors.neutral.$2Base,
  },
  close: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    marginRight: 4,
    height: 46,
    width: 46,
    right: 0,
  },
});
