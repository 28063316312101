import {t} from '@lingui/macro';
import {colors, vectors} from 'config/styles';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Path, Svg} from 'react-native-svg';

type Props = {
  onClose: () => void;
  title: string;
  description: string;
};

export default function PreviewInfo({onClose, title, description}: Props) {
  return (
    <View style={styles.root}>
      <Pressable style={styles.icon} onPress={onClose}>
        <Svg viewBox="0 0 14 14" width={12} height={12}>
          <Path d={vectors.icons.close} fill={colors.neutral.darkgrey} />
        </Svg>
      </Pressable>
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
        <View style={styles.buttonsContainer}>
          <Button
            label={t`Close`}
            onPress={onClose}
            type="Primary"
            customRootStyle={styles.closeButton}
          />
          {/* <Button
            label={t`Create`}
            loading={createLoading}
            onPress={createNewWorkspace}
            type="Primary"
            customRootStyle={styles.rootButton}
            customLoadingColor={theme.colors.neutral.$white}
            disabled={!workspaceName}
          /> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 24,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 7,
    width: 500,
  },
  content: {
    gap: 21,
  },
  icon: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  title: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  description: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  closeButton: {
    width: 57,
    height: 36,
  },
});
