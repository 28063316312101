import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {FilesItemState} from 'store/slices/files/types';
import {resetSlice} from 'store/utils';

import type {Actions, Store} from 'store/slices/files/types';

import {initialState} from '.';
import {
  buildFileDetails,
  ensureRootExists,
  handleFileDeletion,
  handleParentChange,
} from './helpers';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function load(state: Store, action: Actions['Loading']) {
  if (action.payload) {
    state.loading = action.payload;
  } else {
    state.loading = undefined;
  }
}

export function updateFolder(state: Store, action: Actions['UpdateFolder']) {
  const {id, list, instanceId, instanceNs, folderDetails} = action.payload;
  // Create root if not exists
  ensureRootExists(state, instanceId, instanceNs);
  // Create list if not exists
  state[instanceNs][instanceId][id] = [];
  // Update items
  list.forEach(item => {
    state[instanceNs][instanceId][id].push(item.id);
    state.items[item.id] = buildFileDetails(item, instanceId, instanceNs);
  });
  // Update folder details (if exists)
  if (folderDetails) {
    state.items[folderDetails.id] = buildFileDetails(
      folderDetails,
      instanceId,
      instanceNs,
    );
  }
}

export function updateFile(state: Store, action: Actions['UpdateFile']) {
  const {file: newFileInfo, instanceId, instanceNs} = action.payload;
  // Create root if not exists
  ensureRootExists(state, instanceId, instanceNs);
  // Get existing data
  const instanceList = state[instanceNs][instanceId];
  const originalParent = state.items[newFileInfo.id]?.parent;
  // Handle file deletion
  handleFileDeletion(newFileInfo, instanceList);
  // Handle parent folder changes
  handleParentChange(newFileInfo, originalParent, instanceList);
  // Update file details
  state.items[newFileInfo.id] = buildFileDetails(
    newFileInfo,
    instanceId,
    instanceNs,
  );
}

export function pick(state: Store, action: Actions['Picking']) {
  state.pick = action.payload;
}

export function preview(state: Store, action: Actions['Preview']) {
  const {id, name} = action.payload;
  state.preview = {id, name};
  state.previewProgress = 0;
}

export function previewJob(state: Store, action: Actions['PreviewJob']) {
  const {jobId} = action.payload;
  state.preview.jobId = jobId;
}

export function previewProgress(
  state: Store,
  action: Actions['PreviewProgress'],
) {
  const {progress} = action.payload;
  state.previewProgress = progress;
}

export function previewClose(state: Store) {
  state.preview = undefined;
  state.previewProgress = 0;
}

export function move(state: Store, action: Actions['Move']) {
  const {ids} = action.payload;
  state.selection = [];
  state.search = undefined;
  state.query = undefined;
  ids.forEach(id => {
    const item = state.items[id];
    if (item) item.state = FilesItemState.isMoving;
  });
}

export function copy(state: Store, action: Actions['Copy']) {
  const {ids} = action.payload;
  state.selection = [];
  state.search = undefined;
  state.query = undefined;
  ids.forEach(id => {
    const item = state.items[id];
    if (item) item.state = FilesItemState.isCopying;
  });
}

export function update(state: Store, action: Actions['Update']) {
  const {id} = action.payload;
  const item = state.items[id];
  item.state = FilesItemState.isUpdating;
}

export function drag(state: Store, action: Actions['Drag']) {
  const {ids} = action.payload;
  state.drag = ids || undefined;
}

export function select(state: Store, action: Actions['Select']) {
  const {ids, noToggle, focused, itemList} = action.payload;
  const UPLOAD_ID_PREFIX_LENGTH = 8;

  // Range selection
  if (focused && itemList && ids.length === 1) {
    const to = itemList.indexOf(ids[0]);
    const from = itemList.indexOf(focused);
    const list =
      from <= to ? itemList.slice(from, to + 1) : itemList.slice(to, from + 1);
    // Filter out upload items
    const filtered = list.filter(
      i => i.split('-')[0]?.length !== UPLOAD_ID_PREFIX_LENGTH,
    );
    state.selection = filtered;
    // Clear selection
  } else if (ids.length === 0) {
    state.selection = [];
    // Set selection
  } else if (noToggle) {
    state.selection = ids;
    // Toggle selection
  } else {
    ids.forEach(id => {
      const index = state.selection.indexOf(id);
      if (index === -1) {
        state.selection.push(id);
      } else {
        state.selection.splice(index, 1);
      }
    });
  }
}

export function focus(state: Store, action: Actions['Focus']) {
  const {id} = action.payload;
  state.focusedId = id;
}

export function focusType(state: Store, action: Actions['FocusType']) {
  const {type} = action.payload;
  state.focusedType = type;
}

export function follow(state: Store, action: Actions['Follow']) {
  state.following =
    state.following === action.payload.id ? null : action.payload.id;
}

export function resetFolderView(state: Store) {
  state.selection = [];
  state.search = undefined;
  state.query = undefined;
}

export function search(state: Store, action: Actions['Search']) {
  const {id, query, workspaceId} = action.payload;
  state.query = query || undefined;
  state.search = id || undefined;
  if (!state.workspace[workspaceId]) {
    state.workspace[workspaceId] = {};
  }
  state.workspace[workspaceId].search = undefined;
}

export function filter(state: Store, action: Actions['Filtering']) {
  state.filter = action.payload;
}

export function sort(state: Store, action: Actions['Sorting']) {
  state.sort = action.payload;
}

export function layout(state: Store, action: Actions['Layout']) {
  state.layout =
    state.layout === FileListItemLayout.ListNormal
      ? FileListItemLayout.GridSimple
      : FileListItemLayout.ListNormal;
}

export function setThumbnail(state: Store, action: Actions['SetThumbnail']) {
  const {thumbnailKey, source} = action.payload;
  state.thumbnails[thumbnailKey] = source;
}

export function archiveFiles(state: Store, action: Actions['Archive']) {
  const {ids} = action.payload;
  for (const id of ids) {
    delete state.items[id];
    for (const workspaceId in state.workspace) {
      for (const listId in state.workspace[workspaceId]) {
        state.workspace[workspaceId][listId] = state.workspace[workspaceId][
          listId
        ].filter(fileId => fileId !== id);
      }
    }
  }
}
