import {t} from '@lingui/macro';
import {useModal} from 'extensions/viewport/useModal';
import {api} from 'fast-sdk';
import {Prompt} from 'interface/base/Prompt';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as sharedSlice from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';

export default function useDeleteShares() {
  const dispatch = useDispatch();
  const toast = useToast();
  const modal = useModal();

  const [loading, setLoading] = useState(false);

  const confirmDelete = useCallback(async (shares: Share[]) => {
    setLoading(true);
    try {
      const results = await Promise.all(
        shares.map(share => api.share.deleteShare(share.custom_name)),
      );
      if (results) {
        if (shares.length === 1) {
          toast.show(`"${shares[0].title}" permanently deleted.`, {
            type: 'neutral',
          });
        } else {
          toast.show(`${shares.length} shares permanently deleted.`, {
            type: 'neutral',
          });
        }
        results.forEach((result, index) => {
          if (result) {
            dispatch(sharedSlice.default.actions.deleteShare(shares[index]));
          }
        });
      }
    } finally {
      setLoading(false);
      modal.close();
    }
  }, []);

  const deleteShares = useCallback((shares: Share[]) => {
    modal.open(
      <Prompt
        title={
          shares.length === 1
            ? t`Permanently delete "${shares[0].title}"?`
            : t`Permanently delete all archived shares?`
        }
        text={t`If you proceed you will no longer be able to restore the selected item(s). Do you want to continue?`}
        buttons={[
          {
            mode: 'cancel',
            text: t`Cancel`,
            onPress: modal.close,
          },
          {
            mode: 'primary',
            text: t`Permanently Delete`,
            onPress: () => confirmDelete(shares),
          },
        ]}
      />,
    );
  }, []);

  return {deleteShares, loading};
}
