import {t} from '@lingui/macro';
import theme from 'config/theme';
import {WORKSPACE_TRASH} from 'constants/routes';
import {useParams} from 'extensions/navigation';
import {useModal} from 'extensions/viewport/useModal';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {Button} from 'interface/base/Button';
import {ShareModal} from 'interface/stacks/share/components/ShareModal';
import {StyleSheet, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {SharedCustomizeDialog} from '../shared/dialogs/SharedCustomizeDialog';
import {useAddNewNode} from './hooks/useAddNewNode';
import {useWorkspaceContext} from './hooks/useWorkspaceContext';

import type {FilesItem} from 'store/slices/files/types';

interface Props {
  item: FilesItem | undefined;
}

export function StorageToolbar({item}: Props) {
  const {folderKey} = useParams<'folderKey'>();
  const {workspace, uploader} = useWorkspaceContext();
  const {addNewNodeButtonRef, openAddNewNodePopup} = useAddNewNode({
    instanceId: workspace.id,
    instanceAlt: workspace.folder_name,
    instanceNs: 'workspace',
    folderKey,
    uploader,
  });

  const isSharedFolder = item?.type === StorageItemType.Link;
  const isTrash = folderKey === WORKSPACE_TRASH;

  const modal = useModal();
  const openCreateShareDialog = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspace.folder_name}
        close={modal.close}
        folderParentId={folderKey}
      />,
    );
  };

  const onShareClick = () => {
    modal.open(
      <ShareModal
        workspaceFolderName={workspace.folder_name}
        shareId={item.target_id}
        done={modal.close}
      />,
    );
  };

  const onCustomizeClick = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspace.folder_name}
        shareId={item.target_id}
        close={modal.close}
      />,
    );
  };

  if (isTrash) {
    return null;
  }

  return (
    <View style={styles.root}>
      <View style={styles.buttonsContainer}>
        <Button
          ref={addNewNodeButtonRef}
          type="Primary"
          label={t`New`}
          lIcon={
            <MCIcon name="plus" size={20} color={theme.colors.neutral.$white} />
          }
          onPress={openAddNewNodePopup}
        />
        {!isSharedFolder && (
          <Button
            type="Primary"
            label={t`Create Share`}
            lIcon={
              <MCIcon
                name="package-variant-closed"
                size={20}
                color={theme.colors.neutral.$white}
              />
            }
            onPress={openCreateShareDialog}
          />
        )}
      </View>
      {isSharedFolder && (
        <View style={styles.buttonsContainer}>
          <Button
            type="Primary"
            label={t`Share`}
            lIcon={
              <MCIcon
                name="share"
                size={20}
                color={theme.colors.neutral.$white}
              />
            }
            onPress={onShareClick}
          />
          <Button
            type="Primary"
            label={t`Customize`}
            lIcon={
              <MCIcon
                name="tune-variant"
                size={20}
                color={theme.colors.neutral.$white}
              />
            }
            onPress={onCustomizeClick}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
});
