import '@vidstack/react/player/styles/default/layouts/audio.css';
import '@vidstack/react/player/styles/default/theme.css';
import {sortItems} from 'interface/stacks/workspace/storage/utils/sort';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import contentViewer, {
  selectors as contentViewerSelectors,
} from 'store/slices/content-viewer';
import {selectors as filesSelectors} from 'store/slices/files';
import ContentViewer from '../ContentViewer';
import useContentViewerParams from '../hooks/useContentViewerParams';
import useUploadedBy from '../hooks/useUploadedBy';

const RouteContentViewer = () => {
  const {fileId, instanceId, instanceNs} = useContentViewerParams();
  const file = useSelector(filesSelectors.getItem(fileId));
  const files = useSelector(filesSelectors.getItems);
  const currentFiles = useSelector(contentViewerSelectors.getFiles);
  const currentFolderFiles = useSelector(
    filesSelectors.getFolderAsList(instanceNs, instanceId, file?.parent),
  );
  const sort = useSelector(filesSelectors.getSort);

  useUploadedBy(file?.origin?.creator);

  const dispatch = useDispatch();

  useEffect(() => {
    // File is on array of files meaning that we're in the same folder
    // so we don't need to recalculate new files and neighbors
    const isSameFolder = currentFiles.some(sibling => sibling.id === fileId);
    const isSameLength = currentFiles.length === Object.keys(files).length;
    if (isSameFolder && isSameLength) {
      return;
    }

    const siblings = currentFolderFiles
      .map(item => files[item.id])
      .filter(item => item.type === 'file')
      .sort((a, b) => sortItems(a.id, b.id, files, sort));

    const index = siblings.findIndex(sibling => sibling.id === fileId);
    const prev = index > 0 ? siblings[index - 1] : null;
    const next = index < siblings.length - 1 ? siblings[index + 1] : null;

    dispatch(contentViewer.actions.setNeighbors({prev, next}));
    dispatch(contentViewer.actions.setFiles({files: siblings}));
  }, [fileId, instanceId, files]);

  return <ContentViewer file={file} />;
};

export default RouteContentViewer;
