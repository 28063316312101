import {t} from '@lingui/macro';
import theme from 'config/theme';
import {format} from 'date-fns';
import {Popup} from 'extensions/viewport/Popup';
import {MonthDates} from 'interface/stacks/share/layout/calendar/MonthDates';
import {MonthSelect} from 'interface/stacks/share/layout/calendar/MonthSelect';
import {useCallback, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Button} from './Button';

interface Props {
  date: Date | undefined;
  setDate: (date: Date) => void;
  emptyDateText?: string;
  readonly?: boolean;
  placement?: 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end';
}

export function CustomDatePicker({
  date,
  setDate,
  emptyDateText,
  readonly,
  placement = 'bottom-start',
}: Props) {
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [showDate, setShowDate] = useState(date ?? new Date());

  const openCalendar = useCallback(() => {
    if (!readonly) setIsCalendarOpened(true);
  }, [readonly]);

  const handleDateSelect = (selectedDate: Date) => {
    setDate(selectedDate);
    setShowDate(selectedDate);
    setIsCalendarOpened(false);
  };

  const buttonLabel = date
    ? t`${format(date, 'LLLL d, yyyy')}`
    : (emptyDateText ?? t`Select Date`);

  const triggerButton = (
    <Button
      type="Secondary"
      label={buttonLabel}
      lIcon={<MCIcon name="calendar-month" size={20} />}
      rIcon={
        <MCIcon
          name={isCalendarOpened ? 'chevron-up' : 'chevron-down'}
          size={20}
        />
      }
      onPress={openCalendar}
      customTextStyle={styles.buttonText}
      disabled={readonly}
    />
  );

  return (
    <Popup
      triggerElement={triggerButton}
      placement={placement}
      autoClose
      close={() => setIsCalendarOpened(false)}
      isOpen={isCalendarOpened}
      onOpenChange={openCalendar}>
      <View style={styles.calendar}>
        <MonthSelect showDate={showDate} setShowDate={setShowDate} />
        <MonthDates
          showDate={showDate}
          startDate={date}
          setDate={handleDateSelect}
          isDateRange={false}
        />
      </View>
    </Popup>
  );
}

const styles = StyleSheet.create({
  calendar: {
    width: 294,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
    color: theme.colors.neutral.$2Base,
  },
});
