export function getCurrentUTCDateTime() {
  const now = new Date();
  return now.toISOString().replace('T', ' ').replace('Z', ' UTC');
}

export function parserServerDate(dateStr) {
  const [datePart, timePart] = dateStr.slice(0, -4).split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);
  const millisecond = Math.floor(Number(timePart.split('.')[1]) / 100);

  return new Date(
    Date.UTC(year, month - 1, day, hour, minute, second, millisecond),
  );
}

export function compareDatesInUTC(dateStr1: string, dateStr2: string) {
  const date1 = parserServerDate(dateStr1);
  const date2 = parserServerDate(dateStr2);

  if (date1 < date2) return -1;
  if (date1 > date2) return 1;

  return 0;
}

export function getDatesDifference(dateStr1: string, dateStr2: string) {
  const date1 = parserServerDate(dateStr1);
  const date2 = parserServerDate(dateStr2);

  // Calculate the difference in milliseconds
  return Math.abs(date2.getTime() - date1.getTime());
}
