import theme from 'config/theme';
import {formatRelative} from 'date-fns';
import {Avatar} from 'interface/base/Avatar';
import {StyleSheet, Text, View} from 'react-native';
import {ActivityType} from 'store/slices/quick-share/types';
import type {Member} from 'store/slices/settings/types';
import {createInitials} from 'utils/common/data';

interface Props {
  activityTime: Date;
  member: Member;
  activityType: ActivityType;
}

export function Activity(props: Props) {
  return (
    <View style={styles.root}>
      <Avatar
        color="#FFC804"
        initials={createInitials(
          `${props.member.firstName} ${props.member.lastName}`,
        )}
        photo={props.member.profilePic}
        key={props.member.email}
        size={1}
      />
      <View style={styles.infoContainer}>
        <Text style={styles.nameInfo}>
          <Text
            style={
              styles.name
            }>{`${props.member.firstName} ${props.member.lastName} `}</Text>
          {props.activityType === ActivityType.Created
            ? 'created this share'
            : 'extended the expiration time'}
        </Text>
        <Text style={styles.dateInfo}>
          {formatRelative(props.activityTime, new Date())}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem',
    gap: 10,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
  name: {
    fontWeight: '600',
  },
  nameInfo: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  dateInfo: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
});
