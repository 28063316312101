import {t} from '@lingui/macro';
import theme from 'config/theme';
import {format} from 'date-fns';
import {useNavigate} from 'extensions/navigation';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {BillingInfoItem} from './BillingInfoItem';

interface BillingInfoProps {
  planType: string;
  periodEnd: Date;
  formattedPricePerUser: string;
  billableUsers: number;
  handleScroll: () => void;
}

export default function BillingInfo({
  periodEnd,
  planType,
  formattedPricePerUser,
  billableUsers,
  handleScroll,
}: BillingInfoProps) {
  const navigate = useNavigate();
  const toast = useToast();

  const userDetails = useSelector(user.selectors.getUserDetails);
  const selectedOrg = useSelector(user.selectors.getSelectedOrganization);
  const ownerId = `${selectedOrg.parent}`;
  const isOwner = userDetails?.id && ownerId && userDetails.id === ownerId;

  return (
    <View style={styles.root}>
      <View style={styles.billingInfo}>
        <BillingInfoItem
          title={t`${planType} Plan`}
          content={t`${formattedPricePerUser} per user / mo`}
          buttonText={t`Cancel Subscription`}
          buttonOnPress={() => navigate('/cancel-subscription')}
          hidePrimaryButton={!isOwner}
        />
      </View>
      <View style={styles.billingInfo}>
        <BillingInfoItem
          title={t`Total Billable Users`}
          content={t`${billableUsers}`}
          buttonText={t`User List`}
          buttonOnPress={handleScroll}
        />
      </View>
      <View style={styles.billingInfo}>
        <BillingInfoItem
          title={t`Plan Renewal Date`}
          content={format(periodEnd, 'LLLL d, yyyy')}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 12,
    borderColor: theme.colors.neutral.$11,
    backgroundColor: theme.colors.neutral.$14,
    marginBottom: '2.5rem',
  },
  billingInfo: {
    paddingVertical: 25,
    paddingHorizontal: 30,
    flexBasis: '33.3333333333%',
    borderRightWidth: 1,
    borderRadius: 12,
    borderRightColor: theme.colors.neutral.$11,
  },
});
