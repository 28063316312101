import {api} from 'fast-sdk';
import {WorkspaceUserStatus} from 'fast-sdk/src/api/workspace/consts';
import {useDispatch} from 'react-redux';
import * as workspace from 'store/slices/workspace';

export const useGetWorkspaceDetails = () => {
  const dispatch = useDispatch();

  const fetchWorkspaceDetails = async workspaceId => {
    const {result, workspace: workspaceDetails} =
      await api.workspace.getWorkspaceDetails(workspaceId);
    if (result) {
      const workspaceDetailsWithStatus = {
        ...workspaceDetails,
        user_status: WorkspaceUserStatus.Joined,
      };
      dispatch(
        workspace.default.actions.updateWorkspace({
          id: workspaceId,
          workspace: workspaceDetailsWithStatus,
        }),
      );
      return workspaceDetailsWithStatus;
    }
  };

  return {
    fetchWorkspaceDetails,
  };
};
