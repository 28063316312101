import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';
import {AppUserGroup} from 'store/slices/app/types';

const x = (store: Store) => store;

export const isLoaded = createSelector(x, store => store.app.loaded);
export const isActive = createSelector(x, store => store.app.active);
export const isLoggedIn = createSelector(
  x,
  store =>
    !!(/*store.user.user  && */ (store.user.token && store.user.user.id)),
); // TIP: Comment out store.user.user.id to use legacy api
export const isPremium = createSelector(
  x,
  store => store.app.user && store.app.user.group >= AppUserGroup.Pro,
);

export const getUser = createSelector(x, store => store.app.user);
export const getName = createSelector(x, store => store.app.user?.name);
export const getEkey = createSelector(x, store => store.app.user?.ekey);
export const getEmail = createSelector(x, store => store.app.user?.email);
export const getLimits = createSelector(x, store => store.app.user?.limit);
export const getAvatar = createSelector(x, store => store.app.user?.avatar);
export const getRevision = createSelector(x, store => store.app.revision);
export const getHeader = createSelector(x, store => store.app.user?.header);

export const getStopRedirect = createSelector(
  x,
  store => store.app.stopRedirect,
);

export const getEnvironmentDomain = createSelector(
  x,
  store => store.app.domainOrigin,
);

export const getCurrentJoinInvitation = createSelector(
  x,
  store => store.app.currentJoinInvitation,
);

export const getForceLogout = createSelector(x, store => store.app.forceLogout);

export const getRedirectToRenewSubscription = createSelector(x, store => ({
  redirect: store.app.redirectToRenewSubscription,
  subdomain: store.app.renewSubscriptionSubdomain,
}));

export const getIsInvitationFlow = createSelector(
  x,
  store => store.app.isInvitationFlow,
);

export const getUserPermission = createSelector(
  x,
  store => store.app.userPermission,
);

export const getUserIsAdminOrOwner = createSelector(x, store =>
  ['admin', 'owner'].includes(store.app.userPermission),
);

export const currentGroup = createSelector(x, store => store.app.currentItem);
export const activityTrayInfo = createSelector(
  x,
  store => store.app.activityTrayInfo,
);
