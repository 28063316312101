import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {WorkspaceBadge} from 'interface/base/WorkspaceBadge';
import {useHover} from 'interface/base/hooks/useHover';
import {InvitedBy} from 'interface/stacks/auth/components/InvitedBy';
import {MemberAvatarGroup} from 'interface/stacks/auth/components/MemberAvatarGroup';
import {useState} from 'react';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import useArchiveWorkspace from '../hooks/useArchiveWorkspace';
import useDeleteWorkspace from '../hooks/useDeleteWorkspace';
import {useIsWorkspaceAdmin} from '../hooks/useIsWorkspaceAdmin';
import useLeaveWorkspace from '../hooks/useLeaveWorkspace';
import useUnarchiveWorkspace from '../hooks/useUnarchiveWorkspace';
import {useWorkspaceMembers} from '../hooks/useWorkspaceMembers';
import {FolderToolbarButton} from '../storage/FolderToolbarButton';

type WorkspaceActionButton = {
  icon: string;
  color: string;
  action: () => void;
  loading: boolean;
};

interface WorkspaceCardProps {
  workspace: WorkspaceListDetail;
  onJoinButtonPress: (workspace: WorkspaceListDetail) => Promise<void>;
  invitedByEmail?: string;
}

const isJoinedWorkspace = (status: string) =>
  status.toLowerCase() === WorkspaceUserStatus.Joined;

const getIconButton = ({
  icon,
  color,
  action,
  loading,
}: WorkspaceActionButton) => (
  <FolderToolbarButton
    loading={loading}
    icon={<MCIcon name={icon} size={20} color={color} />}
    onPress={action}
  />
);

export function WorkspaceCard({
  workspace,
  invitedByEmail,
  onJoinButtonPress,
}: WorkspaceCardProps) {
  const {width} = useWindowDimensions();
  const {isHover, onHoverIn, onHoverOut} = useHover();
  const [isJoinLoading, setIsJoinLoading] = useState(false);

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const isAdmin = useIsWorkspaceAdmin(workspace);
  const hasJoined = isJoinedWorkspace(workspace.user_status);

  const {archiveWorkspace, loading: archiveLoading} = useArchiveWorkspace();
  const {leaveWorkspace, loading: leaveLoading} = useLeaveWorkspace();
  const {unarchiveWorkspace, loading: unarchiveLoading} =
    useUnarchiveWorkspace();
  const {deleteWorkspace, loading: deleteLoading} = useDeleteWorkspace();

  const {members, isLoading: membersLoading} = useWorkspaceMembers(
    workspace.id,
    {enabled: hasJoined && isLoggedIn},
  );

  const handleJoin = async () => {
    if (!onJoinButtonPress) return;

    try {
      setIsJoinLoading(true);
      await onJoinButtonPress(workspace);
    } finally {
      setIsJoinLoading(false);
    }
  };

  const actionButtons = {
    archive: {
      icon: 'archive-outline',
      color: theme.colors.neutral.$5,
      action: () => archiveWorkspace(workspace),
      loading: archiveLoading,
    },
    delete: {
      icon: 'trash-can-outline',
      color: theme.colors.danger.$4Base,
      action: () => deleteWorkspace(workspace),
      loading: deleteLoading,
    },
  };

  const renderWorkspaceInfo = () => (
    <View style={styles.infoContainer}>
      <View style={styles.workspaceNameContainer}>
        <WorkspaceBadge id={workspace.id} />
        <Text style={styles.name}>{workspace.name}</Text>
      </View>
      {workspace.description && (
        <Text style={styles.description}>
          <Trans>{workspace.description}</Trans>
        </Text>
      )}
      <MemberAvatarGroup members={members} loading={membersLoading} />
    </View>
  );

  const renderActionButtons = () => {
    if (!hasJoined) {
      return (
        <Button
          type="Primary"
          label={t`Join`}
          onPress={handleJoin}
          loading={isJoinLoading}
          customRootStyle={styles.joinButton}
          customLoadingColor={theme.colors.neutral.$white}
        />
      );
    }

    return (
      <View style={styles.actionButtons}>
        {workspace.archived ? (
          <>
            <Button
              type="Secondary"
              label={t`Restore`}
              onPress={() => unarchiveWorkspace(workspace)}
              loading={unarchiveLoading}
              customLoadingColor={theme.colors.neutral.$1}
              lIcon={
                <MCIcon
                  name="refresh"
                  size={20}
                  color={theme.colors.neutral.$5}
                />
              }
            />
            {getIconButton(actionButtons.delete)}
          </>
        ) : (
          <>
            <Button
              type="Secondary"
              label={isHover ? t`Leave` : t`Joined`}
              onHoverIn={!isAdmin ? onHoverIn : undefined}
              onHoverOut={!isAdmin ? onHoverOut : undefined}
              onPress={() => !isAdmin && leaveWorkspace(workspace)}
              loading={leaveLoading}
              customRootStyle={[
                styles.joinButton,
                (isHover || leaveLoading) && styles.leaveButtonHover,
              ]}
            />
            {getIconButton(actionButtons.archive)}
          </>
        )}
      </View>
    );
  };

  return (
    <View style={styles.root}>
      <View style={styles.contentContainer}>
        {renderWorkspaceInfo()}
        <View
          style={[styles.rightSection, width < 790 && styles.verticalLayout]}>
          {!workspace.archived && workspace.perm_join && (
            <Text style={styles.permJoin}>{workspace.perm_join}</Text>
          )}
          {renderActionButtons()}
        </View>
      </View>
      {!hasJoined && invitedByEmail && (
        <InvitedBy invitedByEmail={invitedByEmail} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    padding: 24,
    backgroundColor: theme.colors.neutral.$14,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderRadius: 14,
    width: '100%',
    gap: 12,
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoContainer: {
    flexDirection: 'column',
    gap: 4,
  },
  workspaceNameContainer: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  name: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  description: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
    paddingVertical: 4,
  },
  rightSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalLayout: {
    flexDirection: 'column',
    gap: 10,
  },
  permJoin: {
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 1,
    paddingHorizontal: 8,
    borderRadius: 50,
    backgroundColor: theme.colors.neutral.$10,
    color: theme.colors.neutral.$2Base,
    marginRight: '1rem',
  },
  joinButton: {
    minWidth: 80,
  },
  leaveButtonHover: {
    backgroundColor: theme.colors.danger.$35Hover,
  },
  actionButtons: {
    flexDirection: 'row',
    gap: 10,
  },
});
