import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';
import {VARIANT, type Variant} from './base/consts';

export interface PreviewSidePanelTimelineDateProps {
  label: string;
  variant?: Variant;
}

export function PreviewSidePanelTimelineDate(
  props: PreviewSidePanelTimelineDateProps,
) {
  const isDark = props.variant === VARIANT.dark;

  const classes = {
    root: {
      ...styles.root,
      backgroundColor: isDark
        ? theme.colors.neutral.$1
        : theme.colors.neutral.$white,
    },
    label: {
      ...styles.label,
      backgroundColor: isDark
        ? theme.colors.neutral.$25
        : theme.colors.neutral.$12,
    },
    labelText: {
      ...styles.labelText,
      color: isDark ? theme.colors.neutral.$10 : theme.colors.neutral.$3,
    },
  };

  return (
    <View style={classes.root}>
      <View style={classes.label}>
        <Text style={classes.labelText}>{props.label}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 15,
  },
  label: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 50,
  },
  labelText: {
    height: 20,
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
  },
});
