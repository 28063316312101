import {t} from '@lingui/macro';
import EmptyWorkspacesIcon from 'assets/svgs/icons/empty-workspaces.svg';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {Button} from 'interface/base/Button';
import {CenteredView} from 'interface/base/CenteredView';
import {Image, StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {WorkspacesToolbar} from '../browse/WorkspacesToolbar';
import {WorkspaceCreate} from '../dialogs/WorkspaceCreate';

export function EmptyWorkspaces() {
  const modal = useModal();
  const dimension = useWindowDimensions();

  const handleCreateWorkspace = () => {
    modal.open(<WorkspaceCreate close={modal.close} />);
  };

  const classes = {
    card: [
      styles.card,
      {
        width: dimension.width > 790 ? 650 : '100%',
      },
    ],
  };

  return (
    <View style={styles.root}>
      <WorkspacesToolbar />
      <CenteredView style={styles.cardWrapper}>
        <View style={classes.card}>
          <Image
            style={styles.workspaceLogo}
            source={{uri: EmptyWorkspacesIcon}}
          />
          <Text>
            {t`There aren't any workspaces available to join. Create a new workspace to get started.`}
          </Text>
          <Button
            type="Primary"
            onPress={handleCreateWorkspace}
            label={t`Create Workspace`}
            lIcon={
              <Icon
                name="add-circle"
                size={24}
                color={theme.colors.neutral.$white}
              />
            }
          />
        </View>
      </CenteredView>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardWrapper: {
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 14,
    borderColor: theme.colors.neutral.$11,
    backgroundColor: theme.colors.neutral.$14,
    padding: 21,
    gap: 14,
    alignItems: 'center',
  },
  workspaceLogo: {
    width: 64,
    height: 64,
  },
  addLogo: {
    width: 24,
    height: 24,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 50,
  },
});
