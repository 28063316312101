import {t} from '@lingui/macro';
import {useModal} from 'extensions/viewport/useModal';
import {Prompt} from 'interface/base/Prompt';
import {useCallback} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import files from 'store/slices/files';
import {purge, purgeAll} from 'utils/fast/storage';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';

export function useModalPurge(
  ids: string[] | null,
  instanceId: string,
  instanceNs: StorageNamespace,
  itemName?: string,
) {
  const isAll = ids === null;
  const contextName =
    ids?.length > 1
      ? t`${ids.length} item${ids.length === 1 ? '' : 's'}`
      : `“${itemName}”`;

  const modal = useModal();
  const toast = useToast();
  const dispatch = useDispatch();

  const submit = useCallback(async () => {
    // Purging all files
    if (isAll) {
      const {result} = await purgeAll(dispatch, instanceId, instanceNs);
      if (result) {
        dispatch(files.actions.purge());
        toast.show(t`Trash files permanently deleted`);
      } else {
        toast.show(t`Failed to permanently delete trash files`, {
          type: 'danger',
        });
      }
      modal.close();
      return;
    }

    // Purging selected files
    const {completes, errors} = await purge(
      dispatch,
      ids,
      instanceId,
      instanceNs,
    );
    if (completes.length === ids.length) {
      dispatch(files.actions.archiveFiles({ids}));
      toast.show(t`"${itemName}" permanently deleted`);
    } else {
      const errorContextName =
        ids?.length > 1
          ? t`${errors.length} item${errors.length === 1 ? '' : 's'}`
          : `“${itemName}”`;
      toast.show(t`Failed to permanently delete ${errorContextName}`, {
        type: 'danger',
      });
    }
    modal.close();
  }, [ids, instanceId, instanceNs]);

  const open = useCallback(() => {
    modal.open(
      <Prompt
        title={
          isAll
            ? t`Permanently delete all files in trash?`
            : t`Permanently delete ${contextName}?`
        }
        text={t`If you proceed you will no longer be able to restore the selected item(s). Do you want to continue?`}
        buttons={[
          {
            mode: 'destructive',
            text: t`Permanently Delete`,
            onPress: submit,
          },
          {
            mode: 'cancel',
            text: t`Cancel`,
            onPress: modal.close,
          },
        ]}
      />,
    );
  }, [ids]);

  return {open, close: modal.close};
}
