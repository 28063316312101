import {Trans, t} from '@lingui/macro';
import {colors, vectors} from 'config/styles';
import theme from 'config/theme';
import {api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {TextInput} from 'interface/base/TextInput';
import {useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import {useToast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {prependedWorkspaceName} from 'utils/fast/common';
import {useLayoutWorkspaces} from '../hooks/useLayoutWorkspaces';

interface WorkspaceCreateProps {
  close: () => void;
}

export function WorkspaceCreate(props: WorkspaceCreateProps) {
  const toast = useToast();

  const [workspaceName, setWorkspaceName] = useState<string>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  const currentOrg = useSelector(user.selectors.getSelectedOrganization);

  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();

  const createNewWorkspace = async () => {
    try {
      setCreateLoading(true);
      const folderName = prependedWorkspaceName(workspaceName, currentOrg.id);
      // The "folder_name" property should be unique, so I prepend the organization
      // id at the beginning of the name and set it as the "folder_name"
      const {result} = await api.workspace.createWorkspace(currentOrg.domain, {
        name: workspaceName.trim(),
        folder_name: folderName,
      });
      if (result) {
        toast.show(`The "${workspaceName.trim()}" workspace is created`, {
          type: 'success',
        });
        fetchAndUpdateWorkspaces();
      }
    } catch (err) {
      toast.show(err, {type: 'danger'});
    } finally {
      setCreateLoading(false);
      props.close();
    }
  };

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Pressable style={styles.icon} onPress={props.close}>
            <Svg viewBox="0 0 14 14" width={12} height={12}>
              <Path d={vectors.icons.close} fill={colors.neutral.darkgrey} />
            </Svg>
          </Pressable>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Text style={styles.title}>
          <Trans>Create a Workspace</Trans>
        </Text>
        <TextInput
          label={t`Workspace name`}
          placeHolder={t`Enter workspace name`}
          value={workspaceName}
          setInputValue={setWorkspaceName}
          customStyle={{marginBottom: 24}}
        />
        <View style={styles.buttonsContainer}>
          <Button
            label={t`Cancel`}
            onPress={props.close}
            type="Secondary"
            customRootStyle={styles.rootButton}
          />
          <Button
            label={t`Create`}
            loading={createLoading}
            onPress={createNewWorkspace}
            type="Primary"
            customRootStyle={styles.rootButton}
            customLoadingColor={theme.colors.neutral.$white}
            disabled={!workspaceName}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 450,
    paddingBottom: 24,
    backgroundColor: '#fff',
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
  container: {},
  contentContainer: {
    paddingHorizontal: 24,
    backgroundColor: '#fff',
  },
  title: {
    lineHeight: 26,
    fontSize: 16.5,
    fontWeight: '600',
    marginBottom: '1.5rem',
  },
  textInput: {},
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
  },
  rootButton: {
    width: 61,
    height: 36,
  },
});
