import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store, WorkspaceSort} from './types';

export const defaultSort: WorkspaceSort = {category: 'name', order: 'asc'};

export const initialState: Store = {
  workspacesLoaded: false,
  workspacesLoading: false,
  workspaces: [],
  sort: defaultSort,
};
const sliceName = 'workspace';

const workspaceSliceTransform = {
  in: (state, key) => {
    if (key === sliceName) {
      return {
        ...state,
        workspacesLoaded: initialState.workspacesLoaded,
      };
    }
    return state;
  },
  out: state => state,
};

export default createSlice({name: sliceName, reducers, initialState});
export {selectors, workspaceSliceTransform};
