import {t} from '@lingui/macro';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import useUserWorkspaces from 'interface/stacks/workspace/hooks/useUserWorkspaces';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import {archive, getDropFiles, move} from 'utils/fast/storage';

import * as files from 'store/slices/files';

import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {DragEvent} from 'react';
import type {FilesItem} from 'store/slices/files/types';

type Props = {
  uploader: UploaderState;
  instanceId: string;
  ignoreSelection?: boolean;
};

export function useDragDrop({
  uploader,
  instanceId,
  ignoreSelection = false,
}: Props) {
  const [dropTarget, setDropTarget] = useState<string | null>(null);
  const {workspaces} = useUserWorkspaces();
  const selection = useSelector(files.selectors.getSelection);
  const useSelect = !ignoreSelection && selection.length > 0;
  const toast = useToast();
  const dispatch = useDispatch();

  const start = useCallback(
    (event: DragEvent, item: FilesItem) => {
      const ids = useSelect ? selection : [item.id];
      const urls = ids.length === 1 ? item.id : ids.join('\n');
      dispatch(slices.files.actions.drag({ids}));
      event.dataTransfer.setData('text', urls);
      event.dataTransfer.effectAllowed = 'move';
      try {
        event.dataTransfer.setDragImage(new Image(), 0, 0);
      } catch (e) {}
    },
    [selection, useSelect],
  );

  const enter = useCallback((event: DragEvent, folderId: string) => {
    event.stopPropagation();
    setDropTarget(folderId);
  }, []);

  const leave = useCallback((event: DragEvent) => {
    event.stopPropagation();
    setDropTarget(null);
  }, []);

  const drop = useCallback(
    async (
      event: DragEvent,
      folderId: string,
      targetItem?: FilesItem,
      targetRootId?: string,
      targetRootFolder?: string,
    ) => {
      const instanceId = targetItem?.shareId ?? targetItem?.workspaceId;
      const instanceNs = targetItem?.shareId ? 'share' : 'workspace';

      const isLinkDrop = targetItem?.type === StorageItemType.Link;
      const isWorkspaceDrop = instanceNs === 'workspace';
      const isRootDrop = Boolean(targetRootId);

      // Clear dragging
      dispatch(slices.files.actions.drag({ids: null}));
      setDropTarget(null);

      // Dropped files
      const files = event?.dataTransfer?.files;
      if (files && files.length > 0) {
        getDropFiles(event, async (files: File[]) => {
          const targetInstance = isRootDrop
            ? (targetRootId ?? targetItem?.shareId)
            : isWorkspaceDrop
              ? isLinkDrop
                ? targetItem.target_id
                : workspaces.find(ws => ws.folder_name === instanceId)?.id
              : instanceId;
          const targetFolder =
            isLinkDrop || isRootDrop ? (targetRootFolder ?? 'root') : folderId;
          uploader.instance.addFiles(files, targetFolder, targetInstance);
        });
        // Dropped keys
      } else {
        const text = event?.dataTransfer.getData('text');
        if (!text) return;
        const keys = text.split('\n');
        // Dropped into trash folder
        if (folderId === 'trash') {
          try {
            await archive(dispatch, keys, instanceId, instanceNs);
            toast.show(t`Moved items to trash`);
          } catch (e) {
            toast.show(t`Failed to move items to trash`, {type: 'danger'});
          }
          // Dropped into normal folder
        } else if (!keys.includes(folderId)) {
          // Do nothing if moving files into share root
          if (isRootDrop) return;
          // TODO: handle dropping into a link node (copy to share)
          if (isLinkDrop) {
            console.log(
              '>> drop into link',
              keys,
              folderId,
              instanceId,
              instanceNs,
            );
            return;
          }
          const folderName = targetItem?.name ?? 'root';
          const result = await move(
            dispatch,
            keys,
            folderId,
            instanceId,
            instanceNs,
          );
          if (result.completes.length === keys.length) {
            toast.show(
              t`Moved ${result.completes.length} item${
                result.completes.length === 1 ? '' : 's'
              } to ${folderName}`,
            );
          } else {
            toast.show(
              t`Failed to move ${result.errors.length} item${
                result.errors.length === 1 ? '' : 's'
              } to ${folderName}`,
              {type: 'danger'},
            );
          }
        }
      }
    },
    [instanceId, uploader],
  );

  const end = useCallback(() => {
    dispatch(slices.files.actions.drag({ids: null}));
    setDropTarget(null);
  }, []);

  return {start, enter, leave, drop, end, dropTarget};
}
