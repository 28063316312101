import theme from 'config/theme';
import {subDays} from 'date-fns';
import {Button} from 'interface/base/Button';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import PreviewSidePanelVersionChange from './PreviewSidePanelVersionChange';

const now = new Date();
const yt = subDays(now, 1);
const days = subDays(now, 7);

// TODO: remove these mocked data when API is ready
const mockedVersionChanges = [
  {
    date: new Date(),
    user: {
      id: 'sasdqweqwasdasd0',
      first_name: 'Michael',
      last_name: 'Scott',
    },
    action: 'renamed',
  },
  {
    date: yt,
    user: {
      id: 'sasdqweqwasdasd1',
      first_name: 'Gianfranco',
      last_name: 'Galvez',
    },
    action: 'moved',
  },
  {
    date: days,
    user: {
      id: 'sasdqweqwasdasd2',
      first_name: 'Mauricio',
      last_name: 'Castaneda',
    },
    action: 'modified',
  },
];

const PreviewSidePanelVersions = () => {
  return (
    <View style={styles.root}>
      <Text style={styles.title}>Version History</Text>
      <View>
        <View style={styles.current}>
          <View style={styles.left}>
            <View style={styles.circle} />
            <View style={styles.line} />
          </View>
          <Text style={styles.right}>Current Version</Text>
        </View>
        {mockedVersionChanges.map((version, index) => (
          <PreviewSidePanelVersionChange
            key={version.date.toISOString()}
            action={version.action}
            date={version.date}
            // @ts-ignore
            user={version.user}
            last={index === mockedVersionChanges.length - 1}
          />
        ))}
      </View>
      <Button
        type="Text"
        label="Show More"
        onPress={() => {}}
        customTextStyle={styles.linkText}
        customRootStyle={styles.link}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  title: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 20,
    paddingBottom: 20,
    color: theme.colors.neutral.$2Base,
  },
  current: {
    height: 50,
    borderRadius: 7,
    backgroundColor: theme.colors.brand.$9,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'flex-end',
  },
  left: {
    width: 30,
    alignItems: 'center',
  },
  right: {
    alignSelf: 'center',
    color: theme.colors.neutral.$2Base,
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
  },
  line: {
    width: 2,
    height: 20,
    backgroundColor: theme.colors.brand.$7,
  },
  circle: {
    width: 10,
    height: 10,
    backgroundColor: theme.colors.brand.$7,
    borderRadius: 50,
  },
  link: {
    alignSelf: 'flex-start',
  },
  linkText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.brand.$4Base,
  },
});

export default PreviewSidePanelVersions;
