import {api} from 'fast-sdk';
import type {BillingStatus} from 'fast-sdk/src/api/billing/consts';
import {useEffect, useState} from 'react';

type Options = {
  disable?: boolean;
};

const defaultOptions: Options = {
  disable: false,
};

export default function useBillingDetails(
  domain: string,
  options = defaultOptions,
) {
  const [loading, setLoading] = useState(true);
  const [billingStatus, setBillingStatus] = useState<BillingStatus | null>(
    null,
  );

  useEffect(() => {
    if (!domain || options.disable) {
      return;
    }

    setLoading(true);

    async function fetchBillingDetails() {
      const {result, billing_status} =
        await api.billing.getSubscriptionDetails(domain);

      if (result) {
        setBillingStatus(billing_status);
      }

      setLoading(false);
    }

    fetchBillingDetails();
  }, [domain, options?.disable]);

  return {billingStatus, loading};
}
