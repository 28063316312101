import {ROUTES} from 'constants/routes';
import {LOGOUT_EVENT_KEY} from 'constants/sessionRoutes';
import {useEffect} from 'react';
import {Platform} from 'react-native';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import {clearCache} from 'utils/common/cache';
import {
  getEnvironmentDomain,
  getEnvironmentProtocol,
} from 'utils/common/version';
import {useSubDomain} from './useSubDomain';

type LogoutOptions = {
  redirectToLogout?: boolean;
  callback?: () => void;
  navigateToSignin?: boolean;
  showLoader?: boolean;
  broadcast?: boolean;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {subdomain} = useSubDomain();

  const showLoaderPromise = (showLoader: boolean) => {
    if (showLoader) {
      dispatch(app.default.actions.load({loaded: false}));
    }
    return new Promise(resolve => {
      if (!showLoader) return resolve(true);
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
  };

  const handleRedirection = async (
    redirectToLogout: boolean,
    navigateToSignin: boolean,
  ) => {
    if (redirectToLogout) {
      const host = await getEnvironmentDomain();
      const protocol = await getEnvironmentProtocol();
      const path =
        subdomain === 'go'
          ? `${protocol}://${host}/${ROUTES.NOT_LOGGED_IN.SIGNIN}`
          : `${protocol}://${host}/${ROUTES.NOT_LOGGED_IN.LOGOUT}`.replace(
              subdomain,
              'go',
            );
      window.location.href = path;
    } else if (navigateToSignin) {
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }
  };

  const handleLogout = async ({
    redirectToLogout = false,
    callback = undefined,
    navigateToSignin = false,
    showLoader = true,
  } = {}) => {
    await showLoaderPromise(showLoader);
    await clearCache(true);
    callback?.();
    await handleRedirection(redirectToLogout, navigateToSignin);

    if ((showLoader || navigateToSignin) && !redirectToLogout) {
      dispatch(app.default.actions.load({loaded: true}));
    }
  };

  const broadcastLogout = () => {
    if (Platform.OS === 'web') {
      localStorage.setItem(LOGOUT_EVENT_KEY, Date.now().toString());
    }
  };

  const logout = async (options?: LogoutOptions) => {
    const {broadcast = true, ...restOptions} = options || {};
    if (broadcast) {
      broadcastLogout();
    }
    await handleLogout(restOptions);
  };

  useEffect(() => {
    const handleStorageChange = async (event: StorageEvent) => {
      if (event.key === LOGOUT_EVENT_KEY) {
        await handleLogout({redirectToLogout: true});
      }
    };

    if (Platform.OS === 'web') {
      window.addEventListener('storage', handleStorageChange);
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, []);

  return {logout};
};
