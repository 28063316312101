import theme from 'config/theme';
import type {PropsWithChildren} from 'react';
import {StyleSheet, Text, type TextProps} from 'react-native';

type Props = PropsWithChildren<{}> & TextProps;

const MediaText = ({children, ...props}: Props) => {
  return (
    <Text {...props} style={[styles.text, props.style]}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
  },
});

export default MediaText;
