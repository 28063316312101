import theme from 'config/theme';
import {ContentState, EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import type React from 'react';
import {useEffect, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {StyleSheet, View} from 'react-native';

interface RichTextEditorProps {
  label?: string;
  onChangeText: (html: string) => void;
  content: string;
  placeholder?: string;
  minHeight?: number;
  disabled?: boolean;
}

const getEditorState = (content: string): EditorState => {
  if (!content) return EditorState.createEmpty();

  const contentBlock = htmlToDraft(content);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    return EditorState.createWithContent(contentState);
  }
};

const EMPTY_CONTENT = '<p></p>\n';

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  label,
  content,
  onChangeText,
  placeholder = 'Start typing...',
  minHeight = 200,
  disabled = false,
}) => {
  const [focus, setFocus] = useState(false);
  const [editorState, setEditorState] = useState(getEditorState(content));

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (html === EMPTY_CONTENT) {
      onChangeText(undefined);
    } else {
      onChangeText(html);
    }
  }, [editorState]);

  const toolbar = {
    options: ['inline', 'list', 'link', 'emoji'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    link: {
      options: ['link'],
    },
    emoji: {},
  };

  return (
    <View style={styles.container}>
      {label && <div style={styles.label}>{label}</div>}
      <div onKeyDown={handleKeyDown}>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={toolbar}
          readOnly={disabled}
          placeholder={placeholder}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          editorStyle={{
            minHeight: minHeight - 50,
            ...styles.editorContent,
            ...(focus && {
              borderBottom: `2px solid ${theme.colors.brand.$4Base}`,
            }),
          }}
          wrapperStyle={styles.editorWrapper}
          toolbarStyle={styles.toolbar}
        />
      </div>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  label: {
    marginBottom: 6,
    fontSize: 12,
    fontWeight: '700',
  },
  editor: {
    flex: 1,
    backgroundColor: theme.colors.neutral.$13,
    borderRadius: 4,
  },
  toolbar: {
    margin: 0,
  },
  editorContent: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 14,
  },
  editorWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    borderWidth: 0,
    borderRadius: 4,
    backgroundColor: theme.colors.neutral.$13,
  },
});

export default RichTextEditor;
