import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

interface Props {
  createWorkspace: () => void;
  browseWorkspaces: () => void;
}

export function WorkspacePopup(props: Props) {
  return (
    <View style={styles.root}>
      <Button
        type="Text"
        label={t`Create Workspace`}
        onPress={props.createWorkspace}
        lIcon={
          <Icon name="plus-circle" size={20} color={theme.colors.neutral.$6} />
        }
        customTextStyle={styles.buttonText}
        customLeftIconStyle={styles.buttonIcon}
        customRootStyle={styles.button}
      />
      <Button
        type="Text"
        label={t`Browse Workspaces`}
        onPress={props.browseWorkspaces}
        lIcon={
          <Icon
            name="folder-search"
            size={20}
            color={theme.colors.neutral.$6}
          />
        }
        customTextStyle={styles.buttonText}
        customLeftIconStyle={styles.buttonIcon}
        customRootStyle={styles.button}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 210,
    paddingVertical: '0.5rem',
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 6,

    elevation: 5,
    shadowColor: '#4200FF',
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  buttonIcon: {
    marginRight: 8,
  },
  button: {
    paddingLeft: 30,
    width: '100%',
    justifyContent: 'flex-start',
  },
});
