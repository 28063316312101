import {ROUTES} from 'constants/routes';
import {api} from 'fast-sdk';
import {useLogout} from 'interface/base/hooks/useLogout';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {useNavigate} from 'react-router';
import type {Permission} from './useMemberPermissions';

export const useMemberActions = (
  email: string,
  isInvite: boolean,
  id: string,
  permission: Permission,
  onUpdate: () => void,
) => {
  const {logout} = useLogout();
  const navigate = useNavigate();
  const {subdomain} = useSubDomain();

  const handleAction = async (command: string) => {
    switch (command) {
      case 'resend': {
        const res = await api.organization.addMember(
          subdomain,
          email,
          permission,
          ' ',
        );
        if (res.result) {
          onUpdate();
        }
        break;
      }
      case 'remove': {
        const endpoint = isInvite
          ? api.organization.deleteOrganizationInvitation
          : api.organization.deleteOrganizationMember;
        const res = await endpoint(subdomain, isInvite ? id : email);
        if (res.result) {
          onUpdate();
        }
        break;
      }
      case 'edit':
        navigate(
          `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.profile}`,
        );
        break;
      case 'leave': {
        const res = await api.organization.leaveOrganization(subdomain);
        if (res.result) {
          logout();
        }
        break;
      }
    }
  };

  return {handleAction};
};
