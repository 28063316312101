import {useEffect, useState} from 'react';

import type {MultiplayerConnection, MultiplayerMember} from '../types';

export function useMultiplayerMembers(
  connection: MultiplayerConnection,
  skipSelf?: boolean,
) {
  const {provider} = connection;
  const [members, setMembers] = useState<MultiplayerMember[]>([]);

  useEffect(() => {
    if (!provider) return;

    const update = () => {
      const selfMember =
        provider.awareness.getLocalState() as MultiplayerMember;
      const membersMap = Array.from(provider.awareness.getStates())
        .map(([clientId, state]) => ({
          ...(state as MultiplayerMember),
          clientId,
        }))
        .filter(member => !skipSelf || member.userId !== selfMember.userId)
        .reduce(
          (acc: Map<string, MultiplayerMember>, member: MultiplayerMember) => {
            const existing = acc.get(member.userId);
            if (!existing || member.lastUpdate > existing.lastUpdate) {
              acc.set(member.userId, member);
            }
            return acc;
          },
          new Map<string, MultiplayerMember>(),
        );
      setMembers(Array.from(membersMap.values()));
    };
    update();
    provider.awareness.on('change', update);
    return () => {
      setMembers([]);
      provider.awareness.off('change', update);
    };
  }, [provider]);

  return members;
}
