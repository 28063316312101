import theme from 'config/theme';
import useUploadedBy from 'interface/stacks/content-viewer/hooks/useUploadedBy';
import {PreviewSidePanelComments} from 'interface/stacks/workspace/storage/PreviewSidePanelComments';
import {PreviewSidePanelDetails} from 'interface/stacks/workspace/storage/PreviewSidePanelDetails';
import {PreviewSidePanelHeader} from 'interface/stacks/workspace/storage/PreviewSidePanelHeader';
import {PreviewSidePanelTimeline} from 'interface/stacks/workspace/storage/PreviewSidePanelTimeline';
import {useMemo} from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import * as workspace from 'store/slices/workspace';
import PreviewSidePanelSummary from './PreviewSidePanelSummary';
import PreviewSidePanelVersions from './PreviewSidePanelVersions';
import PreviewFileThumbnail from './thumbnail/PreviewFileThumbnail';

export enum PreviewSidePanelTab {
  FileInfo = 'FileInfo',
  Comments = 'Comments',
  Activity = 'Activity',
  Versions = 'Versions',
}

export interface PreviewSidePanelProps {
  workspaceId: string;
}

// TODO: remove these mocked data when API is ready
const mockedDescription =
  'This image captures a serene night scene featuring a reflective body of water in the foreground. The water perfectly mirrors the buildings and landscape from across the shore, creating a beautiful symmetry. There is a large central building with a rounded rooftop, lit warmly from within, giving it an inviting glow.';

export function PreviewSidePanel(props: PreviewSidePanelProps) {
  const focusedType = useSelector(selectors.getFocusedType);
  const focusedId = useSelector(selectors.getFocused);
  const item = useSelector(selectors.getItem(focusedId));
  const view = useSelector(
    selectors.getFolder('workspace', focusedId, props.workspaceId),
  );
  const {uploadedBy} = useUploadedBy(item?.origin?.creator);
  const currentWorkspace = useSelector(
    workspace.selectors.getWorkspaceByAlt(item?.workspaceId),
  );

  const thumbnail = useMemo(
    () => <PreviewFileThumbnail item={item} />,
    [item?.id],
  );

  return item ? (
    <View style={styles.root}>
      <PreviewSidePanelHeader />
      <ScrollView>
        {thumbnail}
        <View style={styles.nameContainer}>
          <Text style={styles.name}>{item.name}</Text>
        </View>
        {focusedType === PreviewSidePanelTab.FileInfo && (
          <View>
            <PreviewSidePanelSummary description={mockedDescription} />
            <PreviewSidePanelDetails
              size={item?.size}
              type={item?.type}
              items={view?.length ?? 0}
              created={item?.created}
              modified={item?.created}
              location={currentWorkspace.name}
              metadata={item?.file_attributes?.exif_metadata}
              uploadedBy={uploadedBy}
            />
          </View>
        )}

        {focusedType === PreviewSidePanelTab.Comments && (
          <PreviewSidePanelComments item={item} thumbnail={thumbnail} />
        )}

        {focusedType === PreviewSidePanelTab.Activity && (
          <PreviewSidePanelTimeline />
        )}

        {focusedType === PreviewSidePanelTab.Versions && (
          <PreviewSidePanelVersions />
        )}
      </ScrollView>
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  root: {
    width: 400,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.colors.neutral.$12,
    borderLeftWidth: StyleSheet.hairlineWidth,
    backgroundColor: theme.colors.neutral.$white,
  },
  name: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  nameContainer: {
    paddingHorizontal: 20,
    paddingBottom: 5,
    paddingTop: 15,
  },
});
