import {t} from '@lingui/macro';
import Clipboard from '@react-native-clipboard/clipboard';
import analytics from 'extensions/analytics';
import MediaFire from 'mediafire';
import {Linking} from 'react-native';
import config from 'react-native-ultimate-config';
import {bytesize, testBit} from 'utils/common/data';
import {getHost, isWeb} from 'utils/common/platform';

import {slices} from 'store';
import {FilesErrors, FilesItemFlag} from 'store/slices/files/types';

import type {AnyAction, Dispatch} from '@reduxjs/toolkit';
import type {DragEvent} from 'react';
import type {Store as FilesStore} from 'store/slices/files/types';

let _syncFolderTimer: NodeJS.Timeout;

export const api = new MediaFire(getHost());
export const sessionToken = localStorage.getItem('session_token');

// // DEMO
// if (!sessionToken) {
//   sessionToken = window.prompt('Enter a valid MediaFire session token:');
//   localStorage.setItem('session_token', sessionToken);
//   location.reload();
// } else {
//   api.authenticate(sessionToken);
// }

// GENERAL

export async function openPage(url: string) {
  const base = getHost();
  const path = url.includes('://') ? url : `${base}/${url}`;
  openUrl(path);
}

export async function openUrl(url: string) {
  try {
    await Linking.openURL(url);
  } catch (e) {
    try {
      location.href = url;
    } catch (e) {}
  }
}

export function fail(id: string, e: any) {
  const code = e?.body?.response ? e.body.response.error : 0;
  switch (code) {
    case FilesErrors.InvalidToken:
    case FilesErrors.MissingToken:
      break;
    case FilesErrors.InvalidFolderkey:
    case FilesErrors.MissingFolderkey:
    case FilesErrors.AccessDenied:
      localStorage.removeItem('session_token');
      localStorage.reload();
      break;
    default:
      console.log('error:', code, e);
      break;
  }
}

// FILE MANAGEMENT

export async function move(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  destination: string,
) {
  try {
    const files = [];
    const folders = [];
    if (ids.length > 0) {
      ids.forEach(id => (isFolderkey(id) ? folders.push(id) : files.push(id)));
      await Promise.all([
        files.length && api.file.move(files.join(), destination),
        folders.length && api.folder.move(folders.join(), destination),
      ]);
      dispatch(slices.files.actions.move({ids, destination}));
      // Replace the google gtag (for user event) with the log in bugsnag
      // analytics.gtag('move');
      analytics.log('move');
      return true;
    }
  } catch (e) {
    return e?.body ? e.body.response : e;
  }
}

export async function copy(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  destination: string,
) {
  try {
    const files = [];
    const folders = [];
    if (ids.length > 0) {
      ids.forEach(id => (isFolderkey(id) ? folders.push(id) : files.push(id)));
      await Promise.all([
        files.length &&
          api.file.post('copy.php', {
            quickKey: files.join(),
            folderKey: destination,
          }),
        folders.length &&
          api.folder.post('copy.php', {
            folderKeySrc: folders.join(),
            folderKeyDst: destination,
          }),
      ]);
      dispatch(slices.files.actions.copy({ids, destination}));
      // Replace the google gtag (for user event) with the log in bugsnag
      // analytics.gtag('copy');
      analytics.log('copy');
      return true;
    }
  } catch (e) {
    return e?.body ? e.body.response : e;
  }
}

export async function restore(
  dispatch: Dispatch<AnyAction>,
  ids: string[],
  destination: string,
) {
  try {
    const files = [];
    const folders = [];
    if (ids.length > 0) {
      ids.forEach(id => (isFolderkey(id) ? folders.push(id) : files.push(id)));
      await Promise.all([
        files.length && api.file.move(files.join(), destination),
        folders.length && api.folder.move(folders.join(), destination),
      ]);
      dispatch(slices.files.actions.move({ids, destination}));
      // Replace the google gtag (for user event) with the log in bugsnag
      // analytics.gtag('restore');
      analytics.log('restore');
      return true;
    }
  } catch (e) {
    return e?.body ? e.body.response : e;
  }
}

export async function archive(dispatch: Dispatch<AnyAction>, ids: string[]) {
  try {
    // Replace the google gtag (for user event) with the log in bugsnag
    // analytics.gtag('archive');
    analytics.log('archive');
    const files = ids.filter(id => isQuickkey(id));
    const folders = ids.filter(id => isFolderkey(id));
    dispatch(slices.files.actions.select({ids: []}));
    return await Promise.all([
      files.length > 0 && api.file.post('delete.php', {quickKey: files.join()}),
      folders.length > 0 &&
        api.folder.post('delete.php', {folderKey: folders.join()}),
    ]);
  } catch (e) {
    return e?.body ? e.body.response : e;
  }
}

export async function purge(dispatch: Dispatch<AnyAction>, ids: string[]) {
  try {
    // Replace the google gtag (for user event) with the log in bugsnag
    // analytics.gtag('purge');
    analytics.log('purge');
    const files = ids.filter(id => isQuickkey(id));
    const folders = ids.filter(id => isFolderkey(id));
    dispatch(slices.files.actions.select({ids: []}));
    return await Promise.all([
      files.length > 0 && api.file.post('purge.php', {quickKey: files.join()}),
      folders.length > 0 &&
        api.folder.post('purge.php', {folderKey: folders.join()}),
    ]);
  } catch (e) {
    return e?.body ? e.body.response : e;
  }
}

export async function rename(id: string, name: string) {
  try {
    // Replace the google gtag (for user event) with the log in bugsnag
    // analytics.gtag('rename');
    analytics.log('rename');
    const isFolder = isFolderkey(id);
    const fileType = isFolderkey(id) ? 'folder' : 'file';
    return await api[fileType].post(
      'update.php',
      isFolder
        ? {folderKey: id, foldername: name}
        : {quickKey: id, filename: name},
    );
  } catch (e) {
    return e?.body ? e.body.response : e;
  }
}

export async function privacy(ids: string[], state: 'public' | 'private') {
  // Replace the google gtag (for user event) with the log in bugsnag
  // analytics.gtag('private');
  analytics.log('private');
  ids.forEach(async id => {
    const isFolder = isFolderkey(id);
    const fileType = isFolderkey(id) ? 'folder' : 'file';
    try {
      api[fileType].post(
        'update.php',
        isFolder
          ? {privacy: state, folderKey: id}
          : {privacy: state, quickKey: id},
      );
    } catch (e) {
      return e?.body ? e.body.response : e;
    }
  });
}

export async function download(
  url: string,
  bulk?: boolean,
  forceDownload?: boolean,
) {
  if (!url) return;
  const base = bulk ? url : url.replace(/^http:\/\//g, 'https://');
  const path = forceDownload && base ? base.replace('/view/', '/file/') : base;
  try {
    // Replace the google gtag (for user event) with the log in bugsnag
    // analytics.gtag(bulk ? 'bulk_download' : 'download');
    analytics.log(bulk ? 'bulk_download' : 'download');
    if (bulk && isWeb()) {
      location.href = path;
    } else if (isWeb() && path && path.indexOf('/view/') === -1) {
      window.open(path);
    } else if (path) {
      openPage(path);
    }
  } catch (e) {
    try {
      location.href = path;
    } catch (e) {}
  }
}

export async function createFolder(id: string, name: string) {
  try {
    // Replace the google gtag (for user event) with the log in bugsnag
    // analytics.gtag('create_folder');
    analytics.log('create_folder');
    return await api.folder.post('create.php', {
      parentKey: id,
      foldername: name,
    });
  } catch (e) {
    return e?.body ? e.body.response : e;
  }
}

// FOLDER NAVIGATION

export async function load(
  dispatch: Dispatch<AnyAction>,
  id: string,
  init?: boolean,
  sort?: FilesStore['sort'],
  filter?: FilesStore['filter'],
) {
  switch (id) {
    default:
      return await loadFolder(dispatch, id, init, false, false, sort, filter);
  }
}

export async function loadFolder(
  dispatch: Dispatch<AnyAction>,
  id: string,
  isInit?: boolean,
  isSync?: boolean,
  gallery?: boolean,
  sort?: FilesStore['sort'],
  filter?: FilesStore['filter'],
) {
  try {
    // Sorting
    let orderBy: 'name' | 'size' | 'created' | 'downloads';
    const orderDir = sort ? sort.order : 'asc';
    if (sort) {
      switch (sort.category) {
        case 'name':
          orderBy = 'name';
          break;
        case 'size':
          orderBy = 'size';
          break;
        case 'date':
          orderBy = 'created';
          break;
        case 'downloads':
          orderBy = 'downloads';
          break;
      }
    }

    // Filtering
    let filterBy:
      | 'application'
      | 'archive'
      | 'audio'
      | 'development'
      | 'document'
      | 'image'
      | 'presentation'
      | 'spreadsheet'
      | 'video';
    let filesOnly = false;
    let foldersOnly = false;
    if (filter) {
      switch (filter.type) {
        case 'all':
          filterBy = undefined;
          break;
        case 'files':
          filterBy = undefined;
          filesOnly = true;
          break;
        case 'folders':
          filterBy = undefined;
          foldersOnly = true;
          break;
        case 'private':
          filterBy = 'private' as any; // TODO: update MF-JS-SDK
          break;
        case 'public':
          filterBy = 'public' as any; // TODO: update MF-JS-SDK
          break;
        case 'video':
          filterBy = 'video';
          break;
        case 'audio':
          filterBy = 'audio';
          break;
        case 'images':
          filterBy = 'image';
          break;
        case 'document':
          filterBy = 'document';
          break;
        case 'presentation':
          filterBy = 'presentation';
          break;
        case 'spreadsheet':
          filterBy = 'spreadsheet';
          break;
        case 'development':
          filterBy = 'development';
          break;
      }
    }

    // Retrieve data
    const apiFolder = await api.folder.getInfo(id, 'yes');
    const {
      name,
      flag,
      revision,
      fileCount,
      folderCount,
      totalFiles,
      totalFolders,
    } = apiFolder.folderInfo;
    const isOwned = testBit(flag, FilesItemFlag.isOwned);
    const folderRoot = isOwned ? {id: 'myfiles', name: 'My Files'} : {id, name};
    const [apiDepth, apiFolders, apiFiles] = await Promise.all([
      isOwned && id !== 'myfiles' && !isSync && api.folder.getDepth(id),
      !filesOnly &&
        totalFolders > 0 &&
        api.folder.getContent(
          id,
          'folders',
          1,
          100,
          'yes',
          orderDir,
          orderBy,
          filterBy,
        ),
      !foldersOnly &&
        totalFiles > 0 &&
        api.folder.getContent(
          id,
          'files',
          1,
          100,
          'yes',
          orderDir,
          orderBy,
          gallery ? 'image' : filterBy,
        ),
    ]);

    // Dispatch load
    const details = apiFolder;
    const depth = isOwned && apiDepth && apiDepth.folderDepth;
    const root = isInit && folderRoot;
    const files =
      apiFiles.folderContent && (apiFiles.folderContent.files as any);
    const folders =
      apiFolders.folderContent && (apiFolders.folderContent.folders as any);
    dispatch(
      slices.files.actions.load({id, root, depth, details, files, folders}),
    );

    // Load more
    const moreFiles = apiFiles?.folderContent.moreChunks;
    const moreFolders = apiFolders?.folderContent.moreChunks;
    const moreItems = moreFolders || moreFiles;
    moreItems && crawlFolder(dispatch, id, 2, moreFolders, moreFiles);

    // Sync folder until we leave it
    !isSync && syncFolder(dispatch, id, revision, fileCount, folderCount, sort);
  } catch (e) {
    fail(id, e);
  }
}

export async function loadSearch(
  dispatch: Dispatch<AnyAction>,
  id: string,
  query: string,
) {
  try {
    const search = await api.folder.search(id, query, false);
    const fileKeys = search?.results
      ? search.results.filter(e => isQuickkey(e.quickkey))
      : [];
    const folderKeys = search?.results
      ? search.results.filter(e => isFolderkey(e.folderkey))
      : [];
    const [folders, files] = await Promise.all([
      folderKeys.length > 0 &&
        api.folder.getInfo(folderKeys.map(e => e.folderkey).join()),
      fileKeys.length > 0 &&
        api.file.getInfo(fileKeys.map(e => e.quickkey).join()),
    ]);
    const infoFolders = folders
      ? folders.folderInfos
        ? folders.folderInfos
        : [folders.folderInfo]
      : [];
    const infoFiles = files
      ? files.fileInfos
        ? files.fileInfos
        : [files.fileInfo]
      : [];
    if (search?.results) {
      // Replace the google gtag (for user event) with the log in bugsnag
      // analytics.gtag('search', {results: search.results.length});
      analytics.log(`search-results: ${search.results.length}}`);
      dispatch(
        slices.files.actions.loadMulti({
          folders: infoFolders,
          files: infoFiles,
          virtual: 'search',
        }),
      );
    } else {
      dispatch(slices.files.actions.loadMulti({items: [], virtual: 'search'}));
    }
  } catch (e) {
    dispatch(slices.files.actions.loadMulti({items: [], virtual: 'search'}));
  }
}

export async function loadFile(id: string, dispatch: Dispatch<AnyAction>) {
  const file = await api.file.getInfo(id);
  dispatch(
    slices.files.actions.loadMulti({
      items: [file.fileInfo],
      virtual: 'preview',
    }),
  );
}

export async function syncFolder(
  dispatch: Dispatch<AnyAction>,
  id: string,
  startRevision: number,
  startFileCount: number,
  startFolderCount: number,
  sort?: FilesStore['sort'],
  filter?: FilesStore['filter'],
) {
  try {
    const apiFolder = await api.folder.getInfo(id);
    const {revision, fileCount, folderCount} = apiFolder.folderInfo;
    const {pathname, hash} = {pathname: 'myfiles', hash: ''};
    const current = getFolderKeyFromUrl(pathname, hash);
    if (current === id) {
      if (
        (revision && revision > startRevision) ||
        (fileCount && fileCount !== startFileCount) ||
        (folderCount && folderCount !== startFolderCount)
      ) {
        loadFolder(dispatch, id, false, true, undefined, sort, filter);
      }
      clearTimeout(_syncFolderTimer);
      _syncFolderTimer = setTimeout(
        () =>
          syncFolder(
            dispatch,
            id,
            revision,
            fileCount,
            folderCount,
            sort,
            filter,
          ),
        2500,
      );
    }
  } catch (e) {}
}

export async function crawlFolder(
  dispatch: Dispatch<AnyAction>,
  id: string,
  page: number,
  moreFolders: boolean,
  moreFiles: boolean,
  orderDir?: 'asc' | 'desc',
  orderBy?: 'name' | 'size' | 'created' | 'downloads',
) {
  try {
    const size = 100;
    const [folders, files] = await Promise.all([
      moreFolders &&
        api.folder.getContent(
          id,
          'folders',
          page,
          size,
          'no',
          orderDir,
          orderBy,
        ),
      moreFiles &&
        api.folder.getContent(id, 'files', page, size, 'no', orderDir, orderBy),
    ]);
    const hasFolders =
      folders?.folderContent && !!folders.folderContent.folders.length;
    const hasFiles = files?.folderContent && !!files.folderContent.files.length;
    if (hasFolders) {
      dispatch(
        slices.files.actions.chunk({
          id,
          page,
          size,
          type: 'folders',
          content: folders,
        }),
      );
    }
    if (hasFiles) {
      dispatch(
        slices.files.actions.chunk({
          id,
          page,
          size,
          type: 'files',
          content: files,
        }),
      );
    }
    const loadMoreFolders = hasFolders && folders.folderContent.moreChunks;
    const loadMoreFiles = hasFiles && files.folderContent.moreChunks;
    if (loadMoreFolders || loadMoreFiles) {
      const {pathname, hash} = {pathname: 'myfiles', hash: ''};
      const current = getFolderKeyFromUrl(pathname, hash);
      if (current === id) {
        return await crawlFolder(
          dispatch,
          id,
          page + 1,
          loadMoreFolders,
          loadMoreFiles,
        );
      }
    }
    return true;
  } catch (e) {
    return false;
  }
}

// UTILITIES

export function isQuickkey(id: unknown) {
  const LENGTH_QUICKKEY = 15;
  const LENGTH_QUICKKEY_OLD = 11;
  if (!id || typeof id !== 'string') return false;
  if (id.length !== LENGTH_QUICKKEY && id.length !== LENGTH_QUICKKEY_OLD) {
    return false;
  }
  if (/[^a-z0-9]/.test(id)) return false;
  return true;
}

export function isFolderkey(id: unknown) {
  const LENGTH_FOLDERKEY = 13;
  const key = id?.toString();
  if (!key) return false;
  if (key === 'myfiles' || key === 'trash' || key === 'recent') return true;
  if (key.length !== LENGTH_FOLDERKEY) return false;
  if (/[^a-z0-9]/.test(key)) return false;
  return true;
}

export function getShareLink(id: string, type?: string, name?: string) {
  if (!id) return '';
  const slug = name?.length ? `/${name.split(' ').join('+')}` : '';
  const base = getHost();
  if (!type) type = isFolderkey(id) ? 'folder' : '';
  switch (type) {
    case 'folder':
      return `${base}/storage/${id}${slug}`;
    case 'image':
      return `${base}/view/${id}${slug}/file`;
    default:
      return `${base}/file/${id}${slug}/file`;
  }
}

export function getFolderKeyFromUrl(path: string, hash?: string) {
  for (const pattern of [
    /#\/([a-z0-9]+)/, // hash
    /#([a-z0-9]+)/, // legacy
    /([a-z0-9]+)/, // normal
    /\/folder\/([a-z0-9]+)/, // shared
    /\/file\/([a-z0-9]+)/, // download
    /\/content\/([a-z0-9]+)/, // unicorn
    /\/files\/([a-z0-9]+)/, // reserved
    /\/myfiles\/([a-z0-9]+)/, // reserved
  ]) {
    const pathMatch = path?.match(pattern);
    const pathKey = pathMatch?.pop();
    if (isFolderkey(pathKey)) return pathKey;
    const hashMatch = hash?.match(pattern);
    const hashKey = hashMatch?.pop();
    if (isFolderkey(hashKey)) return hashKey;
  }
  return false;
}

export function parseContentItem(
  item: any,
  hierarchy?: any,
  chain?: any,
  cache?: any,
) {
  const id = item.quickkey || item.folderkey;
  const type = item.filetype || 'folder';
  const name = item.filename || item.name;
  const size =
    item.totalSize && item.totalSize > item.size
      ? item.totalSize
      : item.size || 0;
  const url = item.links
    ? item.links.view || item.links.normalDownload
    : getShareLink(id, type, name);

  let files =
    item.totalFiles > item.fileCount ? item.totalFiles : item.fileCount || 0;
  let folders =
    item.totalFolders > item.folderCount
      ? item.totalFolders
      : item.folderCount || 0;

  // Workaround for empty root folders that had previous items
  if (id === 'myfiles') {
    if (item.folderCount === 0 && item.totalFolders > 0) folders = 0;
    if (item.fileCount === 0 && item.totalFiles > 0) files = 0;
  }

  const parent = hierarchy ? hierarchy[hierarchy.length - 1] : undefined;
  const parentKey = parent ? parent[0] : undefined;
  const parentName = parent ? parent[1] : undefined;

  return {
    id,
    url,
    name,
    type,
    size,
    files,
    folders,
    state: 0,
    flag: item.flag || 0,
    hash: item.hash || '',
    downloads: item.downloads || 0,
    filedrop: item.dropboxEnabled || false,
    owner: item.ownerName,
    revision: item.revision,
    created: item.createdUtc,
    deleted: item.deleteDate,
    parentKey: item.parentFolderkey || parentKey,
    parentName: item.parentName || parentName,
    shared: item.dateSharedUtc,
    description: item.description,
    password: !!item.passwordProtected,
    private: item.privacy === 'private',
    hierarchy: hierarchy
      ? [...hierarchy, [id, name]]
      : chain
        ? [...chain.reverse().map((f: any) => [f.folderkey, f.name])]
        : cache
          ? cache.hierarchy
          : [],
  };
}

export function copyLink(link: string) {
  // Replace the google gtag (for user event) with the log in bugsnag
  // analytics.gtag('copy_link');
  analytics.log('copy_link');
  Clipboard.setString(link);
}

export function getDetails(item: any) {
  if (item.type === 'folder') {
    const folderCount = item.folders >= 9999 ? t`many` : item.folders;
    const fileCount = item.files >= 9999 ? t`many` : item.files;
    const count = folderCount + fileCount;
    return `${count} ${count === 1 ? 'item' : 'items'}`;
  }
  const size = bytesize(item.size);
  if (item.downloads === undefined) return size;
  const downloadCount = item.downloads >= 999999 ? t`many` : item.downloads;
  const downloads = `${downloadCount} download${item.downloads === 1 ? '' : 's'}`;
  return `${downloads}, ${size}`;
}

export function getPhoto(item: any, resolution = '3g') {
  if (!testBit(item.flag, FilesItemFlag.isViewable)) return '';
  const subdomain = `www${Number.parseInt(item.hash.charAt(0), 16) + 1}`;
  const host = `https://www.${config.APP_ORIGIN}`.replace('www', subdomain);
  const authed =
    item.isPrivate && sessionToken ? `?session_token=${sessionToken}` : '';
  return `${host}/convkey/${item.hash.substr(0, 4)}/${item.id}${resolution}.jpg${authed}`;
}

export function getKeysFromUrl(url: string) {
  const parts = url.split(getHost());
  const keys: string[] = [];
  let found: boolean;
  const append = (url: string) => {
    if (isFolderkey(url) || isQuickkey(url)) {
      keys.push(url);
      found = true;
    }
  };

  parts.forEach(url => {
    const segments = url.split('/');
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      const urls = segment.split(',');
      found = false;
      urls.forEach(append);
      if (found) break;
    }
  });

  return keys;
}

export function getDroppedFiles(
  e: DragEvent,
  callback: (files: File[]) => void,
) {
  const items = e.dataTransfer.items;
  const entries = [];
  let directoryFound = false;
  let fileList: any = [];
  let counter = 0;
  const toArray = (list: any) => Array.prototype.slice.call(list || [], 0);
  const errorHandler = () => {};
  const getDirectoryItems = (reader: any, callback: any) => {
    let entries: any = [];
    const readEntries = () => {
      counter++;
      reader.readEntries((results: any) => {
        if (!results.length) {
          entries.sort();
          counter--;
          callback(entries);
        } else {
          entries = entries.concat(toArray(results));
          counter--;
          readEntries();
        }
      }, errorHandler);
    };
    readEntries();
  };

  const readDirectory = (entries: any) => {
    if (entries <= 0) callback(fileList);
    for (let i = 0; i < entries.length; i++) {
      if (entries[i]) {
        if (entries[i].isDirectory) {
          const reader = entries[i].createReader();
          getDirectoryItems(reader, readDirectory);
        } else {
          // Path to the current directory (first entry path in directory)
          let folderPath = entries[i].fullPath.slice(1).split('/'); // Remove starting slash.
          folderPath.pop(); // Remove filename.
          folderPath = folderPath.join('/');
          // Creates a file object from the entry.
          counter++;
          entries[i].file((file: any) => {
            counter--;
            try {
              // Attach the url so we know where its located.
              file.mfRelativePath = `${folderPath}/${file.name}`;
              // Add the file to the array.
              fileList = [...fileList, file];
            } catch (e) {}
            // Check if we are completely done.
            if (counter <= 0) callback(fileList);
          }, errorHandler);
        }
      }
    }
  };

  if (items) {
    for (let i = 0; i < items.length; i++) {
      if (!items[i].webkitGetAsEntry) break;
      entries[i] = items[i].webkitGetAsEntry();
      if (entries[i]?.isDirectory) directoryFound = true;
    }
  }

  if (directoryFound) readDirectory(entries);
  else callback(toArray(e.dataTransfer.files));
}
