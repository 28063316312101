import {useNavigate} from 'extensions/navigation';
import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useToast} from 'react-native-toast-notifications';
import {useGetWorkspaceDetails} from './useGetWorkspaceDetails';

export const useWorkspaceJoinMember = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const {fetchWorkspaceDetails} = useGetWorkspaceDetails();

  const onJoinButtonPress = async (
    workspace: WorkspaceListDetail,
  ): Promise<void> => {
    const {result} = await api.workspace.joinMember(workspace.folder_name);
    if (result) {
      toast.show(`You joined "${workspace.name}" workspace`, {
        type: 'neutral',
      });
      await fetchWorkspaceDetails(workspace.id);
      navigate(`/workspace/${workspace.folder_name}`);
    }
  };

  return {
    onJoinButtonPress,
  };
};
