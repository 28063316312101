import {Transfers, UploadStatus, Uploader} from 'fast-sdk';
import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {compareDatesInUTC, getCurrentUTCDateTime} from 'utils/common/dates';
import {useSnapshot} from 'valtio';

import * as activity from 'store/slices/activity';
import * as user from 'store/slices/user';

import type {Upload} from 'fast-sdk';

export interface UploaderState {
  pause: (uuid?: string) => void;
  resume: (uuid?: string) => void;
  cancel: (uuid?: string) => void;
  retry: (uuid?: string) => void;
  uploads: readonly Upload[];
  instance: Uploader;
  active: boolean;
  errors: Error[];
  bytes: number;
  size: number;
  eta: number;
}

export function useUploader(): UploaderState {
  const [lastUpdate, setLastUpdate] = useState<string>(getCurrentUTCDateTime());
  const [lastCheck, setLastCheck] = useState<number>(Date.now());
  const [active, setActive] = useState<boolean>(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [bytes, setBytes] = useState<number>(0);
  const [size, setSize] = useState<number>(0);
  const [eta, setEta] = useState<number>(0);
  const {subdomain} = useSubDomain();

  const member = useSelector(user.selectors.getUserDetails);
  const activities =
    useSelector(activity.selectors.getUserActivities(member?.id))?.activities ??
    {};
  const uploadsActivity = activities[ActivityTypes.UPLOADS];

  /** Initialize the uploader */
  const instance = useMemo(
    () => new Uploader(subdomain, member.id),
    [subdomain, member.id],
  );

  /** Refresh the uploader on upload activity */
  useEffect(() => {
    if (
      uploadsActivity?.lastServerUpdate &&
      compareDatesInUTC(uploadsActivity?.lastServerUpdate, lastUpdate) > 0
    ) {
      setLastUpdate(uploadsActivity?.lastServerUpdate);
      instance.refresh();
    }
  }, [uploadsActivity]);

  /** Snapshot of the uploader state */
  const uploads: readonly Upload[] = useSnapshot(instance?.state?.uploads);

  /** Pauses all uploads or a specific upload */
  const pause = useCallback(
    (uuid?: string) => {
      const _pause = (uuid?: string) => Transfers.pauseUpload(uuid);
      // Single item
      if (uuid) {
        _pause(uuid);
        // All items
      } else {
        uploads.forEach(item => _pause(item.metadata.uuid));
      }
    },
    [uploads],
  );

  /** Resumes all uploads or a specific upload */
  const resume = useCallback(
    (uuid?: string) => {
      const _resume = (uuid?: string) => Transfers.resumeUpload(uuid);
      // Single item
      if (uuid) {
        _resume(uuid);
        // All items
      } else {
        uploads.forEach(item => _resume(item.metadata.uuid));
      }
    },
    [uploads],
  );

  /** Cancels all uploads or a specific upload */
  const cancel = useCallback(
    (uuid?: string) => {
      const $ = instance?.state.uploads;
      const _cancel = (
        uuid: string,
        sessionId: string,
        status: UploadStatus,
      ) => {
        Transfers.cancelUpload(uuid);
        const deleteState = true;
        const deleteSession = status !== UploadStatus.Complete;
        instance.delete(sessionId, deleteState, deleteSession);
      };
      // Single item
      if (uuid) {
        const index = uploads.findIndex(item => item.metadata.uuid === uuid);
        const upload = uploads[index];
        if (upload) _cancel(uuid, upload.sessionId, upload.status);
        $.splice(index, 1);
        // All items
      } else {
        uploads.forEach(item =>
          _cancel(item.metadata.uuid, item.sessionId, item.status),
        );
        $.splice(0, uploads.length);
      }
    },
    [uploads, instance],
  );

  /** Retries all uploads or a specific upload */
  const retry = useCallback(
    (uuid?: string) => {
      const _retry = (uuid?: string) => Transfers.retryUpload(uuid);
      if (uuid) {
        _retry(uuid);
      } else {
        uploads.forEach(item => _retry(item.metadata.uuid));
      }
    },
    [uploads],
  );

  /** Aggregate totals from all uploads */
  const update = useCallback(() => {
    if (!instance) return;
    const _errors: Error[] = [];
    let _isActive = false;
    let _size = 0;
    let _bytes = 0;
    for (const item of uploads) {
      _size += item.metadata.size;
      _bytes += item.bytes;
      _isActive = _isActive || item.status === UploadStatus.Uploading;
      if (item.error) {
        _errors.push(new Error(item.error));
      }
    }
    const _lastCheck = Date.now();
    const _speed = (_bytes - bytes) / _lastCheck - lastCheck;
    const _eta = _size - _bytes / _speed;
    setEta(_eta);
    setSize(_size);
    setBytes(_bytes);
    setErrors(_errors);
    setActive(_isActive);
    setLastCheck(_lastCheck);
  }, []);

  /** Update the uploader state on optimal render frame */
  useEffect(() => {
    update();
    const i = requestAnimationFrame(update);
    return () => cancelAnimationFrame(i);
  }, [update]);

  /** Return the uploader state */
  return {
    instance,
    uploads,
    pause,
    resume,
    cancel,
    retry,
    active,
    errors,
    bytes,
    size,
    eta,
  };
}
