import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {ChatbotButton} from 'interface/base/error/ChatbotButton';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import useSubscriptionStatus from './hooks/useSubscriptionStatus';

type Props = {
  onRetry?: () => void;
};

const OnboardError = ({onRetry}: Props) => {
  const {handleRetry} = useSubscriptionStatus();

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Icon
            name="alert"
            size={24}
            color={theme.colors.danger.$4Base}
            aria-label="error"
          />
          <Text style={styles.title}>An error ocurred</Text>
        </View>
        <Text style={styles.subtitle}>
          We were unable to complete your subscription due to an error. Please
          retry the checkout step to see if that fixes the issue.
        </Text>
        <Text style={styles.subtitle}>
          You can also chat with us if you prefer. We’d be happy to help you
          out!
        </Text>
        <View style={styles.buttonsContainer}>
          <Button
            type="Primary"
            label="Retry to checkout"
            onPress={onRetry ?? handleRetry}
            customRootStyle={styles.button}
          />
          <ChatbotButton
            customRootStyle={styles.button}
            customTextStyle={styles.buttonText}
            iconWidth={46}
            iconHeight={40}
          />
        </View>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    gap: 14,
    maxWidth: 500,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  title: {
    fontSize: 26,
    lineHeight: 40,
    fontWeight: '700',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
    textAlign: 'center',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 14,
    paddingTop: 14,
  },
  button: {
    flex: 1,
    height: 48,
  },
  buttonText: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default OnboardError;
