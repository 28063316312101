import {AppErrors} from './appErrors';
import {type ErrorConfigurations, ErrorFlowActions} from './types';

export const AppErrorsConfiguration: ErrorConfigurations<AppErrors> = {
  [AppErrors.ProcessingPreviewError]: {
    key: 'PROCESSING_PREVIEW_ERROR',
    flowAction: ErrorFlowActions.Dialog,
  },
  [AppErrors.CreateOrUpdateSubError]: {
    key: 'CREATE_OR_UPDATE_SUB_ERROR',
    flowAction: ErrorFlowActions.Dialog,
  },
  [AppErrors.StripeKeyNotFoundError]: {
    key: 'STRIPE_KEY_NOT_FOUND_ERROR',
    flowAction: ErrorFlowActions.Dialog,
  },
  [AppErrors.CreateOrgError]: {
    key: 'CREATE_ORG_ERROR',
    flowAction: ErrorFlowActions.Dialog,
  },
  [AppErrors.FileNetworkError]: {
    key: 'FILE_NETWORK_ERROR',
    flowAction: ErrorFlowActions.Dialog,
    allowRetry: true,
    allowReset: false,
    retryOptions: {
      maxRetries: 1,
    },
  },
  [AppErrors.FilePreviewError]: {
    key: 'FILE_PREVIEW_ERROR',
    flowAction: ErrorFlowActions.Dialog,
    allowRetry: true,
    allowReset: false,
    retryOptions: {
      maxRetries: 1,
    },
  },
  [AppErrors.EntityWebSocketConnectionError]: {
    key: 'ENTITY_WEBSOCKET_CONNECTION_ERROR',
    flowAction: ErrorFlowActions.NoAction,
  },
  [AppErrors.PollingError]: {
    key: 'POLLING_ERROR',
    flowAction: ErrorFlowActions.NoAction,
  },
  [AppErrors.GenericBoundaryError]: {
    key: 'GENERIC_BOUNDARY_ERROR',
    flowAction: ErrorFlowActions.NoAction,
  },
  [AppErrors.JoinEmailPageLoadError]: {
    key: 'JOIN_EMAIL_PAGE_LOAD_ERROR',
    flowAction: ErrorFlowActions.NoAction,
  },
  [AppErrors.JoinInvitationProcessError]: {
    key: 'JOIN_INVITATION_PROCESS_ERROR',
    flowAction: ErrorFlowActions.Dialog,
  },
  [AppErrors.SignInPasswordError]: {
    key: 'SIGN_IN_PASSWORD_ERROR',
    flowAction: ErrorFlowActions.Toast,
  },
  [AppErrors.LoadUserSessionDataError]: {
    key: 'LOAD_USER_SESSION_DATA_ERROR',
    flowAction: ErrorFlowActions.NoAction,
  },
  [AppErrors.UnknownError]: {
    key: 'UNKNOWN_ERROR',
    friendlyMessage: 'Oops! Something went wrong. Please try again later.',
    flowAction: ErrorFlowActions.Toast,
  },
};
