import theme from 'config/theme';
import {ROUTES, WORKSPACE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import Skeleton from 'interface/base/Skeleton';
import {useHover} from 'interface/base/hooks/useHover';
import {useCallback} from 'react';
import {
  type GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useNavigate} from 'react-router';
import type {FilesItem} from 'store/slices/files/types';
import useGetFileInformation from './hooks/useGetFileInformation';

type FileParentFolderProps = {
  item: FilesItem;
};

export function FileParentFolder({item}: FileParentFolderProps) {
  const navigate = useNavigate();
  const {isHover, onHoverIn, onHoverOut} = useHover();

  //@ts-expect-error
  const sharedParentId = item.share_link?.graft_point;
  const {file: parentFile, loading: parentLoading} = useGetFileInformation({
    instanceId: item.workspaceId,
    fileId: sharedParentId,
  });

  const parentId = parentFile?.id ?? sharedParentId;
  const parentName =
    parentId === WORKSPACE_ROOT ? 'Workspace' : parentFile?.name;

  const goToParent = useCallback(
    (e: GestureResponderEvent) => {
      e.stopPropagation();
      if (parentName) {
        const baseUrl = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${item.workspaceId}`;
        navigate(`${baseUrl}/${WORKSPACE_STORAGE}/${parentId}`);
      }
    },
    [item.workspaceId, parentId],
  );

  const parentFolderName =
    item.type === StorageItemType.Link ? parentName : 'Image Library';

  return (
    <Pressable
      style={styles.folder}
      onPress={goToParent}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}>
      {parentLoading ? (
        <Skeleton rows={1} height={20} />
      ) : (
        Boolean(parentFolderName) && (
          <>
            <MCIcon
              name="folder-outline"
              size={20}
              color={theme.colors.neutral.$4}
            />
            <Text
              style={[styles.folderText, isHover && styles.folderTextHover]}>
              {parentFolderName}
            </Text>
          </>
        )
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  folder: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    width: 200,
    paddingRight: 20,
    paddingLeft: 10,
  },
  folderText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  folderTextHover: {
    textDecorationLine: 'underline',
  },
});
