import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {useLogout} from './useLogout';

export const useCheckForceLogout = () => {
  const dispatch = useDispatch();
  const {logout} = useLogout();

  const forceLogout = useSelector(app.selectors.getForceLogout);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  useEffect(() => {
    if (forceLogout) {
      try {
        if (isLoggedIn) {
          logout({
            redirectToLogout: true,
          });
        }
      } finally {
        dispatch(app.default.actions.setForceLogout(false));
      }
    }
  }, [forceLogout, isLoggedIn]);
};
