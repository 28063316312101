import theme from 'config/theme';
import {PreviewSidePanelDetails} from 'interface/stacks/workspace/storage/PreviewSidePanelDetails';
import {PreviewSidePanelTimeline} from 'interface/stacks/workspace/storage/PreviewSidePanelTimeline';
import {useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as contentViewer from 'store/slices/content-viewer';
import type {FilesItem} from 'store/slices/files/types';
import * as workspace from 'store/slices/workspace';
import {getFileTypeFromMime} from 'utils/fast/files';
import Tabs, {Tab} from '../components/side-bar/Tabs';
import {HEIGHT_TOP_TOOLBAR} from './TopToolbar';

type Props = {
  onClose: () => void;
  file: FilesItem;
  parent: FilesItem;
};

const Sidebar = ({onClose, file, parent}: Props) => {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.FILE_INFO);

  const user = useSelector(contentViewer.selectors.getUploadedBy);
  const currentWorkspace = useSelector(
    workspace.selectors.getWorkspaceByAlt(file.workspaceId),
  );

  const onChangeTab = (tab: Tab) => setSelectedTab(tab);

  // Hidding comments for now
  const showComments = !file.quickShare && false;

  return (
    <View style={styles.root}>
      <Tabs
        onClose={onClose}
        onChangeTab={onChangeTab}
        selected={selectedTab}
        showComments={showComments}
      />
      {selectedTab === Tab.FILE_INFO && (
        <ScrollView style={styles.bottom}>
          <PreviewSidePanelDetails
            size={file.size}
            type={getFileTypeFromMime(file)}
            items={0}
            created={file.created}
            modified={file.created}
            location={currentWorkspace?.name}
            variant={'dark'}
            metadata={file.file_attributes.exif_metadata}
            uploadedBy={user}
          />

          <PreviewSidePanelTimeline variant={'dark'} />
        </ScrollView>
      )}
      {selectedTab === Tab.COMMENTS && <View />}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: 350,
    backgroundColor: theme.colors.neutral.$1,
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.neutral.$black,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$black,
  },
  bottom: {
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px)`,
    flexBasis: 0,
  },
});

export default Sidebar;
