import {useNavigate, useParams} from 'extensions/navigation';
import SettingsContent, {
  PUBLIC_SETTINGS_SECTIONS,
} from 'interface/stacks/settings/SettingsContent';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';

export function RouteSettings() {
  const {settingsSection: section} = useParams<'settingsSection'>();

  const navigate = useNavigate();
  const isAdminOrOwner = useSelector(app.selectors.getUserIsAdminOrOwner);

  useEffect(() => {
    if (
      !isAdminOrOwner &&
      !PUBLIC_SETTINGS_SECTIONS.includes(section as SettingsType)
    ) {
      navigate(`/settings/${SettingsType.profile}`);
    }
  }, [isAdminOrOwner, section]);

  return (
    <>
      <SettingsContent type={SettingsType[section]} />
    </>
  );
}

export default RouteSettings;
