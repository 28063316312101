import analytics from 'extensions/analytics';
import {useLocation} from 'extensions/navigation';
import {Utilities, api} from 'fast-sdk';
import type {SSOTokenParams} from 'fast-sdk/src/api/auth/consts';
import {Loading} from 'interface/base/Loading';
import {useCallback, useEffect, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';

interface LoginSSOProps {
  provider: 'Google' | 'Microsoft' | 'Facebook';
}

export function LoginSSO(props: LoginSSOProps) {
  const toast = useToast();
  const location = useLocation();
  const urlQueries = useMemo(() => Utilities.URLToObject(location.search), []);
  const providerMapper = useCallback(
    async (provider: LoginSSOProps['provider'], urlQueries: SSOTokenParams) => {
      switch (provider) {
        case 'Google':
          return api.auth.getGoogleSSOToken(urlQueries);
        case 'Facebook':
          return api.auth.getFacebookSSOToken(urlQueries);
        case 'Microsoft':
          return api.auth.getMicrosoftSSOToken(urlQueries);
      }
    },
    [],
  );

  const getSendToken = async () => {
    return await providerMapper(props.provider, urlQueries)
      .then(async res => {
        if (res.result) {
          window.opener.postMessage(
            {
              userToken: res.token,
              SSOProvider: props.provider,
              SSOResult: JSON.stringify(true),
            },
            '*',
          );
        }
      })
      .catch(async err => {
        analytics.notify(err);
        toast.show(err.message, {type: 'danger'});
        window.opener.postMessage(
          {
            userToken: '',
            SSOProvider: props.provider,
            SSOResult: JSON.stringify(false),
          },
          '*',
        );
      });
  };

  useEffect(() => {
    if (urlQueries) {
      getSendToken();
    }
  }, []);

  return (
    <View style={styles.root}>
      <Loading />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
