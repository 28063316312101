export default {
  colors: {
    brand: {
      $1: '#000647', // Can be used for text or backgrounds.
      $2: '#002070', // Can be used for text or backgrounds.
      $3: '#0040b8', // Can be used for text or backgrounds.
      $5: '#3d9eff', // Can be used for decorative elements or backgrounds. Works with dark text.
      $6: '#8ac8ff', // Can be used for decorative elements or backgrounds. Works with dark text.
      $7: '#b8dfff', //
      $8: '#d6eeff', // Can be used for decorative elements or backgrounds. Works with dark text.
      $9: '#ebf7ff', //
      $10: '#f5fbff', //
      $35Hover: '#0056e0', // Primary button hover color, slightly darker than Brand 4 (base).
      $4Base: '#006cfa', // Use for MediaFire logo and main UI elements like headings, buttons and text links.
    },
    success: {
      $1: '#003d35', //
      $2: '#035943', //
      $3: '#098656', // Use for text on a white background.
      $5: '#49df91', //
      $6: '#87eeb8', //
      $7: '#b0f8d3', //
      $8: '#cffce5', //
      $9: '#e1feef', //
      $10: '#f0fff7', //
      $35Hover: '#0fae69', // Success button hover color, slightly darker than Success 4 (base).
      $4Base: '#17cf79', // Use for CTAs or decorative elements. Must be used in conjunction with dark text (or the reverse).
    },
    caution: {
      $5: '#ffe085', //
      $6: '#ffe79e', //
      $7: '#ffebad', //
      $8: '#ffeebd', //
      $9: '#fff2cc', //
      $10: '#fff6db', //
      $35Hover: '#ffc42e', // Warning button hover color, slightly darker than Caution 4 (base).
      $4Base: '#ffd666', // Use for warning messages or UI that requires caution. Must be used in conjunction with dark text (#222835)
    },
    danger: {
      $5: '#fe8c6c', //
      $6: '#ffb39e', //
      $7: '#ffd3c7', //
      $8: '#ffe3db', //
      $9: '#ffefeb', //
      $10: '#fff7f5', //
      $35Hover: '#f5623d', // Danger button hover color, slightly darker than Danger 4 (base).
      $4Base: '#fe714d', // Use for error messages or UI that requires attention. Must be used in conjunction with dark text (#222835)
      $e1: '#722a17', // Darker red for use on error elements that need more contrast
      $e2: '#b24124', // Darker red for use on error elements that need more contrast
    },
    neutral: {
      $1: '#191e29', // Can be used for text or backgrounds.
      $3: '#3b414e', // Can be used for text or backgrounds.
      $4: '#555b67', // Can be used for text or backgrounds.
      $5: '#71757f', // Use when lighter text is needed on a white background only (passes WCAG level AA contrast tests, text can be no lighter than this).
      $6: '#8a9098', // Can be used for decorative elements or backgrounds.
      $7: '#a6aab0', // Can be used for decorative elements or backgrounds.
      $8: '#c0c4c7', // Can be used for decorative elements or backgrounds.
      $9: '#d8dcdf', // Can be used for decorative elements or backgrounds.
      $10: '#e4e5e7', // Can be used for decorative elements or backgrounds.
      $11: '#eaeaeb', // Can be used for decorative elements or backgrounds.
      $12: '#eff0f0', // Can be used for decorative elements or backgrounds.
      $13: '#f4f4f5', // Can be used for decorative elements or backgrounds.
      $14: '#f7f7f8', // Can be used for decorative elements or backgrounds.
      $15: '#fafafa', // Lightest gray value, Can be used for decorative elements or backgrounds.
      $25: '#2e3441', //
      $black: '#0d1017', // Darkest gray value, Can be used for text or backgrounds.
      $2Base: '#222835', // Default color for headings and body text.
      $white: '#ffffff', // Can be used for backgrounds or as light text on darker backgrounds.
      $border: '#0000000d', // Can be used for borders
    },
    unique: {
      $discountred: '#d9173a',
    },
    external: {
      $appleblack: '#242424',
      $bloggerorange: '#f06a35',
      $facebookblue: '#1877f2',
      $googlered: '#db4a39',
      $linkedinblue: '#0a66c2',
      $microsoftblue: '#0067b8',
      $pinterestred: '#e60023',
      $redditred: '#ff4500',
      $twitterblue: '#1d9bf0',
    },
  },
};
