import {Trans, t} from '@lingui/macro';
import ShareTypeIcon from 'assets/svgs/fileIcons/Folder_Share.svg';
import theme from 'config/theme';
import type {
  Member,
  OrganizationDetails,
} from 'fast-sdk/src/api/organization/consts';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/base/Button';
import {InvitedBy} from 'interface/stacks/auth/components/InvitedBy';
import {useState} from 'react';
import {Image, StyleSheet, Text, View, useWindowDimensions} from 'react-native';

interface ShareInviteCardProps {
  share: ShareItem;
  org: OrganizationDetails;
  members: Member[];
  onJoinButtonPress: (share: ShareItem) => Promise<void>;
  invitedByEmail?: string;
}

export default function ShareInviteCard({
  share,
  org,
  members,
  invitedByEmail,
  onJoinButtonPress,
}: ShareInviteCardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {width} = useWindowDimensions();

  const handleJoinWorkspace = async () => {
    try {
      setIsLoading(true);
      await onJoinButtonPress(share);
    } finally {
      setIsLoading(false);
    }
  };

  const ShareIcon = () => (
    <View style={styles.iconWrapper}>
      <Image style={styles.iconImage} source={ShareTypeIcon} />
    </View>
  );

  const ShareInfo = () => (
    <View style={styles.titleContainer}>
      <Text style={styles.shareTitle}>
        <Trans>{share.title}</Trans>
      </Text>
      <Text style={styles.organizationName}>
        <Trans>({org.name})</Trans>
      </Text>
    </View>
  );

  const JoinButton = () => (
    <Button
      type="Primary"
      label={t`Join`}
      onPress={handleJoinWorkspace}
      loading={isLoading}
      customRootStyle={styles.button}
      customLoadingColor={theme.colors.neutral.$white}
    />
  );

  return (
    <View style={styles.container}>
      <View style={styles.mainContent}>
        <View style={styles.leftSection}>
          <View style={styles.shareDetails}>
            <ShareIcon />
            <View style={styles.infoWrapper}>
              <ShareInfo />
            </View>
          </View>
        </View>
        <View
          style={[
            styles.buttonContainer,
            width < 790 && styles.buttonContainerMobile,
          ]}>
          <JoinButton />
        </View>
      </View>
      {invitedByEmail && <InvitedBy invitedByEmail={invitedByEmail} />}
    </View>
  );
}

// Styles
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    backgroundColor: theme.colors.neutral.$14,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderRadius: 14,
    width: '100%',
    gap: 20,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  shareDetails: {
    flexDirection: 'row',
    gap: 15,
  },
  infoWrapper: {
    flexDirection: 'column',
    gap: 8,
  },
  titleContainer: {
    gap: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareTitle: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  organizationName: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
  iconWrapper: {
    padding: 12,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.colors.brand.$5,
  },
  iconImage: {
    width: 40,
    height: 40,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonContainerMobile: {
    flexDirection: 'column',
    gap: 10,
  },
  button: {
    minWidth: 80,
  },
});
