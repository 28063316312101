import theme from 'config/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Avatar} from './Avatar';

type Props = {
  badgeColor: string;
  color: string;
  initials: string;
  photo: string;
};

export default function OrgBadge({badgeColor, color, initials, photo}: Props) {
  return (
    <View style={styles.container}>
      <View style={[styles.workspaceColor, {backgroundColor: badgeColor}]} />
      <Avatar
        color={color}
        initials={initials}
        photo={photo}
        hideBorder
        size={-1}
        shapeKind="rounded-square"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  workspaceBagde: {
    width: 16,
    height: 16,
    borderRadius: 3,
  },
  container: {
    padding: 2,
  },
  workspaceColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 8,
    height: 8,
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.neutral.$1,
    zIndex: 2,
  },
});
