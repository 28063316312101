import {api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import {
  type Share,
  type ShareMember,
  ShareViewStatus,
} from 'store/slices/shared/types';
import * as user from 'store/slices/user';
import {getMembersFromUsers} from 'utils/common/members';

interface UseFetchShareViewStatusProps {
  share: Share;
  sharedCustomName: string;
}

export default function useFetchShareViewStatus({
  share,
  sharedCustomName,
}: UseFetchShareViewStatusProps) {
  const dispatch = useDispatch();
  const userDetails = useSelector(user.selectors.getUserDetails);

  const [members, setMembers] = useState<ShareMember[]>([]);
  const [viewStatus, setViewStatus] = useState<ShareViewStatus>(
    ShareViewStatus.Loading,
  );

  const determineViewStatus = (
    members: ShareMember[],
    userId: string,
  ): ShareViewStatus => {
    const shareOwners = members.filter(
      member => member.permissions === 'owner',
    );
    return shareOwners.some(owner => owner.id === userId)
      ? ShareViewStatus.Owner
      : ShareViewStatus.Editor;
  };

  const handleShareMembers = async (customName: string): Promise<void> => {
    try {
      const {result, users, error} =
        await api.share.getAllShareMembers(customName);

      if (result && users) {
        const newMembers = getMembersFromUsers(users as ShareMember[]);
        setMembers(newMembers);
        setViewStatus(determineViewStatus(newMembers, userDetails.id));
      } else {
        setViewStatus(
          error.code === ApiErrors.ProfileNotFound || !share
            ? ShareViewStatus.Closed
            : ShareViewStatus.Viewer,
        );
      }
    } catch (error) {
      setViewStatus(ShareViewStatus.Viewer);
    }
  };

  useEffect(() => {
    if (sharedCustomName) {
      handleShareMembers(sharedCustomName);
    }
  }, [sharedCustomName, userDetails.id]);

  useEffect(() => {
    if (share && viewStatus !== ShareViewStatus.Loading) {
      dispatch(
        shared.default.actions.setShareViewStatus({
          share,
          status: viewStatus,
        }),
      );

      dispatch(
        shared.default.actions.setShareMembers({
          share,
          members,
        }),
      );
    }
  }, [share, viewStatus, members, dispatch]);

  return {shareViewStatus: viewStatus};
}
