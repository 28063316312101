import theme from 'config/theme';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import {selectors} from 'store/slices/files';
import {FileListItemLayout} from './FileListItem';
import {FolderToolbarButton} from './FolderToolbarButton';

interface Props {
  layout?: FileListItemLayout;
  onLayoutChange?: () => void;
}

const fileListItemLayoutIcons = {
  [FileListItemLayout.ListNormal]: 'view-module',
  [FileListItemLayout.GridSimple]: 'view-list',
};

export function FolderLayoutSwitchButton({layout, onLayoutChange}: Props) {
  const dispatch = useDispatch();

  const globalLayout = useSelector(selectors.getLayout);

  const handleLayoutChange = () => {
    if (onLayoutChange) {
      onLayoutChange();
    } else {
      dispatch(slices.files.actions.layout());
    }
  };

  return (
    <FolderToolbarButton
      icon={
        <Icon
          name={fileListItemLayoutIcons[layout ?? globalLayout]}
          size={20}
          color={theme.colors.neutral.$5}
        />
      }
      onPress={handleLayoutChange}
    />
  );
}
