import {breakpoints} from 'config/styles';
import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'extensions/navigation';
import {DrawerLayout} from 'extensions/viewport/DrawerLayout';
import {useMultiplayer} from 'interface/multiplayer/hooks/useMultiplayer';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import AppSideNav from 'interface/stacks/app/layout/AppSideNav';
import {useUploader} from 'interface/stacks/uploads/hooks/useUploader';
import {Uploads} from 'interface/stacks/uploads/views/Uploads';
import {FolderPicker} from 'interface/stacks/workspace/storage/FolderPicker';
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import {StyleSheet, View, useWindowDimensions} from 'react-native';
import {useSelector} from 'react-redux';
import {WorkspaceFrame} from '../base/WorkspaceFrame';
import {useGetQuickShares} from '../hooks/useGetQuickShares';
import useUserWorkspaces from '../hooks/useUserWorkspaces';

import * as $app from 'store/slices/app';
import * as $workspace from 'store/slices/workspace';

import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import type {MultiplayerState} from 'interface/multiplayer/types';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import {useLayoutWorkspaces} from '../hooks/useLayoutWorkspaces';

interface LayoutWorkspaceProps {
  workspaceRequired?: boolean;
}

export const WORKSPACE_MENU_WIDTH = 250;

export interface WorkspaceContext {
  workspace: WorkspaceListDetail;
  uploader: UploaderState;
  multiplayer: MultiplayerState;
  hasFixedMenu: boolean;
  openMenu: () => void;
}

export function LayoutWorkspace({workspaceRequired}: LayoutWorkspaceProps) {
  // General
  const {fetchAndUpdateQuickShares} = useGetQuickShares();
  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();
  const navigate = useNavigate();
  const uploader = useUploader();
  const location = useLocation();
  const screen = useWindowDimensions();
  const drawer = useRef<DrawerLayout>(null);
  const isLoggedIn = useSelector($app.selectors.isLoggedIn);
  const isDrawerHidden = useMemo(() => {
    const isPreviewPath = location.pathname.includes(
      `/${ROUTES.LOGGED_IN_WITH_ORG.PREVIEW}/`,
    );
    return isPreviewPath;
  }, [location.pathname]);

  // Workspace
  const {workspaceId, folderKey, fileId} = useParams<{
    workspaceId: string;
    folderKey?: string;
    fileId?: string;
  }>();

  const {workspaces} = useUserWorkspaces();
  const externalWorkspaces = useSelector(
    $workspace.selectors.getExternalWorkspaces,
  );

  const workspace = useMemo(() => {
    return (
      workspaces.find(w => w.folder_name === workspaceId) ||
      externalWorkspaces.find(w => w.folder_name === workspaceId)
    );
  }, [workspaceId, workspaces, externalWorkspaces]);

  const isValidWorkspace = useMemo(() => {
    return !workspaceRequired || workspaceId === WORKSPACE_ROOT || workspace;
  }, [workspaceRequired, workspaceId, workspace]);

  const multiplayer = useMultiplayer(
    {
      id: workspace?.id,
      alt: workspace?.folder_name,
      type: 'workspace',
    },
    {
      id: folderKey ?? fileId,
      type: folderKey ? 'folder' : 'file',
    },
  );

  // Menu
  const hasFixedMenu = isLoggedIn && screen.width > breakpoints.menu;
  const hasDrawer = !hasFixedMenu && isLoggedIn;
  const menu = useMemo(() => {
    return (
      <View style={styles.menuContent}>
        <AppSideNav />
      </View>
    );
  }, []);

  const openMenu = useCallback(() => drawer.current?.openDrawer(), []);
  const renderMenu = useCallback(() => (hasDrawer ? menu : null), [hasDrawer]);

  // Content
  const workspaceContext: WorkspaceContext = useMemo(
    () => ({
      workspace,
      uploader,
      multiplayer,
      hasFixedMenu,
      openMenu,
    }),
    [workspace, uploader, multiplayer, hasFixedMenu, openMenu],
  );

  const renderContent = () => (
    <View style={styles.content}>
      <Outlet context={workspaceContext} />
      {Boolean(multiplayer.following) && (
        <WorkspaceFrame
          mode="follow"
          name={multiplayer.following?.userNameFirst}
          color={multiplayer.following?.userColor}
        />
      )}
    </View>
  );

  useLayoutEffect(() => {
    if (workspaceId && workspaceId !== WORKSPACE_ROOT && !workspace) {
      navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`, {
        replace: true,
      });
    }
  }, [workspaceId, workspace]);

  useEffect(() => {
    fetchAndUpdateWorkspaces();
    if (isValidWorkspace && workspaceId && workspaceId !== WORKSPACE_ROOT) {
      fetchAndUpdateQuickShares(workspaceId);
    }
  }, []);

  if (!isValidWorkspace) {
    return <AppLoading />;
  }

  if (isDrawerHidden) {
    return renderContent();
  }

  return (
    <DrawerLayout
      ref={drawer}
      drawerWidth={WORKSPACE_MENU_WIDTH}
      minSwipeDistance={3}
      edgeWidth={20}
      drawerType="front"
      drawerPosition="right"
      drawerLockMode={hasDrawer ? 'unlocked' : 'locked-closed'}
      renderNavigationView={renderMenu}>
      <View style={styles.root}>
        {hasFixedMenu && <View style={styles.menu}>{menu}</View>}
        {renderContent()}
        <FolderPicker
          instanceNs="workspace"
          instanceId={workspaceId}
          instanceName={workspace?.name}
        />
      </View>
      <Uploads {...uploader} />
    </DrawerLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
  },
  menu: {
    width: WORKSPACE_MENU_WIDTH,
  },
  menuContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
  },
});
