import {api} from 'fast-sdk';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import {base64ToFile} from 'utils/fast/files';

type Options = {
  lazy?: boolean;
};

const defaultOptions: Options = {
  lazy: false,
};

const useUpdateUser = (isSubscribed: boolean, {lazy} = defaultOptions) => {
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const userData = useSelector(onboarding.selectors.getUserData);
  const dispatch = useDispatch();

  const updateUser = useCallback(async () => {
    const isUserDataValid = userData.firstName && userData.lastName;
    if (isUserDataValid) {
      const userRes = await api.user.updateUserDetails({
        ...userDetails,
        first_name: userData.firstName,
        last_name: userData.lastName,
      });

      if (userRes.result && userData.imgSrc) {
        const file = Object.keys(userData.imgFile).length
          ? userData.imgFile
          : base64ToFile(userData.imgSrc, userData.imgName, userData.imgType);
        const imgRes = await api.user.addProfilePhoto(userDetails.id, file);

        if (imgRes.result) {
          dispatch(
            user.default.actions.setUserProfilePic({img: userData.imgSrc}),
          );
        }
      }
    }
    setIsUserUpdated(true);
  }, []);

  useEffect(() => {
    if (isSubscribed && !lazy) {
      updateUser();
    }
  }, [isSubscribed, lazy]);

  return {updateUser, isUserUpdated};
};

export default useUpdateUser;
