import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage, {Storage} from 'extensions/storage';
import {createBrowserHistory, createMemoryHistory} from 'history';
import config from 'react-native-ultimate-config';
import {createReduxHistoryContext} from 'redux-first-history';
import {persistReducer, persistStore} from 'redux-persist';
import {activate} from 'store/middleware/activate';
import {reporter} from 'store/middleware/reporter';
import slices from 'store/slices';
import {isWeb} from 'utils/common/platform';
import {resetManualTransform} from './slices/app';
import {sharedSliceTransform} from './slices/shared';
import {workspaceSliceTransform} from './slices/workspace';

// https://github.com/salvoravida/redux-first-history#options
const {createReduxHistory, routerMiddleware, routerReducer} =
  createReduxHistoryContext({
    reduxTravelling: true,
    history: isWeb() ? createBrowserHistory() : createMemoryHistory(),
  });

// Combine reducers with persistence
const reducer = persistReducer(
  {
    key: config.APP_NAME,
    version: config.STORE_VERSION,
    storage: storage.init(config.APP_NAME),
    whitelist: [
      slices.onboarding.name,
      slices.app.name,
      slices.user.name,
      slices.files.name,
      slices.workspace.name,
      slices.activity.name,
      slices.shared.name,
      slices.quickShare.name,
      slices.events.name,
    ],
    transforms: [
      workspaceSliceTransform,
      sharedSliceTransform,
      resetManualTransform,
    ],
  },
  combineReducers({
    router: routerReducer,
    app: slices.app.reducer,
    activity: slices.activity.reducer,
    files: slices.files.reducer,
    user: slices.user.reducer,
    workspace: slices.workspace.reducer,
    settings: slices.settings.reducer,
    shared: slices.shared.reducer,
    quickShare: slices.quickShare.reducer,
    onboarding: slices.onboarding.reducer,
    errors: slices.errors.reducer,
    contentViewer: slices.contentViewer.reducer,
    events: slices.events.reducer,
  }),
);

const {initActivate} = activate(slices);
const {initReporter, pluginReporter} = reporter(slices);

const store = configureStore({
  reducer,
  devTools: true,
  middleware: [pluginReporter, routerMiddleware],
});

const persistor = persistStore(store);

const resetAppStore = async (resetAuth = true) => {
  // Clear the persisted state
  await persistor.purge();

  // Reset each slice to its initial state
  if (resetAuth) {
    store.dispatch(slices.user.actions.purge());
    store.dispatch(slices.app.actions.purge());
  }
  store.dispatch(slices.activity.actions.purge());
  store.dispatch(slices.files.actions.purge());
  store.dispatch(slices.workspace.actions.purge());
  store.dispatch(slices.shared.actions.purge());
  store.dispatch(slices.onboarding.actions.purge());
  store.dispatch(slices.quickShare.actions.purge());
};

const authDb = new Storage().init('auth');

// https://github.com/remix-run/history/blob/dev/docs/api-reference.md
export const history = createReduxHistory(store);

export type Store = ReturnType<typeof reducer>;
export {authDb, persistor, resetAppStore, slices};
export default store;

(async () => {
  // Flag app as loaded to remove splash
  // store.dispatch(slices.app.actions.load({loaded: true}));

  // Start middleware
  initReporter(store);
  initActivate(store);

  // Reset folder view when navigating
  history.listen(() => {
    store.dispatch(slices.files.actions.resetFolderView());
  });
})();
