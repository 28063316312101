import theme from 'config/theme';
import {forwardRef, useMemo, useState} from 'react';
import {
  type LayoutChangeEvent,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
} from 'react-native';

type Props = {
  text: string;
  kind?: 'dark' | 'light';
  position?: 'top' | 'bottom';
  customRootStyle?: ViewStyle;
};

const ActionTooltip = forwardRef<View, Props>(
  ({text, kind = 'light', position = 'top', customRootStyle}, ref) => {
    const [width, setWidth] = useState(0);

    const onLayout = (event: LayoutChangeEvent) => {
      const {width} = event.nativeEvent.layout;
      setWidth(width);
    };

    const classes = useMemo(
      () => ({
        tooltip: {
          ...styles.tooltip,
          ...(position === 'bottom' && styles.toolTipBottom),
          ...customRootStyle,
          left: width ? `calc(50% - ${width / 2}px)` : 0,
          backgroundColor:
            kind === 'light'
              ? theme.colors.neutral.$white
              : theme.colors.neutral.$2Base,
        },
        tooltipText: {
          ...styles.tooltipText,
          color:
            kind === 'light'
              ? theme.colors.neutral.$2Base
              : theme.colors.neutral.$white,
        },
        arrow: {
          ...styles.arrow,
          ...(position === 'bottom' && styles.arrowBottom),
          borderTopColor:
            kind === 'light'
              ? theme.colors.neutral.$white
              : theme.colors.neutral.$2Base,
        },
      }),
      [width],
    );

    return (
      <>
        <View
          ref={ref}
          onLayout={onLayout}
          style={classes.tooltip}
          pointerEvents="none">
          <Text numberOfLines={1} style={classes.tooltipText}>
            {text}
          </Text>
        </View>
        <View style={classes.arrow} pointerEvents="none" />
      </>
    );
  },
);

const styles = StyleSheet.create({
  tooltip: {
    position: 'absolute',
    top: -36 - 2,
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRadius: 5,
    height: 36,
    justifyContent: 'center',
    zIndex: 99999,
  },
  toolTipBottom: {
    top: 'unset',
    bottom: -36 - 2,
  },
  tooltipText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    top: -2,
    left: 'calc(50% - 5.5px)',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 5.5,
    borderRightWidth: 5.5,
    borderTopWidth: 6,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    zIndex: 99999,
  },
  arrowBottom: {
    top: 'unset',
    bottom: -2,
    transform: [{rotate: '180deg'}],
  },
});

export default ActionTooltip;
