import {DEFAULT_ERROR_TOAST_OPTIONS} from 'constants/errors';
import {ErrorFlowActions} from 'errors/types';
import {useEffect, useState} from 'react';
import {Toast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as errors from 'store/slices/errors';

import {ErrorToast} from '../ErrorToast';

export const useShowToastErrors = () => {
  const toastErrors = useSelector(errors.selectors.getToastErrors);
  const [displayedToasts, setDisplayedToasts] = useState<string[]>([]);

  const isNewError = errorItem =>
    errorItem && !displayedToasts.includes(errorItem.id);

  const getToastOptions = errorItem => {
    const {
      errorConfiguration: {flowAction, allowRetry, toastOptions},
    } = errorItem;
    const canBeRetried = flowAction === ErrorFlowActions.Toast && allowRetry;

    return {
      ...DEFAULT_ERROR_TOAST_OPTIONS,
      ...toastOptions,
      id: errorItem.id,
      duration: canBeRetried
        ? 0
        : (toastOptions?.duration ?? DEFAULT_ERROR_TOAST_OPTIONS.duration),
    };
  };

  const showErrorToast = (errorItem, toastOptions) => {
    Toast.show(
      <ErrorToast errorItem={errorItem} toastId={toastOptions.id} />,
      toastOptions,
    );
    setDisplayedToasts(prev => [...prev, toastOptions.id]);
  };

  useEffect(() => {
    if (toastErrors.length) {
      const latestError = toastErrors.at(-1);
      if (isNewError(latestError)) {
        const toastOptions = getToastOptions(latestError);
        showErrorToast(latestError, toastOptions);
      }
    }
  }, [toastErrors]);
};
