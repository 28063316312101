import {useRef} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import WorkspaceBilling from './WorkspaceBilling';
import WorkspaceMembers from './WorkspaceMembers';
import WorkspaceStorage from './WorkspaceStorage';

export function Billing() {
  const membersRef = useRef(null);
  const workspacesRef = useRef(null);

  const handleScrollToMembers = () => {
    if (membersRef.current) {
      membersRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };
  const handleScrollToWorkspaces = () => {
    if (workspacesRef.current) {
      workspacesRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <ScrollView style={styles.root}>
      <WorkspaceBilling
        handleScrollToMembers={handleScrollToMembers}
        handleScrollToWorkspaces={handleScrollToWorkspaces}
      />
      <WorkspaceMembers ref={membersRef} />
      <WorkspaceStorage ref={workspacesRef} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 50,
    paddingVertical: 45,
  },
});
