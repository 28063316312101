import {t} from '@lingui/macro';
import {Loading} from 'interface/base/Loading';
import Modal, {FooterActions} from 'interface/base/Modal';
import {TabList} from 'interface/base/TabList';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import useShareActions, {UpdateMode} from './hooks/useShareActions';
import {ShareDesign} from './tabs/ShareDesign';
import {ShareDetails} from './tabs/ShareDetails';

enum ShareTab {
  Details = 'Details',
  Design = 'Design',
}

const SHARE_DETAILS_TABS = [ShareTab.Details, ShareTab.Design];

interface SharedCustomizeDialogProps {
  workspaceFolderName: string;
  shareId?: string;
  folderParentId?: string;
  close: () => void;
}

export function SharedCustomizeDialog({
  workspaceFolderName,
  shareId,
  folderParentId,
  close,
}: SharedCustomizeDialogProps) {
  const [activeTab, setActiveTab] = useState<ShareTab>(ShareTab.Details);

  const updateMode = shareId ? UpdateMode.Edit : UpdateMode.Create;

  const {
    shareState,
    handleCreateShare,
    handleUpdateShare,
    isSaveLoading,
    isFormInvalid,
    isContentLoading,
  } = useShareActions({
    workspaceFolderName,
    shareId,
    folderParentId,
    done: close,
    updateMode,
  });

  const onTabChange = (newActiveTab: ShareTab) => {
    setActiveTab(newActiveTab);
    if (newActiveTab === ShareTab.Details) {
      shareState.setCurrentFormLink(undefined);
    }
  };

  const isCreate = updateMode === UpdateMode.Create;
  const showFooterActions = !shareState.currentFormLink;

  return (
    <Modal
      title={isCreate ? 'Create Shared Folder' : 'Customize Shared Folder'}
      footer={
        showFooterActions && (
          <FooterActions
            actionButtonLabel={
              isCreate ? t`Create Share Folder` : t`Save Changes`
            }
            onSave={isCreate ? handleCreateShare : handleUpdateShare}
            isFormInvalid={isFormInvalid}
            onCancel={close}
            isActionLoading={isSaveLoading}
          />
        )
      }
      close={close}>
      <TabList
        tabs={SHARE_DETAILS_TABS.map(tab => ({key: tab, label: tab}))}
        activeTab={activeTab}
        setActiveTab={onTabChange}
        rootStyle={styles.tabList}
        tabStyle={styles.tabItem}
      />
      <View style={styles.tabsBody}>
        {isContentLoading ? (
          <Loading centered />
        ) : activeTab === ShareTab.Details ? (
          <ShareDetails shareState={shareState} />
        ) : (
          <ShareDesign shareState={shareState} />
        )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  tabsBody: {
    paddingTop: 20,
    minHeight: 200,
  },
  tabList: {
    paddingHorizontal: 0,
  },
  tabItem: {
    paddingVertical: 6,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 0,
  },
});
