import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';
import {BilledDurationType} from 'store/slices/settings/types';

import {DISCOUNT} from '../lib/consts';
import {calcAmount} from '../lib/utils';

import type {PlanMetaData} from '../lib/types';

interface PlanDetailsProps {
  plan: PlanMetaData;
  unit: BilledDurationType;
  totalAmount: number;
  totalPlanCost: number;
  annualDiscount: number;
  tax: number;
}

export function PlanDetails(props: PlanDetailsProps) {
  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 14,
          }}>
          <Text selectable style={styles.headerText}>
            <Trans>{`${props.plan.name}, ${props.plan.userNumber} user${props.plan.userNumber > 1 ? 's' : ''}`}</Trans>
          </Text>
          {props.unit === BilledDurationType.Yearly ? (
            <Text selectable style={styles.discountBadge}>
              <Trans>{`${DISCOUNT}% off`}</Trans>
            </Text>
          ) : null}
        </View>
        <View style={styles.headerPriceContainer}>
          <Text
            selectable
            style={{
              fontSize: 14,
              fontWeight: '400',
              lineHeight: 22,
              color: theme.colors.neutral.$2Base,
            }}>
            <Trans>14 day free trial</Trans>
          </Text>
          <Text
            selectable
            style={{
              fontSize: 13,
              fontWeight: '400',
              lineHeight: 20,
              color: theme.colors.neutral.$5,
            }}>
            <Trans>{`$${props.totalPlanCost} per user / month after`}</Trans>
          </Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Text selectable style={styles.contentHeaderText}>
          <Trans>{`Recurring ${props.unit === BilledDurationType.Yearly ? 'annual' : 'monthly'} cost`}</Trans>
        </Text>
        <View style={styles.contentItem}>
          <Text selectable style={styles.contentItemText}>
            <Trans>Subtotal</Trans>
          </Text>
          <Text
            selectable
            style={
              styles.contentItemText
            }>{`$${props.unit === BilledDurationType.Monthly ? props.totalAmount : props.totalAmount * 12}`}</Text>
        </View>
        <View style={styles.contentItem}>
          <Text selectable style={styles.contentItemText}>
            <Trans>Discount (annual billing)</Trans>
          </Text>
          <Text
            selectable
            style={[
              styles.contentItemText,
              {color: theme.colors.brand.$4Base},
            ]}>
            {`-$${props.unit === BilledDurationType.Monthly ? 0 : Math.abs(props.totalPlanCost - props.totalAmount) * 12}`}
          </Text>
        </View>
        {/*<View style={styles.contentItem}>
          <Text style={styles.contentItemText}>
            <Trans>Tax</Trans>
          </Text>
          <Text style={styles.contentItemText}>$???</Text>
        </View>*/}
      </View>
      <View style={styles.footer}>
        <View style={styles.contentItem}>
          <Text
            selectable
            style={[styles.contentItemText, {color: theme.colors.neutral.$4}]}>
            <Trans>Total after trial</Trans>
          </Text>
          <Text
            selectable
            style={[styles.contentItemText, {color: theme.colors.neutral.$4}]}>
            <Trans>
              {`$${calcAmount(props.plan.price, props.unit) * props.plan.userNumber}`}
            </Trans>
          </Text>
        </View>
        <View style={styles.contentItem}>
          <Text
            selectable
            style={[styles.contentItemText, {fontWeight: '700'}]}>
            <Trans>Total due today</Trans>
          </Text>
          <Text
            selectable
            style={[styles.contentItemText, {fontWeight: '700'}]}>
            <Trans>$0</Trans>
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBottom: '3rem',
    borderRadius: 7,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: theme.colors.neutral.$11,
    borderBottomWidth: 1,
    padding: '1.5rem',
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.colors.neutral.$15,
    borderTopColor: theme.colors.neutral.$11,
    borderTopWidth: 1,
    paddingVertical: '1.5rem',
    paddingHorizontal: '2rem',
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  headerText: {
    fontSize: 16.5,
    fontWeight: '700',
    lineHeight: 26,
  },
  discountBadge: {
    display: 'flex',
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderRadius: 50,
    backgroundColor: theme.colors.success.$7,
    color: theme.colors.success.$2,
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
  },
  headerPriceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginVertical: '1.5rem',
    paddingHorizontal: '2rem',
  },
  contentHeaderText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$5,
    marginBottom: '1.5rem',
  },
  contentItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  contentItemText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
});
