import {breakpoints} from 'config/styles';
import {ScrollView, StyleSheet, View, useWindowDimensions} from 'react-native';
import {SettingsGeneralSizeNav} from './SettingsGeneralSizeNav';

/**
 * Settings side menu main component
 */
export function SettingsMenu() {
  const screen = useWindowDimensions();
  const hasFloatingMenu = screen.width <= breakpoints.menu;
  const classes = {
    root: [styles.root, hasFloatingMenu && styles.floating],
  };

  return (
    <ScrollView style={classes.root} contentContainerStyle={styles.inner}>
      <View style={styles.content}>
        <SettingsGeneralSizeNav />
      </View>
    </ScrollView>
  );
}

export const styles = StyleSheet.create({
  root: {
    width: 250,
    borderRightWidth: 1,
  },
  inner: {
    flexGrow: 1,
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  floating: {
    borderRightWidth: 0,
  },
});
