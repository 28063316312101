import theme from 'config/theme';
import {api} from 'fast-sdk';
import {Avatar} from 'interface/base/Avatar';
import RadioButton from 'interface/base/RadioButton';
import React, {useEffect, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import type {Organization} from 'store/slices/user/types';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import {blobToBase64} from 'utils/common/image';

type Props = {
  org: Organization;
  isSelected: boolean;
  onPress: () => void;
};

export default function AppSideOrgSelector({org, isSelected, onPress}: Props) {
  const [logo, setLogo] = useState<string>('');

  useEffect(() => {
    if (!org.domain) {
      return;
    }

    async function fetchLogo() {
      const logo = await api.organization.getAsset(org.domain, 'logo');
      blobToBase64(logo, setLogo);
    }

    fetchLogo();
  }, [org.domain]);

  return (
    <Pressable
      style={[styles.logoName, isSelected && styles.selected]}
      onPress={onPress}>
      <View style={styles.left}>
        <Avatar
          color={org.accent_color?.color ?? getHashColor(org.id)}
          initials={createInitials(org.name)}
          photo={logo}
          size={3}
          shapeKind="rounded-square"
          hideBorder
        />
        <Text style={styles.orgName} numberOfLines={1}>
          {org.name}
        </Text>
      </View>
      <RadioButton checked={isSelected} />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  logoName: {
    padding: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 10,
    marginHorizontal: 10,
    gap: 10,
  },
  logoImage: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.30)',
    borderStyle: 'solid',
    flexDirection: 'row',
    borderRadius: 100,
  },
  orgName: {
    color: theme.colors.neutral.$2Base,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 22,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 150,
  },
  selected: {
    borderWidth: 1,
    borderColor: theme.colors.brand.$4Base,
    borderStyle: 'solid',
    borderRadius: 10,
    backgroundColor: theme.colors.neutral.$13,
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
});
