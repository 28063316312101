import {useFlag} from '@unleash/proxy-client-react';
import {StyleSheet, View} from 'react-native';
import {useOutletContext} from 'react-router';
import type {Share} from 'store/slices/shared/types';
import Comments from './components/Comments';
import type {ShareFilesNavigation} from './hooks/useShareFilesNavigation';
import ShareFiles from './layout/ShareFiles';
import ShareHeaderInfo from './layout/ShareHeaderInfo';

export function ShareRecepientView() {
  const [share, , shareFilesNavigation] =
    useOutletContext<[Share, unknown, ShareFilesNavigation]>();

  const showComments = useFlag('share-comments') && share.comments.enabled;

  return (
    <View style={styles.root}>
      <ShareHeaderInfo share={share} />
      <ShareFiles share={share} shareFilesNavigation={shareFilesNavigation} />
      {showComments && <Comments share={share} />}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 25,
    paddingBottom: 56,
  },
});
