import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useMenu} from 'extensions/viewport/useMenu';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import useArchiveWorkspace from 'interface/stacks/workspace/hooks/useArchiveWorkspace';
import {useIsWorkspaceAdmin} from 'interface/stacks/workspace/hooks/useIsWorkspaceAdmin';
import useLeaveWorkspace from 'interface/stacks/workspace/hooks/useLeaveWorkspace';
import {useModalWorkspaceSettings} from 'interface/stacks/workspace/hooks/useModalWorkspaceSettings';
import type {MouseEvent} from 'react';
import {createRef, useCallback} from 'react';
import type {GestureResponderEvent, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';

enum WorkspaceMenuCommands {
  Details = 'Details',
  Members = 'Members',
  Permissions = 'Permissions',
  Leave = 'Leave',
  Close = 'Close',
}

export default function useWorkspaceMenuSettings(
  workspace: WorkspaceListDetail,
  userId: string,
) {
  const menuAnchorRef = createRef<View>();

  const workspaceModal = useModalWorkspaceSettings(workspace, userId);
  const isWorkspaceAdmin = useIsWorkspaceAdmin(workspace);

  const {leaveWorkspace} = useLeaveWorkspace();
  const {archiveWorkspace} = useArchiveWorkspace();

  const handleMenuCommand = useCallback(
    (command: WorkspaceMenuCommands) => {
      switch (command) {
        case WorkspaceMenuCommands.Details:
        case WorkspaceMenuCommands.Members:
        case WorkspaceMenuCommands.Permissions:
          workspaceModal.open(command);
          break;
        case WorkspaceMenuCommands.Leave:
          leaveWorkspace(workspace);
          break;
        case WorkspaceMenuCommands.Close:
          archiveWorkspace(workspace);
          break;
      }
      menu.close();
    },
    [workspaceModal],
  );

  const menuItems = () => [
    {
      command: WorkspaceMenuCommands.Details,
      text: t`Workspace Settings`,
      icon: (
        <MCIcon
          name="monitor-dashboard"
          size={20}
          color={theme.colors.neutral.$6}
        />
      ),
    },
    {
      command: WorkspaceMenuCommands.Members,
      text: t`Manage Members`,
      icon: <Icon name="group" size={20} color={theme.colors.neutral.$6} />,
    },
    {
      command: WorkspaceMenuCommands.Permissions,
      text: t`Permissions`,
      icon: <Icon name="lock" size={20} color={theme.colors.neutral.$6} />,
    },
    {
      command: 'divider',
      text: '-',
    },
    isWorkspaceAdmin
      ? {
          command: WorkspaceMenuCommands.Close,
          text: 'Archive Workspace',
          icon: (
            <MCIcon
              name="archive-outline"
              size={20}
              color={theme.colors.neutral.$6}
            />
          ),
        }
      : {
          command: WorkspaceMenuCommands.Leave,
          text: 'Leave Workspace',
          icon: (
            <Icon name="logout" size={20} color={theme.colors.neutral.$6} />
          ),
        },
  ];

  const menu = useMenu(menuItems, handleMenuCommand, false);

  const handleOpenMenu = useCallback(
    (e: MouseEvent | GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (menu) {
        if (e?.nativeEvent) {
          menu.setPosition({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          });
        }
        menu.setRef(menuAnchorRef);
      }
      menu.open();
    },
    [menu, menuAnchorRef],
  );

  return {handleOpenMenu, menu};
}
