import theme from 'config/theme';
import {format} from 'date-fns';
import type {BillingUsageMember} from 'fast-sdk/src/api/billing/consts';
import {WorkspaceBadge} from 'interface/base/WorkspaceBadge';
import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/workspace';
import MemberAvatarCard from '../../base/MemberAvatarCard';
import type {Column} from '../../base/SettingsTable';
import SettingsTableDropdown from './SettingsTableDropdown';

type Props = {
  member: BillingUsageMember;
  columns: Array<Column>;
};

const DATE_FORMAT = 'MMM d, yyyy';

const getDateJoined = (member: BillingUsageMember) => {
  if (!member.updated) {
    const firstParent = Object.keys(member.parents)?.[0];

    if (!firstParent) {
      return '---';
    }

    return format(
      new Date(member.parents[firstParent].date_joined),
      DATE_FORMAT,
    );
  }

  return format(new Date(member.updated), DATE_FORMAT);
};

const WorkspaceMemberRow = ({member, columns}: Props) => {
  const workspaces = useSelector(selectors.getAllWorkspacesInOrg);
  const workspaceIds = Object.keys(member.parents);

  const items = useMemo(
    () =>
      workspaceIds
        .filter(id => workspaces.some(workspace => workspace.id === id))
        .map(id => ({
          text: workspaces.find(workspace => workspace.id === id)?.name,
          command: id,
          disabled: true,
          icon: <WorkspaceBadge id={id} />,
        })),
    [workspaceIds, workspaces],
  );

  return (
    <View style={styles.row}>
      <View style={{width: columns[0].width}}>
        <MemberAvatarCard
          id={member.id}
          firstName={member.first_name}
          lastName={member.last_name}
          email={member.email_address}
          profilePic={member.profile_pic}
        />
      </View>
      <View style={{width: columns[1].width}}>
        {workspaceIds.length ? (
          <SettingsTableDropdown
            rootStyle={styles.dropdown}
            label={`${items.length} Workspaces`}
            items={items}
          />
        ) : (
          <Text style={styles.text}>---</Text>
        )}
      </View>
      <View style={{width: columns[2].width}}>
        <Text style={styles.date}>{getDateJoined(member)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    height: 54,
    paddingHorizontal: 10,
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  date: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  dropdown: {
    width: 123,
  },
  text: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  dropdownItem: {
    flexDirection: 'row',
    gap: 10,
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  dropdownItemText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});

export default WorkspaceMemberRow;
