import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {Button} from 'interface/base/Button';
import {Image, StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as settings from 'store/slices/settings';
import {socialLinkTypeToLogo} from '../../base/consts';
import {OrganizationLinksModal} from './OrganizationLinksModal';
import {SectionItem} from './SectionItem';

interface OrganizationLinksProps {
  getOrgDetails: () => void;
}

export const OrganizationLinks = (props: OrganizationLinksProps) => {
  const modal = useModal();
  const links = useSelector(settings.selectors.getOrganizationSocialLinks);

  const openModal = () =>
    modal.open(
      <OrganizationLinksModal
        close={modal.close}
        getOrgDetails={props.getOrgDetails}
      />,
    );

  return (
    <SectionItem
      title={t`Social Links`}
      content={t`Add links to your organization's social profiles to be shown on your shared link pages.`}
      sectionElement={
        <View style={styles.imagesContainer}>
          {links.map(link => (
            // since the link is to an external location we should create it
            // with the "a" element instead of the "Link" element
            <a
              href={link.link}
              key={link.link + link.type}
              target="_blank"
              rel="noreferrer noopener">
              <Image
                style={styles.socialLinkImage}
                source={{uri: socialLinkTypeToLogo(link.type)}}
                resizeMode="contain"
              />
            </a>
          ))}
          {links?.length ? (
            <View style={styles.buttonContainer}>
              <Button
                type="Secondary"
                label={t`Edit Links`}
                onPress={openModal}
                customTextStyle={{color: theme.colors.neutral.$4}}
                customRootStyle={{height: 36}}
              />
            </View>
          ) : (
            <Button
              type="Primary"
              label={t`Add Links`}
              onPress={openModal}
              customRootStyle={{height: 36}}
              lIcon={
                <Icon
                  name="add"
                  color={theme.colors.neutral.$white}
                  size={20}
                />
              }
            />
          )}
        </View>
      }
    />
  );
};

const styles = StyleSheet.create({
  socialLinkImage: {
    width: 24,
    height: 24,
  },
  imagesContainer: {
    gap: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonContainer: {
    paddingLeft: 15,
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.neutral.$10,
  },
});
