import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';
import HTML from 'react-native-render-html';
import type {Share} from 'store/slices/shared/types';
import {ShareExpiration} from './ShareExpiration';

interface Props {
  share: Share;
}

export default function ShareHeaderInfo({share}: Props) {
  return (
    <View style={styles.infoContainer}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{share.title}</Text>
        {share.expires && (
          <ShareExpiration expirationDate={new Date(share.expires)} />
        )}
      </View>
      <HTML source={{html: share.description}} />
    </View>
  );
}

const styles = StyleSheet.create({
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.neutral.$2Base,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    padding: 7,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
});
