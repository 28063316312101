import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import type {InvitationDetails} from 'fast-sdk/src/api/share/consts';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {WorkspaceCard} from 'interface/stacks/workspace/base/WorkspaceCard';
import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import type {User} from 'store/slices/user/types';
import {RouteLayout} from '../_layout/RouteLayout';
import type {OrganizationSummary} from '../consts';
import {useGetAuthHeaderLogo} from '../hooks/useGetAuthHeaderLogo';
import {UserBadge} from './UserBadge';

interface WorkspaceInviteProps {
  userDetails: User;
  userProfilePic: string;
  workspace: WorkspaceListDetail;
  invitation: InvitationDetails;
  organization: OrganizationSummary;
  onJoinButtonPress: (workspace: WorkspaceListDetail) => Promise<void>;
}

export const WorkspaceInvite = ({
  userDetails,
  userProfilePic,
  workspace,
  invitation,
  organization,
  onJoinButtonPress,
}: WorkspaceInviteProps) => {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const {headerLogo} = useGetAuthHeaderLogo(invitation.org);

  const title = useMemo(
    () => (
      <View style={styles.titleContainer}>
        {headerLogo}
        <Text>
          <Trans>
            Join the <Text style={styles.workspaceName}>{workspace.name}</Text>{' '}
            workspace at {organization.name}
          </Trans>
        </Text>
      </View>
    ),
    [organization.name, workspace.name],
  );

  const subTitle = useMemo(() => {
    if (isLoggedIn) {
      return (
        <UserBadge
          id={userDetails.id}
          email={userDetails.email_address}
          imageSrc={userProfilePic}
          customStyle={{marginBottom: '0.5rem'}}
          initials={`${userDetails.first_name[0]}${userDetails.last_name[0]}`}
        />
      );
    }
    return t`To continue, create a Fastio account or sign in to your existing one.`;
  }, []);

  return (
    <RouteLayout title={title} subTitle={subTitle} customRootMaxWidth={700}>
      <WorkspaceCard
        workspace={workspace}
        onJoinButtonPress={onJoinButtonPress}
        invitedByEmail={invitation.inviter}
      />
    </RouteLayout>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.neutral.$2Base,
    marginBottom: '3rem',
    textAlign: 'center',
  },
  workspaceName: {
    color: theme.colors.brand.$3,
  },
  titleContainer: {
    alignItems: 'center',
    gap: 6,
  },
});
