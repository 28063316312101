import theme from 'config/theme';
import {Avatar} from 'interface/base/Avatar';
import {CustomCheckbox} from 'interface/base/CustomCheckbox';
import {MemberAvatarGroup} from 'interface/stacks/auth/components/MemberAvatarGroup';
import {useWorkspaceMembers} from 'interface/stacks/workspace/hooks/useWorkspaceMembers';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import type {Member} from 'store/slices/settings/types';
import {selectors} from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import type {OnChangeFilter, OnRemoveFilter} from '../consts';
import DropdownPill from './dropdown-pill/DropdownPill';
import DropdownPillItem from './dropdown-pill/DropdownPillItem';

type Params = {
  workspaceId: string;
};

type Props = {
  selected: Array<string>;
  onChange: OnChangeFilter;
  onRemove: OnRemoveFilter;
};

const renderLabel = (
  selected: Array<string>,
  members: Array<Member>,
  currentUserId: string,
) => {
  if (selected.length === 0) {
    return 'People';
  }

  const firstMember = members.find(member => member.id === selected[0]);

  const first = `${firstMember?.firstName} ${firstMember?.lastName} ${selected[0] === currentUserId ? '(you)' : ''}`;

  return (
    <MemberAvatarGroup
      members={members}
      loading={false}
      rootStyle={styles.avatarGroup}
      textStyle={styles.avatarGroupText}
      text={selected.length === 1 ? first : `People (${selected.length})`}
    />
  );
};

const PeopleFilter = ({selected, onChange, onRemove}: Props) => {
  const user = useSelector(selectors.getUserDetails);
  const {workspaceId} = useParams<Params>();
  const {members, isLoading} = useWorkspaceMembers(workspaceId);

  return (
    <DropdownPill
      label={renderLabel(selected, members, user?.id)}
      active={Boolean(selected.length)}
      disabled={isLoading}
      removeFilter={onRemove}>
      {!isLoading &&
        members?.map(member => (
          <DropdownPillItem key={member.id} onPress={() => onChange(member.id)}>
            <CustomCheckbox
              customStyle={styles.checkbox}
              check={selected.includes(member.id)}
              pointerEvents="none"
            />
            <Avatar
              initials={`${member.firstName[0]}${member.lastName[0]}`}
              color={getHashColor(member.id)}
              photo={member.profilePic}
            />
            <Text numberOfLines={1}>
              {`${member.firstName} ${member.lastName} ${member.id === user?.id ? '(you)' : ''}`}
            </Text>
          </DropdownPillItem>
        ))}
    </DropdownPill>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    paddingHorizontal: 0,
  },
  label: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
    paddingHorizontal: 10,
  },
  icon: {
    padding: 5,
  },
  name: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  avatarGroup: {
    paddingTop: 0,
    marginLeft: -3,
    width: 'auto',
  },
  avatarGroupText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$white,
  },
});

export default PeopleFilter;
