import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {api} from 'fast-sdk';
import {Loading} from 'interface/base/Loading';
import useHasExternalWorkspacesShares from 'interface/base/hooks/useHasExternalWorkspacesShares';
import {useLogout} from 'interface/base/hooks/useLogout';
import {OnboardSteps} from 'interface/stacks/onboard/lib/consts';
import {Fragment, useEffect, useState} from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import {RouteLayout} from '../_layout/RouteLayout';
import CreateOrganizationBanner from '../components/CreateOrganizationBanner';
import {EmptyOrganizationCard} from '../components/EmptyOrganizationCard';
import {OrganizationCard} from '../components/OrganizationCard';
import PendingInvitations from '../components/PendingInvitations';
import SharedWithMeCard from '../components/SharedWithMeCard';
import TryDifferentEmail from '../components/TryDifferentEmail';

const MAX_WIDTH = 960;

export function Organizations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dimension = useWindowDimensions();
  const {hasExternalWorkspacesOrShares} = useHasExternalWorkspacesShares();

  const organizations = useSelector(user.selectors.getOrganizationsList);

  const areOrganizationsLoaded = organizations?.length;
  const [loading, setLoading] = useState(!areOrganizationsLoaded);

  const {logout} = useLogout();

  const createNewOrganization = () => {
    dispatch(onboarding.default.actions.restartOnboarding());
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Organization}`,
    );
  };

  const fetchOrganizations = async () => {
    try {
      const {result, orgs} =
        await api.organization.getListOfAccessibleOrganizations();
      if (result) {
        dispatch(user.default.actions.setOrganizationsList({orgsList: orgs}));
      }
    } finally {
      setLoading(false);
    }
  };

  const changeSessionEmail = async () => {
    logout({navigateToSignin: true, showLoader: false});
  };

  const navigateToSharedWithMe = () =>
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <RouteLayout customRootMaxWidth={MAX_WIDTH}>
      <CreateOrganizationBanner onCreateOrganization={createNewOrganization} />
      <View style={styles.divider} />
      {loading ? (
        <Loading />
      ) : (
        <>
          <View style={styles.organizationsContainer}>
            {organizations.length === 0 && hasExternalWorkspacesOrShares && (
              <SharedWithMeCard onPress={navigateToSharedWithMe} />
            )}
            {organizations.length > 0 && (
              <>
                <View style={styles.header}>
                  <Text style={styles.headerTitle}>Your organizations:</Text>
                </View>
                <ScrollView contentContainerStyle={styles.organizations}>
                  {organizations?.length ? (
                    organizations.map((org, idx) => (
                      <Fragment key={org.id}>
                        <OrganizationCard
                          index={idx}
                          organization={org}
                          setLoading={setLoading}
                          borderBottom={idx >= 0 && idx < organizations.length}
                          roundedBottom={
                            !hasExternalWorkspacesOrShares &&
                            idx === organizations.length - 1
                          }
                        />
                        {hasExternalWorkspacesOrShares &&
                          idx === organizations.length - 1 && (
                            <View style={styles.viewContainer}>
                              <Pressable onPress={navigateToSharedWithMe}>
                                <Text style={styles.viewLink}>
                                  <Trans>
                                    View Workspaces and Shares I’ve joined
                                  </Trans>
                                </Text>
                              </Pressable>
                            </View>
                          )}
                      </Fragment>
                    ))
                  ) : (
                    <EmptyOrganizationCard />
                  )}
                  <PendingInvitations />
                </ScrollView>
              </>
            )}
          </View>
          <TryDifferentEmail
            onChangeSessionEmail={changeSessionEmail}
            hasOrganizations={organizations?.length > 0}
          />
        </>
      )}
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  headerTitle: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    width: '100%',
    textAlign: 'left',
  },
  organizationsContainer: {
    marginTop: 60,
    marginBottom: '3rem',
    width: '100%',
  },
  organizations: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  bottomLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 7,
  },
  bottomLinkText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  bottomLink: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
  },
  viewContainer: {
    justifyContent: 'center',
    height: 44,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  viewLink: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.brand.$4Base,
  },
  divider: {
    height: 1,
    backgroundColor: theme.colors.neutral.$12,
    width: '100vw',
    marginLeft: `calc((20px + (100vw - ${MAX_WIDTH}px) / 2) * -1)`,
  },
});
