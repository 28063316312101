import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {bytesize} from 'utils/common/data';

interface Props {
  used: number;
  total: number;
  totalSizeUnit: string;
  handleScroll: () => void;
}

const units = {
  B: 1,
  KB: 1_000,
  MB: 1_000_000,
  GB: 1_000_000_000,
  TB: 1_000_000_000_000,
};

export default function StorageInfo({
  used,
  total,
  totalSizeUnit,
  handleScroll,
}: Props) {
  const {totalSize, usedSize, percentage} = useMemo(() => {
    const totalSize = total * (units[totalSizeUnit] || units.B);
    const usedSize = used;
    const percentage = (usedSize / totalSize) * 100;
    return {
      totalSize: bytesize(totalSize, 0),
      usedSize: bytesize(usedSize, 1),
      percentage,
    };
  }, [used, total, totalSizeUnit]);

  return (
    <View style={styles.root}>
      <View style={styles.top}>
        <View>
          <Text style={styles.title}>{t`Total Storage Used`}</Text>
          <Text
            style={
              styles.content
            }>{t`${usedSize} / ${totalSize} (${percentage.toFixed(1)}%)`}</Text>
        </View>
        <Button
          type="Secondary"
          label={t`View Breakdown`}
          onPress={handleScroll}
          customRootStyle={{height: 36}}
        />
      </View>
      <View style={styles.meter}>
        <View
          style={[
            styles.meterFill,
            {width: `${percentage < 1 ? 1 : percentage.toFixed(1)}%`},
          ]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    borderWidth: 1,
    borderRadius: 12,
    borderColor: theme.colors.neutral.$11,
    backgroundColor: theme.colors.neutral.$14,
    marginBottom: '2.5rem',
    paddingTop: 20,
    paddingBottom: 25,
    paddingHorizontal: 25,
  },
  title: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    marginBottom: 7,
  },
  content: {
    fontSize: 21,
    fontWeight: '600',
    lineHeight: 34,
    marginBottom: 21,
  },

  meter: {
    position: 'relative',
    width: '100%',
    height: 12,
    borderRadius: 10,
    backgroundColor: theme.colors.neutral.$10,
    marginBottom: 8,
  },
  meterFill: {
    position: 'absolute',
    left: 0,
    height: '100%',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.colors.brand.$4Base,
  },
  top: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
