import theme from 'config/theme';
import {type MenuOptionItem, useMenu} from 'extensions/viewport/useMenu';
import {Button} from 'interface/base/Button';
import {createRef, useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import type {GestureResponderEvent, StyleProp, ViewStyle} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

type Props = {
  label: string;
  items: Array<MenuOptionItem>;
  rootStyle?: StyleProp<ViewStyle>;
};

const SettingsTableDropdown = <T,>({label, items, rootStyle}: Props) => {
  const memberMenu = useMenu(
    () => items,
    () => {
      memberMenu.close();
    },
    false,
  );

  const menuAnchorRef = createRef<View>();

  const menu = useCallback(
    (e: GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (memberMenu) {
        if (e?.nativeEvent) {
          memberMenu.setPosition({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          });
          memberMenu.setRef(menuAnchorRef);
        }
      }
      memberMenu.open();
    },
    [memberMenu, menuAnchorRef],
  );

  return (
    <View ref={menuAnchorRef} style={[styles.root, rootStyle]}>
      <Button
        type="Text"
        label={label}
        onPress={menu}
        customTextStyle={styles.buttonText}
        rIcon={
          <Icon
            name="chevron-down"
            size={16}
            color={theme.colors.brand.$4Base}
          />
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    maxWidth: 150,
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    whiteSpace: 'nowrap',
  },
  itemsContainer: {
    position: 'absolute',
    top: 36,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 7,
    paddingVertical: 7,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
  },
});

export default SettingsTableDropdown;
