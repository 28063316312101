import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {api} from 'fast-sdk';
import {
  InvitationActions,
  InvitationTypes,
} from 'fast-sdk/src/api/share/consts';
import type {PendingInvitationDetails} from 'interface/stacks/auth/consts';
import {useLayoutWorkspaces} from 'interface/stacks/workspace/hooks/useLayoutWorkspaces';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';

interface HandleInvitationAcceptanceOptions {
  invitation: PendingInvitationDetails;
  showAppLoader?: boolean;
}

export const useJoinInvitationHandler = (shouldGoToOnboarding = false) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();

  const processInvitation = async (invitation: PendingInvitationDetails) => {
    if (invitation.entity_type === InvitationTypes.Organization) {
      const {result} = await api.organization.processOrganizationInvitation(
        invitation.org?.domain ?? '',
        invitation.invitationToken,
        InvitationActions.Accept,
      );
      return result;
    }

    if (invitation.entity_type === InvitationTypes.Workspace) {
      const {result} = await api.workspace.processWorkspaceInvitation(
        invitation.workspace?.folder_name ?? '',
        invitation.invitationToken,
        InvitationActions.Accept,
      );
      return result;
    }

    if (invitation.entity_type === InvitationTypes.Shared) {
      const {result} = await api.share.processShareInvitation(
        invitation.share?.custom_name ?? '',
        invitation.invitationToken,
        InvitationActions.Accept,
      );
      return result;
    }
  };

  const redirectToUpdateProfile = async (
    showAppLoader: boolean,
    invitation: PendingInvitationDetails,
  ) => {
    if (showAppLoader) {
      dispatch(app.default.actions.load({loaded: false}));
    }

    await Promise.all([
      api.organization.getListOfAccessibleOrganizations(),
      fetchAndUpdateWorkspaces(),
    ]);

    const {org} = invitation;

    if (shouldGoToOnboarding) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
    } else {
      const isOrgInvitation =
        invitation.entity_type === InvitationTypes.Organization;
      const isWorkspaceInvitation =
        invitation.entity_type === InvitationTypes.Workspace;
      const isShareInvitation =
        invitation.entity_type === InvitationTypes.Shared;

      if (isOrgInvitation) {
        navigate(
          `/${ROUTES.LOGGED_IN_WITHOUT_ORG.TO_SUBDOMAIN}?orgDomain=${org.domain}`,
        );
      } else if (isWorkspaceInvitation || isShareInvitation) {
        navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`);
      } else {
        navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`);
      }
    }

    if (showAppLoader) {
      dispatch(app.default.actions.load({loaded: true}));
    }
  };

  const handleInvitationAcceptance = useCallback(
    async ({
      invitation,
      showAppLoader = false,
    }: HandleInvitationAcceptanceOptions) => {
      setIsLoading(true);
      try {
        const result = await processInvitation(invitation);

        if (!result) {
          handleAppError({
            appError: AppErrors.JoinInvitationProcessError,
          });
        }

        if (
          invitation.entity_type === InvitationTypes.Organization ||
          invitation.entity_type === InvitationTypes.Workspace ||
          invitation.entity_type === InvitationTypes.Shared
        ) {
          await redirectToUpdateProfile(showAppLoader, invitation);
        }
      } catch (error) {
        handleAppError({
          appError: AppErrors.JoinInvitationProcessError,
          exception: error,
        });
        navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  return {handleInvitationAcceptance, isLoading};
};
