import {t} from '@lingui/macro';
import type {BillingUsageStorageWorkspace as Workspace} from 'fast-sdk/src/api/billing/consts';
import {Loading} from 'interface/base/Loading';
import type React from 'react';
import {forwardRef, useCallback} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {PageContent} from '../../base/PageContent';
import {PageTitle} from '../../base/PageTitle';
import type {Column, SortColumnMap} from '../../base/SettingsTable';
import SettingsTable from '../../base/SettingsTable';
import useBillingStorage from '../../hooks/useBillingStorage';
import WorkspaceStorageRow from './WorkspaceStorageRow';

const columns: Array<Column> = [
  {title: 'Workspace', width: '65%'},
  {title: 'Shares', width: '20%'},
  {
    title: 'Storage Used',
    width: '15%',
    isSortable: true,
    sort: (direction: 'asc' | 'desc') => (a: Workspace, b: Workspace) => {
      if (direction === 'asc') {
        return a.usage?.bytes - b.usage?.bytes;
      }
      return b.usage?.bytes - a.usage?.bytes;
    },
  },
];

const WorkspaceStorage = forwardRef((_, ref: React.ForwardedRef<View>) => {
  const {workspaces, loading} = useBillingStorage();

  const renderRows = useCallback(
    (sortedColumns: SortColumnMap, shouldSort?: boolean) => {
      const sortedWorkspaces = shouldSort
        ? workspaces.sort((a: Workspace, b: Workspace) => {
            for (const column in sortedColumns) {
              const sortInfo = sortedColumns[column];
              const sortFn = sortInfo.sort(sortInfo.direction);
              const result = sortFn(a, b);
              if (result !== 0) {
                return result;
              }
            }
            return 0;
          })
        : workspaces;

      return sortedWorkspaces.map(workspace => (
        <WorkspaceStorageRow
          key={workspace.id}
          workspace={workspace}
          columns={columns}
        />
      ));
    },
    [workspaces],
  );

  return (
    <View ref={ref}>
      <PageTitle customClass={styles.title} text={t`Workspace Storage Usage`} />
      <PageContent
        rootStyle={styles.content}
        text={t`This is a list of all workspaces within your org and with how much storage space is currently being used by each.`}
      />
      {loading ? (
        <Loading />
      ) : workspaces.length ? (
        <SettingsTable columns={columns} renderRows={renderRows} />
      ) : (
        <Text style={styles.noWorkspacesText}>No workspaces to show</Text>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  title: {
    marginBottom: 7,
  },
  content: {
    marginBottom: 28,
  },
  noWorkspacesText: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    textAlign: 'center',
  },
});

export default WorkspaceStorage;
