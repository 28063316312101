import theme from 'config/theme';
import {Avatar} from 'interface/base/Avatar';
import type React from 'react';
import {type ImageStyle, Pressable, StyleSheet, View} from 'react-native';

export type BorderProps = {
  borderTop?: boolean;
  borderBottom?: boolean;
  roundedTop?: boolean;
  roundedBottom?: boolean;
};

type Props = {
  onPress?: () => void;
  borderProps?: BorderProps;
  avatarColor?: string;
  avatarInitials?: string;
  avatarPhoto?: string;
  avatarImageStyle?: ImageStyle;
  disabled?: boolean;
  main: React.ReactNode;
  right: React.ReactNode;
  bottom?: React.ReactNode;
};

export const ROUNDED: BorderProps = {
  borderTop: true,
  borderBottom: true,
  roundedBottom: true,
  roundedTop: true,
};

export const NO_ROUNDED: BorderProps = {
  borderTop: true,
  borderBottom: true,
};

export default function RowCard({
  onPress,
  borderProps,
  avatarColor,
  avatarInitials,
  avatarPhoto,
  avatarImageStyle,
  main,
  right,
  disabled,
  bottom,
}: Props) {
  return (
    <Pressable
      style={[
        styles.root,
        borderProps?.borderTop && styles.borderTop,
        borderProps?.borderBottom && styles.borderBottom,
        borderProps?.roundedTop && styles.roundedTop,
        borderProps?.roundedBottom && styles.roundedBottom,
      ]}
      onPress={onPress}
      disabled={disabled}>
      <View style={styles.container}>
        <View style={styles.left}>
          <Avatar
            size={4}
            shapeKind="rounded-square"
            color={avatarColor}
            initials={avatarInitials}
            photo={avatarPhoto}
            imageStyle={avatarImageStyle}
          />
          {main}
        </View>
        <View style={styles.right}>{right}</View>
      </View>
      {bottom}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 20,
    gap: 21,
    backgroundColor: theme.colors.neutral.$14,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: theme.colors.neutral.$11,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  },
  borderTop: {
    borderTopWidth: 1,
  },
  borderBottom: {
    borderBottomWidth: 1,
  },
  roundedTop: {
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
  },
  roundedBottom: {
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14,
  },
  left: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  },
  text: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    flexGrow: 1,
  },
  right: {},
});
