import {
  type Placement,
  arrow,
  autoPlacement,
  autoUpdate,
  useFloating,
} from '@floating-ui/react-dom';
import theme from 'config/theme';
import {
  PopupContext,
  type PopupOptions,
  type PopupType,
} from 'extensions/viewport/PopupContext';
import {Mouse} from 'interface/base/Mouse';
import type {ReactElement} from 'react';
import {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Pressable, StyleSheet, View, useWindowDimensions} from 'react-native';

export const PopupProvider = ({children}: {children: ReactElement}) => {
  const screen = useWindowDimensions();
  const [type, setType] = useState<PopupType>();
  const [content, setContent] = useState<JSX.Element>();
  const [options, setOptions] = useState<PopupOptions>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [placement, setPlacement] = useState<Placement>();
  const [manualPos, setManualPosition] = useState<{x: number; y: number}>();
  const arrowRef = useRef(null);
  const {update, refs, x, y, middlewareData} = useFloating({
    strategy: 'absolute',
    middleware: [
      placement ? undefined : autoPlacement(),
      arrow({element: arrowRef}),
    ],
    placement,
  });

  const calculatePosition = (pos: {x: number; y: number}) => {
    const position = {...pos};
    const scales = {
      width: screen.width > 1200 ? 0.15 : 0.25,
      height: screen.height < 901 ? 0.55 : 0.45,
    };
    if ((screen.width - pos.x) / screen.width < scales.width) {
      position.x = screen.width - screen.width * scales.width;
    }
    if ((screen.height - pos.y) / screen.height < scales.height) {
      position.y = screen.height - screen.height * scales.height;
    }
    setManualPosition(position);
  };

  const context = useMemo(
    () => ({
      visible,
      reference: refs.reference,
      setRef: (ref: React.RefObject<any>) => refs.setReference(ref.current),
      setPosition: (pos: {x: number; y: number} | undefined) => {
        if (pos) {
          calculatePosition(pos);
        } else {
          setManualPosition(undefined);
        }
      },
      setManualPosition: (pos: {x: number; y: number}) => {
        setManualPosition(pos);
      },
      open: (content: JSX.Element, options?: PopupOptions) => {
        setOptions(options ?? {});
        setContent(content);
        setVisible(true);
      },
      close: () => {
        setOptions({});
        setContent(undefined);
        setVisible(false);
      },
      setPlacement,
      setType,
    }),
    [content, visible],
  );

  const noop = useCallback(e => e.stopPropagation(), []);

  useLayoutEffect(() => {
    if (visible && refs.reference.current && refs.floating.current) {
      autoUpdate(refs.reference.current, refs.floating.current, update, {
        ancestorScroll: false,
        animationFrame: true,
      });
    }
  }, [visible]);

  const popupLeftPosition = manualPos?.x ?? x ?? 0;
  const popupTopPosition = manualPos?.y ?? y ?? 0;
  const {x: arrowX, y: arrowY} = middlewareData.arrow || {};
  const arrowLeftPosition = arrowX + Math.min(popupLeftPosition, 0);
  const arrowTopPosition = arrowX + Math.min(popupTopPosition, 0);

  const classes = {
    popup: {
      ...styles.popup,
      ...(options.style ?? {}),
      left: Math.max(0, popupLeftPosition),
      top: Math.max(0, popupTopPosition),
    },
    arrow: {
      ...styles.arrow,
      left: arrowX != null ? `${arrowLeftPosition}px` : 'auto',
      top: arrowY != null ? `${arrowTopPosition}px` : 'auto',
    },
  };

  const isPositionValid = Boolean(classes.popup?.left && classes.popup?.top);

  return (
    <PopupContext.Provider value={context}>
      {children}
      {visible && options && options.closeOnClickOutside ? (
        type === 'hoverable' ? (
          <View>
            <Mouse onContextMenu={context.close}>
              <Pressable
                ref={refs.setReference as any}
                style={classes.popup}
                onPressIn={noop}>
                {isPositionValid && content}
              </Pressable>
            </Mouse>
          </View>
        ) : (
          <Pressable style={styles.root} onPressIn={context.close}>
            <Mouse onContextMenu={context.close}>
              <Pressable
                ref={refs.setFloating as any}
                style={classes.popup}
                onPressIn={noop}>
                {isPositionValid && content}
              </Pressable>
            </Mouse>
          </Pressable>
        )
      ) : (
        <View ref={refs.setFloating as any} style={classes.popup}>
          {isPositionValid && content}
          {options.showArrow && <View ref={arrowRef} style={classes.arrow} />}
        </View>
      )}
    </PopupContext.Provider>
  );
};

const styles = StyleSheet.create({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999,
    // @ts-ignore
    overflow: 'auto',
    position: 'absolute',
    cursor: 'default',
  },
  popup: {
    position: 'absolute',
    // @ts-ignore
    cursor: 'default',
  },
  arrow: {
    position: 'absolute',
    zIndex: 5,
    width: 0,
    height: 0,
    borderLeftWidth: 6,
    borderLeftColor: 'transparent',
    borderRightWidth: 6,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: theme.colors.neutral.$2Base,
  },
});
