import {api} from 'fast-sdk';
import type {BillingUsageStorageWorkspace} from 'fast-sdk/src/api/billing/consts';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useEffect, useState} from 'react';

export default function useBillingStorage() {
  const [workspaces, setWorkspaces] = useState<
    Array<BillingUsageStorageWorkspace>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {subdomain} = useSubDomain();

  useEffect(() => {
    setLoading(true);

    async function fetchMembers() {
      const response = await api.billing.getBillingUsageStorage(subdomain);

      if (response.result) {
        setWorkspaces(response.billable_storage?.workspaces ?? []);
      }

      setLoading(false);
    }

    if (subdomain) {
      fetchMembers();
    }
  }, [subdomain]);

  return {
    workspaces,
    loading,
  };
}
