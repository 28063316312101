import {useState} from 'react';

export const useHover = (initialState?: boolean) => {
  const [isHover, setIsHover] = useState(initialState);

  const onHoverIn = () => {
    setIsHover(true);
  };

  const onHoverOut = () => {
    setIsHover(false);
  };

  return {isHover, onHoverIn, onHoverOut};
};
