import {t} from '@lingui/macro';
import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {hexToRGBA} from 'utils/common/color';
import {VARIANT, type Variant} from '../workspace/storage/base/consts';

export interface AppActivityItemProps {
  name: string;
  time: string;
  avatar: React.ReactNode;
  description?: string;
  connector?: boolean;
  files?: boolean;
  details?: boolean;
  isMoveAction?: boolean;
  isUploadAction?: boolean;
  customAction?: string;
  fromFolderName?: string;
  destFolderName?: string;
  variant?: Variant;
}

export function AppActivityItem(props: AppActivityItemProps) {
  const isDark = props.variant === VARIANT.dark;
  const classes = {
    connector: {
      ...styles.connector,
      backgroundColor: isDark
        ? theme.colors.neutral.$3
        : theme.colors.neutral.$12,
    },
    member: {
      ...styles.member,
      backgroundColor: isDark
        ? theme.colors.neutral.$1
        : theme.colors.neutral.$white,
    },
    name: {
      ...styles.name,
      color: isDark ? theme.colors.neutral.$white : theme.colors.neutral.$2Base,
    },
    action: {
      ...styles.action,
      color: isDark ? theme.colors.neutral.$white : theme.colors.neutral.$2Base,
    },
    fromText: {
      ...styles.fromText,
      color: isDark ? theme.colors.neutral.$white : theme.colors.neutral.$2Base,
    },
    toText: {
      ...styles.toText,
      color: isDark ? theme.colors.neutral.$white : theme.colors.neutral.$2Base,
    },
    time: {
      ...styles.time,
      color: isDark ? theme.colors.neutral.$8 : theme.colors.neutral.$5,
    },
    folder: {
      ...styles.folder,
      ...(isDark && {
        backgroundColor: hexToRGBA('#FFC42E', 0.25),
        paddingHorizontal: 8,
        borderRadius: 10,
      }),
    },
  };

  const folderIconColor = isDark ? '#FFC42E' : theme.colors.neutral.$2Base;

  return (
    <View style={styles.root}>
      {props.connector && <View style={classes.connector} />}
      <View style={classes.member}>{props.avatar}</View>
      <View style={styles.info}>
        <View style={styles.actor}>
          <Text style={classes.name}>{props.name}</Text>
          {!!props.customAction && (
            <Text style={classes.action}>{props.customAction}</Text>
          )}
          {props.isMoveAction && (
            <View style={styles.moved}>
              <View style={styles.actionSpecial}>
                <Text style={classes.fromText}>{t`Moved this file from`}</Text>
                <View style={classes.folder}>
                  <Icon name="folder" size={22} color={folderIconColor} />
                  <Text style={classes.name}>{props.fromFolderName}</Text>
                </View>
              </View>
              <View style={styles.actionSpecial}>
                <Text style={classes.toText}>{t`to`}</Text>
                <View style={classes.folder}>
                  <Icon name="folder" size={22} color={folderIconColor} />
                  <Text style={classes.name}>{props.destFolderName}</Text>
                </View>
              </View>
            </View>
          )}
          {props.isUploadAction && (
            <View style={styles.uploaded}>
              <Text style={classes.toText}>{'Uploaded this file to'}</Text>
              <View style={classes.folder}>
                <Icon name="folder" size={22} color={folderIconColor} />
                <Text style={classes.name}>{props.destFolderName}</Text>
              </View>
            </View>
          )}
        </View>
        {props.details && (
          <View style={styles.details}>
            {!!props.description && (
              <Text style={styles.description}>{props.description}</Text>
            )}
            {props.files && (
              <View style={styles.files}>
                <View style={styles.file}>
                  <Icon
                    name="insert-drive-file"
                    size={22}
                    color={theme.colors.neutral.$2Base}
                  />
                  <Text style={classes.name}>{'Press Kit Overview.pdf'}</Text>
                </View>
              </View>
            )}
          </View>
        )}
        <View style={classes.time}>
          <Text style={classes.time}>{props.time}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    paddingBottom: 15,
  },
  connector: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 14,
    width: 2,
  },
  member: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 4,
    height: 40,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  actor: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 10,
  },
  name: {
    height: 20,
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '600',
    textAlign: 'left',
    textAlignVertical: 'top',
  },
  action: {
    width: '100%',
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
  },
  actionSpecial: {
    gap: 5,
    flexDirection: 'row',
  },
  moved: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  fromText: {
    height: 20,
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
  },
  folder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  toText: {
    height: 20,
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
  },
  uploaded: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
    paddingBottom: 10,
  },
  description: {
    width: '100%',
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$4,
  },
  files: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: '100%',
  },
  file: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    width: '100%',
  },
  time: {
    flex: 1,
    height: 20,
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$5,
  },
});
