import {t} from '@lingui/macro';
import WorkspaceGraphic from 'assets/svgs/workspaces/workspace_graphic.svg';
import theme from 'config/theme';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {useOrganizationPublicDetails} from 'interface/base/hooks/useOrganizationPublicDetails';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {getHashColor} from 'utils/common/color';
import RowCard, {type BorderProps} from './RowCard';

type Props = {
  workspace: WorkspaceListDetail;
  onJoinButtonPress?: (workspace: WorkspaceListDetail) => Promise<void>;
  borderProps?: BorderProps;
};

export default function WorkspaceInvitation({
  workspace,
  onJoinButtonPress,
  borderProps,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const {org, loading: loadingOrg} = useOrganizationPublicDetails(
    workspace.org_domain,
  );

  const handleJoinWorkspace = async () => {
    if (onJoinButtonPress) {
      try {
        setIsLoading(true);
        await onJoinButtonPress(workspace);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <RowCard
      disabled
      borderProps={borderProps}
      avatarColor={getHashColor(workspace.id)}
      avatarPhoto={WorkspaceGraphic}
      avatarImageStyle={styles.avatarImage}
      main={
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.name} numberOfLines={1}>
              {workspace.name}
            </Text>
            <Text style={styles.domain} numberOfLines={1}>
              {loadingOrg ? `(${workspace.org_domain})` : `(${org?.name})`}
            </Text>
          </View>
        </View>
      }
      right={
        <Button
          type="Primary"
          label={t`Join`}
          onPress={handleJoinWorkspace}
          loading={isLoading}
          customRootStyle={styles.joinButton}
          ariaLabel="Join Organization"
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  content: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 7,
    marginBottom: 7,
  },
  name: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  domain: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$4,
  },
  joinButton: {
    minWidth: 80,
  },
  avatarImage: {
    width: 40,
    height: 28,
  },
});
