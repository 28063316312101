import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {usePopup} from 'extensions/viewport/usePopup';
import {MemberAvatar} from 'interface/base/MemberAvatar';
import {useCallback, useEffect, useRef} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import files from 'store/slices/files';
import {createInitials} from 'utils/common/data';

import type {AvatarBorder} from 'interface/base/Avatar';
import type {MultiplayerMember} from 'interface/multiplayer/types';

interface AppMemberAvatarProps {
  member: MultiplayerMember;
  isFirst?: boolean;
  borderKind?: AvatarBorder;
  disableFollow?: boolean;
}

/**
 * Member Badge, Stackable
 */
export function AppMemberAvatar(props: AppMemberAvatarProps) {
  const followId = useSelector(selectors.getFollow);
  const locked = useRef(false);

  const follow = useCallback(
    () => dispatch(files.actions.follow({id: props.member.userId})),
    [props.member.userId],
  );

  const dispatch = useDispatch();
  const {
    open,
    close,
    visible,
    setType,
    setPlacement,
    setRef: popupSetRef,
    setManualPosition,
  } = usePopup();
  const avatarRef = useRef();

  const renderPopup = () => {
    open(
      <View style={styles.tooltip}>
        <Text style={[styles.text, styles.name]}>
          {props.member.userNameFirst}
        </Text>
        <Text style={styles.text}>
          {followId === props.member.userId ? (
            <Trans>Stop following</Trans>
          ) : (
            <Trans>Press to Follow</Trans>
          )}
        </Text>
      </View>,
      {showArrow: true},
    );
  };

  const onHoverIn = () => {
    if (!visible) {
      setType('hoverable');
      setPlacement('bottom');
      avatarRef && popupSetRef(avatarRef);
      setManualPosition(undefined);
      renderPopup();
    }
  };

  const onHoverOut = () => {
    if (!locked?.current) {
      setType(undefined);
      close();
    }
  };

  useEffect(() => {
    if (followId === props.member.userId) {
      renderPopup();
      locked.current = true;
    } else {
      locked.current = false;
    }
    return () => {
      locked.current = false;
      close();
    };
  }, [followId, props.member.userId]);

  // Update the popup when avatar ref changes
  // (mainly when switching clientIds for the same followed user)
  useEffect(() => {
    avatarRef && popupSetRef(avatarRef);
  }, [avatarRef]);

  return (
    <Pressable
      ref={avatarRef}
      style={[styles.root, followId === props.member.userId && styles.disabled]}
      onPress={follow}
      disabled={props.disableFollow}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}>
      <MemberAvatar
        isStacked={!props.isFirst}
        memberId={props.member.userId}
        borderKind={props.borderKind}
        initials={createInitials(
          `${props.member.userNameFirst} ${props.member.userNameLast}`,
        )}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabled: {
    /** @ts-expect-error */
    pointerEvents: 'none',
  },
  tooltip: {
    backgroundColor: theme.colors.neutral.$2Base,
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 10,
  },
  text: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 19,
    color: theme.colors.neutral.$8,
  },
  name: {
    color: theme.colors.neutral.$white,
    fontWeight: '600',
  },
});
