import {colors, fonts, vectors} from 'config/styles';
import {theme} from 'config/themes';
import events from 'extensions/events';
import {IconButton} from 'interface/base/IconButton';
import {
  Fragment,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import {Path, Svg} from 'react-native-svg';

import {CustomTextInput} from './CustomTextInput';

export interface PromptProps {
  title?: string;
  text?: string;
  maxWidth?: number;
  maxHeight?: number;
  rootStyle?: ViewStyle;
  children?: ReactNode;
  buttons?: PromptButton[];
  options?: PromptOptions;
}

export interface PromptButton {
  mode?: 'primary' | 'cancel' | 'destructive';
  text?: string;
  disabled?: boolean;
  textStyle?: TextStyle;
  buttonStyle?: ViewStyle;
  onPress?: (inputValue?: string) => void;
}

export interface PromptOptions {
  type?: 'default' | 'plain-text' | 'secure-text';
  keyboardType?: 'default' | 'email-address' | 'numeric' | 'number-pad';
  autoCompleteType?: 'email' | 'name' | 'password' | 'username' | 'off';
  selectInput?: 'none' | 'all' | 'filename';
  defaultValue?: string;
  placeholder?: string;
  primaryColor?: string;
  forceChoice?: boolean;
  isMinimal?: boolean;
}

export function Prompt(props: PromptProps) {
  const [input, setInput] = useState(props.options?.defaultValue ?? undefined);

  const submit = useCallback(() => {
    let target: PromptButton | undefined;
    let foundPrimary = false;
    for (const button of props.buttons) {
      const isPrimary = button.mode === 'primary';
      if (isPrimary) foundPrimary = true;
      if (isPrimary || (button.mode === 'destructive' && !foundPrimary)) {
        target = button;
      }
    }
    if (target) {
      pressButton(target, input);
      return true;
    }
    return false;
  }, [props.buttons, input]);

  const cancel = useCallback(() => {
    let target: PromptButton | undefined;
    for (const button of props.buttons) {
      if (button.mode === 'cancel') {
        target = button;
      } else if (!target && button.mode === 'primary') {
        target = button;
      }
    }
    if (target) {
      pressButton(target);
      return true;
    }
    return false;
  }, [props.buttons]);

  const pressButton = useCallback((button: PromptButton, value?: string) => {
    if (button.onPress) {
      button.onPress(value);
    }
  }, []);

  const renderButton = useCallback(
    (button: PromptButton, index: number) => (
      <Fragment key={index}>
        {index > 0 && <View style={styles.spacer} />}
        <IconButton
          mode={button.mode}
          title={button.text}
          text={button.text || ''}
          buttonStyle={button.buttonStyle}
          disabled={button.disabled}
          onPress={e => {
            e.stopPropagation();
            pressButton(button, input);
          }}
        />
      </Fragment>
    ),
    [pressButton, input],
  );

  useEffect(
    () =>
      events.addKeyListener('up', e => {
        if (e.key === 'Escape') cancel();
      }).unsubscribe,
    [cancel],
  );

  return (
    <View style={[styles.root, props.rootStyle]}>
      <View importantForAccessibility="yes">
        <Text style={styles.title}>{props.title}</Text>
      </View>
      {!props.options?.forceChoice && (
        <Pressable onPress={cancel} style={styles.close}>
          <Svg viewBox="0 0 14 14" width={14} height={14}>
            <Path d={vectors.icons.close} fill={colors.neutral.mid} />
          </Svg>
        </Pressable>
      )}
      <View style={styles.content}>
        {props.children}
        {!!props.text && !props.children && (
          <Text style={styles.message}>{props.text}</Text>
        )}
        {props.options?.type && props.options.type !== 'default' && (
          <CustomTextInput
            customProps={{
              autoFocus: true,
              onChangeText: setInput,
              onSubmitEditing: submit,
              defaultValue: props.options?.defaultValue,
              keyboardType: props.options?.keyboardType,
            }}
            placeholder={props.options?.placeholder}
            // @ts-ignore
            autoCompleteType={props.options?.autoCompleteType}
            secureTextEntry={props.options?.type === 'secure-text'}
            selectInput={props.options?.selectInput}
          />
        )}
      </View>
      {props.buttons && props.buttons.length > 0 && (
        <View style={styles.buttons}>{props.buttons.map(renderButton)}</View>
      )}
    </View>
  );
}

const modalPadding = 20;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    maxWidth: 480,
    backgroundColor: theme.dialog.fill,
  },
  title: {
    fontSize: fonts.sizes.xl,
    color: theme.dialog.title,
    padding: modalPadding,
  },
  message: {
    textAlign: 'left',
    fontSize: fonts.sizes.m,
    color: theme.dialog.text,
    marginBottom: 10,
  },
  content: {
    flex: 1,
    paddingHorizontal: modalPadding,
  },
  buttons: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    padding: modalPadding,
  },
  close: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    marginRight: 6,
    padding: 8,
    height: 46,
    width: 46,
    right: 0,
  },
  spacer: {
    width: 6,
  },
});
