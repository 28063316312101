import {t} from '@lingui/macro';
import type {BillingUsageMember as Member} from 'fast-sdk/src/api/billing/consts';
import {Loading} from 'interface/base/Loading';
import type React from 'react';
import {forwardRef, useCallback} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {PageContent} from '../../base/PageContent';
import {PageTitle} from '../../base/PageTitle';
import SettingsTable, {
  type SortColumnMap,
  type Column,
} from '../../base/SettingsTable';
import useBillingMembers from '../../hooks/useBillingMembers';
import WorkspaceMemberRow from './WorkspaceMemberRow';

const columns: Array<Column> = [
  {
    title: 'Member',
    width: '67%',
    isSortable: true,
    sort: (direction: 'asc' | 'desc') => (a, b) => {
      const aName = `${a.first_name} ${a.last_name}`;
      const bName = `${b.first_name} ${b.last_name}`;

      if (direction === 'asc') {
        return aName.localeCompare(bName);
      }

      return bName.localeCompare(aName);
    },
  },
  {title: 'Workspaces', width: '21%'},
  {title: 'Date Joined', width: '12%'},
];

const WorkspaceMembers = forwardRef((_, ref: React.ForwardedRef<View>) => {
  const {members, loading} = useBillingMembers();

  const renderRows = useCallback(
    (sortedColumns: SortColumnMap, shouldSort: boolean) => {
      const sortedMembers = shouldSort
        ? members.sort((a: Member, b: Member) => {
            for (const column in sortedColumns) {
              const sortInfo = sortedColumns[column];
              const sortFn = sortInfo.sort(sortInfo.direction);
              const result = sortFn(a, b);
              if (result !== 0) {
                return result;
              }
            }
            return 0;
          })
        : members;

      return sortedMembers.map(member => (
        <WorkspaceMemberRow key={member.id} member={member} columns={columns} />
      ));
    },
    [members],
  );

  return (
    <View style={styles.root} ref={ref}>
      <PageTitle customClass={styles.title} text={t`Workspace Members`} />
      <PageContent
        rootStyle={styles.content}
        text={t`Below is a list of all members across all of your workspaces, including those who are not members of your org.`}
      />
      {loading ? (
        <Loading />
      ) : members.length ? (
        <SettingsTable columns={columns} renderRows={renderRows} />
      ) : (
        <Text style={styles.noMembersText}>No workspace members to show</Text>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    marginBottom: 28,
  },
  title: {
    marginBottom: 7,
  },
  content: {
    marginBottom: 35,
  },
  noMembersText: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    textAlign: 'center',
  },
});

export default WorkspaceMembers;
