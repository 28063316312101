import theme from 'config/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';

type Props = {
  checked: boolean;
};

export default function RadioButton({checked}: Props) {
  return <View style={[styles.root, checked && styles.checked]} />;
}

const styles = StyleSheet.create({
  root: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$5,
    borderStyle: 'solid',
    borderRadius: 9999,
    backgroundColor: theme.colors.neutral.$white,
  },
  checked: {
    borderWidth: 5,
    borderColor: theme.colors.brand.$4Base,
  },
});
