import theme from 'config/theme';
import {Avatar} from 'interface/base/Avatar';
import Chip from 'interface/base/Chip';
import {ProfileMemberItemInvite} from 'interface/stacks/app/ProfileMemberItemInvite';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

interface MemberInfoProps {
  isInvite: boolean;
  firstName: string;
  lastName: string;
  email: string;
  profilePic: string;
  color: string;
  state?: string;
  id?: string;
  isMe?: boolean;
}

export const MemberInfo = ({
  isInvite,
  firstName,
  lastName,
  email,
  profilePic,
  color,
  state,
  id,
  isMe,
}: MemberInfoProps) => (
  <View style={styles.info}>
    <View style={styles.avatar}>
      {isInvite ? (
        <ProfileMemberItemInvite id={id!} email={email} />
      ) : (
        <Avatar
          color={color}
          initials={firstName[0].toUpperCase() + lastName[0].toUpperCase()}
          photo={profilePic}
        />
      )}
    </View>
    {!isInvite && (
      <View>
        <Text style={styles.name}>
          {firstName} {lastName} {isMe && '(you)'}
        </Text>
        <Text style={styles.subtitle}>{email}</Text>
      </View>
    )}
    {isInvite && state && (
      <Chip
        label={state}
        color={theme.colors.neutral.$11}
        textColor={theme.colors.neutral.$2Base}
      />
    )}
  </View>
);

const styles = StyleSheet.create({
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '35%',
  },
  name: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  avatar: {
    marginRight: 9,
  },
  email: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    width: '25%',
  },
  permissions: {
    width: '15%',
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    color: theme.colors.neutral.$2Base,
  },
});
