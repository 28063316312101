import {t} from '@lingui/macro';
import theme from 'config/theme';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {Requests, api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import {Layout} from 'interface/stacks/auth/Layout';
import {PasswordInput} from 'interface/stacks/auth/components/PasswordInput';
import {UserBadge} from 'interface/stacks/auth/components/UserBadge';
import Auth from 'interface/stacks/auth/consts';
import {useState} from 'react';
import {StyleSheet, View, useWindowDimensions} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import user from 'store/slices/user';
import type {Type} from './layout/Layout';

interface Props {
  email: string;
  profilePic: string;
  useDifferentAccount: () => void;
  type: Type;
  id: string;
}

export function Password(props: Props) {
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const signIn = () => {
    setLoading(true);
    api.auth
      .signIn({email: props.email, password})
      .then(res => {
        if (res.result) {
          Requests.setAuthToken(res.auth_token);
          // Store the user token
          Auth.setAuthToken(res.auth_token);
          dispatch(user.actions.setUserToken(res.auth_token));
          return api.user.userDetails();
        }
      })
      .then(res => {
        if (res?.result) {
          dispatch(user.actions.setUserDetails({...res.user}));
        }
      })
      .finally(() => {
        setLoading(false);
        navigate(`/request-access/${props.type}/${props.id}`);
      })
      .catch(err => {
        handleAppError({
          appError: AppErrors.SignInPasswordError,
          exception: err,
        });
        toast.show(err.message, {type: 'danger'});
      });
  };

  const dimension = useWindowDimensions();

  return (
    <View
      style={[
        styles.root,
        {width: dimension.width > 790 ? 380 : dimension.width - 40},
      ]}>
      <Layout
        title={t`Welcome back`}
        subTitle={
          <View>
            <UserBadge
              id={props.id}
              email={props.email}
              imageSrc={props.profilePic}
              initials="TT"
              customStyle={{marginBottom: '0.5rem'}}
            />
            <Button
              type="Text"
              onPress={props.useDifferentAccount}
              label={t`Use different account`}
              customRootStyle={{backgroundColor: 'transparent'}}
            />
          </View>
        }>
        {loading ? (
          <AppLoading />
        ) : (
          <form
            onSubmit={e => {
              e.preventDefault();
              signIn();
            }}
            style={styles.root}>
            <PasswordInput
              password={password}
              setPassword={setPassword}
              customRootStyle={{marginBottom: '1.5rem'}}
            />
            <Button
              label={t`Continue`}
              onPress={signIn}
              disabled={!password}
              type={'Primary'}
              customRootStyle={styles.fullWidth}
            />
            <Button
              label={t`Forgot password?`}
              type="Text"
              onPress={() => navigate('/forgot-password')}
              customRootStyle={styles.forgotPassword}
              customTextStyle={styles.forgotPasswordText}
            />
          </form>
        )}
      </Layout>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  forgotPassword: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginTop: 21,
    backgroundColor: 'transparent',
  },
  forgotPasswordText: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.brand.$4Base,
    lineHeight: 24,
  },
});
