import theme from 'config/theme';
import {format} from 'date-fns';
import {StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';

interface Props {
  expirationDate: Date;
}

export function ShareExpiration({expirationDate}: Props) {
  return (
    <View style={styles.root}>
      <MCIcon name="calendar-month" style={styles.icon} size={20} />
      <Text
        style={styles.text}>{`${format(expirationDate, 'LLLL d, yyyy')}`}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 7,
    backgroundColor: theme.colors.neutral.$13,
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 50,
  },
  icon: {
    color: theme.colors.neutral.$2Base,
    width: 20,
    height: 20,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});
