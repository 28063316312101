import type {PayloadAction} from '@reduxjs/toolkit';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {PendingInvitationDetails} from 'interface/stacks/auth/consts';

export type Store = {
  user?: AppUser;
  active: boolean;
  loaded: boolean;
  revision: number;
  domainOrigin: string;
  stopRedirect: boolean;
  currentJoinInvitation: PendingInvitationDetails | undefined;
  forceLogout: boolean;
  redirectToRenewSubscription: boolean;
  renewSubscriptionSubdomain: string | undefined;
  isInvitationFlow: boolean;
  currentItem?: EntityGroup;
  activityTrayInfo: ActivityTrayInfo;
  userPermission: string | undefined;
};

export type Actions = {
  Start: PayloadAction<{}>;
  Revision: PayloadAction<{}>;
  Login: PayloadAction<AppUser>;
  Activate: PayloadAction<{active: boolean}>;
  Load: PayloadAction<{loaded: boolean}>;
  ToogleActivityTray: PayloadAction<{
    isManual: boolean;
    isOpen: boolean;
  }>;
  SetActivityTrayTab: PayloadAction<{tab: ActivityTrayTab}>;
  Notify: PayloadAction<{count: number}>;
  DomainOrigin: PayloadAction<{origin: string}>;
  SetCurrentJoinInvitation: PayloadAction<{
    joinInvitation: PendingInvitationDetails;
  }>;
  ClearCurrentJoinInvitation: PayloadAction<{}>;
  SetForceLogout: PayloadAction<boolean>;
  SetRedirectToRenewSubscription: PayloadAction<{
    redirect: boolean;
    subdomain: string;
  }>;
  setIsInvitationFlow: PayloadAction<{isInvitationFlow: boolean}>;
  setUserPermission: PayloadAction<{permission: string}>;
  SetCurrentGroup: PayloadAction<EntityGroup>;
};

export type AppUser = {
  ekey: string;
  name?: string;
  email?: string;
  avatar: string;
  created: string;
  options: number;
  header: AppHeader;
  group: AppUserGroup;
  limit: AppUserLimit;
};

export type AppHeader = {
  custom: boolean;
  logo: string;
  theme: {
    header: string;
  };
  colors: {
    header: string;
    primary: string;
  };
  business: {
    company: string;
    subdomain: string;
  };
};

export type AppUserLimit = {
  storage: {
    used: number;
    total: number;
  };
};

export enum AppUserGroup {
  Unregistered = 0,
  Trial = 1,
  Free = 2,
  Pro = 3,
  Business = 4,
}

export enum EntityGroupType {
  Workspace = 'workspace',
  Folder = 'folder',
  File = 'file',
  Share = 'share',
}

export type ActivityTrayInfo = {
  manualToggle: boolean;
  isOpen: boolean;
  tab: ActivityTrayTab;
};

export enum ActivityTrayTab {
  Activity = 'activity',
}

export interface EntityGroup {
  type: EntityGroupType;
  id: string;
  instanceId: string;
  instanceNs: StorageNamespace;
}
