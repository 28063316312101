import type {Zoom} from './useZoom';

type Props = {
  handleZoom: (zoom: Zoom) => void;
  imageWidth: number;
  imageHeight: number;
  containerWidth: number;
  containerHeight: number;
  callback?: () => void;
};

const useExpandImage = ({
  callback,
  handleZoom,
  imageWidth,
  imageHeight,
  containerWidth,
  containerHeight,
}: Props) => {
  const handleExpandHorizontal = () => {
    if (imageHeight < imageWidth) {
      return handleZoom(1);
    }

    const scaleY = containerHeight / imageHeight;

    const width = Math.ceil(imageWidth * scaleY);
    const scaleX = containerWidth / width;
    handleZoom(scaleX);
    callback?.();
  };

  const handleExpandVertical = () => {
    if (imageHeight > imageWidth) {
      return handleZoom(1);
    }

    const scaleX = containerWidth / imageWidth;

    const height = Math.ceil(imageHeight * scaleX);
    const scaleY = containerHeight / height;
    handleZoom(scaleY);
    callback?.();
  };

  return {handleExpandHorizontal, handleExpandVertical};
};

export default useExpandImage;
