import theme from 'config/theme';
import AppLogo from 'interface/base/AppLogo';
import {Loading} from 'interface/base/Loading';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {OrganizationLogo} from 'interface/stacks/auth/components/OrganizationLogo';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors as settingsSelectors} from 'store/slices/settings';
import {selectors as userSelectors} from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';

interface Props {
  kind?: 'light' | 'dark';
}

const ShareHeaderOrg: React.FC<Props> = ({kind = 'light'}) => {
  const {subdomain} = useSubDomain();
  const orgLogo = useSelector(settingsSelectors.getOrganizationLogo);
  const organization = useSelector(userSelectors.getSelectedOrganization);

  const showOrganizationInfo = isSubdomainValid(subdomain);
  const textColor =
    kind === 'light'
      ? theme.colors.neutral.$2Base
      : theme.colors.neutral.$white;

  const renderOrganizationContent = () => {
    if (!organization) return <Loading />;

    return (
      <>
        <OrganizationLogo
          organization={{...organization, logo: orgLogo}}
          size={2}
          shapeKind="rounded-square"
        />
        <Text style={[styles.orgName, {color: textColor}]}>
          {organization.name}
        </Text>
      </>
    );
  };

  return (
    <View style={styles.root}>
      {showOrganizationInfo ? (
        renderOrganizationContent()
      ) : (
        <AppLogo kind={kind} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  orgName: {
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 24,
    paddingHorizontal: 8,
  },
});

export default ShareHeaderOrg;
