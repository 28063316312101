import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {useDispatch} from 'react-redux';
import * as app from 'store/slices/app';
import {getVersion} from 'utils/common/version';
import useLegalNavigation from './hooks/useLegalNavigation';

export default function Footer() {
  const dispatch = useDispatch();
  const {navigateToTerms, navigateToPrivacy, navigateToHelp} =
    useLegalNavigation();

  const onResetSession = async () => {
    dispatch(app.default.actions.setForceLogout(true));
  };

  return (
    <View style={styles.root}>
      <Popup
        placement="top-start"
        triggerElement={
          <View style={styles.resetContainer}>
            <Text style={styles.text}>©2024 Fast.io</Text>
            <Text style={styles.text}>{`v${getVersion()}`}</Text>
          </View>
        }>
        <View style={styles.popup}>
          <Text>
            <Trans>Advanced Settings</Trans>
          </Text>
          <Button
            type="Primary"
            label={t`Reset Fast.io session`}
            onPress={onResetSession}
          />
        </View>
      </Popup>
      <Pressable onPress={navigateToTerms}>
        <Text style={styles.text}>Terms</Text>
      </Pressable>
      <Pressable onPress={navigateToPrivacy}>
        <Text style={styles.text}>Privacy Policy</Text>
      </Pressable>
      <Pressable onPress={navigateToHelp}>
        <Text style={styles.text}>Support</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: 76,
    flexDirection: 'row',
    gap: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
  popup: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    padding: 15,
  },
  resetContainer: {
    flexDirection: 'row',
    gap: 14,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
