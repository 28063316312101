import {SocialLinkType} from 'store/slices/settings/types';
import FacebookLogo from '../assets/facebook.png';
import InstagramLogo from '../assets/instagram.png';
import TwitterLogo from '../assets/twitter.png';
import WebLogo from '../assets/web.png';
import YoutubeLogo from '../assets/youtube.png';

export type SettingsMenuLink = {
  title: string;
  link: string;
  iconName: string;
  sectionName: SettingsType;
  comunityIcon?: boolean;
};

export enum SettingsType {
  profile = 'profile',
  'account-settings' = 'account-settings',

  organization = 'organization',
  members = 'members',
  billing = 'billing',
  authentication = 'authentication',
  'email-domains' = 'email-domains',
}

const linkNameSpace = '/settings';
const nameToLink = (sectionName: string) => `${linkNameSpace}/${sectionName}`;

export const ORGANIZATION_SETTINGS_MENU_LINKS: Array<SettingsMenuLink> = [
  {
    title: 'Organization Settings',
    link: nameToLink('organization'),
    iconName: 'settings',
    sectionName: SettingsType.organization,
  },
  {
    title: 'Manage Members',
    link: nameToLink('members'),
    iconName: 'people',
    sectionName: SettingsType.members,
  },
  {
    title: 'Billing',
    link: nameToLink('billing'),
    iconName: 'account-balance-wallet',
    sectionName: SettingsType.billing,
  },
  // key-variant key
  {
    title: 'Authentication',
    link: nameToLink('authentication'),
    iconName: 'vpn-key',
    sectionName: SettingsType.authentication,
  },
  {
    title: 'Email Domains',
    link: nameToLink('email-domains'),
    iconName: 'language',
    sectionName: SettingsType['email-domains'],
  },
];

export const ACCOUNT_SETTINGS_MENU_LINKS: Array<SettingsMenuLink> = [
  {
    title: 'Profile',
    link: nameToLink('profile'),
    iconName: 'account-circle-outline',
    sectionName: SettingsType.profile,
    comunityIcon: true,
  },
  {
    title: 'Account Settings',
    link: nameToLink('account-settings'),
    iconName: 'settings',
    sectionName: SettingsType['account-settings'],
  },
];

export const socialLinkTypeToLogo = (type: SocialLinkType): string => {
  switch (type) {
    case SocialLinkType.Youtube:
      return YoutubeLogo;
    case SocialLinkType.Facebook:
      return FacebookLogo;
    case SocialLinkType.Instagram:
      return InstagramLogo;
    case SocialLinkType.Twitter:
      return TwitterLogo;
    case SocialLinkType.Other:
      return WebLogo;
  }
};

export const monthNumberToName = (monthNumber: number) => {
  switch (monthNumber) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
  }
};

export const dateFormatter = (date: Date) => {
  return `${monthNumberToName(date.getMonth())} ${date.getDay() + 1}, ${date.getFullYear()}`;
};

export const timezoneOptions = [
  {title: '(GMT -6:00) Central Time (US & Canada)', value: 'CST'},
  {title: '(GMT -5:00) Eastern Standard Time', value: 'EST'},
  {title: '(GMT -5:00) Indiana Eastern Standard Time', value: 'IET'},
  {title: '(GMT -4:00) Puerto Rico and US Virgin Islands Time', value: 'PRT'},
  {title: '(GMT -3:30) Canada Newfoundland Time', value: 'CNT'},
  {title: '(GMT -3:00) Argentina Standard Time', value: 'AGT'},
  {title: '(GMT -3:00) Brazil Eastern Time', value: 'BET'},
  {title: '(GMT -1:00) Central African Time', value: 'CAT'},
  {title: '(GMT) Greenwich Mean Time', value: 'GMT'},
  {title: '(GMT) Universal Coordinated Time', value: 'UTC'},
  {title: '(GMT +1:00) European Central Time', value: 'ECT'},
  {title: '(GMT +2:00) Eastern European Time', value: 'EET'},
  {title: '(GMT +2:00) Egypt Standard Time (Arabic)', value: 'ART'},
  {title: '(GMT +3:00) Eastern African Time', value: 'EAT'},
  {title: '(GMT +3:30) Middle East Time', value: 'MET'},
  {title: '(GMT +4:00) Near East Time', value: 'NET'},
  {title: '(GMT +5:00) Pakistan Lahore Time', value: 'PLT'},
  {title: '(GMT +5:30) India Standard Time', value: 'IST'},
  {title: '(GMT +6:00) Bangladesh Standard Time', value: 'BST'},
  {title: '(GMT +7:00) Vietnam Standard Time', value: 'VST'},
  {title: '(GMT +8:00) China Taiwan Time', value: 'CTT'},
  {title: '(GMT +9:00) Japan Standard Time', value: 'JST'},
  {title: '(GMT +9:30) Australia Central Time', value: 'ACT'},
  {title: '(GMT +10:00) Australia Eastern Time', value: 'AET'},
  {title: '(GMT +11:00) Solomon Standard Time', value: 'SST'},
  {title: '(GMT +12:00) New Zealand Standard Time', value: 'NST'},
  {title: '(GMT -11:00) Midway Islands Time', value: 'MIT'},
  {title: '(GMT -10:00) Hawaii Standard Time', value: 'HST'},
  {title: '(GMT -9:00) Alaska Standard Time', value: 'AST'},
  {title: '(GMT -8:00) Pacific Standard Time', value: 'PST'},
  {title: '(GMT -7:00) Phoenix Standard Time', value: 'PNT'},
  {title: '(GMT -7:00) Mountain Standard Time', value: 'MST'},
];
