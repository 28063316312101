import {Trans, t} from '@lingui/macro';
import EmptyOrgsIcon from 'assets/svgs/logos/EmptyOrgs.svg';
import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/base/Button';
import {Image, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch} from 'react-redux';
import onboarding from 'store/slices/onboarding';

export const EmptyOrganizationCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backgroundColor = theme.colors.neutral.$14;

  const goToCreateOrg = () => {
    dispatch(onboarding.actions.restartOnboarding());
    navigate('/onboarding');
  };

  return (
    <View style={[styles.root, {backgroundColor}]}>
      <Image
        source={{uri: EmptyOrgsIcon}}
        style={styles.image}
        alt={t`You don't have any organication`}
      />
      <Text style={styles.text}>
        <Trans>
          You don’t currently have any organizations associated with your
          account. Create an org to get started.
        </Trans>
      </Text>
      <Button
        lIcon={
          <Icon
            name="add-circle"
            color={theme.colors.neutral.$white}
            size={20}
          />
        }
        onPress={goToCreateOrg}
        label={t`Create Org`}
        type="Primary"
        ariaLabel="Create Org"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderRadius: 14,
    padding: '1.5rem',
    gap: 14,
  },
  image: {
    width: 64,
    height: 64,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
});
