import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import React from 'react';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {AppSideNavWorkspaceItem} from './AppSideNavWorkspaceItem';

type Props = {
  workspace: WorkspaceListDetail;
};

export default function AppSideNavOrphanWorkspaceItem({workspace}: Props) {
  const currentUser = useSelector(user.selectors.getUserDetails);

  return (
    <AppSideNavWorkspaceItem
      workspace={workspace}
      currentUserId={currentUser.id}
      isExternal
    />
  );
}
