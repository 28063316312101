import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';

type Props = {
  color?: string;
};

/**
 * 1px divider, horizontal.
 */
export function Divider({color}: Props) {
  return <View style={[styles.root, {backgroundColor: color}]} />;
}

const styles = StyleSheet.create({
  root: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.neutral.$11,
  },
});
