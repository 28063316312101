import {ROUTES} from 'constants/routes';
import type {Share} from 'store/slices/shared/types';
import {formatStringForUrl} from 'utils/fast/common';

const useGenerateShareUrl = () => {
  const generateShareUrl = (share: Share) => {
    return `/${ROUTES.LOGGED_IN_WITH_ORG.SHARED}/${share.custom_name}/${formatStringForUrl(share.title)}`;
  };

  return {
    generateShareUrl,
  };
};

export default useGenerateShareUrl;
