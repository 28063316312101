import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import {ActivityTrayTab, type Store} from './types';

export const initialState: Store = {
  loaded: false,
  active: false,
  revision: 0,
  domainOrigin: 'fast.io',
  stopRedirect: false,
  currentJoinInvitation: undefined,
  forceLogout: false,
  redirectToRenewSubscription: false,
  renewSubscriptionSubdomain: undefined,
  isInvitationFlow: false,
  userPermission: undefined,
  currentItem: null,
  activityTrayInfo: {
    manualToggle: false,
    isOpen: true,
    tab: ActivityTrayTab.Activity,
  },
};
const sliceName = 'app';

const resetManualTransform = {
  in: state => state,
  out: (state, key) => {
    if (key === sliceName) {
      return {
        ...state,
        activityTrayInfo: {
          ...state.activityTrayInfo,
          manualToggle: initialState.activityTrayInfo.manualToggle,
        },
      };
    }
    return state;
  },
};

export default createSlice({name: sliceName, reducers, initialState});
export {resetManualTransform, selectors};
