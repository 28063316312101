import materialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import materialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import 'react-image-crop/dist/ReactCrop.css';

import App from 'interface';
import {AppRegistry} from 'react-native';
import config from 'react-native-ultimate-config';

injectIcons();

AppRegistry.registerComponent(config.APP_NAME, () => App);
AppRegistry.runApplication(config.APP_NAME, {
  rootTag: document.getElementById('root'),
});

function injectIcons() {
  const style = document.createElement('style');
  style.type = 'text/css';
  // @ts-ignore
  if (style.styleSheet) {
    // @ts-ignore
    style.styleSheet.cssText = font1;
    // @ts-ignore
    style.styleSheet.cssText = font2;
  } else {
    style.appendChild(
      document.createTextNode(
        `@font-face {src: url(${materialIcons}); font-family: MaterialIcons}`,
      ),
    );
    style.appendChild(
      document.createTextNode(
        `@font-face {src: url(${materialCommunityIcons}); font-family: MaterialCommunityIcons}`,
      ),
    );
  }
  document.head.appendChild(style);
}
