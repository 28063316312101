import {Trans, t} from '@lingui/macro';
import {PaymentElement, useElements} from '@stripe/react-stripe-js';
import type {SetupIntentResult} from '@stripe/stripe-js';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {Loading} from 'interface/base/Loading';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import useSubscriptionStatus from '../hooks/useSubscriptionStatus';
import type {BillingPlan} from '../lib/types';
import BillingPlanDetails from './BillingPlanDetails';

export interface Props {
  plan: BillingPlan;
  tax: number;
  handlePayment: () => Promise<SetupIntentResult>;
  onError?: () => void;
  setPaymentLoading: (loading: boolean) => void;
  subdomain: string;
}

const MAX_RETRIES = 3;
const SUBSCRIPTION_WAIT_TIME = 3000;

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function AddCard({
  handlePayment,
  plan,
  tax,
  subdomain,
  onError,
  setPaymentLoading,
}: Props) {
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(true);
  const {handleSubscribed} = useSubscriptionStatus();

  const handleSubmit = async event => {
    event.preventDefault();

    const elementsResult = await elements?.submit();

    if (elementsResult?.error) {
      return;
    }

    setPaymentLoading(true);

    const payment = await handlePayment();

    if (payment.error) {
      const isPaymentDone = payment.error.setup_intent?.status === 'succeeded';

      if (!isPaymentDone) {
        analytics.notify(payment.error.message);
        setPaymentLoading(false);
        return onError();
      }
    }

    for (let i = 0; i < MAX_RETRIES; i++) {
      await timeout(SUBSCRIPTION_WAIT_TIME);
      const response = await api.billing.getSubscriptionDetails(subdomain);

      if (response.result && response.billing_status.active) {
        setPaymentLoading(false);
        return handleSubscribed();
      }
    }

    setPaymentLoading(false);
    return onError();
  };

  const total = plan.pricing.free
    ? '0.00'
    : (plan.pricing.price_base + tax).toFixed(2);

  return (
    <View style={styles.root}>
      <Text selectable style={styles.title}>
        <Trans>Start your free trial</Trans>
      </Text>
      <Text selectable style={styles.content}>
        <Trans>
          Add your payment details to activate your free 14-day trial. You can
          cancel at any time.
        </Trans>
      </Text>
      <View style={styles.details}>
        <View style={styles.inputs}>
          <View>
            <Text style={styles.sectionTitle}>
              <Trans>Payment details</Trans>
            </Text>
            {loading && <Loading />}
            <form style={{display: loading ? 'none' : 'block'}}>
              <View>
                <PaymentElement
                  onReady={() => setLoading(false)}
                  options={{
                    layout: {
                      type: 'tabs',
                      defaultCollapsed: false,
                    },
                  }}
                />
              </View>
              {!loading && (
                <Button
                  type="Primary"
                  onPress={handleSubmit}
                  label={t`Complete trial activation - pay $${total}`}
                  customRootStyle={styles.button}
                  customTextStyle={styles.buttonText}
                />
              )}
            </form>
          </View>
        </View>

        <View style={styles.plans}>
          <Text style={styles.sectionTitle}>
            <Trans>Summary</Trans>
          </Text>
          <BillingPlanDetails
            title={plan.title}
            tax={tax}
            free={plan.pricing.free}
            freeDays={plan.pricing.free_days}
            priceBase={plan.pricing.price_base}
            billed={plan.pricing.billed}
            discount={(plan.pricing.discount as number) || undefined}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    marginBottom: 21,
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
  },
  content: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    marginBottom: 35,
    textAlign: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 50,
    width: '100%',
  },
  sectionTitle: {
    fontSize: 13,
    lineHeight: 20,
    fontWeight: '600',
    color: theme.colors.neutral.$5,
    paddingBottom: 10,
  },
  inputs: {
    width: '100%',
    maxWidth: 405,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 12,
  },
  plans: {
    width: 405,
  },
  rowInputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    width: '100%',
    marginTop: 26,
    height: 48,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 24,
  },
});
