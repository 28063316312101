import {t} from '@lingui/macro';
import {api} from 'fast-sdk';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as shared from 'store/slices/shared';

export default function useUpdateShare({share}: {share: ShareItem}) {
  const dispatch = useDispatch();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const updateShare = useCallback(
    async (updatedShare: Partial<ShareItem>) => {
      try {
        setIsLoading(true);
        const {result} = await api.share.updateShare(share.id, updatedShare);
        if (result) {
          const workspaceFolderName = share.parent_workspace_folder;
          dispatch(
            shared.default.actions.updateShareDetails({
              workspaceFolderName,
              shareDetails: {
                ...share,
                ...updatedShare,
                comments: {
                  ...share.comments,
                  enabled:
                    updatedShare.comments_enabled ?? share.comments.enabled,
                },
              },
            }),
          );

          toast.show(t`Shared folder "${updatedShare.title}" updated`, {
            type: 'neutral',
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [share],
  );

  return {
    updateShare,
    isLoading,
  };
}
