import {Elements} from '@stripe/react-stripe-js';
import {Loading} from 'interface/base/Loading';
import {useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import useBillingPlan from '../hooks/useBillingPlan';
import useCreateOrg from '../hooks/useCreateOrg';
import useLoadStripe from '../hooks/useLoadStripe';
import useSubscriptionStatus from '../hooks/useSubscriptionStatus';
import PaymentNew from './PaymentNew';

export function Payment() {
  const {loading: loadingBilling, tax, plan} = useBillingPlan();
  const {
    loading: loadingOrgCreation,
    clientSecret,
    stripeKey,
  } = useCreateOrg({plan});
  const {isSubscribed} = useSubscriptionStatus();
  const {stripe, loading: loadingStripe} = useLoadStripe({
    key: stripeKey,
    loading: loadingOrgCreation || !stripeKey,
  });

  const subdomain = useSelector(onboarding.selectors.getSubdomain);
  const createdOrg = useSelector(onboarding.selectors.getCreatedOrganizationId);
  const intent = useSelector(onboarding.selectors.getIntentClientSecret);

  const secret = clientSecret || intent;

  const loading =
    loadingBilling || loadingOrgCreation || loadingStripe || !clientSecret;

  return loading ? (
    <Loading />
  ) : (
    <Elements stripe={stripe} options={{clientSecret: secret}}>
      <PaymentNew
        plan={plan}
        tax={tax}
        isSubscribed={isSubscribed}
        subdomain={subdomain}
        orgId={createdOrg}
      />
    </Elements>
  );
}
