import {t} from '@lingui/macro';
import theme from 'config/theme';
import {IconButton} from 'interface/base/IconButton';
import Skeleton from 'interface/base/Skeleton';
import * as uploadStatus from 'interface/stacks/uploads/utils/status';
import {useRef, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {bytesize, truncate} from 'utils/common/data';
import {MEDIUM_THUMBNAIL_SIZE} from './thumbnail/FileThumbnail';

import type {MouseEvent, ReactNode} from 'react';
import type {GestureResponderEvent} from 'react-native';
import type {FileListItemProps} from './FileListItem';

const THUMBNAIL_PADDING_TOP = 8;
const THUMBNAIL_PADDING_BOTTOM = 4;
const THUMBNAIL_HEIGHT = MEDIUM_THUMBNAIL_SIZE + THUMBNAIL_PADDING_BOTTOM;

const MENU_BUTTON_SIZE = 40;
const MENU_BUTTON_OFFSET = 4;

const TEXT_HEIGHT = 20;
const TEXT_CUTOFF = 25;
const FOOTER_PADDING = 4;
const FOOTER_INFO_HEIGHT = (TEXT_HEIGHT + FOOTER_PADDING) * 2;

export const FILE_CELL_HEIGHT = THUMBNAIL_HEIGHT + FOOTER_INFO_HEIGHT;

export interface FileListItemCellProps extends FileListItemProps {
  thumbnail: ReactNode;
  focus?: (openDetails?: boolean) => void;
  onPress?: () => void;
  renderIcons?: () => ReactNode;
  onDropDownMenu?: (e: MouseEvent | GestureResponderEvent, ref?: any) => void;
  menuAnchor?: any;
  fileLoading: boolean;
}

export function FileListItemCell(props: FileListItemCellProps) {
  const [hovered, setHovered] = useState(false);
  const hasDetails = !props.disableDetails;
  const hasUpload = Boolean(props.item?.upload);
  const hasUploadProgress =
    hasUpload && uploadStatus.isTransferring(props.item.upload.status);
  const hasUploadFailure =
    hasUpload && uploadStatus.isFailure(props.item.upload.status);
  const hasUploadRestart =
    hasUpload && uploadStatus.isRestartable(props.item.upload.status);
  const hasContextMenu =
    hovered && !hasUpload && (props.recents || hasDetails || props.shareds);
  const dotMenuRef = useRef<any>();

  if (props.fileLoading) {
    return <Skeleton height={FILE_CELL_HEIGHT} />;
  }

  return (
    <Pressable
      style={styles.root}
      onPress={props.onPress}
      onFocus={() => props.focus(false)}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}>
      <View style={styles.thumbnail}>{props.thumbnail}</View>
      <View style={styles.menu} ref={dotMenuRef}>
        {hasContextMenu && (
          <IconButton
            title={t`Open context menu`}
            onPress={e => props.onDropDownMenu(e, dotMenuRef)}
            onHoverIn={() => setHovered(true)}
            buttonStyle={styles.menuButton}
            buttonHoverStyle={[styles.menuButton, {opacity: 0.9}]}
            icon={<MCIcon name="dots-horizontal" color="#ffffff" size={20} />}
          />
        )}
      </View>
      <View style={styles.footer}>
        <Text
          style={[styles.text, hasUpload && {color: theme.colors.neutral.$5}]}
          ellipsizeMode="middle"
          lineBreakMode="middle"
          numberOfLines={2}>
          {truncate(props.item.name, TEXT_CUTOFF)}
        </Text>
        {props.item.size && (
          <Text
            style={[
              styles.text,
              !hasUpload && styles.size,
              hasUploadFailure && styles.uploadTextFailed,
            ]}>
            {hasUpload
              ? hasUploadProgress
                ? `${bytesize(props.item.upload.bytes)} / ${bytesize(props.item.size)}`
                : uploadStatus.toString(props.item.upload.status)
              : bytesize(props.item.size)}
          </Text>
        )}
        {hasUpload && (
          <View style={styles.uploadProgressTrack}>
            <View
              style={[
                styles.uploadProgressBar,
                hasUploadRestart && styles.uploadProgressRestart,
                hasUploadFailure && styles.uploadProgressFailure,
                {
                  width: hasUploadFailure
                    ? '100%'
                    : `${Math.round((props.item.upload.bytes / props.item.size) * 100)}%`,
                },
              ]}
            />
          </View>
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    overflow: 'hidden',
    paddingTop: THUMBNAIL_PADDING_TOP,
  },
  thumbnail: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingBottom: THUMBNAIL_PADDING_BOTTOM,
  },
  footer: {
    padding: FOOTER_PADDING,
  },
  text: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: TEXT_HEIGHT,
  },
  size: {
    color: theme.colors.neutral.$4,
  },
  menu: {
    top: MENU_BUTTON_OFFSET,
    right: MENU_BUTTON_OFFSET,
    position: 'absolute',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  menuButton: {
    height: MENU_BUTTON_SIZE,
    width: MENU_BUTTON_SIZE,
    minWidth: MENU_BUTTON_SIZE,
    borderRadius: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  uploadTextFailed: {
    color: theme.colors.danger.$e2,
  },
  uploadProgressTrack: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 3,
    borderRadius: 9999,
    backgroundColor: theme.colors.neutral.$12,
  },
  uploadProgressBar: {
    height: 3,
    transition: 'width 660ms linear',
    borderRadius: 9999,
    backgroundColor: theme.colors.brand.$4Base,
  },
  uploadProgressFailure: {
    backgroundColor: theme.colors.danger.$4Base,
  },
  uploadProgressRestart: {
    backgroundColor: theme.colors.neutral.$8,
  },
});
