import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {DropdownButton} from 'interface/base/DropdownButton';
import {useLogout} from 'interface/base/hooks/useLogout';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import useLegalNavigation from '../hooks/useLegalNavigation';
import useNavigateToSelectOrg from '../hooks/useNavigateToSelectOrg';
import {AccountDropdownAvatar} from './AccountDropdownAvatar';

interface Props {
  close: () => void;
}

export function AccountDropdown(props: Props) {
  const userDetails = useSelector(user.selectors.getUserDetails);
  const profilePic = useSelector(user.selectors.getUserProfilePic);
  const userInitials = `${userDetails.first_name[0]}${userDetails.last_name[0]}`;

  const {
    navigateToTerms,
    navigateToPrivacy,
    navigateToContactUs,
    navigateToHelp,
  } = useLegalNavigation();

  const navigate = useNavigate();
  const navigateToSelectOrg = useNavigateToSelectOrg(props.close);

  const {logout} = useLogout();

  const onLogout = async () => {
    logout({redirectToLogout: true, callback: props.close});
  };

  const openProfilePage = () => {
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.profile}`);
    props.close();
  };
  const openAccountSettings = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType['account-settings']}`,
    );
    props.close();
  };

  return (
    <View style={styles.root} aria-live="polite">
      <View style={styles.userInfo}>
        <AccountDropdownAvatar
          id={userDetails.id}
          color={userDetails.color}
          initials={userInitials}
          photo={profilePic}
          openProfilePage={openProfilePage}
        />
        <View style={styles.nameEmailContainer}>
          <Text style={styles.name}>
            {userDetails.first_name} {userDetails.last_name}
          </Text>
          <Text style={styles.email}>{userDetails.email_address}</Text>
        </View>
      </View>
      <View style={styles.actions}>
        <DropdownButton
          iconName="account-circle-outline"
          label="Profile"
          onPress={openProfilePage}
          key="Profile"
        />
        <DropdownButton
          iconName="tune-vertical-variant"
          label="Account Settings"
          onPress={openAccountSettings}
          key="Account Settings"
        />
        <DropdownButton
          iconName="office-building-outline"
          label="View Orgs and Invites"
          onPress={navigateToSelectOrg}
          key="View Orgs and Invites"
        />
        <View style={styles.divider} />
        <DropdownButton
          role="link"
          iconName="file-sign"
          label="Terms of Service"
          onPress={navigateToTerms}
        />
        <DropdownButton
          role="link"
          iconName="book-lock-outline"
          label="Privacy Policy"
          onPress={navigateToPrivacy}
        />
        <DropdownButton
          role="link"
          iconName="help-circle-outline"
          label="Help"
          onPress={navigateToHelp}
        />
        <DropdownButton
          role="link"
          iconName="forum-outline"
          label="Contact Us"
          onPress={navigateToContactUs}
        />
      </View>
      <DropdownButton
        iconName="logout-variant"
        label="Log Out"
        onPress={onLogout}
        customStyle={{marginVertical: '0.5rem'}}
        key="Log Out"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 250,
    flexDirection: 'column',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: '1rem',
    paddingHorizontal: 30,
    gap: 10,
  },
  nameEmailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  email: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  actions: {
    borderBottomColor: theme.colors.neutral.$11,
    borderBottomWidth: 1,
    borderTopColor: theme.colors.neutral.$11,
    borderTopWidth: 1,
    paddingVertical: '0.5rem',
  },
  divider: {
    height: 1,
    width: '100%',
    marginVertical: 7,
    backgroundColor: theme.colors.neutral.$11,
  },
});
