import {ROUTES, STORAGE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {Navigate, useParams} from 'extensions/navigation';
import {Loading} from 'interface/base/Loading';
import {AppHeader} from 'interface/stacks/app/layout/AppHeader';
import React from 'react';
import {useSelector} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {EmptyWorkspaces} from '../base/EmptyWorkspaces';

export function RouteWorkspaceLoad() {
  const {workspaceId} = useParams<{workspaceId: string}>();

  const workspacesLoaded = useSelector(workspace.selectors.getWorkspacesLoaded);
  const workspacesLoading = useSelector(
    workspace.selectors.getWorkspacesLoading,
  );

  const userWorkspaces = useSelector(workspace.selectors.getUserWorkspaces);
  const allWorkspaces = useSelector(workspace.selectors.getAllWorkspacesInOrg);

  const workspaceToNavigate =
    userWorkspaces.find(w => w.folder_name === workspaceId) ||
    userWorkspaces.at(0);

  const currentOrFirstUserWorkspaceUrl = workspaceToNavigate
    ? `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspaceToNavigate.folder_name}/${WORKSPACE_STORAGE}/${STORAGE_ROOT}`
    : '/';

  const browseWorkspaceUrl = `/${ROUTES.LOGGED_IN_WITH_ORG.BROWSE_WORKSPACES}`;

  return (
    <>
      <AppHeader search />
      {workspacesLoading || !workspacesLoaded ? (
        <Loading centered />
      ) : userWorkspaces.length ? (
        <Navigate to={currentOrFirstUserWorkspaceUrl} replace />
      ) : allWorkspaces.length ? (
        <Navigate to={browseWorkspaceUrl} replace />
      ) : (
        <EmptyWorkspaces />
      )}
    </>
  );
}

export default RouteWorkspaceLoad;
