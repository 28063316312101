import {Trans} from '@lingui/macro';
import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import {PreviewSidePanelTab} from 'interface/stacks/workspace/storage/PreviewSidePanel';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import * as files from 'store/slices/files';

export function PreviewSidePanelHeader() {
  const hasComments = useFlag('storage-comments');
  const dispatch = useDispatch();
  const focusedType = useSelector(files.selectors.getFocusedType);

  const clearFocus = () => {
    dispatch(slices.files.actions.focus({id: ''}));
    dispatch(slices.files.actions.focusType({type: undefined}));
  };
  const setFocusedType = (type: PreviewSidePanelTab) => {
    dispatch(slices.files.actions.focusType({type}));
  };

  return (
    <View style={styles.root}>
      <View style={styles.tabs}>
        <Pressable onPress={() => setFocusedType(PreviewSidePanelTab.FileInfo)}>
          <Text
            style={[
              styles.tab,
              ![
                PreviewSidePanelTab.Activity,
                PreviewSidePanelTab.Comments,
                PreviewSidePanelTab.Versions,
              ].includes(focusedType) && styles.active,
            ]}>
            <Trans>File Info</Trans>
          </Text>
        </Pressable>
        {hasComments && (
          <Pressable
            onPress={() => setFocusedType(PreviewSidePanelTab.Comments)}>
            <Text
              style={[
                styles.tab,
                focusedType === PreviewSidePanelTab.Comments && styles.active,
              ]}>
              <Trans>Comments</Trans>
            </Text>
          </Pressable>
        )}
        <Pressable onPress={() => setFocusedType(PreviewSidePanelTab.Activity)}>
          <Text
            style={[
              styles.tab,
              focusedType === PreviewSidePanelTab.Activity && styles.active,
            ]}>
            <Trans>Activity</Trans>
          </Text>
        </Pressable>
        <Pressable onPress={() => setFocusedType(PreviewSidePanelTab.Versions)}>
          <Text
            style={[
              styles.tab,
              focusedType === PreviewSidePanelTab.Versions && styles.active,
            ]}>
            <Trans>Versions</Trans>
          </Text>
        </Pressable>
      </View>
      <Pressable onPress={clearFocus} style={styles.close}>
        <Icon
          name="arrow-collapse-right"
          size={20}
          color={theme.colors.neutral.$5}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    backgroundColor: theme.colors.neutral.$white,
    padding: 20,
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  close: {
    padding: 6,
  },
  tab: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: theme.colors.neutral.$4,
    paddingVertical: 8,
    paddingHorizontal: 10,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 3,
  },
  active: {
    color: theme.colors.neutral.$2Base,
    backgroundColor: theme.colors.neutral.$13,
  },
});
