import {colors} from 'config/styles';
import theme from 'config/theme';
import {Outlet} from 'extensions/navigation';
import {useLogout} from 'interface/base/hooks/useLogout';
import Footer from 'interface/stacks/app/Footer';
import UserDropdown from 'interface/stacks/content-viewer/components/top-toolbar/UserDropdown';
import LogoHeader from 'interface/stacks/onboard/base/LogoHeader';
import {
  Dimensions,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';

const windowWidth = Dimensions.get('screen').width;

type Props = {
  showCurrentUser?: boolean;
  showAccountDropdown?: boolean;
};

export function PageLayout({showCurrentUser, showAccountDropdown}: Props) {
  const {email_address} = useSelector(user.selectors.getUserDetails);
  const screen = useWindowDimensions();
  const {logout} = useLogout();
  const isSmallScreen = screen.width < 790;
  const lightColors = colors.mainLightColors;
  const classes = {
    root: [
      styles.root,
      isSmallScreen && styles.rootSmall,
      {backgroundColor: theme.colors.neutral.$white},
    ],
    text: [{color: lightColors.text}, styles.text],
    imageContainer: [
      styles.imageContainer,
      isSmallScreen && styles.imageContainerSmall,
    ],
    backgroundImage: [
      styles.backgroundImage,
      isSmallScreen && styles.backgroundImageSmall,
    ],
    outletContainer: [
      styles.outletContainer,
      isSmallScreen && styles.outletContainerSmall,
    ],
  };

  return (
    <View style={classes.root}>
      <View style={styles.header}>
        <LogoHeader />
        {showCurrentUser && (
          <View style={styles.accountPillContainer}>
            <View style={styles.accountPillItem}>
              <Text style={styles.accountPillText}>Signed in as </Text>
              <Text style={[styles.accountPillText, {fontWeight: '600'}]}>
                {email_address}
              </Text>
            </View>
            <View style={styles.accountPillDivider} />
            <Pressable
              style={styles.accountPillItem}
              onPress={() => logout({redirectToLogout: true})}>
              <Text style={styles.accountPillText}>Sign Out</Text>
            </Pressable>
          </View>
        )}
        {showAccountDropdown && <UserDropdown />}
      </View>
      <ScrollView contentContainerStyle={[classes.outletContainer]}>
        <Outlet />
        <Footer />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  rootSmall: {
    flexDirection: 'column',
  },
  imageContainer: {
    // TODO: This should change to absolute position
    // @ts-ignore
    position: 'fixed',
    height: '100%',
    width: '26.4%',
    minWidth: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 5,
  },
  imageContainerSmall: {
    height: 88,
    width: '100%',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: windowWidth > 790 ? 'space-between' : 'center',
    alignItems: 'flex-start',
    padding: 30,
    backgroundColor: 'rgba(0,0,255,0.8)',
  },
  backgroundImageSmall: {
    alignItems: 'center',
  },
  logo: {
    width: 120,
    height: 24,
    margin: 0,
    zIndex: 5,
  },
  outletContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minWidth: 'calc(100%-200px)',
    width: '100%',
    marginTop: 20,
    flexGrow: 1,
  },
  outletContainerSmall: {
    width: '100%',
    paddingLeft: 0,
  },
  textContainer: {
    width: '100%',
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  text: {
    fontWeight: '400',
    fontSize: 13,
    lineHeight: 21,
    marginLeft: 10,
    color: '#ffffff',
  },
  bottomContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bottomLinksContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    gap: 7,
  },
  simpleLink: {
    color: colors._primary.blue,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  clearCacheContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    padding: 15,
    borderRadius: 7,
    backgroundColor: theme.colors.neutral.$white,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: 15,
    zIndex: 10,
  },
  accountPillContainer: {
    height: 36,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: theme.colors.neutral.$9,
    flexDirection: 'row',
  },
  accountPillText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
  },
  accountPillDivider: {
    height: '100%',
    width: 1,
    backgroundColor: theme.colors.neutral.$9,
  },
  accountPillItem: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
