import OrgImage from 'assets/svgs/organizations/org_grapic.svg';
import ZoomImage from 'assets/svgs/organizations/zoom_icon.svg';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

type Props = {
  onChangeSessionEmail: () => void;
  hasOrganizations?: boolean;
};

export default function TryDifferentEmail({
  onChangeSessionEmail,
  hasOrganizations,
}: Props) {
  return (
    <View style={styles.root}>
      <View style={styles.imageContainer}>
        <Image
          source={hasOrganizations ? OrgImage : ZoomImage}
          alt="Organization"
          style={styles.image}
        />
      </View>
      <Text style={styles.text}>
        {hasOrganizations
          ? 'Not seeing your organization?'
          : 'Can’t find what you’re looking for?'}
      </Text>
      <View style={styles.buttonContainer}>
        <Button
          type="Secondary"
          label="Try a different email"
          onPress={onChangeSessionEmail}
          customRootStyle={styles.button}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: 20,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderStyle: 'solid',
    backgroundColor: theme.colors.neutral.$white,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  },
  imageContainer: {
    width: 64,
    height: 64,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderStyle: 'solid',
    backgroundColor: theme.colors.neutral.$14,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 40,
    height: 28,
  },
  buttonContainer: {
    flexGrow: 1,
    alignItems: 'flex-end',
    marginRight: 15,
  },
  button: {
    width: 156,
    height: 40,
  },
  text: {
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 24,
    color: theme.colors.neutral.$2Base,
  },
});
