import {useModal} from 'extensions/viewport/useModal';
import CircleButton from 'interface/base/CircleButton';
import {SharedCustomizeDialog} from 'interface/stacks/workspace/shared/dialogs/SharedCustomizeDialog';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import {ShareModal} from '../../components/ShareModal';

interface Props {
  share: Share;
}

export default function ShareHeaderActions({share}: Props) {
  const modal = useModal();

  const customizeShare = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={share.parent_workspace_folder}
        shareId={share.id}
        close={modal.close}
      />,
    );
  };

  const openShareDialog = () => {
    modal.open(
      <ShareModal
        workspaceFolderName={share.parent_workspace_folder}
        shareId={share.id}
        done={modal.close}
        displayTitle={false}
      />,
    );
  };

  return (
    <View style={styles.root}>
      <CircleButton
        onPress={openShareDialog}
        iconName="cog-outline"
        text="Settings"
      />
      <CircleButton
        onPress={customizeShare}
        iconName="tune-variant"
        text="Customize"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: 10,
    marginRight: 10,
  },
});
