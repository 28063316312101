import theme from 'config/theme';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import RowCard, {ROUNDED} from './RowCard';

type Props = {
  onPress: () => void;
};

export default function SharedWithMeCard({onPress}: Props) {
  const userDetails = useSelector(user.selectors.getUserDetails);

  return (
    <RowCard
      borderProps={ROUNDED}
      onPress={onPress}
      avatarColor={getHashColor(userDetails?.id)}
      avatarInitials={createInitials(
        `${userDetails?.first_name} ${userDetails?.last_name}`,
      )}
      avatarPhoto={userDetails?.profile_pic}
      main={<Text style={styles.text}>Shared with me</Text>}
      right={
        <Icon
          name="arrow-forward"
          size={24}
          color={theme.colors.brand.$4Base}
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    padding: 20,
    alignItems: 'center',
    gap: 15,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$11,
    borderStyle: 'solid',
    borderRadius: 14,
    backgroundColor: theme.colors.neutral.$14,
  },
  text: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    flexGrow: 1,
  },
});
