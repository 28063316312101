import {Provider} from 'interface/Provider';
import {Store} from 'interface/Store';
import {pdfjs} from 'react-pdf';
import {NavigatorHistory} from './base/NavigatorHistory';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default () => (
  <Store>
    <Provider>
      <NavigatorHistory />
    </Provider>
  </Store>
);
