import {ErrorFlowActions} from 'errors/types';
import {useModal} from 'extensions/viewport/useModal';
import {ErrorPage} from 'interface/stacks/app/ErrorPage';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router';
import * as app from 'store/slices/app';
import * as errors from 'store/slices/errors';
import {ErrorComponent} from './ErrorComponent';
import {useHandleNoActionErrors} from './hooks/useHandleNoActionErrors';
import {useShowToastErrors} from './hooks/useShowToastErrors';

export const ErrorWrapper = () => {
  const showError = useSelector(errors.selectors.getShowError);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const lastError = useSelector(errors.selectors.getLastError);

  const [renderErrorPage, setRenderErrorPage] = useState<boolean>(false);
  const modal = useModal();

  useShowToastErrors();
  useHandleNoActionErrors();

  useEffect(() => {
    if (showError) {
      if (
        isLoggedIn ||
        lastError?.errorConfiguration?.flowAction === ErrorFlowActions.Dialog
      ) {
        modal.open(<ErrorComponent showCloseButton />);
        setRenderErrorPage(false);
      } else {
        setRenderErrorPage(true);
      }
    } else {
      modal.close();
      setRenderErrorPage(false);
    }
  }, [showError]);

  if (renderErrorPage) return <ErrorPage />;

  return <Outlet />;
};
