import theme from 'config/theme';
import {Avatar} from 'interface/base/Avatar';
import {StyleSheet, Text, View} from 'react-native';

type Props = {
  photo: string;
  firstName: string;
  lastName: string;
  action: string;
};

const FileListItemAuthor = ({photo, firstName, lastName, action}: Props) => {
  const initials = `${firstName[0]}${lastName[0]}`;

  return (
    <View style={styles.root}>
      <Avatar color={'#6524FF'} photo={photo} initials={initials} />
      <View>
        <Text style={styles.title}>{`${firstName} ${lastName}`}</Text>
        <Text style={styles.subtitle}>{action}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    width: 180,
  },
  title: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    color: theme.colors.neutral.$2Base,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    color: theme.colors.neutral.$4,
  },
});

export default FileListItemAuthor;
