import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import useHost from 'interface/base/hooks/useHost';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {AppLoading} from '../app/AppLoading';
import useSubdomainAuth from './useSubdomainAuth';

export function ToSubdomain() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const userToken = useSelector(user.selectors.getToken);
  const userDetails = useSelector(user.selectors.getUserDetails);

  const {sendToken} = useSubdomainAuth();
  const {protocol, domain} = useHost();
  const {subdomain} = useSubDomain();

  const [subdomainParam, setSubdomainParam] = useState<string>();
  const [userHasAccess, setUserHasAccess] = useState<boolean>();
  const [orgDetails, setOrgDetails] = useState<OrganizationDetails>();

  const checkOrgSubscription = async (orgDomain: string) => {
    const isOwner = `${orgDetails.parent}` === userDetails.id;

    if (!isOwner) {
      return undefined;
    }

    const {result, billing_status} =
      await api.billing.getSubscriptionDetails(orgDomain);

    if (result && !billing_status.active) {
      return `/${ROUTES.LOGGED_IN_WITH_ORG.RENEW_SUBSCRIPTION}?orgDomain=${orgDomain}`;
    }
  };

  const redirectToCorrectPage = async () => {
    const token = userToken;
    const encodedToken = encodeURIComponent(token);
    let redirectPath = '';
    redirectPath = await checkOrgSubscription(subdomainParam);
    if (redirectPath) navigate(redirectPath, {replace: true});
    else {
      if (subdomain) {
        if (subdomain === subdomainParam) {
          redirectPath = `${protocol}://${domain}/from-subdomain`;
        } else {
          const path = `${protocol}://${domain}/from-subdomain`;
          redirectPath = path.replace(subdomain, subdomainParam);
        }
      } else {
        redirectPath = `${protocol}://${subdomainParam}.${domain}/from-subdomain`;
      }
      sendToken(encodedToken, redirectPath);
    }
  };

  useEffect(() => {
    if (searchParams) {
      const subdomain = searchParams.get('orgDomain');
      setSubdomainParam(subdomain);
    }
  }, [searchParams]);

  const checkUserAccess = async (subdomain: string) => {
    try {
      const {result, org} =
        await api.organization.getOrganizationDetails(subdomain);
      if (result) {
        setOrgDetails(org);
        setUserHasAccess(true);
      } else {
        setUserHasAccess(false);
      }
    } catch (error) {
      setUserHasAccess(false);
    }
  };

  useEffect(() => {
    if (subdomainParam) {
      checkUserAccess(subdomainParam);
    }
  }, [subdomainParam]);

  useEffect(() => {
    if (userHasAccess) {
      redirectToCorrectPage();
    } else if (userHasAccess === false) {
      navigate('/request-access?type=Organization');
    }
  }, [userHasAccess]);

  return <AppLoading />;
}
