import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import CheckoutSuccess from '../CheckoutSuccess';
import OnboardError from '../OnboardError';
import Processing from '../Processing';
import usePayment from '../hooks/usePayment';
import useUpdateUser from '../hooks/useUpdateUser';
import {OnboardSteps} from '../lib/consts';
import type {BillingPlan} from '../lib/types';
import {AddCard} from './AddCard';

interface Props {
  plan: BillingPlan;
  tax: number;
  isSubscribed: boolean;
  subdomain: string;
  orgId: string;
}

export default function PaymentNew({
  plan,
  tax,
  isSubscribed,
  subdomain,
  orgId,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const {loading: paymentLoading, handlePayment} = usePayment();

  const {isUserUpdated} = useUpdateUser(isSubscribed);

  if (isSubscribed && isUserUpdated) {
    return (
      <CheckoutSuccess
        next={() =>
          navigate(
            `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Invites}`,
          )
        }
      />
    );
  }

  const isLoading = loading || paymentLoading;

  return (
    <View style={styles.root}>
      {(isLoading || (isSubscribed && !isUserUpdated)) && <Processing />}
      {!isLoading && showError && (
        <OnboardError onRetry={() => setShowError(false)} />
      )}
      {/* Hidden because if this piece is unmounted the stripe process wouldn't finish */}
      <View style={isLoading || showError ? styles.hidden : undefined}>
        <AddCard
          plan={plan}
          tax={tax}
          handlePayment={handlePayment}
          onError={() => setShowError(true)}
          subdomain={subdomain}
          setPaymentLoading={setLoading}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  hidden: {
    display: 'none',
  },
});
