import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {Loading} from 'interface/base/Loading';
import useBillingDetails from 'interface/base/hooks/useBillingDetails';
import useOrgDetails from 'interface/base/hooks/useOrgDetails';
import useLoadStripe from 'interface/stacks/onboard/hooks/useLoadStripe';
import {PLANS_INFO} from 'interface/stacks/onboard/lib/consts';
import {calcAmount} from 'interface/stacks/onboard/lib/utils';
import {useEffect} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useNavigate, useOutletContext} from 'react-router';
import {BilledDurationType} from 'store/slices/settings/types';
import {RenewCheckout} from '../components/RenewCheckout';
import {SubscriptionBackButtonState} from '../layout/SubscriptionLayout';

export const RenewSubscription = () => {
  const {isOwner, details, loading: loadingOrgDetails} = useOrgDetails();
  const {billingStatus, loading: loadingBillingDetails} = useBillingDetails(
    details?.domain,
    {disable: loadingOrgDetails},
  );
  const {stripe, loading: loadingStripe} = useLoadStripe({
    key: billingStatus?.public_key,
    loading: !billingStatus?.public_key,
  });

  const navigate = useNavigate();

  const totalPlanCost = calcAmount(
    PLANS_INFO.Business.price,
    BilledDurationType.Monthly,
  );
  const tax = Math.ceil(totalPlanCost * 0.08);
  const totalAmount = totalPlanCost + tax;

  const [, setStatus] =
    useOutletContext<
      [
        SubscriptionBackButtonState,
        (status: SubscriptionBackButtonState) => void,
      ]
    >();

  useEffect(() => {
    setStatus(SubscriptionBackButtonState.Remove);
  }, []);

  useEffect(() => {
    if (!loadingBillingDetails && billingStatus?.active) {
      navigate(
        `/${ROUTES.LOGGED_IN_WITHOUT_ORG.TO_SUBDOMAIN}?orgDomain=${details.domain}`,
      );
    }
  }, [billingStatus?.active, loadingBillingDetails]);

  const loading = loadingOrgDetails || loadingStripe || loadingBillingDetails;

  useEffect(() => {
    const setupIntent = billingStatus?.setup_intent;
    if (
      !loading &&
      (!setupIntent ||
        (Array.isArray(setupIntent) && setupIntent.length === 0) ||
        !billingStatus?.public_key ||
        !stripe)
    ) {
      handleAppError({
        appError: AppErrors.StripeKeyNotFoundError,
        dialogOptions: {
          message: 'Payment intent or public key not found',
          title: 'proceeding to checkout',
        },
      });
    }
  }, [loading, billingStatus?.setup_intent, billingStatus?.public_key, stripe]);

  if (billingStatus?.active !== null && billingStatus?.active) {
    return null;
  }

  return (
    <View style={styles.root}>
      {loading && <Loading />}
      {!loading && isOwner && billingStatus?.setup_intent?.client_secret && (
        <RenewCheckout
          clientSecret={billingStatus?.setup_intent?.client_secret}
          totalAmount={totalAmount}
          totalPlanCost={totalPlanCost}
          tax={tax}
          orgId={details?.id}
          orgDomain={details?.domain}
          stripe={stripe}
        />
      )}
      {!loading && !isOwner && (
        <View style={[styles.warningBox, {marginBottom: 0}]}>
          <Icon
            name="warning"
            size={24}
            style={styles.warningIcon}
            color={theme.colors.neutral.$2Base}
          />
          <Text style={styles.warningText}>
            <Trans>
              This organization does not currently have an active subscription.
              Contact an organization administrator for assistance.
            </Trans>
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: 960,
    padding: 50,
    backgroundColor: theme.colors.neutral.$white,
  },
  warningBox: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    backgroundColor: theme.colors.caution.$4Base,
    borderRadius: 4,
    marginBottom: '2rem',
  },
  warningIcon: {},
  warningText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  title: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    marginBottom: '1.5rem',
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
  },
  content: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    marginBottom: '2.5rem',
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
  },
});
