import {createSelector} from '@reduxjs/toolkit';
import {WorkspaceUserStatus} from 'fast-sdk/src/api/workspace/consts';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getIsListLoading = createSelector(
  x,
  store => store.shared.isListLoading,
);

export const getSingleSharedItem = (
  workspaceFolderName: string,
  sharedItemId: string,
) => createSelector(x, store => store.shared.shares?.[sharedItemId]);

export const getWorkspaceShares = (
  workspaceFolderName: string,
  showArchivedOnly?: boolean,
) =>
  createSelector(x, store =>
    (store.shared.workspaces[workspaceFolderName] ?? [])
      .map(shareId => store.shared.shares[shareId])
      .filter(
        share =>
          share.share_link?.id &&
          (showArchivedOnly ? share.archived : !share.archived),
      )
      .reduce((acc, share) => {
        acc[share.id] = share;
        return acc;
      }, {}),
  );

export const getPendingWorkspaceShares = (workspaceFolderName: string) =>
  createSelector(x, store =>
    (store.shared.workspaces[workspaceFolderName] ?? [])
      .map(shareId => store.shared.shares[shareId])
      .filter(
        share =>
          share.share_link?.id &&
          share.user_status === WorkspaceUserStatus.Available &&
          !share.archived,
      )
      .reduce((acc, share) => {
        acc[share.id] = share;
        return acc;
      }, {}),
  );

export const getSort = createSelector(x, store => store.shared.sort);

export const getShareSort = (shareId: string) =>
  createSelector(x, store => store.shared.shares[shareId]?.sort);

export const getSharedByCustomName = (customName: string) =>
  createSelector(x, store => store.shared.sharedsByCustomName?.[customName]);

export const getSharesLoaded = createSelector(
  x,
  store => store.shared.sharesLoaded,
);
