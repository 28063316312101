import type {GestureEvent} from '@vidstack/react';
import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import FullScreenButton from 'interface/stacks/workspace/storage/thumbnail/PreviewFullScreenButton';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
  Animated,
  Image,
  type LayoutChangeEvent,
  StyleSheet,
  View,
} from 'react-native';
import Pagination from '../../components/Pagination';
import useExpandImage from '../../hooks/useExpandImage';
import useFileUrl from '../../hooks/useFileUrl';
import useFullscreen from '../../hooks/useFullscreen';
import useZoom, {ZOOM_VALUES} from '../../hooks/useZoom';
import type {ViewerProps as Props} from '../../types';
import ControlBar, {HEIGHT_CONTROL_BAR} from '../ControlBar';
import {HEIGHT_TOP_TOOLBAR} from '../TopToolbar';
import ControlsMiddle from './controls/ControlsMiddle';
import ControlsRight from './controls/ControlsRight';

type Position = {x: number; y: number};
type Dimension = {width: number; height: number};

const SPEED_FACTOR_MAP = {
  [ZOOM_VALUES['12.5%']]: 6,
  [ZOOM_VALUES['25%']]: 3,
  [ZOOM_VALUES['50%']]: 1.5,
  [ZOOM_VALUES['100%']]: 0.7,
  [ZOOM_VALUES['200%']]: 0.5,
  [ZOOM_VALUES['300%']]: 0.3,
  [ZOOM_VALUES['400%']]: 0.2,
};

const initialPosition = {x: 0, y: 0};
const initialDimension = {width: 0, height: 0};

const ImageViewer = ({file, isThumbnail}: Props) => {
  const [position, setPosition] = useState<Position>(initialPosition);
  const [transformOrigin, setTransformOrigin] =
    useState<string>('center center');
  const imageDimensionsRef = useRef<Dimension | null>(initialDimension);
  const containerDimensionsRef = useRef<Dimension>(initialDimension);
  const prevPositionRef = useRef<Position>(initialPosition);
  const isDraggingRef = useRef(false);
  const {url, loading} = useFileUrl(file);
  const {isFullscreen, ref, toggleFullscreen} = useFullscreen();
  const {handleZoomIn, handleZoomOut, handleZoom, handleLastZoom, zoom} =
    useZoom();
  const {handleExpandHorizontal, handleExpandVertical} = useExpandImage({
    callback: () => {
      setTransformOrigin(getTransformOrigin());
    },
    handleZoom,
    imageWidth: imageDimensionsRef.current.width,
    imageHeight: imageDimensionsRef.current.height,
    containerWidth: containerDimensionsRef.current.width,
    containerHeight: containerDimensionsRef.current.height,
  });

  const [hasImageLoaded, setHasImageLoaded] = useState(false);
  const animatedScale = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    setHasImageLoaded(false);
  }, []);

  useEffect(() => {
    Image.getSize(url, (width, height) => {
      imageDimensionsRef.current = {width, height};
    });
  }, [url]);

  useEffect(() => {
    Animated.timing(animatedScale, {
      toValue: zoom,
      duration: 200,
      useNativeDriver: true,
    }).start();

    isDraggingRef.current = false;
    prevPositionRef.current = initialPosition;
    setPosition(initialPosition);
  }, [zoom]);

  const onViewLayout = (event: LayoutChangeEvent) => {
    const {width, height} = event.nativeEvent.layout;
    containerDimensionsRef.current = {width, height};
  };

  const handleMouseDown = (event: GestureEvent) => {
    if (zoom <= 1) {
      return;
    }

    isDraggingRef.current = true;
    // @ts-ignore
    prevPositionRef.current = {x: event.clientX, y: event.clientY};
  };

  const handleMouseMove = (event: GestureEvent) => {
    if (!isDraggingRef.current || zoom <= 1) {
      return;
    }

    // @ts-ignore
    const deltaX = event.clientX - prevPositionRef.current.x;
    // @ts-ignore
    const deltaY = event.clientY - prevPositionRef.current.y;
    // @ts-ignore
    prevPositionRef.current = {x: event.clientX, y: event.clientY};
    setPosition(prev => {
      const newX = prev.x + deltaX * SPEED_FACTOR_MAP[zoom];
      const newY = prev.y + deltaY * SPEED_FACTOR_MAP[zoom];

      const isOutOfBoundsX =
        Math.abs(newX) < 0 ||
        Math.abs(newX) > Math.abs(containerDimensionsRef.current.width / 2);

      const isOutOfBoundsY =
        newY < -containerDimensionsRef.current.height / 2 ||
        newY > containerDimensionsRef.current.height / 2;

      return {
        x: isOutOfBoundsX ? prev.x : newX,
        y: isOutOfBoundsY ? prev.y : newY,
      };
    });
  };

  const handleMouseUp = () => {
    if (zoom <= 1) {
      return;
    }

    isDraggingRef.current = false;
  };

  const handleMouseOut = () => {
    if (zoom <= 1) {
      return;
    }

    isDraggingRef.current = false;
  };

  const getTransformOrigin = () => {
    const isVertical =
      imageDimensionsRef.current.width < imageDimensionsRef.current.height;

    if (zoom > 1 || isVertical) {
      return 'top center';
    }

    return 'center center';
  };

  const thumbnail = useMemo(
    () => (
      <FileThumbnail
        item={file}
        size={FileThumbnailSizes.Preview}
        options={{
          previewResizeMode: 'contain',
          imageResizeMode: 'contain',
          imageZoom: zoom,
        }}
      />
    ),
    [file, zoom],
  );

  return (
    <View style={[styles.root, isThumbnail && {width: '100%'}]}>
      <View style={styles.content}>
        <View
          ref={ref}
          style={[
            styles.imageWrapper,
            isFullscreen && styles.fullscreen,
            isThumbnail && {height: '100%'},
          ]}>
          {loading ? (
            thumbnail
          ) : (
            <>
              {!hasImageLoaded && thumbnail}
              <View
                onLayout={onViewLayout}
                style={[
                  styles.imageContainer,
                  // @ts-ignore
                  {
                    overflow: 'auto',
                  },
                  isThumbnail && {height: '100%'},
                ]}>
                <Animated.Image
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseOut={handleMouseOut}
                  style={[
                    styles.image,
                    {
                      opacity: hasImageLoaded ? 1 : 0,
                      // @ts-ignore
                      transformOrigin,
                      transform: [
                        {scale: animatedScale},
                        {translateX: position.x},
                        {translateY: position.y},
                      ],
                    },
                  ]}
                  source={{
                    uri: url,
                  }}
                  onLoadEnd={() => setHasImageLoaded(true)}
                  resizeMode="contain"
                />
              </View>
            </>
          )}
        </View>
        {!isThumbnail && <Pagination />}
        {isThumbnail ? (
          <View>
            <FullScreenButton handleOnFullScreen={toggleFullscreen} />
          </View>
        ) : (
          <ControlBar
            left={<View />}
            middle={
              <ControlsMiddle
                zoom={zoom}
                handleZoomIn={handleZoomIn}
                handleZoomOut={handleZoomOut}
                handleLastZoom={handleLastZoom}
              />
            }
            right={
              <ControlsRight
                handleExpandHorizontal={handleExpandHorizontal}
                handleExpandVertical={handleExpandVertical}
                isFullscreen={isFullscreen}
                toggleFullscreen={toggleFullscreen}
              />
            }
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#000',
  },
  content: {
    height: '100%',
    flexGrow: 1,
    flexShrink: 1,
  },
  imageWrapper: {
    position: 'relative',
    // Total viewport height - header height - control bar height
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px - ${HEIGHT_CONTROL_BAR}px)`,
    backgroundColor: '#000',
  },
  imageContainer: {
    position: 'relative',
    height: '100%',
  },
  pagesContainer: {
    flex: 1,
    maxWidth: 180,
    padding: 15,
    paddingTop: 0,
    backgroundColor: theme.colors.neutral.$1,
  },
  fullscreen: {
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px)`,
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
});

export default ImageViewer;
