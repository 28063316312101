import Bugsnag, {type NotifiableError, type OnErrorCallback} from '@bugsnag/js';
import type {AnalyticsBase} from 'extensions/analytics/base';
import {getStage, getVersion} from 'utils/common/version';

const loaded = {
  bugsnag: false,
};

class Analytics implements AnalyticsBase {
  start() {
    // Bugsnag
    try {
      Bugsnag.start({
        apiKey: 'c2574495ca68f8c71fffd6bb9363cd18',
        appVersion: getVersion(true),
        releaseStage: getStage(),
        enabledBreadcrumbTypes: [
          'log',
          'user',
          'state',
          'error',
          'manual',
          'process',
          'request',
        ],
        maxBreadcrumbs: 50,
      });
      loaded.bugsnag = true;
    } catch (e) {}
  }

  log(value: string) {
    loaded.bugsnag && Bugsnag.leaveBreadcrumb(value);
  }

  notify(error: NotifiableError, onError?: OnErrorCallback) {
    if (!loaded.bugsnag) return;
    Bugsnag.notify(error, onError);
  }
}

export default new Analytics();
