import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useMultiplayerConnection} from './useMultiplayerConnection';
import {useMultiplayerCursors} from './useMultiplayerCursors';
import {useMultiplayerFollow} from './useMultiplayerFollow';
import {useMultiplayerMembers} from './useMultiplayerMembers';
import {useMultiplayerSync} from './useMultiplayerSync';

import * as files from 'store/slices/files';

import type {
  MultiplayerMember,
  MultiplayerProfile,
  MultiplayerResource,
  MultiplayerState,
} from '../types';

export function useMultiplayer(
  profile: MultiplayerProfile,
  resource: MultiplayerResource,
): MultiplayerState {
  const connection = useMultiplayerConnection(profile);
  const members = useMultiplayerMembers(connection, true);
  const cursors = useMultiplayerCursors(members, resource.id);
  const followId = useSelector(files.selectors.getFollow);
  const following = members?.find(member => member.userId === followId);
  const localState =
    connection?.provider?.awareness?.getLocalState() as MultiplayerMember;

  useMultiplayerFollow(connection, following);
  useMultiplayerSync(connection, resource.id, resource.type);

  return useMemo(
    () => ({
      members,
      cursors,
      following,
      localState,
      setState: connection.setState,
    }),
    [members, cursors, following, localState, connection.setState],
  );
}
