import {api} from 'fast-sdk';
import type {MemberPermission} from 'fast-sdk/src/api/workspace/consts';
import {useCallback, useState} from 'react';

type InvitationResponse = {
  successEmails: Array<string>;
  failedEmails: Array<string>;
};

const useOrgInvitation = (subdomain: string, orgName: string) => {
  const [loading, setLoading] = useState(false);

  const sendInvitations = useCallback(
    async (
      emails: Array<string>,
      permission: MemberPermission = 'member',
    ): Promise<InvitationResponse> => {
      setLoading(true);
      const successEmails = [];
      const failedEmails = [];
      for (const email of emails) {
        const response = await api.organization.addMember(
          subdomain,
          email,
          permission,
          `You've been invited to the ${orgName} organization`,
        );

        if (response.result) {
          successEmails.push(email);
        } else {
          failedEmails.push(email);
        }
      }

      setLoading(false);

      return {successEmails, failedEmails};
    },
    [subdomain, orgName],
  );

  return {
    sendInvitations,
    loading,
  };
};

export default useOrgInvitation;
