import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import {StyleSheet, Text, View} from 'react-native';
import {useDispatch} from 'react-redux';
import * as app from 'store/slices/app';
import {getVersion} from 'utils/common/version';
import StatusButton from '../StatusButton';
import useShowUpdateNotice from '../hooks/useShowUpdateNotice';
import AppNewVersionNotice from './AppNewVersionNotice';

interface AppSideNavFooterProps {
  showAppStatus?: boolean;
}

export function AppSideNavFooter({showAppStatus}: AppSideNavFooterProps) {
  const dispatch = useDispatch();

  const {showUpdateAlert} = useShowUpdateNotice();

  const onResetSession = () => {
    dispatch(app.default.actions.setForceLogout(true));
  };

  const popupContent = (
    <View style={styles.clearCacheContainer}>
      <Text>
        <Trans>Advanced Settings</Trans>
      </Text>
      <Button
        type="Primary"
        label={t`Reset Fastio Session`}
        onPress={onResetSession}
      />
    </View>
  );

  return (
    <View style={styles.footer}>
      {showUpdateAlert && (
        <View style={styles.footerNewVersion}>
          <AppNewVersionNotice />
        </View>
      )}
      <View style={styles.footerContent}>
        <View style={styles.footerButtons}>
          <Popup
            placement="top-start"
            triggerElement={
              <Text style={styles.footerTagline}>{'©2023 Fast.io'}</Text>
            }>
            {popupContent}
          </Popup>
          {showAppStatus && <StatusButton />}
        </View>
        <Popup
          placement="top-start"
          triggerElement={
            <Text style={styles.footerTagline}>{`v${getVersion()}`}</Text>
          }>
          {popupContent}
        </Popup>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.$1,
  },
  footerNewVersion: {
    padding: 10,
  },
  footerContent: {
    flexDirection: 'column',
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$25,
  },
  footerTagline: {
    lineHeight: 18,
    fontSize: 11,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$6,
  },
  footerButtons: {
    flexDirection: 'row',
    gap: 5,
  },
  clearCacheContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    padding: 15,
    borderRadius: 7,
    backgroundColor: theme.colors.neutral.$white,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
});
