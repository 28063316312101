import {t} from '@lingui/macro';
import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import {format} from 'date-fns';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {IconButton} from 'interface/base/IconButton';
import Skeleton from 'interface/base/Skeleton';
import {AppMemberAvatar} from 'interface/stacks/app/AppMemberAvatar';
import * as uploadStatus from 'interface/stacks/uploads/utils/status';
import type {FileListItemProps} from 'interface/stacks/workspace/storage/FileListItem';
import {PreviewSidePanelTab} from 'interface/stacks/workspace/storage/PreviewSidePanel';
import {MainDialog} from 'interface/stacks/workspace/storage/quick-share/MainDialog';
import {
  type MouseEvent,
  type ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import type {GestureResponderEvent} from 'react-native';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch} from 'react-redux';
import * as files from 'store/slices/files';
import type {FilesComment} from 'store/slices/files/types';
import {bytesize} from 'utils/common/data';
import FileListItemActions from './FileListItemActions';
import FileListItemAuthor from './FileListItemAuthor';
import {FileParentFolder} from './FileParentFolder';

import {useModal} from 'extensions/viewport/useModal';

export interface FileListItemRowProps extends FileListItemProps {
  menuAnchor?: any;
  renderMembers?: () => ReactNode;
  thumbnail?: ReactNode;
  recents?: boolean;
  shareds?: boolean;
  onDropDownMenu?: (
    e: MouseEvent | GestureResponderEvent,
    ref?: React.RefObject<any>,
  ) => void;
  onTitlePress?: (e: GestureResponderEvent) => void;
  onSelectThumbnail?: (e: MouseEvent | GestureResponderEvent) => void;
  focus?: (openDetails?: boolean) => void;
  rename?: () => void;
  onPress?: () => void;
  onLongPress: (e: any) => void;
  downloadItem: () => void;
  previewShare: () => void;
  customizeShare: () => void;
  openShare: () => void;
  fileLoading: boolean;
}

export function FileListItemRow(props: FileListItemRowProps) {
  const [hovered, setHovered] = useState<boolean>(false);
  const classes = {
    root: [
      styles.root,
      props.selected && {borderColor: 'rgba(0, 0, 0, 0)'},
      props.recents && {paddingVertical: 4},
    ],
  };

  const recents = props.recents;
  const shareds = props.shareds;
  const shareView = props.shareView;
  const uploader = props.members?.find(
    member => member.userId === props.item.upload?.member,
  );
  const hasUpload = Boolean(props.item.upload);
  const hasUploadProgress =
    hasUpload && uploadStatus.isTransferring(props.item.upload.status);
  const hasUploadFailure =
    hasUpload && uploadStatus.isFailure(props.item.upload.status);
  const hasUploadRestart =
    hasUpload && uploadStatus.isRestartable(props.item.upload.status);

  const hasComments = useFlag('storage-comments');
  const hasDetails = !props.disableDetails;
  const hasActions = !props.disableActions;
  const hasSize = props.item.type === StorageItemType.File;
  const showMenu = recents || hasDetails || shareds;
  const dispatch = useDispatch();
  const modal = useModal();
  const dotMenuRef = useRef<any>(null);

  const openActivity = () => {
    dispatch(files.default.actions.focus({id: props.item.id}));
    dispatch(
      files.default.actions.focusType({type: PreviewSidePanelTab.Activity}),
    );
  };

  const unseenComments = (comments: Array<FilesComment>) => {
    let unseenCount = 0;
    for (const comment of comments) {
      if (comment.seen === false) unseenCount++;
      unseenCount = unseenCount + unseenComments(comment.reply);
    }

    return unseenCount;
  };

  const openQuickShare = useCallback(
    (e: MouseEvent | GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      modal.open(<MainDialog file={props.item} />);
    },
    [props.item],
  );

  const expires = props.item?.expires ?? props.share?.expires;

  const expiresAt = expires
    ? `Expires ${format(new Date(expires), 'PP')}`
    : undefined;

  const selectedColorStyle = {
    color:
      props.selected || hovered
        ? theme.colors.neutral.$2Base
        : theme.colors.neutral.$5,
  };

  if (props.fileLoading) {
    return <Skeleton height={36} />;
  }

  return (
    <Pressable
      style={[
        classes.root,
        {
          backgroundColor:
            hovered && !props.selected && theme.colors.neutral.$15,
        },
      ]}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onFocus={() => props.focus(false)}
      onPress={props.onPress}
      onLongPress={props.onLongPress}>
      <View style={styles.thumbnail}>
        <Pressable
          onPress={props?.onSelectThumbnail}
          onHoverIn={() => setHovered(true)}>
          {props.thumbnail}
        </Pressable>
      </View>
      <View style={styles.name}>
        <Text
          style={[styles.title, hasUpload && {color: theme.colors.neutral.$5}]}
          accessibilityLabel={props.item.name}
          onPress={props.onTitlePress}
          ellipsizeMode="middle"
          lineBreakMode="middle"
          numberOfLines={1}>
          <span className="text-link">
            {props.item ? props.item.name : 'Unknown'}
          </span>
        </Text>
        {props.renderMembers()}
      </View>
      {recents && (
        <FileListItemAuthor
          photo={''}
          firstName={'Bill'}
          lastName={'Lumbergh'}
          action={'Updated metadata'}
        />
      )}
      {recents && (
        <Pressable pointerEvents="none" style={[styles.column, {width: 90}]}>
          <Text
            style={[
              styles.text,
              {
                color:
                  props.selected || hovered
                    ? theme.colors.neutral.$2Base
                    : theme.colors.neutral.$5,
              },
            ]}>
            11:15AM
          </Text>
        </Pressable>
      )}
      {false && hasUpload && (
        <View style={[styles.column, {width: 122}]}>
          <AppMemberAvatar member={uploader} />
        </View>
      )}
      {hasComments && props.item.comments?.length > 0 && (
        <Pressable
          style={styles.iconContainer}
          onPress={openActivity}
          onHoverIn={() => setHovered(true)}>
          {unseenComments(props.item.comments) > 0 && (
            <View style={styles.unseenComments}>
              <Text style={styles.unseenCommentsText}>
                {unseenComments(props.item.comments) > 99
                  ? '99+'
                  : unseenComments(props.item.comments)}
              </Text>
            </View>
          )}
          <MCIcon
            name="comment-outline"
            size={20}
            color={
              props.selected
                ? theme.colors.neutral.$2Base
                : theme.colors.neutral.$6
            }
          />
        </Pressable>
      )}
      {expiresAt && (
        <Pressable pointerEvents="none" style={[styles.column, {width: 140}]}>
          <Text style={[styles.text, selectedColorStyle]}>{expiresAt}</Text>
        </Pressable>
      )}
      {shareView && false && (
        <FileListItemAuthor
          photo={''}
          firstName={'Michael'}
          lastName={'Scott'}
          action={'Shared'}
        />
      )}
      {hasSize ? (
        <Pressable
          style={[
            styles.column,
            styles.size,
            recents && {width: 70},
            hasUploadProgress && {width: 140},
          ]}
          onHoverIn={() => setHovered(true)}>
          <Text
            style={[
              styles.text,
              {
                color:
                  props.selected || hovered || hasUpload
                    ? theme.colors.neutral.$2Base
                    : theme.colors.neutral.$5,
              },
            ]}>
            {hasUploadProgress
              ? `${bytesize(props.item.upload.bytes)} / ${bytesize(props.item.size)}`
              : bytesize(props.item.size)}
          </Text>
        </Pressable>
      ) : (
        <View style={{width: 90}} />
      )}
      {(recents || shareds) && !props.archivedShares && (
        <FileParentFolder item={props.item} />
      )}
      {!props.disableActions &&
      !hasUpload &&
      !props.share?.archived &&
      !props.item.deleted ? (
        <FileListItemActions
          show={hovered}
          rename={props.rename}
          details={() => props.focus(true)}
          download={props.downloadItem}
          openQuickShare={openQuickShare}
          openActivity={openActivity}
          previewShare={props.previewShare}
          customizeShare={props.customizeShare}
          openShare={props.openShare}
          tooltipPos={props.tooltipPos}
          file={props.item}
        />
      ) : (
        !shareView && <View style={{width: 150}} />
      )}
      {showMenu && !hasUpload && (
        <View style={styles.action}>
          <IconButton
            reference={dotMenuRef}
            title={t`Open context menu`}
            onPress={e => props.onDropDownMenu(e, dotMenuRef)}
            buttonStyle={styles.actionButton}
            icon={
              <MCIcon
                name="dots-horizontal"
                size={20}
                color={
                  props.selected
                    ? theme.colors.neutral.$2Base
                    : theme.colors.neutral.$5
                }
              />
            }
          />
        </View>
      )}
      {hasUpload && (
        <View style={styles.uploadProgressTrack}>
          <View
            style={[
              styles.uploadProgressBar,
              hasUploadRestart && styles.uploadProgressRestart,
              hasUploadFailure && styles.uploadProgressFailure,
              {
                width: hasUploadFailure
                  ? '100%'
                  : `${Math.round((props.item.upload.bytes / props.item.size) * 100)}%`,
              },
            ]}
          />
        </View>
      )}
      {hasUpload && (
        <View style={styles.action}>
          <Text
            style={[
              styles.text,
              styles.uploadText,
              hasUploadFailure && styles.uploadTextFailed,
            ]}>
            {uploadStatus.toString(props.item.upload.status)}
          </Text>
          {/* <Icon name="refresh" size={20} color={theme.colors.neutral.$6} /> */}
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  thumbnail: {
    width: 56,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  name: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 13,
    marginRight: 8,
    marginBottom: 1,
  },
  action: {
    flex: 0,
    minWidth: 70,
    gap: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    width: 40,
    minWidth: 40,
  },
  column: {
    width: 90,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  text: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  iconText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 24,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginRight: 22,
  },
  unseenComments: {
    position: 'absolute',
    height: 16,
    minWidth: 16,
    backgroundColor: theme.colors.brand.$4Base,
    paddingHorizontal: 4,
    paddingVertical: 0,
    borderRadius: 50,
    top: 2,
    right: -7,
    zIndex: 1,
  },
  unseenCommentsText: {
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 16,
    color: theme.colors.neutral.$white,
  },
  folder: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    width: 200,
    paddingRight: 20,
  },
  folderText: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  uploadText: {
    textAlign: 'center',
  },
  uploadTextFailed: {
    color: theme.colors.danger.$e2,
  },
  uploadMember: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  uploadProgressTrack: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 3,
    backgroundColor: theme.colors.neutral.$12,
  },
  uploadProgressBar: {
    height: 3,
    transition: 'width 660ms linear',
    backgroundColor: theme.colors.brand.$4Base,
  },
  uploadProgressFailure: {
    backgroundColor: theme.colors.danger.$4Base,
  },
  uploadProgressRestart: {
    backgroundColor: theme.colors.neutral.$8,
  },
  size: {
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
});
