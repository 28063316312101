import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import type {
  Member,
  OrganizationDetails,
} from 'fast-sdk/src/api/organization/consts';
import {
  type InvitationDetails,
  InvitationStatuses,
  InvitationTypes,
} from 'fast-sdk/src/api/share/consts';
import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import {Loading} from 'interface/base/Loading';
import useHasExternalWorkspacesShares from 'interface/base/hooks/useHasExternalWorkspacesShares';
import {useJoinInvitationHandler} from 'interface/base/hooks/useJoinInvitationHandler';
import type {
  OrganizationSummaryPending,
  PendingInvitationDetails,
} from 'interface/stacks/auth/consts';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {InvalidToken} from '../components/InvalidToken';
import {InvitationUserNotMatch} from '../components/InvitationUserNotMatch';
import {OrganizationInvite} from '../components/OrganizationInvite';
import {SharedInvite} from '../components/SharedInvite';
import {WorkspaceInvite} from '../components/WorkspaceInvite';
import {MEMBERS_MOCK, ORGANIZATION_MOCK} from './dummyShareInvite';

export function JoinEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const userProfilePic = useSelector(user.selectors.getUserProfilePic);
  const organizations = useSelector(user.selectors.getOrganizationsList);
  const {hasExternalWorkspacesOrShares} = useHasExternalWorkspacesShares();

  const [invitation, setInvitation] = useState<
    PendingInvitationDetails | undefined
  >(undefined);
  const [organization, setOrganization] = useState<
    OrganizationSummaryPending | undefined
  >(undefined);
  const [workspace, setWorkspace] = useState<WorkspaceListDetail | undefined>(
    undefined,
  );

  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {handleInvitationAcceptance} = useJoinInvitationHandler();
  //const {fetchPublicShareDetails} = useFetchShareDetails();

  const fetchOrganization = async (invitation: InvitationDetails) => {
    let orgData: OrganizationDetails;

    if (invitation.org) {
      orgData = invitation.org;
    } else if (invitation.workspace) {
      const {org} = await api.organization.getOrganizationPublicDetails(
        invitation.workspace.org_domain,
      );
      orgData = org;
    }

    if (orgData)
      setOrganization({
        ...orgData,
        invitedBy: {email: invitation.inviter},
      });
    return orgData;
  };

  const fetchWorkspace = async (invitation: InvitationDetails) => {
    if (invitation.workspace) {
      setWorkspace({
        ...invitation.workspace,
        user_status: WorkspaceUserStatus.Available,
      });
    }

    return await fetchOrganization(invitation);
  };

  const fetchInvitationEntity = async (invitation: InvitationDetails) => {
    let org: OrganizationDetails;
    let members: Member[];
    if (invitation.entity_type === InvitationTypes.Organization)
      org = await fetchOrganization(invitation);
    else if (invitation.entity_type === InvitationTypes.Workspace)
      org = await fetchWorkspace(invitation);
    else if (invitation.entity_type === InvitationTypes.Shared) {
      /*const {org, users: members} = await fetchPublicShareDetails(
        invitation.share?.custom_name,
      );*/
      // @ts-expect-error
      org = ORGANIZATION_MOCK;
      // @ts-expect-error
      members = MEMBERS_MOCK;
    }
    return {org, members};
  };

  const fetchInvitation = async (invitationToken: string) => {
    try {
      if (!invitationToken)
        throw new Error('JoinEmail - No invitation token provided');
      const {result, invitation} =
        await api.user.getInvitationDetails(invitationToken);
      if (result) {
        if (invitation.state === InvitationStatuses.Pending) {
          const {org, members} = await fetchInvitationEntity(invitation);
          const invitationWithToken = {
            ...invitation,
            invitationToken: invitationToken,
            org,
            members,
          };
          setInvitation(invitationWithToken);
          dispatch(
            app.default.actions.setCurrentJoinInvitation({
              joinInvitation: invitationWithToken,
            }),
          );
        } else {
          reportAndLogError(
            new Error(
              `Join Email - Invitation not longer valid for ${JSON.stringify(invitation)}`,
            ),
          );
        }
      }
    } catch (error) {
      reportAndLogError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const redirectToSignUp = () => navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNUP}`);

  const reportAndLogError = async (error: Error) => {
    dispatch(app.default.actions.clearCurrentJoinInvitation());
    dispatch(
      app.default.actions.setIsInvitationFlow({
        isInvitationFlow: false,
      }),
    );
    setError(error);
    handleAppError({
      appError: AppErrors.JoinEmailPageLoadError,
      exception: error,
    });
  };

  const onJoinButtonPress = async () => {
    if (isLoggedIn) {
      await handleInvitationAcceptance({invitation});
    } else {
      dispatch(
        app.default.actions.setCurrentJoinInvitation({
          joinInvitation: {
            ...invitation,
            hasClickedOnJoined: true,
          },
        }),
      );
      dispatch(
        app.default.actions.setIsInvitationFlow({
          isInvitationFlow: true,
        }),
      );
      redirectToSignUp();
    }
  };

  useEffect(() => {
    fetchInvitation(searchParams.get('token'));
  }, []);

  const onInvalidNavigate = () => {
    if (!isLoggedIn) {
      return navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }

    if (hasExternalWorkspacesOrShares) {
      return navigate(
        `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`,
      );
    }

    if (organizations.length > 0) {
      return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`);
    }
  };

  const renderContent = () => {
    if (isLoading) return <Loading centered />;

    const isInvalidInvitationToken =
      error || !invitation || invitation?.state !== InvitationStatuses.Pending;
    if (isInvalidInvitationToken)
      return <InvalidToken onInvalidNavigate={onInvalidNavigate} />;

    const userDoesntMatch =
      userDetails.id &&
      userDetails.email_address.toLowerCase() !==
        invitation.invitee_email.toLowerCase();
    if (userDoesntMatch)
      return (
        <InvitationUserNotMatch
          invitation={invitation}
          userDetails={userDetails}
          userProfilePic={userProfilePic}
        />
      );

    switch (invitation.entity_type) {
      case InvitationTypes.Organization:
        return (
          organization && (
            <OrganizationInvite
              userDetails={userDetails}
              userProfilePic={userProfilePic}
              invitation={invitation}
              organization={organization}
              onJoinButtonPress={onJoinButtonPress}
            />
          )
        );
      case InvitationTypes.Workspace:
        return (
          workspace &&
          organization && (
            <WorkspaceInvite
              userDetails={userDetails}
              userProfilePic={userProfilePic}
              invitation={invitation}
              workspace={workspace}
              organization={organization}
              onJoinButtonPress={onJoinButtonPress}
            />
          )
        );
      case InvitationTypes.Shared:
        return (
          <SharedInvite
            invitation={invitation}
            userDetails={userDetails}
            userProfilePic={userProfilePic}
            onJoinButtonPress={onJoinButtonPress}
          />
        );
      default:
        reportAndLogError(
          new Error(
            `JoinEmail - Invalid invitation type found for ${JSON.stringify(invitation)}`,
          ),
        );
    }
  };

  return renderContent();
}
