import {breakpoints} from 'config/styles';
import {DrawerLayout} from 'extensions/viewport/DrawerLayout';
import {useCallback, useRef} from 'react';
import {View, useWindowDimensions} from 'react-native';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router';

import {StyleSheet} from 'react-native';
import * as app from 'store/slices/app';
import {SettingsMenu} from '../base/SettingsMenu';

export function LayoutSettings() {
  const drawer = useRef<DrawerLayout>(null);
  const screen = useWindowDimensions();
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const isFixedMenu = screen.width > breakpoints.menu;
  const isDrawerEnabled = !isFixedMenu && isLoggedIn;

  const openMenu = useCallback(() => drawer.current?.openDrawer(), []);
  const renderMenu = useCallback(
    () => (isDrawerEnabled ? <SettingsMenu /> : null),
    [isDrawerEnabled],
  );

  return (
    <DrawerLayout
      ref={drawer}
      drawerWidth={250}
      minSwipeDistance={3}
      edgeWidth={20}
      drawerType="front"
      drawerPosition="right"
      drawerLockMode={isDrawerEnabled ? 'unlocked' : 'locked-closed'}
      renderNavigationView={renderMenu}>
      <View style={styles.root}>
        {isFixedMenu && (
          <View style={styles.menu}>
            <SettingsMenu />
          </View>
        )}
        <Outlet context={[openMenu]} />
      </View>
    </DrawerLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
  },
  menu: {
    width: 250,
  },
});
