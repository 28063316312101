import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface FullScreenButtonProps {
  handleOnFullScreen: () => void;
}

export default function FullScreenButton({
  handleOnFullScreen,
}: FullScreenButtonProps) {
  return (
    <Button
      type="Secondary"
      onPress={handleOnFullScreen}
      lIcon={
        <Icon name="fullscreen" size={20} color={theme.colors.neutral.$15} />
      }
      customRootStyle={styles.button}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    bottom: 10,
    right: 10,
    position: 'absolute',
    width: 36,
    backgroundColor: theme.colors.neutral.$1,
    borderColor: theme.colors.neutral.$1,
  },
});
