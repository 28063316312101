import theme from 'config/theme';
import type {ErrorBoundaryDetails} from 'errors/types';
import AppLogo from 'interface/base/AppLogo';
import {ErrorComponent} from 'interface/base/error/ErrorComponent';
import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as errors from 'store/slices/errors';

export interface ErrorPageProps {
  isUnhandledError?: boolean;
  errorDetails?: ErrorBoundaryDetails;
}

export const ErrorPage = ({
  isUnhandledError = false,
  errorDetails,
}: ErrorPageProps) => {
  const showError = useSelector(errors.selectors.getShowError);

  useEffect(() => {
    if (!showError && !isUnhandledError) {
      window.location.href = '/';
    }
  }, [showError, isUnhandledError]);

  return (
    <View style={styles.root}>
      <View
        style={[
          styles.header,
          {
            backgroundColor: theme.colors.neutral.$15,
          },
        ]}>
        <AppLogo />
      </View>
      <View style={styles.content}>
        <ErrorComponent
          showConfirmResetModal={!isUnhandledError}
          errorDetails={errorDetails}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$11,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingTop: 50,
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
});
