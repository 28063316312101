import theme from 'config/theme';
import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import ShareOrgBadge from 'interface/base/ShareOrgBadge';
import useOpenLinkInNewTab from 'interface/base/hooks/useOpenLinkInNewTab';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import React from 'react';
import {Pressable} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import type {Share} from 'store/slices/shared/types';
import {AppSideNavItem} from './AppSideNavItem';

type Props = {
  share: ShareItem;
};

export function AppSideNavOrphanShareItem({share}: Props) {
  const {openAppLink} = useOpenLinkInNewTab();
  const {generateShareUrl} = useGenerateShareUrl();

  const onPress = () => {
    openAppLink(generateShareUrl(share as Share));
  };

  return (
    <Pressable onPress={onPress}>
      <AppSideNavItem
        label={share.title}
        state={'Default'}
        icon={<ShareOrgBadge id={share.id} orgId={share.parent_org} />}
        hoverIcon={
          <MCIcon
            name="open-in-new"
            size={16}
            color={theme.colors.neutral.$4}
          />
        }
      />
    </Pressable>
  );
}
