// @ts-ignore
import ShareNodeIcon from 'assets/svgs/fileIcons/Folder_Share.svg';
import theme from 'config/theme';
import {useHover} from 'interface/base/hooks/useHover';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';

import type {MultiplayerMember} from 'interface/multiplayer/types';

export interface FolderToolbarItemProps {
  id: string;
  name: string;
  state: 'Default' | 'Hover' | 'Current';
  info?: string;
  members?: MultiplayerMember[];
  onPress: () => void;
  isShared?: boolean;
}

export function FolderToolbarItem(props: FolderToolbarItemProps) {
  const {isHover, onHoverIn, onHoverOut} = useHover();
  const classes = {
    root: [
      styles.root,
      (props.state === 'Hover' || isHover) && styles.rootHover,
    ],
    name: [
      styles.name,
      props.state === 'Hover' && styles.nameHover,
      props.state === 'Default' && styles.nameDefault,
    ],
  };

  const dots = props?.members
    ?.filter(member => member.currentItemId === props.id)
    ?.map(member => (
      <View
        key={member.clientId}
        style={[styles.dot, {backgroundColor: member.userColor}]}
      />
    ));

  return (
    <Pressable
      style={classes.root}
      onPress={props.onPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}>
      <View style={styles.nameInfo}>
        {props.isShared && (
          <Image
            source={ShareNodeIcon}
            style={styles.shareIcon}
            resizeMode="center"
          />
        )}
        <Text style={classes.name}>{props.name}</Text>
        <Text style={styles.info}>{props.info}</Text>
      </View>
      <View style={styles.membersPlaceholder}>
        <View style={styles.members}>
          {dots?.length > 0 ? dots : <View style={styles.dot} />}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 8,
    paddingHorizontal: 10,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 3,
  },
  rootHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  nameInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  name: {
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '700',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$2Base,
  },
  nameHover: {
    fontWeight: '400',
  },
  nameDefault: {
    fontWeight: '400',
    color: theme.colors.neutral.$5,
  },
  info: {
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$2Base,
  },
  membersPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  members: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
  },
  dot: {
    display: 'flex',
    flexDirection: 'column',
    width: 7,
    height: 7,
    borderRadius: 99999,
  },
  shareIcon: {
    width: 20,
    height: 20,
  },
});
