import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import useGenerateQuickShareUrl from 'interface/stacks/share/hooks/useGenerateQuickShareUrl';
import {Timer} from 'interface/stacks/workspace/storage/quick-share/Timer';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import Icon from 'react-native-vector-icons/MaterialIcons';
import type {QuickShare} from 'store/slices/quick-share/types';
import {copyLink} from 'utils/mediafire';

interface Props {
  quickShare: QuickShare;
  goToCancelMode: () => void;
  close: () => void;
}

export function ActiveQuickShare({quickShare, goToCancelMode, close}: Props) {
  const toast = useToast();
  const {generateQuickShareUrl} = useGenerateQuickShareUrl();

  const handleCopyLink = () => {
    copyLink(`${location.origin}${generateQuickShareUrl(quickShare)}`);
    close();
    toast.show('QuickShare link copied to clipboard', {
      type: 'neutral',
    });
  };

  return (
    <View style={styles.activated}>
      <Timer quickShare={quickShare} />
      <View style={styles.activatedButtons}>
        <Button
          type="Primary"
          label={t`Copy Link`}
          onPress={handleCopyLink}
          lIcon={
            <Icon name="link" size={20} color={theme.colors.neutral.$white} />
          }
        />
        <Button
          type="Secondary"
          ariaLabel="Remove Link"
          onPress={goToCancelMode}
          lIcon={
            <Icon name="link-off" size={20} color={theme.colors.neutral.$5} />
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  activated: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 90,
  },
  activatedButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
});
