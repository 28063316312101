import {MemberAvatar} from 'interface/base/MemberAvatar';
import {useSelector} from 'react-redux';
import {selectors as user} from 'store/slices/user';

interface Props {
  profilePicture?: string;
}

export function AppUserAvatar({profilePicture}: Props) {
  const details = useSelector(user.getUserDetails);
  const userId = details?.id ? details.id.toString() : '';

  return (
    <MemberAvatar
      memberId={userId}
      initials={
        details ? `${details.first_name[0]}${details.last_name[0]}` : ''
      }
      profilePicture={profilePicture}
    />
  );
}
