import {Outlet} from 'extensions/navigation';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import type React from 'react';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {EntityType} from 'store/slices/activity/types';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import useEntityCommunication from '../hooks/useEntityCommunication';
import useSyncOrganizationData from '../hooks/useSyncOrgData';

const OrganizationActivityProvider = () => {
  const {subdomain} = useSubDomain();

  const {initializeActivityForEntity} = useEntityCommunication();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const organizations = useSelector(user.selectors.getOrganizationsList);

  const currentOrganization = useMemo(
    () => organizations.find(({domain}) => subdomain === domain),
    [organizations, subdomain],
  );
  const currentOrganizationId = currentOrganization?.id;

  useEffect(() => {
    if (isLoggedIn && currentOrganizationId) {
      return initializeActivityForEntity({
        entityId: currentOrganizationId,
        entityType: EntityType.Organization,
      });
    }
  }, [isLoggedIn, currentOrganizationId]);

  useSyncOrganizationData({organization: currentOrganization});

  return <Outlet />;
};

export default OrganizationActivityProvider;
