import theme from 'config/theme';
import {memo} from 'react';
import {
  Image,
  type ImageStyle,
  type StyleProp,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
} from 'react-native';
import {getContrast} from 'utils/common/color';

export type AvatarSize = -1 | 0 | 1 | 2 | 3 | 4 | 5;

export type AvatarBorder = 'dark' | 'light';

export type ShapeKind = 'circle' | 'rounded-square';

export const AVATAR_SIZES = {
  AVATAR_SIZE_0L: {width: 20, height: 20},
  AVATAR_SIZE_0: {width: 30, height: 30},
  AVATAR_SIZE_1: {width: 36, height: 36},
  AVATAR_SIZE_2: {width: 40, height: 40},
  AVATAR_SIZE_3: {width: 50, height: 50},
  AVATAR_SIZE_4: {width: 64, height: 64},
  AVATAR_SIZE_5: {width: 96, height: 96},
};

export interface AvatarProps {
  color: string;
  initials: string;
  photo?: string;
  size?: AvatarSize;
  emptyFallback?: boolean;
  hideBorder?: boolean;
  isStacked?: boolean;
  rootStyle?: StyleProp<ViewStyle>;
  borderKind?: AvatarBorder;
  shapeKind?: ShapeKind;
  imageStyle?: StyleProp<ImageStyle>;
}

/**
 * Avatar component for orgs, shares, users, etc.
 * Displays initials w/ bg color or photo and colored border.
 */
export const Avatar = memo(function Avatar(props: AvatarProps) {
  const contrast = getContrast(props.color);
  const size = props.size || 0;

  const classes = {
    root: [
      props.isStacked && styles.stacked,
      props.rootStyle && props.rootStyle,
      props.borderKind && {
        ...styles.border,
        borderColor:
          props.borderKind === 'dark'
            ? theme.colors.neutral.$1
            : theme.colors.neutral.$white,
      },
    ],
    inner: [
      styles.inner,
      size === -1 && AVATAR_SIZES.AVATAR_SIZE_0L,
      size === 0 && AVATAR_SIZES.AVATAR_SIZE_0,
      size === 1 && AVATAR_SIZES.AVATAR_SIZE_1,
      size === 2 && AVATAR_SIZES.AVATAR_SIZE_2,
      size === 3 && AVATAR_SIZES.AVATAR_SIZE_3,
      size === 4 && AVATAR_SIZES.AVATAR_SIZE_4,
      size === 5 && AVATAR_SIZES.AVATAR_SIZE_5,
      props.emptyFallback
        ? {backgroundColor: 'transparent'}
        : {backgroundColor: props.color},
      props.emptyFallback && styles.pending,
      !props.hideBorder &&
        !props.emptyFallback && {
          borderWidth: 3,
          borderColor: props.color,
        },
      props.shapeKind === 'rounded-square' && {
        borderRadius: 8,
      },
    ],
    image: [
      styles.image,
      size === -1 && AVATAR_SIZES.AVATAR_SIZE_0L,
      size === 0 && AVATAR_SIZES.AVATAR_SIZE_0,
      size === 1 && AVATAR_SIZES.AVATAR_SIZE_1,
      size === 2 && AVATAR_SIZES.AVATAR_SIZE_2,
      size === 3 && AVATAR_SIZES.AVATAR_SIZE_3,
      size === 4 && AVATAR_SIZES.AVATAR_SIZE_4,
      size === 5 && AVATAR_SIZES.AVATAR_SIZE_5,
      props.imageStyle,
    ],
    label: [
      styles.label,
      size === -1 && {fontSize: 8},
      size === 0 && {fontSize: 12},
      size === 1 && {fontSize: 14},
      size === 2 && {fontSize: 16},
      size === 3 && {fontSize: 18},
      size === 4 && {fontSize: 24},
      size === 5 && {fontSize: 28},
      {
        color: props.emptyFallback
          ? theme.colors.neutral.$2Base
          : contrast === 'black'
            ? theme.colors.neutral.$2Base
            : theme.colors.neutral.$white,
      },
    ],
  };
  return (
    <View style={classes.root}>
      <View style={classes.inner}>
        <>
          <Text style={classes.label}>{props.initials}</Text>
          {props.photo && (
            <Image style={classes.image} source={{uri: props.photo}} />
          )}
        </>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#000000',
    borderStyle: 'solid',
    borderRadius: 99999,
    position: 'relative',
  },
  stacked: {
    marginLeft: -6,
  },
  pending: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.colors.neutral.$7,
  },
  label: {
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.colors.neutral.$7,
    position: 'absolute',
    zIndex: 1,
  },
  image: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transition: 'opacity 0.2s ease-in-out',
    zIndex: 2,
  },
  border: {
    borderWidth: 2,
    boxSizing: 'content-box',
    borderRadius: 100,
  },
});
