import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import {
  ROUTES,
  STORAGE_ROOT,
  WORKSPACE_SHARED,
  WORKSPACE_STORAGE,
} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {TabList, createTabItem} from 'interface/base/TabList';
import {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as workspaceSlice from 'store/slices/workspace';

export enum WorkspaceTab {
  Storage = 'Storage',
  Shared = 'Shared',
}

interface Props {
  workspaceAlt: string;
}

export function WorkspaceTabList({workspaceAlt}: Props) {
  const navigate = useNavigate();

  const isSharedEnabled = useFlag('view-shared');
  const isSharedRoute = location.pathname.toLowerCase().includes('/shared');

  const workspace = useSelector(
    workspaceSlice.selectors.getWorkspaceByAlt(workspaceAlt),
  );

  const baseUrl = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}`;

  const activeTab = isSharedRoute ? WorkspaceTab.Shared : WorkspaceTab.Storage;

  const setActiveTab = (tab: WorkspaceTab) => {
    const route =
      tab === WorkspaceTab.Shared
        ? WORKSPACE_SHARED
        : `${WORKSPACE_STORAGE}/${STORAGE_ROOT}`;
    navigate(`${baseUrl}/${route}`);
  };

  const tabs = useMemo(() => {
    return [
      createTabItem(WorkspaceTab.Storage, 'Storage', 'folder', activeTab),
      isSharedEnabled
        ? createTabItem(
            WorkspaceTab.Shared,
            'Shares',
            'package-variant-closed',
            activeTab,
          )
        : undefined,
    ].filter(Boolean);
  }, [activeTab, isSharedEnabled]);

  return (
    <View style={styles.container}>
      <TabList
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        rootStyle={styles.container}
        tabStyle={styles.tabItem}
        activeTabStyle={styles.activeTab}
        tabTextStyle={styles.tabText}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.neutral.$15,
  },
  tabItem: {
    paddingRight: 0,
    backgroundColor: 'transparent',
  },
  activeTab: {
    backgroundColor: 'transparent',
    paddingBottom: 18,
  },
  tabText: {
    fontSize: 14,
    fontWeight: '600',
  },
});
