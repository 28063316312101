import ArchiveTypeIcon from 'assets/svgs/fileIcons/Archive.svg';
import AudioTypeIcon from 'assets/svgs/fileIcons/Audio.svg';
import CSSTypeIcon from 'assets/svgs/fileIcons/CSS.svg';
import CodeTypeIcon from 'assets/svgs/fileIcons/Code.svg';
import CreativeTypeIcon from 'assets/svgs/fileIcons/Creative.svg';
import DocumentTypeIcon from 'assets/svgs/fileIcons/Document.svg';
import ExecutableTypeIcon from 'assets/svgs/fileIcons/Executable.svg';
import FolderTypeIcon from 'assets/svgs/fileIcons/Folder.svg';
import ShareTypeIcon from 'assets/svgs/fileIcons/Folder_Share.svg';
import HTMLTypeIcon from 'assets/svgs/fileIcons/HTML.svg';
import ImageTypeIcon from 'assets/svgs/fileIcons/Image.svg';
import InstallableTypeIcon from 'assets/svgs/fileIcons/Installable.svg';
import JSTypeIcon from 'assets/svgs/fileIcons/JS.svg';
import PDFTypeIcon from 'assets/svgs/fileIcons/PDF.svg';
import PresentationTypeIcon from 'assets/svgs/fileIcons/Presentation.svg';
import SpreadsheetTypeIcon from 'assets/svgs/fileIcons/Spreadsheet.svg';
import TextTypeIcon from 'assets/svgs/fileIcons/Text.svg';
import UnknownTypeIcon from 'assets/svgs/fileIcons/Unknown.svg';
import VideoTypeIcon from 'assets/svgs/fileIcons/Video.svg';
import {
  FilePreviewState,
  PreviewType,
  StorageItemType,
} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem} from 'store/slices/files/types';

export enum FileType {
  Archive = 'Archive',
  Audio = 'Audio',
  Code = 'Code',
  Creative = 'Creative',
  CSS = 'CSS',
  Document = 'Document',
  Executable = 'Executable',
  Folder = 'Folder',
  Share = 'Share',
  HTML = 'HTML',
  Image = 'Image',
  Installable = 'Installable',
  JS = 'JS',
  PDF = 'PDF',
  Presentation = 'Presentation',
  Spreadsheet = 'Spreadsheet',
  Text = 'Text',
  Unknown = 'Unknown',
  Video = 'Video',
}

export enum FileExtensions {
  JS = 'js',
  HTML = 'html',
  CSS = 'css',
  PDF = 'pdf',
}

export enum MimeType {
  Text = 'text/',
  JavaScript = 'text/javascript',
  CSS = 'text/css',
  HTML = 'text/html',
  JSON = 'application/json',
  XML = 'application/xml',
  Audio = 'audio/',
  Video = 'video/',
  Image = 'image/',
  Zip = 'application/zip',
  Rar = 'application/x-rar-compressed',
  SevenZip = 'application/x-7z-compressed',
  PDF = 'application/pdf',
  MSWord = 'application/msword',
  DocX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Photoshop = 'application/photoshop',
  PhotoshopImage = 'image/vnd.adobe.photoshop',
  Illustrator = 'application/illustrator',
  MSDownload = 'application/x-msdownload',
  Executable = 'application/x-executable',
  Deb = 'application/x-deb',
  AndroidPackage = 'application/vnd.android.package-archive',
  Excel = 'application/vnd.ms-excel',
  ExcelX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PowerPoint = 'application/vnd.ms-powerpoint',
  PowerPointX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}
const categoryMimeTypes: Record<FileType, MimeType | MimeType[]> = {
  [FileType.Text]: MimeType.Text,
  [FileType.Code]: [
    MimeType.JavaScript,
    MimeType.CSS,
    MimeType.HTML,
    MimeType.JSON,
    MimeType.XML,
  ],
  [FileType.Audio]: MimeType.Audio,
  [FileType.Video]: MimeType.Video,
  [FileType.Image]: MimeType.Image,
  [FileType.Archive]: [MimeType.Zip, MimeType.Rar, MimeType.SevenZip],
  [FileType.Document]: [MimeType.PDF, MimeType.MSWord, MimeType.DocX],
  [FileType.Creative]: [
    MimeType.Photoshop,
    MimeType.PhotoshopImage,
    MimeType.Illustrator,
  ],
  [FileType.Executable]: [MimeType.MSDownload, MimeType.Executable],
  [FileType.Installable]: [MimeType.Deb, MimeType.AndroidPackage],
  [FileType.Spreadsheet]: [MimeType.Excel, MimeType.ExcelX],
  [FileType.Presentation]: [MimeType.PowerPoint, MimeType.PowerPointX],
  [FileType.Folder]: undefined,
  [FileType.Share]: undefined,
  [FileType.JS]: MimeType.JavaScript,
  [FileType.HTML]: MimeType.HTML,
  [FileType.CSS]: MimeType.CSS,
  [FileType.PDF]: MimeType.PDF,
  [FileType.Unknown]: undefined,
};

export function typeToIcon(type: FileType, selected?: boolean) {
  switch (type) {
    case FileType.Archive:
      return ArchiveTypeIcon;
    case FileType.Audio:
      return AudioTypeIcon;
    case FileType.Code:
      return CodeTypeIcon;
    case FileType.Creative:
      return CreativeTypeIcon;
    case FileType.CSS:
      return CSSTypeIcon;
    case FileType.Document:
      return DocumentTypeIcon;
    case FileType.Executable:
      return ExecutableTypeIcon;
    case FileType.Folder:
      return FolderTypeIcon;
    case FileType.HTML:
      return HTMLTypeIcon;
    case FileType.Image:
      return ImageTypeIcon;
    case FileType.Installable:
      return InstallableTypeIcon;
    case FileType.JS:
      return JSTypeIcon;
    case FileType.PDF:
      return PDFTypeIcon;
    case FileType.Presentation:
      return PresentationTypeIcon;
    case FileType.Spreadsheet:
      return SpreadsheetTypeIcon;
    case FileType.Text:
      return TextTypeIcon;
    case FileType.Unknown:
      return UnknownTypeIcon;
    case FileType.Video:
      return VideoTypeIcon;
    case FileType.Share:
      return ShareTypeIcon;
  }
}

export function fileListToFile(files: FileList) {
  const result: Array<File> = [];
  for (let idx = 0; idx < files.length; idx++) {
    result.push(files.item(idx));
  }
  return result;
}

export const KB = 1024;
export const MB = KB * 1024;
export const GB = MB * 1024;
export const TB = GB * 1024;

export const calcSize = (bytes: number) => {
  if (!bytes) return '0 Bytes';
  if (bytes < KB) return `${bytes} Bytes`;
  if (bytes < MB) return `${(bytes / KB).toFixed(1)} KB`;
  if (bytes < GB) return `${(bytes / MB).toFixed(1)} MB`;
  if (bytes < TB) return `${(bytes / GB).toFixed(1)} GB`;
  return `${(bytes / TB).toFixed(1)} TB`;
};

export const getIcon = (type: string) => {
  const splitedType = type.split('/');
  if (splitedType[0] === 'image') return typeToIcon(FileType.Image);
  if (splitedType[0] === 'video') return typeToIcon(FileType.Video);
  if (splitedType[0] === 'audio') return typeToIcon(FileType.Audio);
  if (splitedType[0] === 'text') {
    if (splitedType[1] === 'html') return typeToIcon(FileType.HTML);
    if (splitedType[1] === 'css') return typeToIcon(FileType.CSS);
    if (splitedType[1] === 'js') return typeToIcon(FileType.JS);
    if (splitedType[1] === 'csv' || splitedType[1] === 'xml') {
      return typeToIcon(FileType.Spreadsheet);
    }
    return typeToIcon(FileType.Text);
  }
  if (splitedType[0] === 'application') {
    if (splitedType[1] === 'pdf') return typeToIcon(FileType.PDF);
    if (splitedType[1] === 'zip') return typeToIcon(FileType.Archive);
    return typeToIcon(FileType.Document);
  }
  return typeToIcon(FileType.Unknown);
};

export const base64ToBlob = (base64: string, type?: string) => {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = Array.from(byteCharacters).map(char =>
    char.charCodeAt(0),
  );
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: type ?? 'image/jpeg'});
};

export const base64ToFile = (base64: string, type: string, name: string) => {
  const blob = base64ToBlob(base64, type);
  return new File([blob], name, {type});
};

//We can potentially get rid off this function if item.type returned by backend
//is something meaningful for us, currently the item.type is always being returned as file
//so this function help us to intefer the type based on mimetype and extension
export const getFileTypeFromMime = (item: FilesItem): FileType => {
  const {mimetype: mimeType, name: filename, type} = item;

  if (type === StorageItemType.Folder) {
    return FileType.Folder;
  }

  if (type === StorageItemType.Link) {
    return FileType.Share;
  }

  const extension = filename.split('.').pop()?.toLowerCase();
  switch (extension) {
    case FileExtensions.JS:
      return FileType.JS;
    case FileExtensions.CSS:
      return FileType.CSS;
    case FileExtensions.HTML:
      return FileType.HTML;
    case FileExtensions.PDF:
      return FileType.PDF;
    default:
      break;
  }

  for (const [category, mimePatterns] of Object.entries(categoryMimeTypes)) {
    if (Array.isArray(mimePatterns)) {
      if (mimePatterns.some(pattern => mimeType.startsWith(pattern))) {
        return category as FileType;
      }
    } else if (mimePatterns && mimeType.startsWith(mimePatterns)) {
      return category as FileType;
    }
  }

  return FileType.Unknown;
};

export const formatFileId = (fileId: string) => {
  return fileId?.includes('-') ? fileId : fileId?.replace(/(.{5})/g, '$1-');
};

const PREVIEW_MESSAGE_MAP = {
  [FilePreviewState.UNKNOWN]: {
    title: 'Unable to preview this file',
    description:
      'Unable to preview this file, try again later or contact support.',
  },
  [FilePreviewState.NOT_POSSIBLE]: {
    title: 'Preview not possible',
    description:
      'This file format is not supported for viewing, try again later or contact support.',
  },
  [FilePreviewState.NOT_GENERATED]: {
    title: 'Preview not generated yet',
    description:
      'File preview has not been generated yet. Please check back later.',
  },
  [FilePreviewState.ERROR]: {
    title: 'We encountered an error previewing this file',
    description:
      'We encountered an error previewing this file. Please try again later or contact support.',
  },
  [FilePreviewState.IN_PROGRESS]: {
    title: 'Preview generation in progress',
    description: 'Preview generation is in progress. Please check back later.',
  },
};

export const PREVIEW_TYPE_MAP = {
  [FileType.Image]: PreviewType.IMAGE,
  [FileType.PDF]: PreviewType.PDF,
  [FileType.Video]: PreviewType.HLSSTREAM,
  [FileType.Spreadsheet]: PreviewType.SPREADSHEET,
};

export const isPreviewReady = (file: FilesItem) => {
  const {previews} = file;

  const type = getFileTypeFromMime(file);
  const previewType = PREVIEW_TYPE_MAP[type];

  const {state} = previews?.[previewType] ?? {
    state: FilePreviewState.EMPTY,
  };

  const isReady = [FilePreviewState.READY, FilePreviewState.EMPTY].includes(
    state,
  );
  const {title, description} = PREVIEW_MESSAGE_MAP[state] ?? {};
  return {isReady, title, description, state};
};
