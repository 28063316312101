import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {getHashColor} from 'utils/common/color';
import {FileType, typeToIcon} from 'utils/fast/files';

interface FolderIconProps {
  size?: number;
}

export function FolderIcon({size = 16}: FolderIconProps) {
  return (
    <View style={styles.container}>
      <Image
        style={[styles.folderIcon, {width: size, height: size}]}
        source={typeToIcon(FileType.Folder)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  folderIcon: {
    width: 16,
    height: 16,
    borderRadius: 3,
  },
  container: {
    padding: 2,
  },
});
