import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {Transfers} from 'fast-sdk';
import {Button} from 'interface/base/Button';
import {CropImage} from 'interface/base/CropImage';
import {type PropsWithChildren, useEffect, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import {fileListToFile} from 'utils/fast/files';
import {Avatar, type AvatarSize} from './Avatar';

type Props = {
  fullname?: string;
  profilePicture?: string;
  hideButtons?: boolean;
  hideAddIcon?: boolean;
  size?: AvatarSize;
  onCropImage?: (file: File, src: string) => void | Promise<void>;
  onRemove?: () => void | Promise<void>;
};

const getInitials = (fullname: string) => {
  const [firstName, lastName] = fullname.split(' ');

  if (!firstName && !lastName) {
    return '';
  }

  if (firstName && !lastName) {
    return firstName[0];
  }

  if (!firstName && lastName) {
    return lastName[0];
  }

  return `${firstName[0]}${lastName[0]}`;
};

const ProfilePhotoPicker = ({
  fullname,
  children,
  hideButtons = false,
  hideAddIcon = false,
  profilePicture,
  size = 5,
  onCropImage,
  onRemove,
}: PropsWithChildren<Props>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [croppedImg, setCroppedImg] = useState<string>();
  const [showFile, setShowFile] = useState<string>('');
  const [profileFile, setProfileFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');
  const modal = useModal();

  const userDetails = useSelector(user.selectors.getUserDetails);

  const color = userDetails.color ?? getHashColor(userDetails.id);
  const initials = getInitials(
    fullname ?? `${userDetails.first_name} ${userDetails.last_name}`,
  );

  useEffect(() => {
    setCroppedImg(profilePicture);
  }, []);

  useEffect(() => {
    if (showFile) {
      modal.open(
        <CropImage
          setImage={setProfileFile}
          image={showFile}
          setCompletedImg={setCroppedImg}
          imageName={fileName}
          imageType={fileType}
        />,
      );
    }
  }, [showFile]);

  useEffect(() => {
    if (
      onCropImage &&
      profileFile &&
      croppedImg &&
      croppedImg !== profilePicture
    ) {
      async function onSelectImage() {
        setLoading(true);
        await onCropImage(profileFile, croppedImg);
        setLoading(false);
      }

      onSelectImage();
    }
  }, [profileFile, croppedImg, onCropImage]);

  const handlePickPhoto = async () => {
    const input = await Transfers.pickFiles();
    const files = fileListToFile(input);
    if (files.length) {
      const [file] = files;
      setFileName(file.name);
      setFileType(file.type);
      const url = URL.createObjectURL(file);
      setShowFile(url);
    }
  };

  const handleRemovePhoto = async () => {
    setLoading(true);
    setShowFile(undefined);
    setCroppedImg(undefined);
    setProfileFile(undefined);
    setFileName('');
    setFileType('');
    await onRemove?.();
    setLoading(false);
  };

  return (
    <View style={styles.root}>
      <Pressable style={styles.emptyPhoto} onPress={handlePickPhoto}>
        <Avatar
          color={color}
          initials={initials}
          photo={croppedImg}
          hideBorder
          size={size}
        />
        {!hideAddIcon && (
          <Icon
            name="add-circle"
            size={24}
            color={theme.colors.success.$4Base}
            style={styles.addIcon}
          />
        )}
      </Pressable>

      <View style={styles.container}>
        {children}

        {!hideButtons &&
          (croppedImg ? (
            <View style={styles.buttons}>
              <Button
                type="Secondary"
                label={t`Change`}
                ariaLabel={t`Change Photo`}
                customTextStyle={styles.buttonText}
                onPress={handlePickPhoto}
                disabled={loading}
              />
              <Button
                type="Secondary"
                label={t`Remove`}
                ariaLabel={t`Remove Photo`}
                customTextStyle={styles.buttonText}
                onPress={handleRemovePhoto}
                disabled={loading}
                loading={loading}
              />
            </View>
          ) : (
            <Button
              type="Secondary"
              label={t`Upload photo`}
              ariaLabel={t`Upload Photo`}
              customTextStyle={styles.buttonText}
              customRootStyle={styles.button}
              onPress={handlePickPhoto}
              loading={loading}
              disabled={loading}
            />
          ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 21,
  },
  emptyPhoto: {
    position: 'relative',
  },
  initial: {
    fontSize: 24,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
    textTransform: 'uppercase',
  },
  addIcon: {
    borderRadius: 50,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$white,
    position: 'absolute',
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.neutral.$white,
  },
  image: {
    width: 96,
    height: 96,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },

  button: {
    maxWidth: 108,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
  },
});

export default ProfilePhotoPicker;
