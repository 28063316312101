import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {useHover} from 'interface/base/hooks/useHover';
import type {StyleProp, ViewStyle} from 'react-native';
import {Pressable, StyleSheet, Text, View} from 'react-native';

export interface AppSideNavItemProps {
  icon: JSX.Element;
  state: 'Default' | 'Hover' | 'Active';
  label: string;
  link?: string;
  new?: boolean;
  endIcon?: JSX.Element;
  activeMembers?: boolean;
  memberCount?: string;
  rootStyles?: StyleProp<ViewStyle>;
  hoverIcon?: JSX.Element;
}

/**
 * Sidebar navigation, menu item; can be configured to display a workspace item or basic menu item. Use this to bulk edit styles or adjust state variants.
 */
export function AppSideNavItem(props: AppSideNavItemProps) {
  const navigate = useNavigate();

  const {isHover, onHoverIn, onHoverOut} = useHover();

  const classes = {
    root: [
      styles.root,
      (props.state === 'Hover' || isHover) && styles.rootHover,
      props.state === 'Active' && styles.rootActive,
    ],
  };

  const navItem = (
    <View
      style={[classes.root, props.rootStyles]}
      //@ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}>
      <View style={styles.iconText}>
        <View style={styles.icon}>{props.icon}</View>
        <Text style={styles.label}>{props.label}</Text>
      </View>
      {props.activeMembers && (
        <View style={styles.activeMembers}>
          <View style={styles.layout}>
            <Text style={styles.$1}>{props.memberCount}</Text>
          </View>
        </View>
      )}
      {props.new && (
        <View style={styles.newIndicator}>
          <View style={styles.rectangle$387} />
        </View>
      )}
      {props.endIcon && props.endIcon}
      {isHover && props.hoverIcon}
    </View>
  );

  return props.link ? (
    <Pressable onPress={() => navigate(props.link)}>{navItem}</Pressable>
  ) : (
    navItem
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 2,
    paddingLeft: 14,
    paddingRight: 4,
    marginHorizontal: 15,
    borderRadius: 3,
  },
  rootActive: {
    backgroundColor: theme.colors.neutral.$3,
  },
  rootHover: {
    backgroundColor: theme.colors.neutral.$2Base,
  },
  iconText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    flex: 1,
    paddingVertical: 4,
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 4,
  },
  label: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$white,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  activeMembers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 10,
  },
  layout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
    paddingVertical: 2,
    paddingLeft: 4,
    paddingRight: 6,
    backgroundColor: theme.colors.neutral.$1,
    borderRadius: 4,
    borderColor: '#3b414e',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  $1: {
    fontSize: 10,
    fontWeight: '400',
    textAlign: 'center',
    color: theme.colors.neutral.$white,
  },
  newIndicator: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 10,
  },
  rectangle$387: {
    display: 'flex',
    flexDirection: 'column',
    width: 8,
    height: 8,
    borderRadius: 1.5,
  },
});
