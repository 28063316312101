import theme from 'config/theme';
import {FolderIcon} from 'interface/base/FolderIcon';
import {WorkspaceBadge} from 'interface/base/WorkspaceBadge';
import {useEffect, useMemo, useState} from 'react';
import {Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import {selectors as appSelectors} from 'store/slices/app';
import {ActivityTrayTab, EntityGroupType} from 'store/slices/app/types';
import {selectors} from 'store/slices/files';
import {selectors as workspaceSelectors} from 'store/slices/workspace';
import {FileType, typeToIcon} from 'utils/fast/files';
import {PreviewSidePanelTab} from './PreviewSidePanel';
import {WorkspaceActivityTrayHeader} from './WorkspaceActivityTrayHeader';
import {WorkspaceActivityTrayTimeline} from './WorkspaceActivityTrayTimeline';

export interface WorkspaceActivityTrayProps {
  workspaceId: string;
}

const MIN_SCREEN_WIDTH_TO_AUTO_OPEN = 1300;

export function WorkspaceActivityTray(props: WorkspaceActivityTrayProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(
        slices.app.actions.toogleActivityTray({
          isManual: false,
          isOpen: window.innerWidth >= MIN_SCREEN_WIDTH_TO_AUTO_OPEN,
        }),
      );
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {isOpen, tab} = useSelector(appSelectors.activityTrayInfo);

  return (
    isOpen && (
      <View style={styles.root}>
        <WorkspaceActivityTrayHeader />
        <FolderName workspaceId={props.workspaceId} />
        <ScrollView>
          {tab === ActivityTrayTab.Activity && (
            <WorkspaceActivityTrayTimeline />
          )}
        </ScrollView>
      </View>
    )
  );
}

function FolderName(props: {workspaceId: string}) {
  const currentGroup = useSelector(appSelectors.currentGroup);
  const currentWorkspace = useSelector(
    workspaceSelectors.getWorkspaceByAlt(props.workspaceId),
  );
  const item = useSelector(selectors.getItem(currentGroup?.id ?? ''));
  const isWorkspace = currentGroup?.type === EntityGroupType.Workspace;

  const iconSize = 20;

  return (
    <View style={styles.folderName}>
      {isWorkspace ? (
        <WorkspaceBadge id={currentWorkspace.id} size={iconSize} />
      ) : (
        <FolderIcon size={iconSize} />
      )}
      <Text style={styles.name}>
        {item ? item.name : currentWorkspace.name}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 400,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.colors.neutral.$12,
    borderLeftWidth: StyleSheet.hairlineWidth,
    backgroundColor: theme.colors.neutral.$white,
  },
  name: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    marginStart: 10,
    color: theme.colors.neutral.$2Base,
  },
  nameContainer: {
    paddingHorizontal: 20,
    paddingBottom: 5,
    paddingTop: 15,
  },
  folderName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingStart: 20,
  },
});
