import {t} from '@lingui/macro';
import {Avatar} from 'interface/base/Avatar';
import {AppActivityItem} from 'interface/stacks/app/AppActivityItem';
import {StyleSheet, View} from 'react-native';
import {PreviewSidePanelTimelineDate} from './PreviewSidePanelTimelineDate';
import {PreviewSidePanelTimelineHeader} from './PreviewSidePanelTimelineHeader';
import type {Variant} from './base/consts';

type Props = {
  variant?: Variant;
};

export function WorkspaceActivityTrayTimeline({variant}: Props) {
  return (
    <View style={styles.root}>
      <PreviewSidePanelTimelineHeader text={t`Activity`} variant={variant} />
      <PreviewSidePanelTimelineDate label={t`Today`} variant={variant} />
      <AppActivityItem
        connector
        time="1 minute ago"
        name="Jim Halpert"
        isUploadAction
        destFolderName="Drafts"
        avatar={<Avatar initials="jh" color={'#FFC804'} />}
        variant={variant}
      />
      <AppActivityItem
        time="10:25 AM"
        name="Bill Lumbergh"
        avatar={<Avatar initials="bl" color={'#10EB9C'} />}
        customAction="Renamed this file from office_building.jpg to Office at Sunset.jpg"
        variant={variant}
      />
      <PreviewSidePanelTimelineDate label={t`April 17th`} variant={variant} />
      <AppActivityItem
        isMoveAction
        fromFolderName="Drafts"
        destFolderName="Marketing"
        time="April 17th"
        name="Michael Scott"
        avatar={<Avatar initials="ms" color={'#6524FF'} />}
        variant={variant}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
    marginTop: 10,
  },
});
