export const ROUTES = {
  // The user is logged into the application and having the subdomain or organization does not matter
  LOGGED_IN: {
    INVITE: 'invite',
    REQUEST_ACCESS: 'request-access',
    ACCESS: 'access',
  },
  // The user is logged into the application and have a selected organization
  LOGGED_IN_WITH_ORG: {
    INBOX: 'inbox',
    SETTINGS: 'settings',
    BROWSE_WORKSPACES: 'browse-workspaces',
    ARCHIVED_WORKSPACES: 'archived-workspaces',
    WORKSPACE: 'workspace',
    SHARED: 'shared',
    PREVIEW: 'preview',
    UPGRADE_DOWNGRADE: 'upgrade-downgrade',
    CANCEL_SUBSCRIPTION: 'cancel-subscription',
    RENEW_SUBSCRIPTION: 'renew-subscription',
    RECENTS: 'recents',
  },
  // The user is logged into the application but didn't select any organziation yet
  LOGGED_IN_WITHOUT_ORG: {
    TO_SUBDOMAIN: 'to-subdomain',
    ONBOARDING: 'onboarding',
    ORGANIZATION_SELECT: 'organization-select',
    VERIFY_EMAIL: 'account/verify_email',
    EMAIL_VERIFICATION: 'email-verification',
  },
  // The user is logged into the application with a subdomain in the URL
  LOGGED_IN_WITH_SUBDOMAIN: {
    EMAIL_CHECK: 'email-check',
    FROM_SUBDOMAIN: 'from-subdomain',
  },
  // The user is not logged into the application yet
  NOT_LOGGED_IN: {
    SSO_GOOGLE: 'signin/google',
    SSO_FACEBOOK: 'signin/facebook',
    SSO_MICROSOFT: 'signin/microsoft',
    SIGNIN: 'signin',
    SIGNUP: 'signup',
    EMAIL_SIGNIN: 'signin/email',
    EMAIL_SIGNUP: 'signup/email',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    LOGOUT: 'logout',
  },
  LOGGED_OR_NOT_LOGGED: {
    JOIN: 'join',
    QUICK_SHARE: 'quickshare',
  },
};

export const WORKSPACE_ROOT = 'root';
export const WORKSPACE_TRASH = 'trash';

export const WORKSPACE_STORAGE = 'storage';
export const WORKSPACE_PREVIEW = 'preview';
export const WORKSPACE_SHARED = 'shared';
export const WORKSPACE_SHARED_ARCHIVED = 'shared-archived';

export const STORAGE_ROOT = 'root';

export const SHARED_STATISTICS = 'statistics';
