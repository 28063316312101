import theme from 'config/theme';
import type {BillingUsageStorageWorkspace} from 'fast-sdk/src/api/billing/consts';
import {WorkspaceBadge} from 'interface/base/WorkspaceBadge';
import type React from 'react';
import {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {bytesize} from 'utils/common/data';
import type {Column} from '../../base/SettingsTable';
import SettingsTableDropdown from './SettingsTableDropdown';

type Props = {
  workspace: BillingUsageStorageWorkspace;
  columns: Array<Column>;
};

const WorkspaceStorageRow = ({workspace, columns}: Props) => {
  const usage = useMemo(
    () => `${bytesize(workspace.usage?.bytes, 1)}`,
    [workspace.usage?.bytes],
  );

  return (
    <View style={styles.row}>
      <View style={[styles.workspace, {width: columns[0].width}]}>
        <WorkspaceBadge id={workspace.id} />
        <Text style={styles.name}>{workspace.name}</Text>
      </View>
      <View style={{width: columns[1].width}}>
        {workspace.shares?.length ? (
          <SettingsTableDropdown
            rootStyle={styles.dropdown}
            label={`${workspace.shares.length} Shares`}
            items={workspace.shares.map(share => ({
              text: `${share.title} ${share.usage?.bytes > 0 ? `(${bytesize(share.usage?.bytes, 0)})` : ''}`,
              command: share.id,
              disabled: true,
              icon: (
                <Icon name="folder" size={22} color={theme.colors.brand.$5} />
              ),
            }))}
          />
        ) : (
          <Text style={styles.text}>---</Text>
        )}
      </View>
      <View style={{width: columns[2].width}}>
        <Text style={styles.text}>{usage}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    height: 54,
    paddingHorizontal: 10,
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  workspace: {
    flexDirection: 'row',
    gap: 10,
  },
  name: {
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  date: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  text: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  dropdown: {
    width: 102,
  },
  dropdownItem: {
    flexDirection: 'row',
    gap: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    whiteSpace: 'nowrap',
  },
  dropdownItemText: {
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});

export default WorkspaceStorageRow;
