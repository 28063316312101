import TimeDisplay from 'interface/stacks/content-viewer/components/control-bar/TimeDisplay';
import ZoomInButton from 'interface/stacks/content-viewer/components/control-bar/ZoomInButton';
import ZoomOutButton from 'interface/stacks/content-viewer/components/control-bar/ZoomOutButton';
import ZoomTextButton from 'interface/stacks/content-viewer/components/control-bar/ZoomTextButton';
import type {Zoom} from 'interface/stacks/content-viewer/hooks/useZoom';
import {StyleSheet} from 'react-native';

type Props = {
  zoom: Zoom;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleLastZoom: () => void;
};

const ControlsMiddle = ({
  zoom,
  handleZoomIn,
  handleZoomOut,
  handleLastZoom,
}: Props) => {
  return (
    <TimeDisplay>
      <ZoomOutButton onPress={handleZoomOut} />
      <ZoomTextButton zoom={zoom} handleLastZoom={handleLastZoom} />
      <ZoomInButton onPress={handleZoomIn} />
    </TimeDisplay>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});

export default ControlsMiddle;
