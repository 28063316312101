import {t} from '@lingui/macro';
import {Loading} from 'interface/base/Loading';
import useBillingDetails from 'interface/base/hooks/useBillingDetails';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {View} from 'react-native';
import {PageTitle} from '../../base/PageTitle';
import BillingInfo from './BillingInfo';
import StorageInfo from './StorageInfo';

const CURRENCY_SYMBOL = {
  usd: '$',
};

const getCurrencySymbol = (currency: string) => {
  return CURRENCY_SYMBOL[currency] || currency;
};

type Props = {
  handleScrollToMembers: () => void;
  handleScrollToWorkspaces: () => void;
};

const WorkspaceBilling = ({
  handleScrollToMembers,
  handleScrollToWorkspaces,
}: Props) => {
  const {subdomain} = useSubDomain();
  const {billingStatus, loading} = useBillingDetails(subdomain);

  return (
    <View>
      <PageTitle text={t`Billing`} />
      {billingStatus?.current_plan && !loading ? (
        <View>
          <BillingInfo
            planType={billingStatus.current_plan?.title}
            periodEnd={new Date(billingStatus.subscription.current_period_end)}
            formattedPricePerUser={`${getCurrencySymbol(
              billingStatus.customer.currency,
            )}${billingStatus.current_plan.pricing.meters.users.price_meter}`}
            billableUsers={billingStatus.current_plan.pricing.meters.users.unit}
            handleScroll={handleScrollToMembers}
          />
          <StorageInfo
            used={billingStatus.current_plan.pricing.meters.storage.unit}
            total={billingStatus.current_plan.pricing.meters.storage.free_units}
            totalSizeUnit={
              billingStatus.current_plan.pricing.meters.storage.unit_desc
            }
            handleScroll={handleScrollToWorkspaces}
          />
        </View>
      ) : (
        <Loading />
      )}
    </View>
  );
};

export default WorkspaceBilling;
