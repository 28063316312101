import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Avatar} from 'interface/base/Avatar';
import {IconButton} from 'interface/base/IconButton';
import {useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as settings from 'store/slices/settings';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import {AppSideNavOrgMenu} from './AppSideNavOrgMenu';

/**
 * Sidenav header subcomponent
 */
export function AppSideNavHeader() {
  const organization = useSelector(user.selectors.getSelectedOrganization);
  const organizationLogo = useSelector(settings.selectors.getOrganizationLogo);
  const userDetails = useSelector(user.selectors.getUserDetails);

  const [isOrgMenuOpen, setIsOrgMenuOpen] = useState(false);

  const openOrgMenu = () => {
    setIsOrgMenuOpen(true);
  };

  const closeOrgMenu = () => {
    setIsOrgMenuOpen(false);
  };

  const isSharedWithMe = !organization;
  const logo = isSharedWithMe ? userDetails?.profile_pic : organizationLogo;
  const initials = createInitials(
    isSharedWithMe
      ? `${userDetails?.first_name}${userDetails?.last_name}`
      : organization?.name,
  );
  const color = isSharedWithMe
    ? userDetails?.color || getHashColor(userDetails?.id)
    : organization.accent_color?.color || getHashColor(organization.id);

  return (
    <Popup
      isOpen={isOrgMenuOpen}
      close={closeOrgMenu}
      placement="right-start"
      triggerElement={
        <Pressable style={styles.root} onPress={openOrgMenu}>
          <View style={styles.orgInfo}>
            <Avatar
              color={color}
              initials={initials}
              photo={logo}
              hideBorder
              size={3}
              shapeKind="rounded-square"
            />
            <Text style={styles.name}>
              {organization?.name || 'Shared with Me'}
            </Text>
          </View>
          <IconButton
            buttonStyle={styles.menuIconButton}
            buttonHoverStyle={styles.menuIconButtonHovered}
            onPress={openOrgMenu}
            icon={
              <Icon
                name="keyboard-arrow-down"
                size={20}
                color={theme.colors.neutral.$6}
              />
            }
          />
        </Pressable>
      }>
      <AppSideNavOrgMenu
        name={organization?.name}
        logo={logo}
        close={closeOrgMenu}
      />
    </Popup>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    padding: 10,
    backgroundColor: theme.colors.neutral.$1,
  },
  orgInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    flex: 1,
  },
  name: {
    height: 22,
    lineHeight: 22,
    marginLeft: 8,
    fontSize: 14,
    fontWeight: '700',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$white,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  menuIconButton: {
    minWidth: 0,
  },
  menuIconButtonHovered: {
    backgroundColor: theme.colors.neutral.$2Base,
  },
});
