import analytics from 'extensions/analytics';

export function reporter(_store: any) {
  return {
    initReporter: (_state: any) => {
      analytics.start();
    },
    pluginReporter: (_store: any) => (next: any) => (action: any) => {
      analytics.log(action.type);
      try {
        return next(action);
      } catch (error) {
        analytics.notify(error);
      }
    },
  };
}
