import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {AppUserAvatar} from 'interface/stacks/app/AppUserAvatar';
import {useRef, useState} from 'react';
import {
  type NativeSyntheticEvent,
  StyleSheet,
  Text,
  TextInput,
  type TextInputKeyPressEventData,
  View,
} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import type {Share} from 'store/slices/shared/types';
import useShareComments from '../hooks/useShareComments';
import {ShareComment} from './ShareComment';

export default function Comments({share}: {share: Share}) {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const {addComment, addReply} = useShareComments({share});

  const [comment, setComment] = useState<string>();
  const [replyMode, setReplyMode] = useState<boolean>(false);
  const [commentId, setCommentId] = useState<string>();

  const inputRef = useRef<TextInput>();

  const action = () => {
    if (replyMode && commentId) {
      addReply(comment, commentId);
      setReplyMode(false);
      setCommentId(undefined);
    } else {
      addComment(comment);
    }
    setComment('');
  };

  return (
    <View style={[styles.root]}>
      <View style={styles.header}>
        <Text style={styles.headerText}>
          <Trans>Comments</Trans>
        </Text>
      </View>
      <View style={styles.commentsContainer}>
        <AppUserAvatar />
        <TextInput
          placeholder={
            isLoggedIn ? 'Add a comment...' : 'Sign in to add a comment...'
          }
          placeholderTextColor={theme.colors.neutral.$5}
          style={[styles.input]}
          value={comment}
          onChangeText={setComment}
          onKeyPress={(e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
            if (e.nativeEvent.key === 'Enter') {
              action();
            }
          }}
          editable={isLoggedIn}
          ref={inputRef}
        />
      </View>
      <View style={{paddingTop: 30}}>
        {share.commentsList?.map(comment => (
          <ShareComment
            key={comment.content + comment.sender.email}
            comment={comment}
            setReplyMode={(mode: boolean, commentId: string) => {
              setReplyMode(mode);
              setCommentId(commentId);
              if (inputRef?.current) {
                inputRef.current.focus();
              }
            }}
          />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '1rem',
  },
  headerText: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  commentsContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    width: '100%',
    alignSelf: 'stretch',
    alignItems: 'center',
    borderRadius: 7,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
  },
  input: {
    marginLeft: '1rem',
    width: '100%',
    height: 28,
    paddingLeft: 5,
    outlineStyle: 'none',
  },
});
