import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Prompt} from 'interface/base/Prompt';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import StatusIndicator from './StatusIndicator';
import type {Status, StatusItem} from './hooks/useSystemStatus';

interface Props {
  overallStatus: Status;
  statuses: Array<StatusItem>;
  label: string;
  onClose: () => void;
}

export default function StatusListModal({
  overallStatus,
  label,
  statuses,
  onClose,
}: Props) {
  return (
    <Prompt
      rootStyle={styles.root}
      buttons={[
        {
          mode: 'primary',
          text: t`Close`,
          onPress: onClose,
          buttonStyle: styles.modalButton,
        },
      ]}>
      <View style={styles.modalTitle}>
        <Text style={styles.modalTitleText}>System status:</Text>
        <View style={styles.modalTitleIndicator}>
          <StatusIndicator status={overallStatus} width={12} height={12} />
        </View>
        <Text style={styles.modalTitleText}>{label}</Text>
      </View>

      <View style={[styles.tableRow, styles.tableHeader]}>
        <Text style={styles.tableHeaderCell}>System</Text>
        <Text style={styles.tableHeaderCell}>Status</Text>
        <Text style={styles.tableHeaderCell}>Last Update</Text>
      </View>

      <ScrollView>
        {statuses.map((status, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCellName}>{status.name}</Text>
            <View style={styles.tableStatusContent}>
              <StatusIndicator status={status.status} />
              <Text style={styles.tableCellStatus}>{status.statusText}</Text>
            </View>
            <Text style={styles.tableCellLastUpdate}>{status.updatedAt}</Text>
          </View>
        ))}
      </ScrollView>
    </Prompt>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 600,
    maxWidth: 600,
    maxHeight: 600,
  },
  modalButton: {
    width: 57,
    height: 36,
    minWidth: 57,
    minHeight: 36,
  },
  modalTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  modalTitleText: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
  },
  modalTitleIndicator: {
    marginLeft: 10,
    marginRight: 5,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
  },
  tableHeader: {
    backgroundColor: theme.colors.neutral.$14,
  },
  tableHeaderCell: {
    flex: 1,
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
    paddingVertical: 4,
  },
  tableCellName: {
    flex: 1,
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 20,
    paddingVertical: 4,
  },
  tableStatusContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  tableCellStatus: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    paddingVertical: 4,
  },
  tableCellLastUpdate: {
    flex: 1,
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    paddingVertical: 4,
    color: theme.colors.neutral.$5,
  },
});
