import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {Navigate, useNavigate, useParams} from 'extensions/navigation';
import {InvitationTypes} from 'fast-sdk/src/api/share/consts';
import useUserWorkspaces from 'interface/stacks/workspace/hooks/useUserWorkspaces';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import AddProfilePicture from '../AddProfilePicture';
import InviteCoworkers from '../InviteCoworkers';
import OnboardError from '../OnboardError';
import OrganizationName from '../OrganizationName';
import Subdomain from '../Subdomain';
import useUpdateUser from '../hooks/useUpdateUser';
import LayoutOnboard from '../layout/LayoutOnboard';
import {
  OnboardSteps,
  getCurrentStepIndex,
  getStepFromIndex,
  totalSteps,
} from '../lib/consts';
import {Payment} from '../payment';

function RouteOnboard() {
  const params = useParams<'step'>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const step = params.step as OnboardSteps;

  const startOnboardingEmail = useSelector(
    onboarding.selectors.getStartOnboardingEmail,
  );
  const userDetails = useSelector(user.selectors.getUserDetails);
  const error = useSelector(onboarding.selectors.getError);
  const onboardingSubdomain = useSelector(onboarding.selectors.getSubdomain);
  const isInvitationFlow = useSelector(app.selectors.getIsInvitationFlow);
  const invitation = useSelector(app.selectors.getCurrentJoinInvitation);

  const {updateUser} = useUpdateUser(true, {lazy: true});
  const {workspaces} = useUserWorkspaces();

  const next = () => {
    if (stepIndex !== totalSteps) {
      const nextStepIndex = Math.min(stepIndex + 1, totalSteps);
      navigate(
        `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${getStepFromIndex(nextStepIndex)}`,
      );
    }
  };

  const done = async () => {
    if (!isInvitationFlow) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`);
    }

    const {org, entity_type} = invitation;

    const isOrgInvitation = entity_type === InvitationTypes.Organization;
    const isWorkspaceInvitation = entity_type === InvitationTypes.Workspace;

    const toWorkspaceRoute = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`;
    const toOrganizationRoute = `/${ROUTES.LOGGED_IN_WITHOUT_ORG.TO_SUBDOMAIN}?orgDomain=${org.domain}`;
    const toOrganizationSelectRoute = `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`;

    const newRoute = isWorkspaceInvitation
      ? toWorkspaceRoute
      : isOrgInvitation
        ? toOrganizationRoute
        : toOrganizationSelectRoute;

    await updateUser();
    dispatch(app.default.actions.clearCurrentJoinInvitation());
    dispatch(
      app.default.actions.setIsInvitationFlow({isInvitationFlow: false}),
    );

    navigate(newRoute);
  };

  useEffect(() => {
    if (
      startOnboardingEmail &&
      startOnboardingEmail !== userDetails.email_address
    ) {
      dispatch(onboarding.default.actions.restartOnboarding());
    }
    dispatch(
      onboarding.default.actions.setStartOnboardingEmail({
        email: userDetails.email_address,
      }),
    );
  }, [startOnboardingEmail]);

  const stepIndex = useMemo(() => getCurrentStepIndex(step), [step]);

  return (
    <LayoutOnboard step={step} stepIndex={stepIndex} next={next}>
      {error ? (
        <OnboardError />
      ) : (
        <>
          {step === OnboardSteps.Profile && (
            <AddProfilePicture next={isInvitationFlow ? done : next} />
          )}
          {step === OnboardSteps.Organization && (
            <OrganizationName next={next} />
          )}
          {step === OnboardSteps.Subdomain && <Subdomain next={next} />}
          {step === OnboardSteps.Payment && <Payment />}
          {step === OnboardSteps.Invites && <InviteCoworkers next={done} />}
          {(stepIndex < 1 || stepIndex > totalSteps) && (
            <Navigate
              to={`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Profile}`}
            />
          )}
        </>
      )}
    </LayoutOnboard>
  );
}

export default RouteOnboard;
