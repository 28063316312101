import {format} from 'date-fns';
import {CustomDatePicker} from 'interface/base/CustomDatePicker';
import {ModalSection} from 'interface/base/Modal';
import {useEffect, useState} from 'react';
import {View} from 'react-native';
import type {Share} from 'store/slices/shared/types';

interface Props {
  share: Share;
  updateShare: (updatedShare: Partial<Share>) => void;
  isEditable: boolean;
}

export function ExpirationDate({share, updateShare, isEditable}: Props) {
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(
    undefined,
  );

  const handleSetExpirationDate = (newExpirationDate: Date | undefined) => {
    setExpirationDate(newExpirationDate);
    updateShare({
      expires: newExpirationDate
        ? format(newExpirationDate, 'yyyy-MM-dd hh:mm:ss')
        : undefined,
    });
  };

  useEffect(() => {
    setExpirationDate(share?.expires ? new Date(share.expires) : undefined);
  }, [share?.expires]);

  return (
    <View>
      <ModalSection title="Expiration Date" />
      <CustomDatePicker
        date={expirationDate}
        setDate={handleSetExpirationDate}
        emptyDateText="No expiration"
        readonly={!isEditable}
      />
    </View>
  );
}
