import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {Loading} from 'interface/base/Loading';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {isSubdomainValid} from 'utils/common/platform';
import {WrongEmail} from './WrongEmail';

const isEmailValid = (userEmail: string, subDomain: string) => {
  const emailOrigin = userEmail.split('@')[1];
  if (emailOrigin === subDomain) return true;
  return false;
};

export function CheckEmail() {
  const userDetails = useSelector(user.selectors.getUserDetails);
  const userEmail = userDetails?.email_address;

  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const {subdomain} = useSubDomain();

  useEffect(() => {
    if (
      isSubdomainValid(subdomain) &&
      userEmail &&
      isEmailValid(userEmail, subdomain)
    ) {
      return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`);
    }

    setLoading(false);
  }, [subdomain, userEmail]);

  return loading ? (
    <Loading />
  ) : (
    <WrongEmail
      subDomain={subdomain}
      userEmail={userEmail}
      organizationName={''}
    />
  );
}
