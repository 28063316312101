import {IconButton} from 'interface/base/IconButton';
import useFileMenu from 'interface/stacks/content-viewer/hooks/useFileMenu';
import {Timer} from 'interface/stacks/workspace/storage/quick-share/Timer';
import type React from 'react';
import ActionIcon from './ActionIcon';
import {styles} from './styles';
import type {ActionButtonProps} from './types';

export const QuickShareActions: React.FC<ActionButtonProps> = ({
  toggleSidebar,
  file,
}) => {
  const {downloadItem} = useFileMenu(file);

  return (
    <>
      <Timer quickShare={file.quickShare} kind="inline" />
      <IconButton
        buttonStyle={styles.iconButton}
        iconStyle={styles.icon}
        buttonHoverStyle={styles.iconButtonHover}
        onPress={toggleSidebar}
        icon={<ActionIcon name="information-outline" />}
      />
      <IconButton
        buttonStyle={styles.shareButton}
        iconStyle={styles.icon}
        buttonHoverStyle={styles.shareButtonHover}
        textStyle={styles.shareButtonText}
        text="Download"
        onPress={downloadItem}
        icon={<ActionIcon name="tray-arrow-down" />}
      />
    </>
  );
};
