import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {useModal} from 'extensions/viewport/useModal';
import {Avatar} from 'interface/base/Avatar';
import {Divider} from 'interface/base/Divider';
import {DropDownItem} from 'interface/base/DropDownItem';
import RadioButton from 'interface/base/RadioButton';
import useHasExternalWorkspacesShares from 'interface/base/hooks/useHasExternalWorkspacesShares';
import useHost from 'interface/base/hooks/useHost';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {InviteMembersModal} from 'interface/stacks/settings/components/members/InviteMembersModal';
import {WorkspaceCreate} from 'interface/stacks/workspace/dialogs/WorkspaceCreate';
import {Pressable, ScrollView, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import {isSubdomainValid} from 'utils/common/platform';
import AppSideOrgSelector from './AppSideOrgSelector';

interface AppSideNavOrgMenuProps {
  logo: string;
  name: string;
  close: () => void;
}

/**
 * Org Dropdown Menu
 * Organization dropdown menu, accessed from the sidebar nav header.
 */
export function AppSideNavOrgMenu(props: AppSideNavOrgMenuProps) {
  const selectedOrganization = useSelector(
    user.selectors.getSelectedOrganization,
  );
  const organizations = useSelector(user.selectors.getOrganizationsList);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const isAdminOrOwner = useSelector(app.selectors.getUserIsAdminOrOwner);

  const modal = useModal();
  const navigate = useNavigate();

  const {subdomain} = useSubDomain();
  const {domain, protocol, loaded} = useHost();
  const {hasExternalWorkspacesOrShares} = useHasExternalWorkspacesShares();

  const navigateToSettings = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.organization}`,
    );
    props.close();
  };

  const navigateToSharedWithMe = () => {
    if (!loaded) {
      return;
    }

    if (isSubdomainValid(subdomain)) {
      const url = `${protocol}://${domain}/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`;
      window.location.href = url.replace(subdomain, 'go');
    } else {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ORGANIZATION_SELECT}`);
    }

    props.close();
  };

  const navigateToOrg = (orgDomain: string) => {
    if (subdomain === orgDomain) {
      return;
    }

    if (isSubdomainValid(subdomain)) {
      const url = `${protocol}://${domain}/${ROUTES.LOGGED_IN_WITHOUT_ORG.TO_SUBDOMAIN}?orgDomain=${orgDomain}`;
      window.location.href = url.replace(subdomain, 'go');
    } else {
      navigate(
        `/${ROUTES.LOGGED_IN_WITHOUT_ORG.TO_SUBDOMAIN}?orgDomain=${orgDomain}`,
      );
    }

    props.close();
  };

  const openInviteModal = () =>
    modal.open(<InviteMembersModal orgName={props.name} onSubmit={() => {}} />);
  const openCreateWorkspace = () =>
    modal.open(<WorkspaceCreate close={modal.close} />);

  const isSharedWithMe = !selectedOrganization;

  return (
    <View style={styles.root}>
      <ScrollView style={styles.orgSelectContainer}>
        {organizations?.map(org => (
          <AppSideOrgSelector
            key={org.id}
            org={org}
            isSelected={org.id === selectedOrganization?.id}
            onPress={() => navigateToOrg(org.domain)}
          />
        ))}
        {hasExternalWorkspacesOrShares && (
          <Pressable
            style={[styles.logoName, isSharedWithMe && styles.selected]}
            onPress={navigateToSharedWithMe}>
            <View style={styles.left}>
              <Avatar
                photo={userDetails?.profile_pic}
                color={userDetails?.color ?? getHashColor(userDetails?.id)}
                initials={`${userDetails?.first_name?.[0]}${userDetails?.last_name?.[0]}`}
                size={3}
                shapeKind="rounded-square"
              />
              <Text style={styles.orgName}>Shared with Me</Text>
            </View>
            <RadioButton checked={isSharedWithMe} />
          </Pressable>
        )}
      </ScrollView>
      <View style={[styles.divider, {paddingTop: 0}]}>
        <Divider />
      </View>
      {!isSharedWithMe && (
        <>
          <Pressable onPress={openInviteModal}>
            <DropDownItem
              label={t`Invite Teammates`}
              icon={
                <Icon
                  name="person-add"
                  size={22}
                  color={theme.colors.neutral.$6}
                />
              }
            />
          </Pressable>
          <Pressable onPress={openCreateWorkspace}>
            <DropDownItem
              label={t`Create Workspace`}
              icon={
                <Icon
                  name="add-circle"
                  size={22}
                  color={theme.colors.neutral.$6}
                />
              }
            />
          </Pressable>
        </>
      )}
      {isAdminOrOwner && (
        <Pressable onPress={navigateToSettings}>
          <DropDownItem
            label={t`Settings and Administration`}
            icon={
              <Icon name="settings" size={22} color={theme.colors.neutral.$6} />
            }
          />
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 280,
    margin: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  logo: {
    width: 50,
    height: 50,
  },
  logoName: {
    padding: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 10,
    marginHorizontal: 10,
    gap: 10,
  },
  logoImage: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.30)',
    borderStyle: 'solid',
    flexDirection: 'row',
    borderRadius: 100,
  },
  orgName: {
    color: theme.colors.neutral.$2Base,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 22,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  divider: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    paddingVertical: 7,
    paddingHorizontal: 0,
  },
  orgSelectContainer: {
    maxHeight: 400,
    width: '100%',
  },
  selected: {
    borderWidth: 1,
    borderColor: theme.colors.brand.$4Base,
    borderStyle: 'solid',
    borderRadius: 10,
    backgroundColor: theme.colors.neutral.$13,
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
});
