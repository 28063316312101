import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {Pressable, Text} from 'react-native';
import {StyleSheet} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props {
  title: string;
  link: string;
  iconName: string;
  sectionName: string;
  active?: boolean;
  cominutyIcon?: boolean;
}

/**
 * Settings side menu link button component
 */

export function SettingsGeneralMenuButtonItem(props: Props) {
  const navigate = useNavigate();
  return (
    <Pressable
      onPress={() => navigate(props.link)}
      style={[styles.root, props.active && styles.active]}>
      {props.cominutyIcon ? (
        <MCIcon
          name={props.iconName}
          size={17}
          style={[
            styles.icon,
            props.active && {color: theme.colors.neutral.$white},
          ]}
        />
      ) : (
        <Icon
          name={props.iconName}
          size={17}
          style={[
            styles.icon,
            props.active && {color: theme.colors.neutral.$white},
          ]}
        />
      )}
      <Text style={styles.text}>{props.title}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 14,
    padding: 9,
    paddingLeft: 25,
  },
  icon: {
    color: theme.colors.neutral.$5,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$white,
  },
  active: {
    backgroundColor: theme.colors.neutral.$25,
  },
});
