import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {DraggableList} from 'interface/base/DraggableList';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import * as workspace from 'store/slices/workspace';
import {AppSideNavWorkspaceItem} from './AppSideNavWorkspaceItem';

interface AppSideNavWorkspaceListProps {
  workspaces: WorkspaceListDetail[];
  isExternal?: boolean;
}

export function AppSideNavWorkspaceList({
  workspaces,
  isExternal = false,
}: AppSideNavWorkspaceListProps) {
  const dispatch = useDispatch();

  const currentUser = useSelector(user.selectors.getUserDetails);

  const handleReorder = useCallback(
    (newWorkspacesOrdered: WorkspaceListDetail[]) => {
      dispatch(
        workspace.default.actions.setWorkspaces({
          workspaces: newWorkspacesOrdered,
          preserveOrder: false,
        }),
      );
    },
    [],
  );

  const renderWorkspaceItem = useCallback(
    (item: WorkspaceListDetail) => (
      <AppSideNavWorkspaceItem
        workspace={item}
        currentUserId={currentUser.id}
        isExternal={isExternal}
      />
    ),
    [currentUser.id],
  );

  return (
    <DraggableList
      data={workspaces}
      renderItem={renderWorkspaceItem}
      onReorder={handleReorder}
      keyExtractor={item => item.id}
    />
  );
}
