import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import type React from 'react';
import {useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

type SortDirection = 'asc' | 'desc';

export type Column = {
  title: string;
  width: string;
  isSortable?: boolean;
  sort?: (direction: SortDirection) => (a: any, b: any) => number;
};

type SortInfo = {
  direction: SortDirection;
  sort: (direction: SortDirection) => (a: any, b: any) => number;
};

export type SortColumnMap = Record<string, SortInfo>;

type Props = {
  columns: Array<Column>;
  renderRows: (
    sortedColumns: SortColumnMap,
    shouldSort: boolean,
  ) => React.ReactNode;
  onShowMore?: () => void;
};

const SettingsTable = ({columns, renderRows, onShowMore}: Props) => {
  const [sortedColumns, setSortedColumns] = useState<SortColumnMap>(
    columns.reduce((acc, column) => {
      if (column.isSortable) {
        acc[column.title] = {
          direction: 'asc',
          sort: column.sort,
        };
      }
      return acc;
    }, {}),
  );

  const handleSort = (title: string) => {
    setSortedColumns(prev => {
      const newDirection = prev[title].direction === 'asc' ? 'desc' : 'asc';
      return {
        ...prev,
        [title]: {
          ...prev[title],
          direction: newDirection,
        },
      };
    });
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        {columns.map((column: Column) => (
          <View
            key={column.title}
            style={[styles.headerColumn, {width: column.width}]}>
            <Text key={column.title} style={styles.headerColumnText}>
              {column.title}
            </Text>
            {column.isSortable && (
              <Pressable onPress={() => handleSort(column.title)}>
                <Icon
                  name="arrow-down-thin"
                  size={20}
                  color={theme.colors.neutral.$5}
                  style={{
                    transform: [
                      {
                        rotate:
                          sortedColumns[column.title].direction === 'asc'
                            ? '0deg'
                            : '180deg',
                      },
                    ],
                  }}
                />
              </Pressable>
            )}
          </View>
        ))}
      </View>
      <View style={styles.body}>
        {renderRows(sortedColumns, Object.keys(sortedColumns).length > 0)}
      </View>
      {onShowMore && (
        <View style={styles.showMoreContainer}>
          <Button type="Text" label={t`Show More`} onPress={onShowMore} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  header: {
    height: 36,
    backgroundColor: theme.colors.neutral.$14,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
    paddingHorizontal: 10,
    paddingVertical: 8,
    display: 'flex',
    flexDirection: 'row',
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerColumnText: {
    fontSize: 12,
    lineHeight: 20,
    fontWeight: '700',
  },
  sortButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {},
  showMoreContainer: {
    width: 123,
  },
});

export default SettingsTable;
