import {WORKSPACE_ROOT} from 'constants/routes';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import {useGetFileDetails} from '../../hooks/useGetFileDetails';

interface UseGetSharedParentOptions {
  instanceId: string;
  fileId: string;
  isUpload?: boolean;
}

export default function useGetFileInformation(
  props: UseGetSharedParentOptions,
) {
  const {instanceId, fileId, isUpload} = props;
  const [loading, setLoading] = useState(false);
  const {fetchFileDetails} = useGetFileDetails();
  const file = useSelector(files.selectors.getItem(fileId));
  const fetchParent = useCallback(async () => {
    if (!file && fileId && fileId !== WORKSPACE_ROOT && !isUpload) {
      try {
        setLoading(true);
        await fetchFileDetails(fileId, instanceId, 'workspace');
      } finally {
        setLoading(false);
      }
    }
  }, [file, fileId, isUpload]);

  useEffect(() => {
    fetchParent();
  }, [file, fileId]);

  return {
    file,
    loading,
  };
}
