import {Trans} from '@lingui/macro';
import {Elements} from '@stripe/react-stripe-js';
import type {Stripe} from '@stripe/stripe-js';
import theme from 'config/theme';
import {PLANS_INFO} from 'interface/stacks/onboard/lib/consts';
import {PlanDetails} from 'interface/stacks/onboard/payment/PlanDetails';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {BilledDurationType} from 'store/slices/settings/types';
import {AddCard} from '../new_components/AddCard';

type Props = {
  clientSecret: string;
  totalAmount: number;
  totalPlanCost: number;
  tax: number;
  orgId: string;
  orgDomain: string;
  stripe: Stripe;
};

export function RenewCheckout({
  clientSecret,
  totalAmount,
  totalPlanCost,
  tax,
  orgId,
  orgDomain,
  stripe,
}: Props) {
  return (
    <>
      <View style={styles.warningBox}>
        <Icon
          name="warning"
          size={24}
          style={styles.warningIcon}
          color={theme.colors.neutral.$2Base}
        />
        <Text style={styles.warningText}>
          <Trans>
            Your organization does not currently have an active subscription.
            You will need to purchase a plan before continuing.
          </Trans>
        </Text>
      </View>
      <Text selectable style={styles.title}>
        <Trans>Purchase Plan</Trans>
      </Text>
      <Text selectable style={styles.content}>
        <Trans>
          Review your plan summary below, then add your payment details to
          continue with your purchase.
        </Trans>
      </Text>
      <View style={styles.container}>
        <View style={styles.paymentContainer}>
          <Elements stripe={stripe} options={{clientSecret}}>
            <AddCard stripe={stripe} orgDomain={orgDomain} orgId={orgId} />
          </Elements>
        </View>
        <View style={styles.planDetailsContainer}>
          <PlanDetails
            plan={PLANS_INFO.Business}
            unit={BilledDurationType.Monthly}
            totalAmount={totalAmount}
            totalPlanCost={totalPlanCost}
            annualDiscount={0}
            tax={tax}
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 650,
    padding: 50,
    backgroundColor: theme.colors.neutral.$white,
  },
  warningBox: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    backgroundColor: theme.colors.caution.$4Base,
    borderRadius: 4,
    marginBottom: '2rem',
  },
  warningIcon: {},
  warningText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  title: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    marginBottom: '1.5rem',
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
  },
  content: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    marginBottom: '2.5rem',
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
  },
  container: {
    flexDirection: 'row',
    gap: 50,
  },
  paymentContainer: {
    flex: 1,
  },
  planDetailsContainer: {
    flex: 1,
  },
});
