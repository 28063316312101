import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {useModal} from 'extensions/viewport/useModal';
import {getBaseOrigin} from 'fast-sdk';
import {FilePreviewState, PreviewType} from 'fast-sdk/src/api/storage/consts';
import type {OK} from 'fast-sdk/src/requests';
import {useGetFileDetails} from 'interface/stacks/workspace/hooks/useGetFileDetails';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import contentViewer, {selectors} from 'store/slices/content-viewer';
import type {FilesItem} from 'store/slices/files/types';
import {
  PREVIEW_TYPE_MAP,
  getFileTypeFromMime,
  isPreviewReady,
} from 'utils/fast/files';
import {getFileUrl, getPreviewFile} from 'utils/fast/storage';
import PreviewInfo from '../components/PreviewInfo';

const PREVIEW_ERROR_MESSAGE = 'Error: Unable to retrieve preview';

const buildAppErrorParams = (
  fileId: string,
  type: string,
  retryFn: () => Promise<OK>,
) => ({
  appError: AppErrors.FilePreviewError,
  exception: new Error(`${PREVIEW_ERROR_MESSAGE}, fileId: ${fileId}`),
  retryFn,
  dialogOptions: {
    title: `playing this ${type?.toLowerCase() || 'file'}`,
    message: PREVIEW_ERROR_MESSAGE,
  },
});

export default function useFileUrl(file: FilesItem) {
  const dispatch = useDispatch();
  const [blob, setBlob] = useState<Blob | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const {fetchFileDetails} = useGetFileDetails();
  const {id: fileId, shareId, workspaceId, quickShare} = file;
  const quickShareId = quickShare?.id;
  const instanceId = quickShareId ?? shareId ?? workspaceId;
  const instanceNs = quickShareId
    ? 'quickshare'
    : shareId
      ? 'share'
      : 'workspace';
  const type = getFileTypeFromMime(file);
  const url = useSelector(selectors.getUrl(fileId));

  const modal = useModal();

  useEffect(() => {
    const {isReady, title, description, state} = isPreviewReady(file);
    if (!isReady) {
      if (state !== FilePreviewState.ERROR) {
        return modal.open(
          <PreviewInfo
            title={title}
            description={description}
            onClose={modal.close}
          />,
        );
      }
      handleAppError(
        buildAppErrorParams(fileId, 'file', async () => {
          await fetchFileDetails(fileId, instanceId, instanceNs);
          return {result: true};
        }),
      );
    }
  }, [file]);

  useEffect(() => {
    setLoading(true);

    if (url) {
      return setLoading(false);
    }

    async function loadFile() {
      const previewType = PREVIEW_TYPE_MAP[type];

      if (!previewType) {
        const endpoint = getFileUrl(fileId, instanceId, instanceNs);
        try {
          const url = await endpoint;
          dispatch(contentViewer.actions.cacheUrl({id: fileId, url}));
        } catch (error) {
          handleAppError(
            buildAppErrorParams(fileId, type, async () => {
              const url = await endpoint;
              if (url) {
                dispatch(contentViewer.actions.cacheUrl({id: fileId, url}));
              }
              return {result: true};
            }),
          );
        } finally {
          setLoading(false);
        }
        return;
      }

      if (
        previewType === PreviewType.IMAGE ||
        previewType === PreviewType.SPREADSHEET
      ) {
        const url = await getFileUrl(fileId, instanceId, instanceNs);
        dispatch(contentViewer.actions.cacheUrl({id: fileId, url}));
        return setLoading(false);
      }

      const endpoint = getPreviewFile(
        previewType,
        fileId,
        instanceId,
        instanceNs,
      );
      const isHlsStream = previewType === PreviewType.HLSSTREAM;
      try {
        const pathOrBlob = await endpoint;
        if (!isHlsStream) {
          setBlob(pathOrBlob as Blob);
        }
        dispatch(
          contentViewer.actions.cacheUrl({
            id: fileId,
            url: isHlsStream
              ? pathOrBlob
                ? `${getBaseOrigin()}${pathOrBlob}`
                : ''
              : URL.createObjectURL(pathOrBlob as Blob),
          }),
        );
      } catch (error) {
        handleAppError(
          buildAppErrorParams(fileId, type, async () => {
            const pathOrBlob = await endpoint;
            if (!isHlsStream) {
              setBlob(pathOrBlob as Blob);
            }
            dispatch(
              contentViewer.actions.cacheUrl({
                id: fileId,
                url: isHlsStream
                  ? pathOrBlob
                    ? `${getBaseOrigin()}${pathOrBlob}`
                    : ''
                  : URL.createObjectURL(pathOrBlob as Blob),
              }),
            );
            return {result: true};
          }),
        );
      } finally {
        setLoading(false);
      }
    }

    loadFile();
  }, [fileId, instanceId, shareId, url, type]);

  return {url, loading, blob};
}
