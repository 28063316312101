import {t} from '@lingui/macro';
import {api} from 'fast-sdk';
import type {OrganizationPermission} from 'fast-sdk/src/api/organization/consts';
import {useSubDomain} from 'interface/base/hooks/useSubDomain';
import {useState} from 'react';
import {useToast} from 'react-native-toast-notifications';

export type Permission = Extract<
  OrganizationPermission,
  'admin' | 'member' | 'view' | 'owner'
>;

export const useMemberPermissions = (
  initialPermission: Permission,
  email: string,
) => {
  const [permission, setPermission] = useState<Permission>(initialPermission);
  const [isLoading, setIsLoading] = useState(false);
  const {subdomain} = useSubDomain();
  const toast = useToast();

  const updatePermission = async (newPermission: Permission) => {
    try {
      setIsLoading(true);
      const {result} = await api.organization.updateOrganizationMember(
        subdomain,
        email,
        newPermission,
      );
      if (result) {
        setPermission(newPermission);
        toast.show(t`Updated permissions for "${email}"`, {
          type: 'neutral',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {permission, isLoading, updatePermission};
};
