import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {RecyclerListView} from 'recyclerlistview/web';
import {FileListItem} from './FileListItem';
import {useFileList} from './hooks/useFileList';

import type {MultiplayerState} from 'interface/multiplayer/types';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {FileListItemLayout} from './FileListItem';

export interface FileListProps {
  view: string[];
  items: {[id: string]: FilesItem};
  layout?: FileListItemLayout;
  root?: string[];
  share?: Share;
  shareView?: boolean;
  shareds?: boolean;
  archivedShares?: boolean;
  recents?: boolean;
  onOpen?: (item: FilesItem) => void;
  renderEmpty?: () => JSX.Element;
  renderLoading?: () => JSX.Element;
  multiplayer?: MultiplayerState;
  options?: {
    hideFolders?: boolean;
    hideFiles?: boolean;
    hideShares?: boolean;
    hideGutter?: boolean;
    linkParent?: boolean;
    disableOpen?: boolean;
    disableDrag?: boolean;
    disableFocus?: boolean;
    disableSelect?: boolean;
    disableDetails?: boolean;
    disableActions?: boolean;
    disablePullToRefresh?: boolean;
    overrideWorkspaceId?: string;
    useWindowScroll?: boolean;
  };
}

export function FileList(props: FileListProps) {
  const list = useFileList(props);

  const renderFooter = useCallback(() => <View style={styles.footer} />, []);

  const renderItem = useCallback(
    (type: number, id: string, index: number, ext: typeof list.extended) => {
      if (list.refResize.current) {
        const selected =
          list.hasSelect && ext.selection && ext.selection.includes(id);
        const dragging =
          list.hasDrag && ext.dragging && ext.dragging.includes(id);
        const dropping = list.hasDrag && ext.dropTarget === id;
        const focused = list.hasFocus && ext.focused === id;
        const members = ext.members;
        const item = ext.items[id];
        const prevId = list.itemList[index - 1];
        const nextId = list.itemList[index + 1];
        const isPrevSelected =
          prevId &&
          (ext.selection?.includes(prevId) ||
            !!members?.find(e => e.selection?.includes(prevId)));
        const isNextSelected =
          nextId &&
          (ext.selection?.includes(nextId) ||
            !!members?.find(e => e.selection?.includes(nextId)));
        return (
          <FileListItem
            item={item}
            layout={type}
            workspaceId={list.workspaceTarget}
            members={members}
            focused={focused}
            selected={selected}
            dragging={dragging}
            dropping={dropping}
            isPremium={list.isPremium}
            isPrevSelected={isPrevSelected}
            isNextSelected={isNextSelected}
            linkParent={props.options?.linkParent}
            multiSelect={ext.selection.length > 0}
            disableDetails={props.options?.disableDetails}
            disableActions={props.options?.disableActions}
            tooltipPos={index === 0 ? 'bottom' : 'top'}
            shareView={props.shareView}
            share={props.share}
            shareds={props.shareds}
            recents={props.recents}
            archivedShares={props.archivedShares}
            onOpen={list.hasOpen ? list.open : list.noop}
            onFocus={list.hasFocus ? list.focus : list.noop}
            onSelect={list.hasSelect ? list.select : list.open}
            onDragStart={list.hasDrag ? list.dragDrop.start : list.noopCancel}
            onDragEnd={list.hasDrag ? list.dragDrop.end : list.noopCancel}
            onDragLeave={list.hasDrag ? list.dragDrop.leave : list.noopCancel}
            onDragEnter={
              list.hasDrag
                ? (event, target) => {
                    list.dragDrop.enter(
                      event,
                      target.type !== StorageItemType.File
                        ? target.id
                        : list.targetFolderId,
                    );
                  }
                : list.noopCancel
            }
            onDragDrop={
              list.hasDrag
                ? (event, target) => {
                    // Prevent moving files to the same parent
                    if (
                      !event?.dataTransfer?.files?.length &&
                      target.type === StorageItemType.File &&
                      target.parent === list.targetFolderId
                    )
                      return;
                    list.dragDrop.drop(
                      event,
                      target.type !== StorageItemType.File
                        ? target.id
                        : list.targetFolderId,
                      item,
                    );
                  }
                : list.noopCancel
            }
          />
        );
      }
    },
    [
      list.items,
      list.itemList,
      list.dragDrop,
      list.listWidth,
      list.hasGridView,
      list.workspaceTarget,
    ],
  );

  if (list.isLoading && props.renderLoading) return props.renderLoading();
  if (list.isEmpty && props.renderEmpty) return props.renderEmpty();

  return !list.isEmpty ? (
    <View style={{width: '100%', height: '100%'}}>
      <View
        testID="file-list-view"
        style={[styles.root, {paddingLeft: list.listGutter}]}
        removeClippedSubviews={false}
        onPointerLeave={list.syncMouseIdle}
        onPointerMove={list.syncMouse}
        onLayout={list.resize}>
        <RecyclerListView
          ref={list.refList}
          key={`file-list-view-${list.listWidth}`}
          onScroll={list.syncScrollPos}
          rowRenderer={renderItem}
          renderFooter={renderFooter}
          layoutProvider={list.listLayout}
          dataProvider={list.listData}
          extendedState={list.extended}
          useWindowScroll={props.options?.useWindowScroll}
          renderAheadOffset={
            // FIXME: this is a workaround for a bug in the RLV library.
            // Without renderAheadOffset being infinite, items will not appear
            // if the window height is too small when using useWindowScroll.
            // The library should be patched once the issue is found, this is a temp fix
            // as this will cause performance issues for large lists.
            props.options?.useWindowScroll
              ? Number.POSITIVE_INFINITY
              : screen.height / 2
          }
        />
      </View>
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  footer: {
    height: 10,
  },
});
