import theme from 'config/theme';
import {Outlet, useParams} from 'extensions/navigation';
import {Mouse} from 'interface/base/Mouse';
import {useMultiplayer} from 'interface/multiplayer/hooks/useMultiplayer';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import {useUploader} from 'interface/stacks/uploads/hooks/useUploader';
import {Uploads} from 'interface/stacks/uploads/views/Uploads';
import {FolderPicker} from 'interface/stacks/workspace/storage/FolderPicker';
import {useDragDrop} from 'interface/stacks/workspace/storage/hooks/useDragDrop';
import {ScrollView, StyleSheet, View} from 'react-native';
import {ShareViewStatus} from 'store/slices/shared/types';
import useFetchShareViewStatus from '../hooks/useFetchShareViewStatus';
import useGetShareDetails from '../hooks/useGetShareDetails';
import {useShareFilesNavigation} from '../hooks/useShareFilesNavigation';
import {ShareFooter} from './ShareFooter';
import ShareHeader from './ShareHeader';
import {ShareNotAvailable} from './ShareNotAvailable';

export interface ShareMemberListItem {
  id: string;
  permissions: string;
}

export function ShareLayout() {
  const {sharedCustomName} = useParams();
  const uploader = useUploader();
  const {share} = useGetShareDetails({
    sharedCustomName,
  });

  const dragDrop = useDragDrop({
    instanceId: share.id,
    uploader,
  });

  const shareFilesNavigation = useShareFilesNavigation();

  const {shareViewStatus} = useFetchShareViewStatus({share, sharedCustomName});

  const multiplayer = useMultiplayer(
    {
      id: share.id,
      alt: share.name,
      type: 'share',
    },
    {
      id: shareFilesNavigation.folderKey,
      type: 'folder',
    },
  );

  if (shareViewStatus === ShareViewStatus.Loading) {
    return <AppLoading />;
  }

  const isShareAvailable =
    share && shareViewStatus !== ShareViewStatus.Closed && !share.archived;

  if (!isShareAvailable) {
    return <ShareNotAvailable />;
  }

  return (
    <View style={styles.root}>
      <Mouse
        onDragEnter={e => dragDrop.enter(e, share.id)}
        onDragLeave={e => dragDrop.leave(e)}
        onDragDrop={e =>
          dragDrop.drop(
            e,
            share.id,
            undefined,
            share.id,
            shareFilesNavigation.folderKey,
          )
        }>
        <View style={styles.inner}>
          <ShareHeader share={share} members={multiplayer?.members} />
          <ScrollView contentContainerStyle={styles.outlet}>
            <Outlet context={[share, uploader, shareFilesNavigation]} />
          </ScrollView>
          <ShareFooter />
          <Uploads {...uploader} />
          <FolderPicker
            instanceNs="share"
            instanceId={share.id}
            instanceName={share.name}
          />
        </View>
      </Mouse>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.neutral.$white,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  inner: {
    flex: 1,
  },
  outlet: {
    height: '100%',
    width: '100%',
    maxWidth: 950,
    alignSelf: 'center',
    paddingVertical: 50,
  },
});
