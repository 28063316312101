import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {
  type PressableProps,
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';

type Order = 'start' | 'end' | 'middle';

export interface FolderToolbarButtonProps extends PressableProps {
  label?: string;
  icon?: JSX.Element;
  isActive?: boolean;
  order?: Order;
  customRootStyle?: ViewStyle | Array<ViewStyle>;
  customTextStyle?: TextStyle | Array<TextStyle>;
  loading?: boolean;
}

const getRootStyle = (order: Order) => {
  const orderGroup: Record<Order, object> = {
    start: styles.buttonGroupStart,
    middle: styles.buttonGroupMiddle,
    end: styles.buttonGroupEnd,
  };

  return orderGroup[order];
};

/**
 * Unique button type for the file list header, contains various states and member tracking. Use this to bulk edit styles or adjust state variants.
 */
export function FolderToolbarButton(props: FolderToolbarButtonProps) {
  return (
    <Button
      {...props}
      customRootStyle={[
        styles.root,
        getRootStyle(props.order),
        props.customRootStyle,
      ]}
      type="Secondary"
      customLeftIconStyle={styles.icon}
      customTextStyle={styles.label}
      lIcon={props.icon}
      onPress={props.onPress}
      customLoadingColor={theme.colors.neutral.$1}>
      {(props.label || props.isActive) && (
        <View style={styles.labelMembers}>
          {props.label && (
            <Text style={[styles.label, props.customTextStyle]}>
              {props.label}
            </Text>
          )}
          {props.isActive && (
            <View style={styles.members}>
              <View style={styles.dot} />
            </View>
          )}
        </View>
      )}
    </Button>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: 0,
    paddingHorizontal: 10,
  },
  buttonGroupStart: {
    borderRadius: 4,
    borderRightWidth: 0.5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonGroupMiddle: {
    borderRadius: 0,
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
  },
  buttonGroupEnd: {
    borderRadius: 4,
    borderLeftWidth: 0.5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
  },
  labelMembers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  label: {
    height: 20,
    lineHeight: 20,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    color: theme.colors.neutral.$2Base,
  },
  members: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    paddingBottom: 4,
  },
  dot: {
    display: 'flex',
    flexDirection: 'column',
    width: 7,
    height: 7,
    borderRadius: 99999,
    backgroundColor: theme.colors.neutral.$2Base,
  },
});
